import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import { Form, Input, message, AutoComplete } from 'antd';
import WhiteBox from '@/pages/business/components/whiteBox';
import TicketTitle from '@/pages/business/components/ticketTitle';
import FormContent from '@/pages/business/components/FormContent';
import AuthButton from '@/pages/business/components/AuthButton';
import BusinessProtocol from '@/pages/business/components/BusinessProtocol';

import styles from './index.less';

class RiZhao extends Component {

  constructor(props) {
    super(props);
    this.state = {
      inputSize: 'large',
      companyName: '',
      loading: false,
      // 是否同意授权
      agreeAuthProtocol: false,
      // 企业列表
      companyOptions: [],
    }

    this.infoFormRef = React.createRef();
    this.throttleTimer = null;
  }

  componentDidMount() {
    this.initQueryInfo()
    this.props.rizhaoBusinessStore.getToken();
  }

  componentWillUnmount() {
    this.props.rizhaoBusinessStore.resetStore();
  }

  initQueryInfo = () => {
    const location = this.props.location;
    if (!location.search) {
      message.error({
        content: '系统错误，请联系客户经理',
        duration: 0,
        onClick: () => message.destroy(),
      });
      this.props.rizhaoBusinessStore.setValue('hasError', true);
      return false;
    }
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (query.notifyUrl) {
      this.props.rizhaoBusinessStore.setValue('notifyUrl', decodeURIComponent(query.notifyUrl));
    }
  }

  gotoInvalidation = ({ message, code } = {}) => {
    if ( code ) {
      this.props.history.replace(`/invalidation?code=${ code }`);
      return false;
    }
    this.props.history.replace(`/invalidation?message=${ message }`);
  }

  requestCompanyList = (searchText) => {
    console.log('searchText----->', searchText);
    // 此处接口未提供，数据需要处理为自动补全内容
    return this.props.rizhaoBusinessStore.searchCompanyList({companyName: searchText})
      .then((data) => {
        this.setState({companyOptions: data});
      })
      .catch(() => {
        this.setState({
          companyOptions: [],
        })
      });
  }

  throttleCompanyChange = (searchText) => {
    const timer = this.throttleTimer ? 500 : 10;
    if (!searchText || searchText.length < 3) {
      return null;
    }
    if (this.throttleTimer) {
      clearTimeout(this.throttleTimer);
    }
    this.throttleTimer = setTimeout(() => {
      this.requestCompanyList(searchText).finally(() => clearTimeout(this.throttleTimer));
    }, timer);
  };

  handleCompanySelect = (companyName) => {
    this.setState({ companyName });
    this.handleCreditCode(companyName);
  }

  handleCreditCode = (companyName) => {

    // 此处数据未联调，需要依据后端接口字段进行联调
    this.props.rizhaoBusinessStore.getCorpInfo({companyName})
      .then((data) => {
        console.log(data);
        if (data.socialCreditIdentifier) {
          this.updateFormValue({nsrsbh: data.socialCreditIdentifier});
        }
      })
      .catch((errorData) => {
        message.error(errorData.message, 1000);
        this.updateFormValue({nsrsbh: ''});
      });
  }

  hanldeAuthProtocol = (isAgree) => {
    this.setState({ agreeAuthProtocol: isAgree });
  }

  getFormData = () => {
    const form = this.infoFormRef.current;
    return form.validateFields();
  }

  // 回填表单信息
  updateFormValue = (formValue) => {
    const form = this.infoFormRef.current;
    if (form) {
      form.setFieldsValue(formValue);
    }
  }

  onSubmit = () => {
    const rizhaoBusinessStore = this.props.rizhaoBusinessStore;
    this.getFormData()
      .then((params) => this.getFormData(params))
      .then((params) => {
        params.qymc = params.companyName;
        params.notifyUrl = rizhaoBusinessStore.notifyUrl;
        console.log('params----->', params);
        return params;
      })
      .then(params => {
        this.setState({loading: true});
        // 此处接口未联调
        rizhaoBusinessStore.getAuthorizeUrl(params)
          .then((url) => {
            console.log('url: ', url);
            window.location = `${url}&protocol=noProtocol`;
          })
          .catch(errorData => {
            const { message } = errorData;
            this.gotoInvalidation( { message });
          })
          .finally(() => {
            this.setState({loading: false});
          });
      })
      .catch((error) => {
        console.log('error----->', error);
      })
  }

  checkAgreeProtocol = (data) => {
    return new Promise((resolve, reject) => {
      const { agreeAuthProtocol } = this.state;
      if (!agreeAuthProtocol) {
        message.error('请先阅读并同意相关协议');
        reject(new Error('请先阅读并同意相关协议'));
        return false;
      }
      resolve(data);
    })
  }

  renderTaxFormitems = () => {
    const { inputSize, companyOptions } = this.state;
    return (
      <>
        <Form.Item
          key="companyName"
          colon={false}
          label="企业名称"
          name="companyName"
          rules={[
            { required: true, message: '请输入企业名称' },
          ]}
        >
          <AutoComplete
            size={inputSize}
            options={companyOptions}
            onSearch={this.throttleCompanyChange}
            onSelect={this.handleCompanySelect}
            placeholder="请输入企业名称"
            getPopupContainer={triggerNode => triggerNode.parentElement}
          />
        </Form.Item>
        <Form.Item
          key="nsrsbh"
          colon={false}
          label="统一社会信用代码"
          name="nsrsbh"
          rules={[{ required: true, message: '请输入统一社会信用代码' }]}
        >
          <Input size={inputSize} placeholder="请输入统一社会信用代码" />
        </Form.Item>
      </>
    );
  }

  render() {
    const { agreeAuthProtocol, companyName, loading } = this.state;
    const { source, hasError } = this.props.rizhaoBusinessStore;
    return (
      <>
        <WhiteBox source={source}>
          <TicketTitle {...this.props} source={source} />
          <FormContent source={source}>
            <Form
              layout="vertical"
              ref={this.infoFormRef}
            >
              {this.renderTaxFormitems()}
            </Form>
            <BusinessProtocol
              source={source}
              enableWatchScroll
              companyName={companyName}
              isAgree={agreeAuthProtocol}
              onChange={this.hanldeAuthProtocol}
            />
          </FormContent>
          <AuthButton
            block
            className={styles.btn}
            source={source}
            size="large"
            disabled={hasError}
            loading={loading}
            type="primary"
            onClick={this.onSubmit}
          >生成授权页面</AuthButton>
        </WhiteBox>
      </>
    );
  }
};

export default inject('rizhaoBusinessStore')(observer(RiZhao));
