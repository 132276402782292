import { isMobileDevice } from '@/utils'
import { DownOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import React, { useMemo } from 'react'
import { Picker } from 'react-vant';

const { Option } = Select;
const defaultColumnsFieldNames = {
  text: 'label',
  value: 'value',
}

const iconStyle = {
  fonstSize: 12,
  color: 'rgba(0, 0, 0, 0.25)'
}

const createOptions = (options) => {
  if (!options || options.length === 0) {
    return undefined;
  }
  const output = [];
  options.forEach((opt, index) => {
    if (typeof opt === 'object') {
      output.push(<Option key={index} value={opt.value} disabled={opt.disabled}>{opt.label || opt.name}</Option>);
    } else {
      output.push(<Option key={index} value={opt}>{opt}</Option>);
    }
  });
  return output;
}

/**
 * 选择器（PC端采用select，移动端采用Pick模式）
 * @param {{
 *  options: any[];
 *  value: any;
 *  placeholder?: string;
 *  columnsFieldNames?: { text: string, value: string }
 * }} props 
 * @returns 
 */
const SelectPick = (props) => {
  const isMobile = isMobileDevice();
  const {
    options,
    value,
    placeholder,
    onChange,
    columnsFieldNames = defaultColumnsFieldNames,
    ...other
  } = props;

  const columns = useMemo(() => {
    return options?.map((item) => item[columnsFieldNames?.text])
  }, [options, columnsFieldNames])

  if (isMobile) {
    return (
      <Picker
        popup={{ round: true, safeAreaInsetBottom: true }}
        value={value}
        title='请选择'
        placeholder=''
        columns={columns}
        columnsFieldNames={defaultColumnsFieldNames}
        onConfirm={(value, item, index) => {
          onChange?.(options[index]?.[columnsFieldNames.value])
        }}
      >
        {(val, _, actions) => {
          return (
            <Input
              {...other}
              onClick={() => actions.open()}
              readOnly
              placeholder={placeholder}
              value={options?.find(item => item[columnsFieldNames.value] === value)?.[columnsFieldNames.text]}
              suffix={<DownOutlined style={iconStyle} />}
            />
          )
        }}
      </Picker>
    )
  }
  return (
    <Select placeholder={placeholder} {...other} value={value} onChange={onChange}>
      { createOptions(options ) }
    </Select>
  )
}

export default SelectPick