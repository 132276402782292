import ElectronicTaxBureau from "@/pages/electronicTaxBureau";
import HxTaxBureau from "@/pages/HxTaxBureau";
import Home from "@/pages/home";
import test from "@/pages/testComponent";
import Invalidation from "@/pages/invalidation";
import AuthSuccess from "@/pages/authSuccess";
import QszcResult from "@/pages/qszcResult";
import Access from "@/pages/access";
import Trade from "@/pages/trade";
import Smeng from "@/pages/Smeng";
import HubeiTest from "@/pages/HubeiTest";

import CollectionMode from "@/pages/collectionMode";
import SelfFilling from "@/pages/selfFilling";

// 引导页
import AreaSelect from "@/pages/guide/areaSelect";
import ETB from "@/pages/guide/electronicTaxBureau";
import GuideElectronicTaxBureauOld from "@/pages/guide/electronicTaxBureau/guideElectronicTaxBureauOld";
import AuthSuccessGuide from "@/pages/guide/authSuccess";
import FuMinAuth from "@/pages/fuminAuth";
import ElectronicTaxBureauOld from "@/pages/electronicTaxBureau/electronicTaxBureauOld";
import businessRoutes from "./businessRouter";


const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  // 授权页面
  {
    path: "/authorize",
    component: ElectronicTaxBureau,
  },
  // 旧版本授权页面
  {
    path: "/auth",
    component: ElectronicTaxBureauOld,
  },
  {
    path: "/trade",
    component: Trade,
    isFullPage: true,
  },
  {
    path: "/smeng",
    component: Smeng,
  },
  {
    path: "/hxTaxBureau",
    component: HxTaxBureau,
  },
  {
    path: "/test",
    component: test,
  },
  {
    path: "/hubeiTest",
    component: HubeiTest,
  },
  // 失效页面
  {
    path: "/invalidation",
    component: Invalidation,
  },
  {
    path: "/qszcResult",
    component: QszcResult,
  },
  // 授权成功页面
  {
    path: "/authSuccess",
    component: AuthSuccess,
  },
  {
    path: "/access",
    component: Access,
  },
  {
    path: "/changeMode",
    component: CollectionMode,
  },
  {
    path: "/selfFilling",
    component: SelfFilling,
  },
  {
    path: "/guide/area-select",
    component: AreaSelect,
  },
  {
    path: "/guide/auth",
    component: ETB,
  },
  {
    path: "/guide/authOld",
    component: GuideElectronicTaxBureauOld,
  },
  {
    path: "/guide/auth-success",
    component: AuthSuccessGuide,
  },
  {
    path: "/f",
    component: FuMinAuth,
  },
  ...businessRoutes,
];

export default routes;
