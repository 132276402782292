import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

class NeiMengGu {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then((resp) => {
        const { mobile, uuid } = get(resp, 'data.data.data') || {};
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{
          label: mobile,
          value: uuid
        }];
        return this.bsrsList;
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData, options }) => {
    const { bsr } = formData;
    const { roleList = [] } = options;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { taxAccount, taxPassword } = stepOneFormData;
    const phoneNumber = roleList.filter((item) => item.value === bsr)?.[0].label
    const params =  {
      serialNo,
      province,
      creditCode: nsrsbh,
      authentication: 'tpass.sichuan.chinatax.gov.cn',
      uuid: bsr,
      phoneNumber,
      authorizationType: "SMS",
      userName: taxAccount,
      userPass: taxPassword
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const smscodeId = get(resp, 'data.data.smscode_id') || '';
        this.smscodeId = smscodeId;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData, options }) => {
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { captcha, bsr } = formData;
    const { roleList = [] } = options;
    const phoneNumber = roleList.filter((item) => item.value === bsr)?.[0].label
    const params = {
      'uuid': bsr,
      'smsCodeId': this.smscodeId,
      'captcha': captcha,
      'serialNo': serialNo,
      'creditCode': nsrsbh,
      'province': province,
      'phoneNumber': phoneNumber,
      'authentication':'tpass.sichuan.chinatax.gov.cn',
      'authorizationType': 'SMS'
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  };

}

export default new NeiMengGu();