import * as CryptoJS from 'crypto-js';

/** base64加密 */
export function encodeBase64(content) {
  if (!content || content == null) {
    return Promise.resolve('');
  }
  let stringContent = '';
  if (typeof content === 'object') {
    stringContent = JSON.stringify(content);
  } else {
    stringContent = content.toString();
  }
  return new Promise((resolve, reject) => {
    const blob = new Blob([stringContent], { type: "application/json" });
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const base64 = event.target.result;
      const base64Content = base64.split(',')[1];
      resolve(base64Content);
    };
    fileReader.readAsDataURL(blob);
    fileReader.onerror = (error) => {
      reject(error);
    }
  });
}

/** base64解密 */
export function dcodeBase64(text) {
  if (!text || text == null) {
    return Promise.resolve('');
  }
  return new Promise((resolve, reject) => {
    try {
      const words = CryptoJS.enc.Base64.parse(text);
      resolve(JSON.parse(words.toString(CryptoJS.enc.Utf8)));
    } catch (error) {
      reject(error);
    }
  });
}
