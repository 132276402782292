import { hubeiSPLoginApi } from '@/api';
import { get, set } from 'lodash';
import { message } from 'antd';
import { encryptedParams } from '@/utils';
import commonStore from './common';

class HubeiSPLogin {

  ukeyType = '';
  dwProvType = '1';
  strContainer = '';
  caDeviceNum = '';
  crosFlag = '1';
  authType = '1';
  strProvider = '';

  formValue = {};

  queryParams = {};

  /** 未安装驱动 */
  isDriveError = false;
  /** 未插入税盘 */
  isDeviceError = false;

  setValue = (key, value) => {
    set(this, key, value);
  }

  /** 检查税盘驱动 */
  checkSPDrive = (params) => {
    return hubeiSPLoginApi.checkSPDrive(params)
      .then((data) => {
        const r_data = this.gshjson(data);
        const json = JSON.parse(r_data);
        if (json.code + '' === '0') {
          this.ukeyType = 'N';
          this.dwProvType = '1';
          this.strContainer = '';
          this.caDeviceNum = json.deviceNum;
          this.isDriveError = false;
          return true;
        } else {
          this.ukeyType = 'Y';
          this.dwProvType = '0x802';
          this.strContainer = '//SM2/SM2CONTAINER0002';
          const _params = {...params, strContainer: this.strContainer, dwProvType: this.dwProvType };
          return hubeiSPLoginApi.checkSPDrive(_params)
            .then((data) => {
              const r_data = this.gshjson(data);
              const json = JSON.parse(r_data);
              if (json.code + '' === '0') {
                this.isDeviceError = false;
                this.caDeviceNum = json.deviceNum;
                return json;
              } else {
                this.isDeviceError = true;
                /** 错误后，重置初始值 */
                this.ukeyType = '';
                this.dwProvType = '1';
                this.strContainer = '';
                throw json;
              }
            })
            .catch(error => {
              this.isDeviceError = true;
              /** 错误后，重置初始值 */
              this.ukeyType = '';
              this.dwProvType = '1';
              this.strContainer = '';
              return Promise.reject(error);
            });
        }
      })
      .catch(error => {
        let message = '';
        if (error.readyState === 0) {
          message = '未检查到驱动';
        } else if (error.msg) {
          message = error.msg;
        }
        commonStore.sendErrorReport(error, {api: 'https://127.0.0.1:28000/api/getDeviceNum', params, message, method: 'POST'});
        this.isDriveError = true;
        return Promise.reject(error);
      });
  }

  /** 登录 */
  gethello = (param) => {
    return hubeiSPLoginApi.gethello(param)
      .then((data) => {
        const r_data = this.gshjson(data);
        const json = JSON.parse(r_data);
        if (json.code + '' === '0') {
          return json.clientHello;
        } else {
           throw json;
        }
      })
      .catch(error => {
        commonStore.sendErrorReport(error, {api: 'https://127.0.0.1:28000/api/clientHello', param, method: 'POST'});
        return Promise.reject(error);
      });
  }

  ajaxaction = (clienthello) => {
    const params = {
      clienthello,
      jglx: 1,
      isSwUkey: this.ukeyType,
      caServer: 'new'
    };
    return hubeiSPLoginApi.ajaxaction(params)
      .then((resp) => {
        const data = get(resp, 'data.data');
        if (this.checkResponse(data)) {
          const { serverHello, serverHello1 } = data.repData;
          return { serverHello, serverHello1 };
        } else {
          throw new Error('ajaxaction error');
        }
      })
      .catch(error => {
        const errorResponse = error.response;
        const errorMessage = get(errorResponse, 'data.message');
        message.error(errorMessage);
        commonStore.sendErrorReport(error, {api: '/api/dbc/taxation/caSign', params, method: 'POST'});
      })
  }

  getserverhello = (serverHello, serverHello1) => {
    const password = this.formValue.ca_password;
    const params = {
      strProvider: '',
      strContainer: '',
      dwProvType: this.dwProvType,
      crosFlag: this.crosFlag,
      serverHello: serverHello1,
      password
    };
    return hubeiSPLoginApi.getserverhello(params)
      .then((data) => {
        const r_data = this.gshjson(data);
        const json = JSON.parse(r_data);
        if (json.code + '' === '0') {
          // this.getnsrsbh(json.clientAuth, serverHello, serverHello1);
          return {
            clientAuth: json.clientAuth,
            serverHello,
            serverHello1
          };
        } else {
          const strmsg = json.msg;
          if (strmsg.indexOf('口令失败') > -1) {
            message.error('密码和证书不匹配');
          } else {
            message.error(strmsg);
          }
          throw new Error('getserverhello error');
        }
      })
      .catch(error => {
        commonStore.sendErrorReport(error, {api: 'https://127.0.0.1:28000/api/clientAuth', params, method: 'POST'});
        console.log('error: ', error);
      });
  }

  getnsrsbh = (clientAuth, serverHello, serverHello1) => {
    const params = {
      crosFlag: this.crosFlag,
      strProvider: this.strProvider,
      strContainer: this.strContainer,
      dwProvType: this.dwProvType,
      dwCertNum: 1,
      dwFlag: ''
    };
    return hubeiSPLoginApi.getnsrsbh(params)
      .then((data) => {
        const r_data = this.gshjson(data);
        const json = JSON.parse(r_data);
        if (json.code + '' === '0') {
          // this.ajaxaction2(clientAuth, serverHello, serverHello1, json.cert);
          return {
            clientAuth,
            serverHello,
            serverHello1,
            nsrsbhh: json.cert
          };
        } else {
          throw new Error('getnsrsbh error');
        }
      })
      .catch(error => {
        commonStore.sendErrorReport(error, {api: 'https://127.0.0.1:28000/api/readCert', params, method: 'POST'});
        console.log('error: ', error);
      });
  }

  ajaxaction2 = (clientAuth, serverHello, serverHello1, nsrsbhh) => {
    const { ca_password } = this.formValue;
    const { qymc, nsrsbh, serialNo } = this.queryParams;
    const area = '湖北(ca)';
    const params = {
      authData: clientAuth,
      caServer: 'new',
      casbh: this.caDeviceNum,
      isCa: true,
      isSwUkey: this.ukeyType,
      signData: serverHello,
      zsnsrsbh: nsrsbhh,

      companyName: qymc,
      creditCode: nsrsbh,
      password: ca_password,
      province: area,
      serialNo
    };

    return hubeiSPLoginApi.ajaxaction2(encryptedParams(params, ['password']), serialNo)
      .then((data) => data)
      .catch(error => {
        const errorData = error.response.data;
        const errorCode = errorData.errorCode;
        let errMessage = errorData.message;
        if (errorCode === 401206) {
          errMessage = '证书和企业不匹配，请插入对应的证书';
        }
        message.error(errMessage);
        commonStore.sendErrorReport(error, {api: `/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, method: 'POST'});
        return Promise.reject(error);
      })
  }

  /** 解析本地服务返回数据 */
  gshjson = (data) => {
    const frist0 = data.indexOf("{");
    const fritstStr0 = data.substring(frist0);
    const frist1 = fritstStr0.indexOf("}");
    const fritstStr1 = fritstStr0.substring(0, frist1 + 1);
    return fritstStr1;
  }

  checkResponse = (data) => {
    try {
      if ("0" === data.code || "00" === data.code) {
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

}

export default new HubeiSPLogin();
