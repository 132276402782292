import { provinceValidatorApi } from '@/api';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';
import { get } from 'lodash';
import taxHelperStore from '../taxHelper';

const MAP_BSRTYPE = {
  1: '办税员',
  2: '法定代表人',
  3: '财务负责人',
  6: '购票员'
};

class HebieOld {

  bsrs = [];
  bsrsList = [];

  /** 获取办税员列表 */
  getRoleList = (params) => {
    const _params = encryptedParams(params, ['userName']);
    const { creditCode, province, serialNo, userName, scuuid } = _params;
    return provinceValidatorApi.getBsyList({ creditCode, province, serialNo, userName, scuuid })
      .then(resp => {
        const bsrs = get(resp, 'data.data.data');
        this.bsrs = bsrs;
        this.bsrsList = bsrs.map((item, idx) => {
          return {
            value: idx,
            label: `${MAP_BSRTYPE[item.bsrType]}/${item.xm}/${item.mobile}/${item.sfzjhm}`
          }
        });
      })
      .catch( error => {
        showErrorMessage(error, '获取办税员失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData, stepOneFormData }) => {
    const { bsr, bsrPassword } = formData;
    const { taxAccount } = stepOneFormData;
    const { serialNo, province, nsrsbh } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { sfzjhm, bsrType, mobile } = selectedBsr;
    const params = {
      username: taxAccount,
      password: bsrPassword,
      creditCode: nsrsbh,
      serialNo,
      province,
      sfzjhm,
      bsrType,
      mobile,
    }
    return provinceValidatorApi.beijinPasswordnAuth(serialNo, encryptedParams(params, [ 'username', 'password', 'sfzjhm' ]))
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

};

export default new HebieOld();
