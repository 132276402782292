import { inject, observer } from 'mobx-react';
import React from 'react';
import { Form, Select } from 'antd';

import { formLayout, formSize } from '../formConponentConfig';


class Hebei extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const creditCode = this.props.creditCode;
    // 获取河北角色列表
    this.props.hebeiValidatorStore.getRoleList({ creditCode, area: '河北'});
  }

  _scpublicValidateFields = () => this.formRef.current.validateFields();

  render() {
    const { roleList } = this.props.hebeiValidatorStore;
    return (
      <Form ref={this.formRef} layout={formLayout}>
        <Form.Item
          name="info"
          label="登录角色"
          rules={[
            {required: true, message: '请选择登录角色'}
          ]}
        >
          <Select size={formSize} placeholder="请选择登录角色">
            {
              roleList.map(item => <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>)
            }
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

export default inject('hebeiValidatorStore')(observer(Hebei));
