import { selfFillingApi } from '@/api';
import { message } from 'antd';
import { get, set } from 'lodash';

class SelfFillingStore {

  taxKey = null;
  serialNo = null;
  loading = false;

  setValue = (key, value) => {
    set(this, key, value);
  }

  manualSubmit = (params) => {
    this.loading = true;
    const datas = {
      serialNo: this.serialNo,
      taxpayerType: params.taxpayerType,
      exeStandardType: params.exeStandardType,
      year: params.date && params.date.length ? params.date[0] : null,
      quarter: params.date && params.date.length ? params.date[1] : null,
      financeReport: [
        {
          fileStoreId: params.FINANCE_REPORT_0 || null,
          taxReportDetailType: params.FINANCE_REPORT_0 ? 'FINANCE_REPORT_0' : null
        },
        {
          fileStoreId: params.FINANCE_REPORT_1 || null,
          taxReportDetailType: params.FINANCE_REPORT_1 ? 'FINANCE_REPORT_1' : null
        },
        {
          fileStoreId: params.FINANCE_REPORT_2 || null,
          taxReportDetailType: params.FINANCE_REPORT_2 ? 'FINANCE_REPORT_2' : null
        },
        {
          fileStoreId: params.FINANCE_REPORT_3 || null,
          taxReportDetailType: params.FINANCE_REPORT_3 ? 'FINANCE_REPORT_3' : null
        },
      ].filter(item => item.fileStoreId !== null),
      vatReport: [
        {
          fileStoreId: params.VAT_REPORT_0 || null,
          taxReportDetailType: params.VAT_REPORT_0 ? 'VAT_REPORT_0' : null
        },
        {
          fileStoreId: params.VAT_REPORT_1 || null,
          taxReportDetailType: params.VAT_REPORT_1 ? 'VAT_REPORT_1' : null
        },
        {
          fileStoreId: params.VAT_REPORT_2 || null,
          taxReportDetailType: params.VAT_REPORT_2 ? 'VAT_REPORT_2' : null
        },
        {
          fileStoreId: params.VAT_REPORT_3 || null,
          taxReportDetailType: params.VAT_REPORT_3 ? 'VAT_REPORT_3' : null
        },
        {
          fileStoreId: params.VAT_REPORT_4 || null,
          taxReportDetailType: params.VAT_REPORT_4 ? 'VAT_REPORT_4' : null
        },
        {
          fileStoreId: params.VAT_REPORT_5 || null,
          taxReportDetailType: params.VAT_REPORT_5 ? 'VAT_REPORT_5' : null
        },
        {
          fileStoreId: params.VAT_REPORT_6 || null,
          taxReportDetailType: params.VAT_REPORT_6 ? 'VAT_REPORT_6' : null
        },
      ].filter(item => item.fileStoreId !== null),
      incomeTaxReport: [
        {
          fileStoreId: params.INCOME_TAX_REPORT_0 || null,
          taxReportDetailType: params.INCOME_TAX_REPORT_0 ? 'INCOME_TAX_REPORT_0' : null
        },
        {
          fileStoreId: params.INCOME_TAX_REPORT_1 || null,
          taxReportDetailType: params.INCOME_TAX_REPORT_1 ? 'INCOME_TAX_REPORT_1' : null
        },
        {
          fileStoreId: params.INCOME_TAX_REPORT_2 || null,
          taxReportDetailType: params.INCOME_TAX_REPORT_2 ? 'INCOME_TAX_REPORT_2' : null
        },
        {
          fileStoreId: params.INCOME_TAX_REPORT_3 || null,
          taxReportDetailType: params.INCOME_TAX_REPORT_3 ? 'INCOME_TAX_REPORT_3' : null
        },
        {
          fileStoreId: params.INCOME_TAX_REPORT_4 || null,
          taxReportDetailType: params.INCOME_TAX_REPORT_4 ? 'INCOME_TAX_REPORT_4' : null
        },
      ].filter(item => item.fileStoreId !== null),
    }
    return new Promise((resolve, reject) => {
      selfFillingApi.manualSubmit(datas)
        .then(resp => {
          const code = get(resp, 'data.errorCode');
          this.loading = false;
          if (code === 200) {
            message.success('提交成功！会尽快处理上传的表格，请耐心等待！');
            resolve();
          }
        })
        .catch(error => {
          const errorData = get(error, 'response.data.message');
          this.loading = false;
          message.error(errorData);
          reject();
        });
    })
  }

  downloadTaxTmp = (params) => {
    return new Promise((resolve, reject) => {
      selfFillingApi.downloadTaxTmp(params)
        .then(resp => {
          const content = get(resp, 'data.data');
          resolve(content);
        })
        .catch(error => {
          const errorData = get(error, 'response.data');
          reject(errorData);
        });
    })
  }
}

export default new SelfFillingStore();
