import axios from 'axios';
import { taxAxios } from '@/utils/taxAxios';


export const receiveFrontLog = (params) => {
  return axios.post('/collect/pageView/receiveFrontLog', params);
}

/** 协议获取-单个 */
export const getProtocolSingleV2 = (data) => {
  return taxAxios.post('/api/dbc/taxation/protocolInfo/single', data,);
}

/** logo获取 */
export const getLogoInfo = (params) => {
  return taxAxios.get(`/api/dbc/taxation/logoInfo`, { params })
}

/** 获取旧版授权 */
export const getOldTaxBureau = () => {
  return taxAxios.get(`/api/dbc/taxation/oldTaxBureau`,)
}
export const getToken = (params) => {
  return axios.post('/api/dbc/sc/token', params);
}
export const getAccountInfoBySource = (source) => {
  return axios.get(`/api/dbc/taxation/account?source=${source}`);
}