
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { Spin, Alert } from 'antd';

import decryptedParams from '@/utils/decryptedParams';
import TicketTitle from '@/components/ticketTitle';
import WhiteBox from '@/components/layout/whiteBox';

import styles from './index.less';

class Home extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      paramsIsEmpty: false
    };
  }

  componentDidMount() {
    const { taxHelperStore, history } = this.props;
    const params = decryptedParams();
    taxHelperStore.setQueryParams(params);
    if (isEmpty(params)) {
      // this.setState({ paramsIsEmpty: true })
      const errorCode = 1099;
      history.replace(`/invalidation?code=${errorCode}`);
    } else {
      taxHelperStore.getTaxWorkTypeAsync()
      .then(() => {
        this.props.history.replace(`/authorize${window.location.search}`);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    }
  }

  render() {
    return (
      <WhiteBox>
        {
          this.state.paramsIsEmpty ? 
          <div className={styles.alert}>
            <TicketTitle />
            <Alert
              message="授权页加载失败，请联系管理员"
              type="error"
            />
          </div> :
          <div className={styles.spinBox}>
            <Spin size="large" />
          </div>
        }
      </WhiteBox>
    );
  }
}

Home.propTypes = {
  history: PropTypes.object,
};

export default inject('taxHelperStore')(observer(Home));
