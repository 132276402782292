import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, message } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import TaxAuthProtocol from '@/components/common/TaxAuthProtocol';
import TaxForm from './components/TaxForm';
import { taxWork } from './helper';
import AxinoErrorMap from '@/components/HxTaxBureau/helper/errorMap';
import loadingPng from '@/assets/images/common/loading.png';
import { downloadFile } from '@/utils';
import { AUTH_TYPE_DICT } from '@/utils/taxUtil';

import styles from './index.less';
import { get } from 'lodash';

const HxTaxBureau = (props) => {

  const { hxTaxBuresuStore, inputSize, params, taxType } = props;

  const [ isLoading, setLoading ] = useState(false);
  const [ agreeAuthProtocol, setAgreeAuthProtocol ] = useState(false);
  const [ downloadPlugVisible, setDownloadPlugVisible ] = useState(false);
  const [ stateText, setStateText ] = useState('数据采集中...');

  useEffect(() => {
    return () => {
      hxTaxBuresuStore.resetStore();
    };
  }, []);

  const hanldeAuthProtocol = (isAgree) => {
    setAgreeAuthProtocol(isAgree);
  }

  const checkAgreeProtocol = (data) => {
    return new Promise((resolve, reject) => {
      if (!agreeAuthProtocol) {
        reject(new Error('请先阅读并同意相关协议'));
        return false;
      }
      resolve(data);
    })
  }

  /**
   * 检查授权状态，发起扣款
   */
  const pluginAuth = () => {
    const { serialNo } = props.hxTaxBuresuStore.queryParams;
    // 如果在电子税局已经授权过，此处不再进行二次授权（扣费）
    if (taxType === AUTH_TYPE_DICT.ELECTRON_PLUGIN) {
      console.warn('已扣费，不再继续扣费')
      return null;
    }
    return props.taxAuthStore.pluginAuth({serialNo})
      .catch(() => Promise.reject(null))
  }

  /**
   * 获取授权表单数据
   * @param {*} form 授权表单
   * @returns 返回表单数据的Promise
   */
  const getFormData = (form) => {
    return form.validateFields()
      .then((data) => {
        hxTaxBuresuStore.setValue('hxTaxFormData', { ...props.hxTaxBuresuStore.hxTaxFormData, ...data });
      })
      .catch((error) => Promise.reject())
  }

  const submitForm = (form) => {
    setLoading(true);
    getFormData(form)
    .then(() => checkAgreeProtocol())
    .then(() => {
      setDownloadPlugVisible(true);
      setStateText('数据采集中，请耐心等待');
    })
    .then(() => props.hxTaxBuresuStore.getServerPlatformAccessToken())
    .then(() => props.hxTaxBuresuStore.getLocalPrepareOrderInfo())
    .then(() => pluginAuth())
    .then(() => props.hxTaxBuresuStore.requestServerSyncAddOrder())
    .then(() => {
      const params = hxTaxBuresuStore.getParams();
      return taxWork(toJS(params), ({ status }) => {});
    })
    .then(() => {
      setLoading(false);
      setDownloadPlugVisible(false);
      setStateText('');
      props.history.replace(`/authSuccess?code=SUCCESS_CAIPAI`);
    })
    .catch(error => {
      console.log('out error=====>', error);
      setLoading(false);
      setDownloadPlugVisible(false);
      setStateText('');
      if (error) {
        const { code, message: errMsg } = error;
        const errorState = AxinoErrorMap.get(code);
        console.log('errorState error=====>', errorState);
        message.error(errMsg || errorState.message);
      }
    });
  }
  
  /**
   * 下载爱信诺软件
   */
  const redirectDownload = () => {
    const url = get(props, 'taxAuthStore.pluginConfig.exeUrl');
    if (url) {
      downloadFile(url);
    }
  }

  const taxFormProps = {
    formData: hxTaxBuresuStore.hxTaxFormData,
    inputSize,
    renderFooter: ({ form }) => (
      <div className={styles.formActionWrapper}>
        <TaxAuthProtocol isAgree={agreeAuthProtocol} onChange={hanldeAuthProtocol}>
          <Button className={styles.actionBtn} block size={inputSize} loading={isLoading} onClick={() => submitForm(form)} type="primary">确认</Button>
        </TaxAuthProtocol>

        <Modal
          visible={downloadPlugVisible}
          footer={null}
          keyboard={false}
          maskClosable={false}
          closeIcon=" "
        >
          <div className={styles.stateText}>
            <img className={styles.loading} alt="loading" src={loadingPng} />
            <span>{stateText}</span>
          </div>
          <div className={styles.tipText}>如果未安装插件，请先 <a onClick={redirectDownload}>下载</a> 并安装插件，否则会导致采票失败</div>
        </Modal>
        
      </div>
    )
  };

  return (<TaxForm {...taxFormProps} />);
}

HxTaxBureau.propTypes = {
  hxTaxBuresuStore: PropTypes.any,
};

export default inject('hxTaxBuresuStore', 'taxAuthStore')(observer(HxTaxBureau));
