import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-left: 24px;
`;

export const Button = styled.span`
  display: block;
  line-height: 32px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  user-select: none;
`;
