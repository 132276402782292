import { isSDKLocaltion } from '@/utils/sdk';
import { commonApi } from '@/api';
import config from '@/config';
import { get } from 'lodash';
import * as dayjs from 'dayjs';

class CommonStore {
  constructor() {
    // 优先使用缓存
    const logoURL = sessionStorage.getItem('logoURL')
    const certificationUrl = sessionStorage.getItem('certificationUrl')
    if (logoURL) this.logoURL = logoURL
    if (certificationUrl) this.certificationUrl = certificationUrl
  }

  sdk = false;

  /** 协议列表 */
  protocolList = [];

  /** v2协议信息 */
  protocolInfo = {}

  /** logo地址 */
  logoURL = ""

  /** 资质地址 */
  certificationUrl = ""

  isSDK() {
    if (this.sdk) {
      return this.sdk;
    }
    this.sdk = isSDKLocaltion();
    return this.sdk;
  }

  resetStore() {
    this.sdk = false;
  }

  /**
   * v2 协议获取-单个
   * 改版：之前的协议移除，所有平台都请求后端返回的协议内容和协议名称
   */
  getProtocolSingleV2 = (parmas) => {
    const { swiftCode, qymc, nsrsbh } = parmas;
    const req = {
      protocolCode: swiftCode,
      partyB: qymc,
      creditCodeB: nsrsbh,
      // date: dayjs().format(`YYYY-MM-DD HH:mm:ss`),
    }
    return commonApi.getProtocolSingleV2(req)
      .then(resp => {
        this.protocolInfo = get(resp, 'data.data');
      })
  }

  /** logo获取 */
  getLogoInfo = (parmas) => {
    const { swiftCode, qymc, nsrsbh } = parmas;
    
    return commonApi.getLogoInfo({ code: swiftCode })
    .then(resp => {
      this.logoURL = get(resp, 'data.data.logoUrl');
      this.certificationUrl = get(resp, 'data.data.certificationUrl');
      if (this.logoURL) {
        sessionStorage.setItem('logoURL', this.logoURL)
      }
      if (this.certificationUrl) {
        sessionStorage.setItem('certificationUrl', this.certificationUrl)
      }
    })
  }

  /**
   * 发送错误信息到服务端
   * @description
   * errorRecord 用于传递一个错误信息，主要收集两类错误，第一类是代码层面的报错，第二类是接口错误
   * 第一类错误是一个 new Error(message: string) 的形式，会读取error.message 和 error.stack
   * 第二类错误是接口返回的 Obejct 的形式，会将对象转为字符串，上报到邮箱中
   * 使用方式1：
   * try {
   * } catch(error) {
   *    this.props.commonStore.sendErrorReport(error);
   * }
   * 使用方式2：
   * this.taxApi.getXXX()
   *  .then()
   *  .catch((error) => {
   *    this.props.commonStore.sendErrorReport(error, option: {api: string, [key: string]: any});
   *  })
   * @param {*} errorRecord 
   * @param {*} url 
   * @returns 
   */
  sendErrorReport = (errorRecord, option) => {
    const errorParams = this.packHttpErrorResponse(errorRecord, option);
    console.log('errorParams--66--->', errorParams);
    return commonApi.receiveFrontLog(errorParams)
      .then(() => {})
      .catch((error) => {
        console.log('error--66--->', error);
      });
  }

  packHttpErrorResponse(error, option) {
    const target = (config.target || '').toUpperCase();
    const envMapper = {
      PROD: 'PRODUCTION'
    };
    const moreOption = Object.assign({}, option);
    const errorRecordRequest = {
      env: envMapper[target] || target,
      errorType: moreOption.url ? 'API' : 'WEB',
      headerMap: {
        userAgent: [navigator.userAgent],
        url: [moreOption.url || ''],
        helpInfo: ['\n' + JSON.stringify(moreOption) + '\n'],
        page: [`${window.location.toString()}`],
      },
      projectName: 'API税局授权',
      message: '',
      token: null
    };
    if (error instanceof Error) {
      errorRecordRequest.message = `${error.message}\n${error.stack}`;
    } else {
      try {
        errorRecordRequest.message = `${JSON.stringify(error)}`;
      } catch (error) {
        errorRecordRequest.message = error;
      }
    }
    return errorRecordRequest;
  }
}

export default new CommonStore();
