import React, { useState, useEffect, useMemo } from "react";
import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import { isProvinceOf } from './helper/func';
import { FORM_STEP_MAP } from './helper/data';
import { get } from 'lodash';
import TaxForm from './TaxForm';
import TaxFormButton from "./TaxFormButton";
import TaxFormAuthProtocol from './TaxFormAuthProtocol';
import TaxFormVerification from './TaxFormVerification';
import ExtraComponent from './ExtraComponent';
import useProvinceStore from "@/hooks/useProvinceStore";
import { AUTH_METHOD_TYPE_MAP } from '@/utils/taxUtil/dict';

import styles from './index.less';

const TaxBureauStep = ({
  taxHelperStore,
  /** 是否新版授权 */
  NEW_AUTH,
  onFinishAuth }) => {

  /** 是否同意授权协议 */
  const [ agreeAuthProtocol, setAgreeAuthProtocol ] = useState(false)
  /** 验证器引用 */
  const [ verificationRef ] = useState(React.createRef());
  /** 表单区域引用 */
  const [ taxFormRef ] = useState(React.createRef());
  /** 表单步骤 */
  const [ formStep, setFormStep ] = useState(FORM_STEP_MAP.get('first'));
  /** 表单loading */
  const [ formLoading, setFormLoading ] = useState(false);
  
  /** 获取办税人列表loading */
  const [ bsrLoading, setBsrLoading ] = useState(false);

  /** 第一步表单数据 */
  const [ stepOneFormData, setStepOneFormData ] = useState(null);

  /** 江苏授权第一步的图形验证码结果，存储发送手机验证时会使用 */
  const [ jiangshuImageCaptcha, setJiangshuImageCaptcha ] = useState('');

  /** 贵州授权的第一次获取手机验证码的图片验证码结果，存储第二次发送手机验证码时会使用 */
  const [ guizhouImageCaptcha, setGuizhouImageCaptcha ] = useState('');

  const provinceKey = taxHelperStore.getProvincePingYing();
  const provinceStore = useProvinceStore(provinceKey, NEW_AUTH);
  
  
  /**
   * 授权方式，（暂时只有山东身份使用）
   * SMS 短信验证码授权, PWD 账密授权, QR 扫二维码授权, WeChat 微信授权
   */
  const [ authMethodType, setAuthMethodType] = useState(AUTH_METHOD_TYPE_MAP.get('PWD'));

  /** 办税员列表 */
  const roleList = useMemo(() => provinceStore.bsrsList, [ provinceStore.bsrsList ]);

  /** 表单实例 */
  const formInstances = () => {
    return taxFormRef.current.formRef.current;
  };

  /** 修改表单显示步骤 */
  const changeFormStep = (step) => {
    formInstances().resetFields();
    setFormStep(step);
  }

  /**
   * 自动填入授权省份，企业名称
   */
  useEffect(() => {
    const { qymc, province } = taxHelperStore.authQueryParams;
    formInstances().setFieldsValue({
      companyName: qymc,
      taxDeclareProvince: province
    });
  }, [ formStep, taxHelperStore.authQueryParams ]);

  /**
   * 获取办税员后，将返回的办税员信息回填到办税员
   */
  useEffect(() => {

    let provinceList = [
      '北京', '山东', '西藏', '安徽', '海南', '新疆',
      '甘肃', '黑龙江', '福建', '江西', '深圳', '山西',
      '宁波', '河南', '云南', '内蒙古', '厦门', '天津'
    ]

    // 新版才回填
    if (NEW_AUTH) {
      provinceList = provinceList.concat([
        '四川','江苏', '贵州', '重庆', '湖南', '河北', '广西', '辽宁', '宁夏', '青海'
      ])
    }

    if (isProvince(provinceList) && formStep === FORM_STEP_MAP.get('second')) {
      formInstances().setFieldsValue({ bsr: roleList[0].value });
    }
  }, [ roleList, formStep ]);

  useEffect(() => {
    // 立即获取办税员列表
    if (isProvince(['辽宁']) && !NEW_AUTH) {
      const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
      const params = {
        creditCode: nsrsbh,
        userName: nsrsbh,
        province,
        serialNo
      };
      getRoleList(params, { serialNo });
    }
  }, []);


  /** 
   * 如果是山东税局，获取山东税局的授权方式。
   * 如果是授权方式时QR(扫二维码方式授权)，则判断是否有二维码等待验证。如果有二维码，则直接转到第二步显示二维码。
   */
  // useEffect(() => {
  //   if (isProvince('山东')) {
  //     const { serialNo, province } = taxHelperStore.authQueryParams;
  //     provinceStore.getAuthMethodType(province)
  //       .then(authMethodType => {
  //         setAuthMethodType(authMethodType);
  //         if (authMethodType === AUTH_METHOD_TYPE_MAP.get('QR')) {
  //           provinceStore.shandongQrCode(serialNo)
  //             .then(() => {
  //               changeFormStep(FORM_STEP_MAP.get('second'));
  //             })
  //         }
  //       })
  //       .catch(error => {
  //         console.log('error: ', error);
  //       });
  //   }
  // }, []);

  /** 检查是否同意协议 */
  const isAgreeP =async (params) => {
    if (!agreeAuthProtocol) {
      message.error('请先阅读并同意相关协议');
      throw new Error('请先阅读并同意相关协议');
    }
    return params;
  }

  /** 判断是否是当前省份 */
  const isProvince = (provinceName) => {
    const provinceKey = taxHelperStore.getProvincePingYing();
    
    return isProvinceOf(provinceKey)(provinceName);
  }

  /** 获取办税员列表 */
  const getRoleList = (params, options) => {
    setBsrLoading(true)
    return provinceStore.getRoleList(params, options).finally(() => setBsrLoading(false))
  };

  /** 获取表单数据 */
  const formData = () => {
    return formInstances().validateFields()
      .then(formData => {
        // 将表单值保存到store
        taxHelperStore.setAuthFormData(formData);
        return formData;
      });
  }

  /** 验证码数据 */
  const verificationData = () => {
    return verificationRef.current.validateFields()
      .then(veriData => {
        if (isProvince('贵州') && !NEW_AUTH) {
          setGuizhouImageCaptcha(veriData);
        }
        if (isProvince('江苏') && !NEW_AUTH) {
          setJiangshuImageCaptcha(veriData);
        }
        if (isProvince('山东')) {
          console.log('veriData: ', veriData);
        }
        return veriData;
      })
      .catch(error => {
        const captchaError = get(error, 'captchaError');
        if (captchaError) { message.error('请先完成验证'); }
        return Promise.reject(error);
      });
  }

  const hanldeAuthProtocol = (val) => {
    setAgreeAuthProtocol(val);
  };

  // Mark: 新加 公共授权
  /** 公共授权 */
  const handleSubmitCommon = () => {
    return formData()
      .then(formData => isAgreeP(formData))
      // .then(formData => isGetCaptchaP(formData))
      .then(formData => {
        setFormLoading(true);
        return formData;
      })
      .then(formData => {let options = {};
        if (isProvince('江苏') && !NEW_AUTH) {
          options = { jiangshuImageCaptcha };
        }
        if (isProvince('内蒙古')) {
          options = { roleList}
        }
        if (isProvince('山东') && !NEW_AUTH) {
          return verificationData().then(verifData => {
            const options = { ...verifData };
            return provinceStore.taxAuth({ formData, stepOneFormData, options }, NEW_AUTH);
          });
        }
        return provinceStore.taxAuth({ formData, stepOneFormData, options }, NEW_AUTH);
      })
      .then(() => {
        // if (isProvince('山东') && authMethodType === AUTH_METHOD_TYPE_MAP.get('QR')) {
        //   changeFormStep(FORM_STEP_MAP.get('second'));
        //   return false;
        // }
        onFinishAuth();
      })
      .catch(error => {
        console.error(error)
        /** 登录超时返回第一步重新登录 */
        if (isProvince('安徽') && !NEW_AUTH) {
          const errorCode = get(error.response, 'data.errorCode');
          if (errorCode === 401206) {
            changeFormStep(FORM_STEP_MAP.get('first'));
          }
        }
        /** 登录超时返回第一步重新登录 */
        if (isProvince('上海')) {
          const errorCode = get(error.response, 'data.errorCode');
          if (errorCode === 401206) {
            changeFormStep(FORM_STEP_MAP.get('first'));
          }
        }
      })
      .finally(() => {
        setFormLoading(false);
      });
  }

  /** 公共发送验证码 */
  const handleSendCaptcha = (validateFields) => {
    if (isProvince('贵州') && !NEW_AUTH) {
      if (formStep === FORM_STEP_MAP.get('first')) {
        return formInstances().validateFields(validateFields)
          .then(formData => verificationData().then(veriData => ({ ...formData, veriData })))
          .then(formDataExt => provinceStore.sendCaptcha({ formData: formDataExt, stepOneFormData }));
      }
      if (formStep === FORM_STEP_MAP.get('second')) {
        const options = { guizhouImageCaptcha };
        return formInstances().validateFields(validateFields)
          .then(formData => provinceStore.sendCaptcha({ formData, stepOneFormData, options }))
      }
    }
    return formInstances().validateFields(validateFields)
      .then(formData => {
        let options = {};
        if (isProvince('江苏') && !NEW_AUTH) {
          options = { jiangshuImageCaptcha };
        }
        if (isProvince('内蒙古')) {
          options = { roleList }
        }
        return provinceStore?.sendCaptcha?.({ formData, stepOneFormData, options })
          .then(resp => {
           
          });
      });
  }

  // Mark: getBsrList 获取办税员列表

  /** 获取办税员列表 */
  const getBsrList = async () => {
    formInstances().validateFields()
      .then((formData) => {
        if (isProvince('吉林') && !NEW_AUTH) {
          return Promise.resolve(formData).then(
           formData => {
              return verificationData().then(() => (formData));
            }
          );
        }
        // if (isProvince('云南')) {
        //   const ynPasswordReg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,18}$/;
        //   if (!ynPasswordReg.test(formData.taxPassword)) {
        //     message.error('您的密码口令弱，请前往税局修改密码');
        //     return Promise.reject(new Error('您的密码口令弱，请前往税局修改密码'));
        //   }
        // }
        if (isProvince('江苏') && !NEW_AUTH) {
          return Promise.resolve(formData)
            .then(formData => {
              return verificationData().then(veriData => {
                return { ...formData, captchaVerification: veriData };
              });
            });
        }
        if (isProvince('贵州') && !NEW_AUTH) {
          return Promise.resolve(formData)
            // .then(formData => isGetCaptchaP(formData))
            // .then((formData) => isAgreeP(formData));
        }
        return formData;
      })
      .then(formData => {
        setStepOneFormData(formData);
        setFormLoading(true);
        const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
        const { taxAccount, taxPassword, captchaVerification, bsrType } = formData;
        let postData = {
          creditCode: nsrsbh,
          userName: taxAccount,
          userPass: taxPassword,
          /** 接口必传字段，安徽授权传验证器字段 */
          captchaVerification: captchaVerification ? captchaVerification : '',
          province,
          serialNo,
          sflx: bsrType
        };
        /** 额外的参数以后再store里面自行添加，避免逻辑过于复杂 */
        if (isProvince('内蒙古')) {
          postData.authentication = 'tpass.sichuan.chinatax.gov.cn';
          postData.yddh = formData.phone;
          postData.captcha = formData.captcha
        }
        if (isProvince('陕西')) {
          postData = {
            creditCode: nsrsbh,
            userName: taxAccount,
            userPass: taxPassword,
            province,
            serialNo,
            authentication: 'tpass.shaanxi.chinatax.gov.cn',
          };
        }
        if (isProvince('青岛')) {
          postData = {
            serialNo,
            province,
            creditCode: nsrsbh,
            userName: taxAccount,
            userPass: taxPassword,
            authentication: 'tpass.qingdao.chinatax.gov.cn',
          };
        }
        if (isProvince('厦门')) {
          postData = {
            creditCode: nsrsbh,
            userName: taxAccount,
            userPass: taxPassword,
            province,
            serialNo,
            authentication: 'tpass.xiamen.chinatax.gov.cn',
          };
        }
        if (isProvince('贵州') && !NEW_AUTH) {
          postData.mobileCode = formData.captcha;
          postData.phoneNumber = formData.phone;
        }
        return postData; 
      })
      .then(params => {
        const { serialNo } = taxHelperStore.authQueryParams;
        return getRoleList(params, { serialNo })
          .catch( error => {
            /** 重置验证器 */
            if (isProvince(['江苏']) && !NEW_AUTH) {
              verificationRef.current.errorVerificationCb();
            }
            return Promise.reject(error);
          })
      })
      .then((data) => {
        setFormLoading(false);
        /** 贵州获取办税员列表，如果手机号为办税员手机号则会直接授权成功 */
        if (isProvince('贵州') && data?.loginSuccess && !NEW_AUTH) {
          onFinishAuth();
          return false;
        }
        changeFormStep(FORM_STEP_MAP.get('second'));
      })
      .catch(error => {
        console.log('error: ', error);
        setFormLoading(false);
      });
  }

  // Mark: 获取短信验证码

  /** 获取短信验证码 */
  const getCaptcha = () => {
    if (isProvince('四川') && !NEW_AUTH) {
      return handleSendCaptcha(['bsr', 'bsrPassword']);
    }
    if (isProvince('重庆') && !NEW_AUTH) {
      return handleSendCaptcha(['companyName', 'taxAccount', 'taxPassword', 'phone']);
    }
    if (isProvince('辽宁') && !NEW_AUTH) {
      return handleSendCaptcha(['bsr', 'phone', 'taxPassword']);
    }
    if (isProvince('贵州') && !NEW_AUTH) {
      if ((formStep === FORM_STEP_MAP.get('first'))) {
        return handleSendCaptcha([ 'taxAccount', 'taxPassword', 'phone' ]);
      }
      if ((formStep === FORM_STEP_MAP.get('second'))) {
        return handleSendCaptcha(['bsr']);
      }
    }
    return handleSendCaptcha(['bsr']);
  }

  // Mark: handleSubmit 提交按钮方法

  const handleSubmit = () => {
    let provinceList = ['广东', '浙江'];
    // 旧版
    if (!NEW_AUTH) {
      provinceList = provinceList.concat([ '山东', '重庆', '湖南', '辽宁' ])
    }
    if (isProvince(provinceList)) {
      return handleSubmitCommon;
    }
    if (formStep === FORM_STEP_MAP.get('first')) {
      return getBsrList;
    }
    if (formStep === FORM_STEP_MAP.get('second')) {
      return handleSubmitCommon;
    }
  }

  /** 选择的办税员改变时检查 */
  const bsrOnChange = (value) => {
    if (isProvince('江苏') && !NEW_AUTH) {
      const bsrList = provinceStore.bsrsList;
      const selectBsr = bsrList[value];
      const { sfzh, bz, sjhm, rybz, xm } = selectBsr;
      if ( sfzh === 'N') {
        formInstances().setFieldsValue({ bsr: null });
        return message.error('该人员身份证件号码不存在，请进行税务登记变更');
      }
      if (bz === 'N') {
        formInstances().setFieldsValue({ bsr: null });
        return message.error('系统未能检测到您的实名数据，请到税局官网进行认证');
      }
      if (!sjhm) {
        formInstances().setFieldsValue({ bsr: null });
        return message.error('该人员手机号码不存在，请进行税务登记变更');
      }
      if (!rybz) {
        formInstances().setFieldsValue({ bsr: null });
        return message.error('人员标识为空，请重新选择人员身份类型');
      }
      if (!xm) {
        formInstances().setFieldsValue({ bsr: null });
        return message.error('人员姓名为空，请重新选择有效的人员姓名');
      }
    }
  }

  return (
    <div className={styles.content}>
      <div className={styles.formWrap}>
        {/* 授权表单 */}
        {
          <TaxForm
            provinceStore={provinceStore}
            isProvince={isProvince}
            ref={taxFormRef}
            bsrOnChange={bsrOnChange}
            bsrLoading={bsrLoading}
            getCaptcha={getCaptcha}
            formStep={formStep}
            formInstances={formInstances}
            NEW_AUTH={NEW_AUTH}
          />
        }
        {/* {
          isProvince('山东') && formStep === FORM_STEP_MAP.get('second') ?
          <ExtraComponent
            isProvince={isProvince}
            formStep={formStep}
            onFinishAuth={onFinishAuth}
            changeFormStep={changeFormStep}
          /> : null
        } */}
        {/* 验证码器组件 */}
        <TaxFormVerification
          isProvince={isProvince}
          NEW_AUTH={NEW_AUTH}
          formStep={formStep}
          onFinishAuth={onFinishAuth}
          ref={verificationRef}
        />
        {/* 协议组件 */}
        <TaxFormAuthProtocol
          isProvince={isProvince}
          NEW_AUTH={NEW_AUTH}
          formStep={formStep}
          isAgree={agreeAuthProtocol}
          onChange={hanldeAuthProtocol}
        />
        {/* 按钮 */}
        <TaxFormButton
          formStep={formStep}
          isProvince={isProvince}
          authMethodType={authMethodType}
          NEW_AUTH={NEW_AUTH}
          btnOption={{
            block: true,
            size: 'large',
            type: 'primary',
            loading: formLoading,
            onClick: handleSubmit()
          }}
        />
      </div>
    </div>
  );

}

export default inject(
  'taxHelperStore',
)(observer(TaxBureauStep));