import React from 'react';
import { Form, Select } from 'antd';
import { isFunction } from 'lodash';
import { inject, observer } from 'mobx-react';
import {getProvinces} from "@/pages/guide/fake";

const TaxDeclareProvince = ({ inputSize, defaultValue, onChange, taxAuthStore, taxHelperStore }) => {

  const taxDeclareProvince = getProvinces();

  const thisOnChange = (value, option) => {
    if (isFunction(onChange)) {
      return onChange(value, option);
    }
    const { setQueryParams, authQueryParams, } = taxHelperStore;
    const { serialNo } = authQueryParams;
    setQueryParams({ ...authQueryParams, province: value });
    /** 更新订单税局省份 */
    taxAuthStore.updateTaxOrderBureau({ serialNo, province: value });
  }

  return (
    <Form.Item
      key="taxDeclareProvince"
      colon={false}
      label="报税税务局："
      name="taxDeclareProvince"
      rules={[{ required: true, message: '请选择报税税务局' }]}
      initialValue={defaultValue}
    >
      <Select onChange={thisOnChange} size={inputSize ? inputSize : 'large'} placeholder="请选择报税税务局">
        {
          taxDeclareProvince.map((item, index) => {
            return (
              <Select.Option key={index} value={item.area}>{item.area}</Select.Option>
            );
          })
        }
      </Select>
    </Form.Item>
  );
};

export default inject('taxAuthStore', 'taxHelperStore')(observer(TaxDeclareProvince));
