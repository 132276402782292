import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import taxHelperStore from '../taxHelper';

/** 重庆旧版 */
class ChongqingOld {

  /** 发送验证码 */
  sendCaptcha = ({ formData }) => {
    const { taxAccount, taxPassword, phone } = formData;
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const params = {
      serialNo,
      userName: taxAccount,
      userPass: taxPassword,
      creditCode: nsrsbh,
      province,
      authorizationType: 'SMS',
      phoneNumber: phone
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  taxAuth = ({ formData }) => {
    const { taxAccount, taxPassword, phone, captcha } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params = {
      creditCode: nsrsbh,
      username: taxAccount,
      password: taxPassword,
      province,
      mobile: phone,
      verifyCode: captcha,
      authType: 'SMS'
    };
    return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password']))
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

};

export default new ChongqingOld();
