import {useMemo} from "react";
import {ReactSearchAutocomplete} from "react-search-autocomplete";
import styled from "styled-components";

const Header = styled.div`
  width: 100%;
  height: 120px;
  background-image: url("/statics/guide/1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 60px;
`;

const ItemCard = styled.div`
  background: white;
`;

function Search({options, onConfirm}) {
  // note: the id field is mandatory
  const items = useMemo(() => {
    if (options) {
      return options.map(i => ({id: i.value, name: i.label}));
    }
    return [];
  }, [options])

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    onConfirm?.(item.id);
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <ItemCard>
        <span style={{display: 'block', textAlign: 'left', background: 'white'}}>{item.name}</span>
      </ItemCard>
    )
  }

  return (
    <Header>
      <div style={{width: "80%"}}>
        <ReactSearchAutocomplete
          items={items}
          onSearch={handleOnSearch}
          onHover={handleOnHover}
          onSelect={handleOnSelect}
          onFocus={handleOnFocus}
          placeholder="请输入税务局名称"
          formatResult={formatResult}
          showNoResultsText="无搜索结果"
          styling={{borderRadius: '4px', height: '40px', boxShadow: 'none', zIndex: '100'}}
        />
      </div>
    </Header>
  )
}

export default Search;