import React from 'react';
import { observer } from 'mobx-react';

import styles from './index.less';

const DefaultTitle = ({ title }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{title || '请根据页面提示，完成操作'}</div>
      <div className={styles.border}></div>
    </div>
  );
};

export default observer(DefaultTitle);
