import { NEED_SPSB_TYPE } from '@/utils/taxUtil/dict';
import { PROVINCE_MAP } from '@/utils/taxUtil';
import ProvinceStatusList from '@/components/common/ProvinceStatusList';

/** 新版授权通用配置 */
const NEW_AUTH_CONFIG = {
  first: [
    'taxDeclareProvince',
    'companyName',
    { name: 'taxAccount',
      options: {
        label: '电子税务局查询账号（个人账号非企业账号）',
        placeholder: '请输入身份证号/手机号/用户名'
      }
    },
    { name: 'taxPassword',
      options: {
        placeholder: '个人密码（初始密码为证件号后六位）'
      }
    }
  ],
  second: [
    'taxDeclareProvince',
    'companyName',
    'spsb',
    {
      name: 'bsr',
      options: {
        label: '办税员手机电话'
      }
    },
    'captcha'
  ]
}

export const PROVINCE_FORM_CONFIG_MAP = new Map([
  [
    PROVINCE_MAP.get('四川').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('青海').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('宁夏').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('新疆').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('甘肃').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('福建').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('海南').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('广西').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('江西').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('深圳').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('山西').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('宁波').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('天津').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('浙江').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
    }
  ],
  [
    PROVINCE_MAP.get('大连').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入居民身份证号码/手机号码/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '个人用户密码(初始密码为证件号码后六位)'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('内蒙古').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入居民请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('云南').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机号码'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('安徽').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('吉林').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('陕西').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount', options: { label: '电子税务局查询账号（个人账号非企业账号）', placeholder: '请输入用户名/手机号码/身份证号' }},
        { name: 'taxPassword', options: { placeholder: '请输入个人用户密码' }},
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        { name: 'bsr', options: { label: '办税员手机号码' }},
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('河南').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('江苏').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('贵州').py,
    NEW_AUTH_CONFIG
  ],
  [
    PROVINCE_MAP.get('辽宁').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('北京').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('青岛').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount', options: { label: '电子税务局查询账号（个人账号非企业账号）', placeholder: '请输入用户名/手机号码/身份证号' }},
        { name: 'taxPassword', options: { placeholder: '请输入个人用户密码(初始密码为身份证后六位)' }},
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        { name: 'bsr', options: { label: '办税员手机号码' }},
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('黑龙江').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('厦门').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount', options: { label: '电子税务局查询账号（个人账号非企业账号）', placeholder: '请输入居民身份证号/手机号码/用户名' }},
        // 'taxPassword'
        { name: 'taxPassword', options: { placeholder: '请输入个人用户密码' }},
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        { name: 'bsr', options: { label: '办税员手机号码' }},
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('河北').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('重庆').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount', options: { label: '电子税务局查询账号（个人账号非企业账号）', placeholder: '请输入用户名/手机号码/身份证号' }},
        { name: 'taxPassword', options: { placeholder: '请输入个人用户密码' }},
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        { name: 'bsr', options: { label: '办税员手机号码' }},
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('西藏').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('湖南').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('山东').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号（个人账号非企业账号）',
            placeholder: '请输入身份证号/手机号/用户名'
          }
        },
        { name: 'taxPassword',
          options: {
            placeholder: '请输入个人用户密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        {
          name: 'bsr',
          options: {
            label: '办税员手机电话'
          }
        },
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('上海').py,
    {
      first: [ 'taxDeclareProvince', 'companyName', { name: 'taxAccount', options: {
        label: '电子税务局查询账号（个人账号非企业账号）',
        placeholder: '请输入手机号/身份证/操作员代码',
      }}, 'taxPassword' ],
      second: [ 'taxDeclareProvince', 'companyName', 'bsr', 'captcha' ]
    }
  ],
  [
    PROVINCE_MAP.get('广东').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount', options: { label: '电子税务局查询账号（个人账号非企业账号）', placeholder: '请输入居民身份证号码/手机号码/用户名' }},
        { name: 'taxPassword', options: { placeholder: '请输入个人用户密码' }} ]
    }
  ],
]);

/** 旧版本 */
export const OLD_PROVINCE_FORM_CONFIG_MAP = new Map([
  [
    PROVINCE_MAP.get('吉林').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount', 'taxPassword'],
      second: ['taxDeclareProvince', 'companyName', 'spsb', 'bsr', 'bsrPassword']
    }
  ],
  [
    PROVINCE_MAP.get('江苏').py,
    {
      first: ['taxDeclareProvince', 'companyName', {name: 'taxAccount', options: {
        placeholder: '请输入纳税人识别号/社会信用代码/用户名'
      }}, 'taxPassword', 'bsrType'],
      second: ['taxDeclareProvince', 'companyName', 'spsb', 'bsr', 'captcha']
    }
  ],
  [
    PROVINCE_MAP.get('四川').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount'],
      second: ['taxDeclareProvince', 'companyName', 'spsb', 'bsr', 'bsrPassword', 'captcha']
    }
  ],
  [
    PROVINCE_MAP.get('山东').py,
    {
      first: ['taxDeclareProvince', 'companyName', { name: 'taxAccount', options: {
        placeholder: '请输入手机号/身份证/操作员代码',
        label: '请输入手机号/身份证/操作员代码'
      }}, 'taxPassword'],
    }
  ],
  [
    PROVINCE_MAP.get('贵州').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount', 'taxPassword', 'phone', 'captcha'],
      second: ['taxDeclareProvince', 'companyName', 'spsb', 'bsr', 'captcha']
    }
  ],
  [
    PROVINCE_MAP.get('重庆').py,
    {
      first: ['taxDeclareProvince', 'companyName', {name: 'taxAccount', options: {
        placeholder: '请输入用户名/手机号码/身份证号'
      }}, 'taxPassword', 'phone', 'captcha']
    }
  ],
  [
    PROVINCE_MAP.get('深圳').py,
    {
      first: [
        'taxDeclareProvince',
        'companyName',
        { name: 'taxAccount',
          options: {
            label: '电子税务局查询账号',
            placeholder: '请输入税务局查询账号'
          }
        },
        { name: 'taxPassword',
          options: {
            label: '电子税务局查询密码',
            placeholder: '请输入税务局查询密码'
          }
        }
      ],
      second: [
        'taxDeclareProvince',
        'companyName',
        'spsb',
        'bsr',
        'captcha'
      ]
    }
  ],
  [
    PROVINCE_MAP.get('湖南').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount', 'taxPassword'],
    }
  ],
  [
    PROVINCE_MAP.get('河北').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount'],
      second: ['taxDeclareProvince', 'companyName', 'bsr', 'bsrPassword']
    }
  ],
  [
    PROVINCE_MAP.get('广西').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount', 'taxPassword'],
      second: ['taxDeclareProvince', 'companyName', 'bsr', 'captcha']
    }
  ],
  [
    PROVINCE_MAP.get('云南').py,
    {
      first: ['taxDeclareProvince', 'companyName', 'taxAccount', 'taxPassword'],
      second: ['taxDeclareProvince', 'companyName', 'spsb', 'bsr', 'bsrPassword']
    }
  ],
  [
    PROVINCE_MAP.get('北京').py,
    {
      first: ['taxDeclareProvince', 'companyName', {name: 'taxAccount', options: {
        placeholder: '请输入企业账号'
      }}, 'taxPassword'],
      second: ['taxDeclareProvince', 'companyName', 'bsr', 'captcha']
    }
  ],
  [
    PROVINCE_MAP.get('辽宁').py,
    {
      first: ['taxDeclareProvince', 'companyName',
      { name: 'taxPassword', options: { placeholder: '请输入企业登录密码' }  },
      { name: 'bsr', options: { placeholder: '请选择登录身份',  label: '登录身份' } },
      { name: 'phone', options: { placeholder: '请输入手机号' }
      }, 'captcha'],
    }
  ],
])

/**
 * 报税税局的FormItem配置
 * @param {*} options 省份列表
 * @param {*} inputProps inputProps
 * @returns 
 */
export const taxDeclareProvince = (formInstances, options = {}, inputProps) => {
  const { selectList } = options;
  return {
    wrapperClassName: 'form_taxDeclareProvince',
    key: 'taxDeclareProvince',
    label: '报税税局',
    type: 'select',
    options: selectList,
    formItemProps: {
      rules: [
        { required: true, message: '请选择报税税局' },
      ]
    },
    inputProps: {
      placeholder: '请选择报税税局',
      size: 'large',
      ...inputProps
    },
    children: <ProvinceStatusList />
  };
}

/**
 * 企业名称的FormItem配置
 * @param {*} creditCode 统一社会信用代码
 * @returns 企业名称的FormItem配置
 */
export const companyName = (formInstances, options = {}, inputProps) => {
  const { creditCode } = options;
  return {
    key: 'companyName',
    label: '企业名称',
    type: 'text',
    formItemProps: {
      rules: [
        { required: true, whitespace: true, message: '请输入企业名称' },
      ],
      extra: `统一社会信用代码：${creditCode}`
    },
    inputProps: {
      placeholder: '请输入企业名称',
      size: 'large',
      ...inputProps
    }
  }
};

/**
 * 获取税局账号的FormItem配置
 * @returns 税局账号的FormItem配置
 */
export const taxAccount = (formInstances, options = {}) => {
  const placeholder = options.placeholder ? options.placeholder : '请输入纳税人识别号/社会信用代码';
  const label = options.label ? options.label : '电子税务局查询账号';
  return {
    key: 'taxAccount',
    label,
    type: 'text',
    formItemProps: {
      rules: [
        { required: true, whitespace: true, message: placeholder },
      ]
    },
    inputProps: {
      placeholder: placeholder,
      size: 'large',
      onBlur: (evt) => {
        const value = evt.target.value;
        if (value !== value.trim()) {
          const form = formInstances();
          form.setFieldsValue({ taxAccount: value.trim() });
        };
      }
    }
  };
};

/**
 * 获取税局密码的FormItem配置
 * @returns 税局密码的FormItem配置
 */
export const taxPassword = (formInstances, options = {}) => {
  const placeholder = options.placeholder ? options.placeholder : '请输入电子税务局查询密码';
  return {
    key: 'taxPassword',
    label: '电子税务局查询密码',
    type: 'password',
    formItemProps: {
      rules: [
        { required: true, message: '请输入电子税务局查询密码' },
      ]
    },
    inputProps: {
      placeholder,
      size: 'large',
      onBlur: (evt) => {
        const value = evt.target.value;
        if (value !== value.trim()) {
          const form = formInstances();
          form.setFieldsValue({ taxPassword: value.trim() });
        };
      }
    }
  }
};

export const phone = () => {
  return {
    key: 'phone',
    label: '手机号',
    type: 'text',
    formItemProps: {
      rules: [
        { required: true, message: '请输入手机号' },
        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' }
      ]
    },
    inputProps: {
      placeholder: '请输入手机号',
      size: 'large',
    }
  }
}

/** 税盘类型 */
export const spsb = (formInstances, options ={}) => {
  /**
   * taxType 数据采集方式，
   * 如果taxType是 NEED_SPSB_TYPE 列表中所包含的类型则显示税盘类型选择，否则展示类型选择框
   */
  const { taxType } = options;
  return {
    key: 'spsb',
    disabled: !NEED_SPSB_TYPE.includes(taxType),
    label: '开票软件设备类型',
    type: 'select',
    options: [
      { label: 'Ukey', value: '1' },
      { label: '税控盘', value: '2' },
      { label: '金税盘', value: '3' },
    ],
    formItemProps: {
      rules: [
        { required: true, message: '请选择开票软件设备类型' },
      ]
    },
    inputProps: {
      placeholder: '请选择开票软件设备类型',
      size: 'large',
      // onChange: (value) => {}
    }
  };
}

/**
 * 获取办税员列表FormItem配置
 * @param {*} roleList 办税员列表
 * @returns 办税员列表FormItem配置
 */
export const bsr = (formInstances, options = {}, inputProps) => {
  const { selectList, label } = options;
  const _label = label || '办税员';
  return {
    key: 'bsr',
    label: _label,
    type: 'select',
    options: selectList,
    formItemProps: {
      rules: [
        { required: true, message: `请选择${_label}` },
      ]
    },
    inputProps: {
      placeholder: `请选择${_label}`,
      size: 'large',
      ...inputProps
    }
  };
}

/**
 * 获取办税员类型FormItem配置
 * @returns 办税员类型FormItem配置
 */
export const bsrType = () => {
  return {
    key: 'bsrType',
    label: '办税员类型',
    type: 'select',
    options: [
      { label: '法定代表人', value: '10' },
      { label: '财务负责人', value: '20' },
      { label: '办税人员', value: '30' },
    ],
    formItemProps: {
      rules: [
        { required: true, message: '请选择办税员类型' },
      ]
    },
    inputProps: {
      placeholder: '请选择办税员类型',
      size: 'large',
      // onChange: (value) => {}
    }
  }
}

/**
 * 获取办税员密码FormItem配置
 * @param {*} options options.disabled 是否disabled 
 * @returns 办税员密码FormItem配置
 */
export const bsrPassword = (formInstances, options, inputProps) => {
  return {
    key: 'bsrPassword',
    label: '办税员密码',
    type: 'password',
    formItemProps: {
      rules: [
        { required: true, message: '请输入办税员密码' },
      ]
    },
    inputProps: {
      placeholder: '请输入办税员密码',
      size: 'large',
      ...inputProps
    }
  };
}

export const captcha = (formInstances, options) => {
  const { getCaptcha } = options;
  return {
    key: 'captcha',
    label: '短信验证码',
    type: 'getCaptcha',
    formItemProps: {
      rules: [
        { required: true, message: '请输入短信验证码' },
      ]
    },
    inputProps: {
      placeholder: '请输入短信验证码',
      size: 'large',
    },
    captchaProps: {
      getCaptcha: getCaptcha,
      timeOut: 120,
    }
  };
}
