import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';

export const Container = styled.div`
  height: ${(props) => (props.collapsed ? '32px' : 'auto')};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-grow: 1;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-shrink: 0;
`;

export const UpDownArrow = styled(DownOutlined)`
  transform: rotate(${(props) => (props.active ? '0deg' : '180deg')});
  transition: transform 100ms linear;
`;
