import { taxAxios } from '@/utils/taxAxios';

export const taxAuth = (params, serialNo) => {
  return taxAxios.post(`/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, params);
}

/** 通用税局授权，即不是电子税局授权类的，大象、微风企等 */
export const commonTaxAuthorization = (params) => {
  return taxAxios.get(`/api/dbc/taxation/authorization`, {params});
};

/** 获取订单采集状态 */
export const checkoutTaxAuthOrderCollectionStatus = (serialNo) => {
  const params = { serialNo };
  return taxAxios.get(`/api/dbc/taxation/taxAuthOrderCollectionStatus`, { params });
}

/** 获取插件配置信息 */
export const getPluginConfig = (taxType) => {
  const params = { taxType };
  return taxAxios.get('/api/dbc/taxation/pluginConfig', { params });
}

/** 获取授权状态 */
export const getAuthStatus = (serialNo) => {
  const params = { serialNo };
  return taxAxios.get('/api/dbc/taxation/authStatus', { params } );
}

/** 获取已开通身份列表 */
export const getProvinceList = () => {
  return taxAxios.post('/api/dbc/taxation/taxBureau');
}

/** 更新订单税局省份 */
export const updateTaxOrderBureau = (params) => {
  return taxAxios.put('/api/dbc/taxation/updateTaxOrderBureau', params);
}