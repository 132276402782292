import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import FormWrapper from '../FormWrapper';

const NumberInput = (props) => {
  const { item } = props;
  return (
    <FormWrapper {...props}>
      <InputNumber {...item.inputProps} />
    </FormWrapper>
  );
}

// item 下更多其他配置参考 FormWrapper.propTypes
NumberInput.propTypes = {
  item: PropTypes.object,
};

export default NumberInput;
