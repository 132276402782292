import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from 'antd';

import styles from './index.less';

const plugConfirmation = ({ onOk, onCancel }) => {
  return (
    <div className={styles.wrap}>
      {/* <div className={styles.title}>软件状态确认</div> */}
      <div className={styles.description}>请先确认采票软件状态，否则可能会导致采票失败</div>
      <div className={styles.btn}>
        <Space size="large">
          <Button onClick={onCancel}>未安装</Button>
          <Button type="primary" onClick={onOk}>已安装，去授权</Button>
        </Space>
      </div>
    </div>
  );
};

plugConfirmation.propTypes = {
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default plugConfirmation;
