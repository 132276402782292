import { taxAxios } from '@/utils/taxAxios';

// 手动提交税票数据
export const manualSubmit = (params) => {
  return taxAxios.post(`/api/dbc/taxation/manualSubmit`, params);
}

// 税票模板文件下载
export const downloadTaxTmp = (params) => {
  return taxAxios.get(`/api/dbc/taxation/tmp/download`, { params });
}