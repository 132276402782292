import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';

import router from './router';
import stores from './stores';
import RouteWithSubRoutes from './components/common/RouteWithSubRoutes';
import ErrorBoundary from './components/common/ErrorBoundary';
import NotFound from './components/notFound';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import { initTaxAsios } from '@/utils/taxAxios';
import config from './config';

const App = () => {

  initTaxAsios();

  return (
    <Provider {...stores}>
      <ConfigProvider locale={zh_CN}>
        <ErrorBoundary>
          <Router basename={config.basename}>
            <Switch>
              {router.map((route, key) => {
                return <RouteWithSubRoutes {...route} key={key} />;
              })}
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
