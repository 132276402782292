import { Form } from 'antd';
import FileInput from './../../FileInput';

import styles from './index.module.less';

const Item = (props) => {

  const { label, placeholder, required, isSingle, handleUpload, form } = props;

  return (
    <div className={styles.item}>
      <Form.Item label={label} required={required}>
        {
          placeholder && placeholder.length ?
            placeholder.map((item, index) => (
              <Form.Item key={index} name={item.type} rules={[{ required: required, message: item.text }]}>
                <FileInput placeholder={item.text} type={item.type} form={form} />
              </Form.Item>
            )) : null
        }
      </Form.Item>
      <a className={styles.btn} onClick={handleUpload}>{isSingle ? '单个上传' : '一键上传'}</a>
    </div>
  )
}

export default Item;