/**
 * 湖北税盘授权登录相关接口
 */

import { taxAxios } from '@/utils/taxAxios';

const localRequestP = ({
  url,
  type,
  params,
}) => {
  return new Promise((resolve, reject) => {
    window.$.ajax({
      url,
      type,
      data: params,
      async: true,
      success: (data) => { resolve(data) },
      error: (error) => { reject(error) }
    })
  })
}

/**
 * 检查税盘驱动
 */
// export const checkSPDrive = (params) => normalAxios.post('https://127.0.0.1:28000/api/getDeviceNum', params);
export const checkSPDrive = (params) => localRequestP({
  url: 'https://127.0.0.1:28000/api/getDeviceNum',
  type: 'post',
  params
});

/**
 * gethello
 */
// export const gethello = (params) => normalAxios.post('https://127.0.0.1:28000/api/clientHello', params);
export const gethello = (params) => localRequestP({
  url: 'https://127.0.0.1:28000/api/clientHello',
  type: 'post',
  params
});

/**
 * ajaxaction
 */
// export const ajaxaction = (params) => normalAxios.post('/caSign.c', params);
// export const ajaxaction = (params) => localRequestP({
//   url: 'http://52.80.145.20:8000/caSign.c',
//   type: 'post',
//   params
// });
export const ajaxaction = (params) => taxAxios.post('/api/dbc/taxation/caSign', params);


// export const getserverhello = (params) => normalAxios.post('https://127.0.0.1:28000/api/clientAuth', params);
export const getserverhello = (params) => localRequestP({
  url: 'https://127.0.0.1:28000/api/clientAuth',
  type: 'post',
  params
});

export const getnsrsbh = (params) => localRequestP({
  url: 'https://127.0.0.1:28000/api/readCert',
  type: 'post',
  params
});

// export const ajaxaction2 = (params) => localRequestP({
//   url: 'http://52.80.145.20:8000/crawl',
//   type: 'post',
//   params: JSON.stringify(params)
// })
export const ajaxaction2 = (params, serialNo) => taxAxios.post(`/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, params);
