import { provinceValidatorApi } from '@/api';
import { message } from 'antd';
import { get } from 'lodash';

class Zhejiang {

  roleList = [];

  getZhejiangBsyList = (params) => {
    return provinceValidatorApi.getBsyList(params)
      .then((resp) => {
        const data = get(resp, 'data.data.data');
        this.roleList = Object.keys(data).map(key => data[key]);
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '获取登录角色失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

}

export default new Zhejiang();
