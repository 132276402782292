import { InputNumber } from 'antd';

const NumberRange = ({ onChange, value }) => {
  const handleMinChange = (val) => {
    onChange({ min: val, max: value?.max });
  };
  const handleMaxChange = (val) => {
    onChange({ min: value?.min, max: val });
  };

  return (
    <>
      <InputNumber value={value?.min} onChange={handleMinChange} size="small" min={0} max={value.max} />
      ~
      <InputNumber value={value?.max} onChange={handleMaxChange} size="small" min={value.min} />
    </>
  );
};

export default NumberRange;
