export const accountConfig = () => {
  return {
    key: 'account',
    label: '订烟平台账号',
    type: 'text',
    formItemProps: {
      rules: [
        { required: true, whitespace: true, message: '请输入订烟平台账号' },
      ]
    },
    inputProps: {
      placeholder: '请输入订烟平台账号',
      size: 'large',
      disabled: true,
    }
  };
};

export const passwordConfig = () => {
  return {
    key: 'password',
    label: '订烟平台登录密码',
    type: 'password',
    formItemProps: {
      rules: [
        { required: true, message: '请输入订烟平台登录密码' },
      ]
    },
    inputProps: {
      placeholder: '请输入订烟平台登录密码',
      size: 'large',
    }
  };
}
