import React, {useState, useEffect, useRef} from 'react';
import styled from "styled-components";
import {Button, Space} from "antd";
import qs from 'qs';
import { looprquest, stopLooprquest } from '@/utils';
import {useHistory, useLocation} from "react-router-dom";

import WhiteBox from '@/components/layout/whiteBox';
import TicketTitle from '@/components/ticketTitle';

import successIcon from '@/assets/images/common/success.png';

import styles from './index.less';
import { inject, observer } from 'mobx-react';

const INVALID_TIME = 15 * 60 * 1000;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const BigButton = styled(Button)`
  width: 270px;
  flex-shrink: 0;
  & ${Icon} {
    position: absolute;
    right: 10px;
    top: ${props => props.size === 'large' ? '10px':'6px'};
  }
`

const StyledSpace = styled(Space)`
  width: 100%;
  align-items: center;
`;



const Invalidation = ({ taxAuthStore, smengStore, taxHelperStore }) => {
  const history = useHistory();
  const location  = useLocation();
  const u = new URLSearchParams(location.search);
  const area = u.get('area');

  const to = (path) => {
    history.push(path);
  }
  const timeRef = useRef(null)
  const [ queryParams, setQueryParams ] = useState({});

  const checkoutTaxAuthOrderCollectionStatus = (serialNo) => {
    return taxAuthStore.checkoutTaxAuthOrderCollectionStatus(serialNo)
      .then((resp) => {
        // PROCESSED:已完成,FAILED:失败，COLLECTING:采集中
        const status = resp.data;
        if (status === 'PROCESSED') {
          return status;
        } else {
          throw(status);
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  const checkStatus = (searchObj) => {
    const { serialNo, type } = searchObj;
    if (type === 'smeng') {
      return smengStore.checkSmengStatus({ orderNo: serialNo })
        .then(status => {
          if (status === 'PROCESSED') {
            return status;
          } else {
            throw(status);
          }
        });
    }
    return checkoutTaxAuthOrderCollectionStatus(serialNo);
  };

  const checkStatue = (searchObj) => {
    const { serialNo, cburl } = searchObj;

    /** 轮询采数结果 */
    timeRef.current = looprquest(
      checkStatus.bind(null, searchObj),
      (data) => {
        setDescText('采数成功');
        /**
         * 如果是嵌入frame的方式巧用存电子税局授权页面,
         * 采数成功后发送数据给调用页面
         */
        const messageChannelPort = taxHelperStore.messageChannelPort;
        if (messageChannelPort && messageChannelPort.ports.length > 0) {
          messageChannelPort.ports[0].postMessage({ serialNo });
          return false;
        }
        if (cburl) {
          setTimeout(() => {
            window.location.href=cburl;
          }, 500);
        }
      },
      (error) => {
        console.log('error callback: ', error);
      },
      {
        eachSecond: 3000,
        maxTime: 100,
        maxSecond: INVALID_TIME,
      }
    );
  }

  const [ title, setTitle ] = useState('授权认证');
  const [ descText, setDescText ] = useState('授权成功，数据采集中...');

  useEffect(() => {
    const search = window.location.search;
    const searchObj = qs.parse(search, { ignoreQueryPrefix: true });
    setQueryParams(searchObj);
    if (searchObj.watch) {
      checkStatue(searchObj);
    }
    if (searchObj && searchObj.code === 'SUCCESS_CAIPAI') {
      setTitle('采数结果');
      setDescText('采数成功');
    }
    return () => stopLooprquest(timeRef)
  }, [])

  return (
    <WhiteBox>
      <TicketTitle title={title} />
      <div className={styles.wrap}>
        <img src={successIcon} alt=""/>
        <div>{descText}</div>
      </div>
      <StyledSpace direction="vertical" size={20}>
        <BigButton
          type="primary"
          size="large"
          icon={<Icon src="/statics/guide/3.png"/>}
          onClick={() => to(`/guide/auth?area=${area}`)}
        >再次体验</BigButton>
        <BigButton
          ghost
          type="primary"
          size="large"
          icon={<Icon src="/statics/guide/4.png"/>}
          onClick={() => to("/guide/area-select")}
        >返回首页</BigButton>
      </StyledSpace>
    </WhiteBox>
  );
};

export default inject('taxAuthStore', 'smengStore', 'taxHelperStore')(observer(Invalidation));
