import { Component } from 'react';
import { Tabs } from 'antd';
import HubeiCaAuth from './HubeiCaAuth';

import styles from './index.less';

const { TabPane } = Tabs;

class HubeiEleAuthWrap extends Component {
  render() {
    const { NEW_AUTH, onFinishAuth} = this.props;
    return this.props.children
    // return (
    //   <div className={styles.form}>
    //     <Tabs defaultActiveKey="1">
    //       <TabPane tab="账密认证" key="1">
    //         {this.props.children}
    //       </TabPane>
    //       <TabPane tab="CA证书认证" key="2">
    //         <HubeiCaAuth onFinishAuth={onFinishAuth} />
    //       </TabPane>
    //     </Tabs>
    //   </div>
    // );
  }
}

export default HubeiEleAuthWrap;
