import { anhuiQrAuthApi } from '@/api';
import { get, set } from 'lodash';
import { message } from 'antd';

class anhuiQrAuth {

  queryParams = {};
  qrCodeImgurl = '';
  isCodeInvalidCode = false;

  setValue = (key, value) => {
    set(this, key, value);
  }

  getQrCodeImage = () => {
    const { serialNo } = this.queryParams;
    this.isCodeInvalidCode = false;
    return anhuiQrAuthApi.getQrCodeImage({ serialNo })
      .then(({ data }) => {
        const img = get(data, 'data.data.data');
        this.qrCodeImgurl = img;
        return data;
      })
      .catch(error => {
        const errorResponse = get(error, 'response.data', {});
        let errorMessage = errorResponse.message;
        const errorCode = errorResponse.errorCode;
        if (errorCode === 403220) { errorMessage = '授权链接已失效，请重新获取授权链接'; }
        message.error(errorMessage);
        return Promise.reject({ errorCode, errorMessage });
      })
  }

  checkQrCodeResult = () => {
    const { serialNo } = this.queryParams;
    return anhuiQrAuthApi.checkQrCodeResult({ serialNo })
      .then(({ data }) => {
        return data.data.data;
      })
      .then(data => {
        const code = get(data, 'code', '');

        // if (['1001003'].includes(code)) {
        //   message.error('扫描成功');
        //   return Promise.resolve({ ...data, reStart: true });
        // }

        if ([ '1001001', '1001002' ].includes(code)) {
          this.isCodeInvalidCode = true;
          return Promise.reject({ ...data, outLopp: true });
        }

        if(['10002'].includes(code)) {
          /** 扫码信息不匹配 */
          message.error('扫码信息和企业不匹配，请重新扫码');
          return Promise.resolve({ ...data, reStart: true });
        }
        if(['10001', '10003', '10004', '10017'].includes(code)) {
          /** 扫码错误 */
          message.error('登录失败，请重新扫码');
          return Promise.resolve({ ...data, reStart: true });
        }
        if (['10000'].includes(code)) {
          /** 成功 */
          return Promise.resolve(data);
        }
        return Promise.reject(data);
      })
      .catch(error => {
        return Promise.reject(error);
      })
  }
}

export default new anhuiQrAuth();
