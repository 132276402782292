import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import FormWrapper from '../FormWrapper';

const RadioInput = (props) => {
  const { item } = props;
  const createOptions = (options) => {
    if (!options || options.length === 0) {
      return undefined;
    }
    const output = [];
    options.forEach((opt, index) => {
      if (typeof opt === 'object') {
        output.push(<Radio key={index} value={opt.value}>{opt.label}</Radio>);
      } else {
        output.push(<Radio key={index} value={opt}>{opt}</Radio>);
      }
    });
    return output;
  }
  return (
    <FormWrapper {...props}>
      <Radio.Group {...item.inputProps}>
        {createOptions(item.options || [])}
      </Radio.Group>
    </FormWrapper>
  );
}

// item 下更多其他配置参考 FormWrapper.propTypes
RadioInput.propTypes = {
  item: PropTypes.object,
};

export default RadioInput;
