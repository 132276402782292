import { taxAxios } from '@/utils/taxAxios';
import { get } from 'lodash';

/** 获取河北税局角色类别 */
export const getHebeiRoleList = (params) => {
  return taxAxios.get('/api/dbc/taxation/taxAuthentication', { params });
}

/** 通用获取税局办税员列表 */
export const getBsyList = (params) => {
  return taxAxios.post('/api/dbc/taxation/zj/brs', params);
}

/** 获取安徽captchaType */
export const getTokenCaptcha = () => {
  return taxAxios.get('/api/agent/order/mobile/taxAuthentication/ah/getTokenCaptcha');
}

/** 山东税局获取验证码图片 */
export const getShandongCaptchaImage = (serialNo) => {
  const params = { serialNo };
  return taxAxios.get('/api/dbc/taxation/sd/hdyzmActionForImage', { params });
}

/** 山东税局验证图片验证码 */
export const hdyzmActionForCheck = (params) => {
  return taxAxios.get('/api/dbc/taxation/sd/hdyzmActionForCheck', { params });
}

/** 发送授权验证码 */
export const taxAuthSendCaptcha = (params) => {
  return taxAxios.post('/api/dbc/taxation/taxAuthSendCaptcha', params);
}

/** 另一种发送授权验证码方法 */
export const taxAuthSendCaptcha2 = (params) => {
  return taxAxios.post('/api/dbc/taxation/sendMsg', params);
}

/** 提交验证码授权 */
export const taxAuthCaptchaCommit = (serialNo, params) => {
  return taxAxios.put(`/api/dbc/taxation/taxAuthCaptchaCommit?serialNo=${serialNo}`, params);
}

/** 税局授权 */
export const taxAuthCaptchaCommit2 = (serialNo, params) => {
  return taxAxios.post(`/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, params);
}

/** 获取吉林的办税员列表 */
export const getJilinRoleList = (params) => {
  return taxAxios.post('/api/dbc/taxation/zj/brs', params);
}

/** 贵州获取办税员列表时发送验证码 */
export const guizhouSendCaptcha = (params) => {
  return taxAxios.post('/api/dbc/taxation/sendMsg', params);
}

/** 办税员密码授权 */
export const beijinPasswordnAuth = (serialNo, params) => {
  return taxAxios.post(`/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, params);
}

/** 北京发送授权验证码 */
export const beijinTaxAuthSendCaptcha = (serialNo, params) => {
  return taxAxios.post(`/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, params);
}

/** 北京验证码授权 */
export const beijinCaptchaAuth = (serialNo, params) => {
  return taxAxios.put(`/api/dbc/taxation/taxAuthCaptchaCommit?serialNo=${serialNo}`, params);
}

/** 密码授权 */
export const passwordAuth = (serialNo, params) => {
  return taxAxios.post(`/api/dbc/taxation/taxAuth?serialNo=${serialNo}`, params);
}

/** 检查山东授权二维码 */
export const shandongQrCode = (serialNo) => {
  return taxAxios.get(`/api/dbc/taxation/sdQrCode?serialNo=${serialNo}`);
}

/** 检查山东二维码是否已经授权 */
export const checkShangdongTaxAuthStatus = (serialNo) => {
  return taxAxios.get(`/api/dbc/taxation/taxAuthStatus?serialNo=${serialNo}`);
}

/**
 * 获取税局授权方式   SMS 短信验证码授权, PWD 账密授权, QR 扫二维码授权, WeChat 微信授权
 */
export const getAuthMethodType = (province => {
  const params = { province };
  return taxAxios.get(`/api/dbc/taxation/authType`, { params });
})
