import React from "react";
import { Modal, Button } from "antd";
import styles from "./index.less";

const InstallProtocol = ({ visible, closeModal }) => {
  return (
    <Modal
      visible={visible}
      title="软件安装许可协议"
      style={{ top: 30 }}
      width="1000px"
      onCancel={closeModal}
      footer={[
        <Button key="protocolok" type="primary" onClick={closeModal}>
          确认并关闭
        </Button>,
      ]}
    >
      <div className={styles.content}>
        <p>
          <strong>【采票服务】</strong>是一款为方便用户开展发票业务推出的客户端软件，一旦安装、复制或以其他方式使用本软件产品，即表示同意接受关于<strong>【采票服务】</strong>软件产品（以下简称“本软件产品”）相关法律协议各项条件的约束。如果您不同意协议的条件，请不要使用本软件产品。
        </p>
        <p>一、软件产品保护条款</p>
        <p>
          1）本软件产品之著作权及其它知识产权等相关权利或利益（包括但不限于：现已取得或未来可取得之著作权、专利权、商标权、商业秘密等）皆为我们或我们的合作方所有。本软件产品受《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》等相关法律法规保护。用户仅获得本软件产品的非排他性使用权。
        </p>
        <p>
          2）您不得删除本软件产品及其他副本上一切关于版权的信息；对本软件产品进行反向工程，如反汇编、反编译等；
        </p>
        <p>
          3）<strong>本软件产品以现状方式提供，我们或我们的合作方不保证本软件产品能够完全满足用户需求，在用户手册、帮助文件、使用说明书等软件文档中的介绍性内容仅供用户参考，不得理解为对用户所做的任何承诺。</strong>我们或我们的合作方保留对软件版本进行升级，对功能、内容、结构、界面、运行方式等进行修改或自动更新的权利。
        </p>
        <p>
          4）使用本软件产品由您自行承担相关风险，在适用法律允许的最大范围内，我们或我们的合作方在任何情况下不就因使用或不能使用本软件产品所发生的特殊的、意外的、非直接或间接的损失承担赔偿责任。即使已事先被告知该损害发生的可能性。
        </p>
        <p>
          5）我们定义的信息内容包括：文字、软件等我们为您提供的商业信息，所有这些内容受版权（著作权）、商标权、专利和其它知识产权和所有权法律的保护。所以，您只能在我们授权下才能使用这些内容，而不能擅自复制、修改、编撰这些内容、或创造与内容有关的衍生产品。
        </p>
        <p>
          6）如果您未遵守本条的任何一项条款，我们有权立即终止本协议，并保留通过法律手段追究相关责任的权利。
        </p>
        <p>二、信息处理授权</p>
        <p>
          1）您理解，为了给您提供客观、科学、全面的信用管理、评价，以及我们为您提供的其他服务（投融资咨询等）的需要，您授权我们可以使用本软件产品“处理”（指通过“<strong>【采票服务】</strong>软件产品对您的销项发票、进项发票信息进行采集、查询或验证、使用、共享、保存、整理、分析、比对、演算、归纳及（或）加工等各项操作，下同）您的销项发票、进项发票信息（下称“发票信息”）。您已经认识到您的发票信息对于您而言是相当私密而重要的。您确认我们通过本软件产品在适用的法律法规许可及您的授权文件（包括但不限于本协议及您签署的其他授权文件）的范围内处理这些信息时，您已经充分理解并知晓该等信息被处理的风险。
          <strong>这些风险包括但不限于:纳入这些信息对您的信用评分、融资评分等结果可能产生的不利影响，因您的信用状况较好而造成您被第三方向您推销产品或服务等商业广告的风险。</strong>
        </p>
        <p>
          2）您同意授权处理您的发票信息的第三方包括与我们签订合作协议的正规机构及提供软件技术服务的正规机构，用于向您提供服务。
        </p>
        <p>
          3）为降低您身份被冒用等风险事件而影响您享受有关服务的风险，您授权我们及我们的合作机构可向留存您信息的第三方机构进行对比分析，直接向该第三方输出您的信息。
        </p>
        <p>
          4）为了给您提供更多优质的服务，包括享受有关优惠、享受更加便捷的流程体验等，我们会向我们合作或服务的机构共享服务所必须的您的发票信息、或去标识化共享匿名化后的发票信息。
        </p>
        <p>5）您同意在我们为您提供服务期间的授权不可变更、撤回或撤销。</p>
        <p>
          6）如果您对于我们的服务有异议，您可以向我们提出，我们将及时进行核查、处理及答复。
        </p>
        <p>三、协议的补充与修改</p>
        <p>
          我们具有对本协议补充或修改的权利。如您对我们的补充或修改有异议，应当立即停止使用本软件产品。您继续使用本软件产品的行为将被视为对我们的解释或修改的接受。
        </p>
        <p>四、争议解决</p>
        <p>
          若您与我们发生任何争议，您同意首先应友好协商解决。若协商不成的，您同意将争议提交至我们公司所在地有管辖权的人民法院管辖。本协议适用中华人民共和国大陆地区法律。
        </p>
        <p>五、声明</p>
        <p>
          您已知悉本协议所有内容，并对本协议条款的含义及相应的法律后果已全部通晓并充分理解，自愿接受本协议并同意承担由此带来的一切法律后果。
        </p>
      </div>
    </Modal>
  );
};

export default InstallProtocol;
