/** 云南、海南办税人类型MAP */
export const YUNNAN_BSR_TYPE_MAP = new Map([
  ['cwfzr', '财务负责人'],
  ['bsr', '办税员'],
  ['fddbr', '法定代表人'],
  ['smbs', '实名办税'],
  /** 根据官网推测 */
  ['dzswj', '办税员']
]);

/** 安徽办税人类型MAP */
export const ANHUI_BSR_TYPE_MAP = new Map([
  ['01', '法定代表人'],
  ['02', '财务负责人'],
  ['03', '办税人'],
  ['04', '财务代理人(涉税服务人员)'],
  ['05', '普通管理员'],
  ['06', '出口退税人员'],
  ['07', '购票员'],
  ['08', '社保联系人'],
  ['99', '其他人员']
]);

