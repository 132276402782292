import * as smengApi from '@/api/smeng';
import { showErrorMessage } from "@/utils/taxUtil";
import { message } from 'antd';
import { get } from 'lodash';
// import { get } from "lodash";

class SmengStore {

  /** 授权 */
  auth = (params) => {
    return smengApi.auth(params)
    .then((res) => {
      console.log(res);
      const data = res?.data?.data || {}
      if (data.code === '10000') {
        return
      } 
      if (data.code === '10009') {
        return data.data
      }
      return Promise.reject(data.message);
    })
    .catch(error => {
      if (typeof error === 'string') {
        message.error(error);
        return Promise.reject(error);
      }
      showErrorMessage(error, '发送验证码失败');
      return Promise.reject(error);
    })
  }

  /** 检查商盟采数状态 */
  checkSmengStatus = (params) => {
    return smengApi.checkStatus(params)
      .then(resp => {
        const status = get(resp, 'data.data');
        return status;
      })
  }

};

export default new  SmengStore();
