import React, { useEffect, useState } from 'react'
import styles from './index.module.less';
import { Button, Form, Input, Modal, message } from 'antd';
import CaptchaInput from '@/components/Captcha/CaptchaInput';
import { postSendSms, postVerify } from '@/api/smeng';
import { requestMiddleware } from '@/utils/taxUtil';
import { encryptedParams } from '@/utils';

const configuration = {
  DX: {
    title: '短信认证',
    des: '点击“获取验证码”,短信接收4位安全码',
    showPhow: true
  },
  QY: {
    title: '企业微信认证',
    des: '点击“获取验证码”,企业微信接收4位安全码',
    showPhow: false
  },
}

/** 
 * 验证modal
 */
const VerifyModal = (props) => {
  const { data, queryParams, onAuthSuccess } = props;
  const [form ] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { verify_type } = data || {};
  const [visible, setVisible] = useState(false)
  const config = configuration[verify_type] || {}

  useEffect(() => {
    if (data) {
      setVisible(true)
    }
  }, [data])
  

  const onSendCaptch = async () => {
    const res = await requestMiddleware(postSendSms({
      orderNo: queryParams.serialNo,
      manageUnitUuid: data.manage_unit_uuid
    }))
    message.success('发送成功！请注意查收')
  }

  const onSubmit = async (values) => {
    setLoading(true)
    const params = encryptedParams({
      accountUuid: data.account_uuid,
      orderNo: queryParams.serialNo,
      manageUnitUuid: data.manage_unit_uuid,
      verifyCode: values.verifyCode?.trim(),
      pwd: data.values?.password,
    }, ['pwd'])
    await requestMiddleware(postVerify(params)).catch((err) => {
      setLoading(false)
      return Promise.reject(err)
    })
    onAuthSuccess()
    setLoading(false)
  }

  return (
    <Modal
      className={styles.index}
      title={config.title}
      visible={visible}
      footer={null}
      centered
      onCancel={() => setVisible(false)}
      maskClosable={false}
    >
      <p className={styles.des}>{config.des}</p>
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          phone: data?.mobile
        }}
      >
        {
          config.showPhow && (
            <Form.Item
              label="手机号"
              name="phone"
            >
              <Input disabled />
            </Form.Item>
          )
        }
        <Form.Item
          label="短信验证码"
          name="verifyCode"
          rules={[{ required: true, whitespace: true ,message: '请输入短信验证码' }]}
        >
          <CaptchaInput
            placeholder='请输入验证码'
            captchProps={{
              needPhone: false,
              onSendCaptch: onSendCaptch
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} className={styles.btn} type="primary" htmlType='submit'>提交</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default VerifyModal