import { configure, makeAutoObservable, isObservable } from 'mobx';

// 检查是否是 ie 浏览器
export function checkIeBrowser(notLowerThanVerison) {
  const userAgent = navigator.userAgent;
  // 判断是否IE<11浏览器
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
  // 判断是否IE11浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  // 判断是否为 Edge 浏览器
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE;
  if (notLowerThanVerison) {
    if (notLowerThanVerison > 11) {
      return isEdge;
    }
    if (notLowerThanVerison === 11) {
      return isEdge || isIE11;
    }
  }
  return isIE || isIE11 || isEdge;
}
const isIE = checkIeBrowser();

export function mobxPolyfill() {
  configure({
    enforceActions: 'always',
    useProxies: isIE ? 'never' : 'always'
  });
}

export function mobxFactory(stores) {
  for (const key in stores) {
    if (Object.hasOwnProperty.call(stores, key)) {
      const store = stores[key];
      if (!isObservable(store)) {
        stores[key] = makeAutoObservable(store, undefined, {proxy: !isIE});
      }
    }
  }
  return stores;
}
