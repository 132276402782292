import { taxAxios } from '@/utils/taxAxios';

/** 获取授权参数 */
export const getHgUrlParam = (params) => {
  return taxAxios.get('/api/dbc/hg/parseHgUrlParam', { params });
}

/** 登录授权 */
export const auth = (params) => {
  return taxAxios.post('/api/dbc/hg/auth', params);
}

/** 采集 */
export const collectSwCrawl = (params) => {
  return taxAxios.post(`/api/dbc/hg/collect/swCrawl?serialNo=${params.serialNo}`, params);
}

/** 报关单状态查询 */
export const checkeCusQueryStatus = (params) => {
  return taxAxios.get('/api/dbc/hg/cusQueryStatus', { params});
}

/** 报关单查询列表 */
export const getCusQueryList = (params) => {
  return taxAxios.get('/api/dbc/hg/cusQueryList', { params });
}

/** 报关单详情多条 */
export const getDecDetail = (params) => {
  return taxAxios.post('/api/dbc/hg/getDecDetail', params);
}

/** 报关单详情单条 */
export const getDecDetailSingle = (params, serialNo) => {
  return taxAxios.post(`/api/dbc/hg/getDecDetail/single?serialNo=${serialNo}`, params);
}

/** 登录，cookice失效后登录 */
export const hgLogin = (params) => {
  return taxAxios.post(`/api/dbc/hg/hgLogin`, params);
}

/** 轮询获取需要加密的报关单号列表 */
export const getSwSignNos = (params) => {
  return taxAxios.get(`/api/dbc/hg/collect/swSignNos`, { params })
}

/** 上传报关单号加密数据 */
export const postSwCrawlRest = (params) => {
  return taxAxios.post(`/api/dbc/hg/collect/swCrawlRest`, params);
}