export const taxpayerType = {
  VAT_GENERAL_TAXPAYER: "增值税一般纳税人",
  VAT_SMALL_SCALE_TAXPAYER: "增值税小规模纳税人"
}

export const criterionType = {
  STANDARD_1: "执行会计准则制度",
  STANDARD_2: "执行小企业会计准则",
  STANDARD_3: "适用于已执行新金融准则、新收入准则和新租赁准则的一般企业",
  STANDARD_4: "适用于未执行新金融准则、新收入准则和新租赁准则的一般企业",
}

const quarter = [
  {
    value: 'FIRST_QUARTER',
    label: '第一季度'
  },
  {
    value: 'TWO_QUARTER',
    label: '第二季度'
  },
  {
    value: 'THREE_QUARTER',
    label: '第三季度'
  },
  {
    value: 'FOUR_QUARTER',
    label: '第四季度'
  },
]

export const getFiveYaer = () => {
  const endYear = new Date().getFullYear();
  const startYear = endYear - 4;
  let years = [];
  for (let i = endYear; i >= startYear; i--) {
    years.push({
      value: i,
      label: `${i}年`,
      children: quarter,
    })
  }
  return years;
}

// 财务报表
// 汇总
export const financeTaxTotal = [
  {
    text: '请上传财务报表',
    type: 'FINANCE_REPORT_0'
  }
]
// 分表
export const financeTaxSingle = [
  {
    text: '请上传资产负债表',
    type: 'FINANCE_REPORT_1'
  },
  {
    text: '请上传现金流量表',
    type: 'FINANCE_REPORT_2'
  },
  {
    text: '请上传利润表',
    type: 'FINANCE_REPORT_3'
  },
]

// 增值税报表
// 汇总
export const vatTaxTotal = [
  {
    text: '请上传增值税报表',
    type: 'VAT_REPORT_0'
  }
]
// 分表
export const vatTaxSingle = [
  {
    text: '请上传主表',
    type: 'VAT_REPORT_1'
  },
  {
    text: '请上传附表1（销售情况明细表）',
    type: 'VAT_REPORT_2'
  },
  {
    text: '请上传附表2（进项税额明细表）',
    type: 'VAT_REPORT_3'
  },
  {
    text: '请上传附表3（服务，不动产和无形资产扣除项目明细表）',
    type: 'VAT_REPORT_4'
  },
  {
    text: '请上传附表4（税额抵减情况表）',
    type: 'VAT_REPORT_5'
  },
  {
    text: '请上传附表5（增值税减免税申报明细表）',
    type: 'VAT_REPORT_6'
  },
]

// 所得税报表
// 汇总
export const incomeTaxTotal = [
  {
    text: '请上传所得税申报表',
    type: 'INCOME_TAX_REPORT_0'
  }
]
// 分表
export const incomeTaxSingle = [
  {
    text: '请上传企业所得税月季申报表(A类)',
    type: 'INCOME_TAX_REPORT_1'
  },
  {
    text: '请上传免税收入,减计收入所得减免等优惠明细表',
    type: 'INCOME_TAX_REPORT_2'
  },
  {
    text: '请上传固定资产加速折旧(扣除)优惠明细表',
    type: 'INCOME_TAX_REPORT_3'
  },
  {
    text: '请上传减免所得税优惠明细表',
    type: 'INCOME_TAX_REPORT_4'
  },
]