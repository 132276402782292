import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';

class Anhui {

  getTokenCaptcha = () => {
    return provinceValidatorApi.getTokenCaptcha()
      .then(({ data }) => {
        return get(data, 'data');
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        throw error;
      });
  }
}

export default new Anhui();
