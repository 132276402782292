import RiZhaoBusiness from '@/pages/business/rizhao';

const businessRoutes = [
  // 创建授权页面
  {
    path: "/rzBusiness",
    component: RiZhaoBusiness,
  },
]

export default businessRoutes;