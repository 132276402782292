import styled from 'styled-components';
import { Cascader } from 'antd';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CustomCascader = styled(Cascader)`
  width: 500px !important;
`;
