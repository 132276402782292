import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import Verification from '@/pages/electronicTaxBureau/components/Verification';
import { FORM_STEP_MAP } from '../helper/data';

const TaxFormVerification = ({
  NEW_AUTH,
  taxHelperStore,
  taxAuthStore,
  isProvince,
  formStep,
  onFinishAuth,
}, parentRef) => {

  const verificationRef = useRef();

  useImperativeHandle(parentRef, () => verificationRef.current);

  const provinceKey = taxHelperStore.getProvincePingYing();
  const params = taxHelperStore.setQueryParams;
  if (formStep === FORM_STEP_MAP.get('first')) {
    let provinceList = ['江西' ];
    // 旧版
    if (!NEW_AUTH) {
      provinceList = provinceList.concat([ '江苏', '山东', '贵州', '吉林'])
    }
    if (isProvince(provinceList)) {
      return (
        <Verification
          ref={verificationRef}
          taxAuth={taxAuthStore}
          NEW_AUTH={NEW_AUTH}
          onFinishAuth={onFinishAuth}
          params={params}
          provinceKey={provinceKey}
        />
      );
    }
  }
  return null;
};

export default inject(
  'taxHelperStore',
  'taxAuthStore'
)(observer(forwardRef(TaxFormVerification)));
