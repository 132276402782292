import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import IconImg from '@/assets/images/mobileAuth/icon.png';
import ClipboardJS from 'clipboard';
import styles from './index.less';

const MobileAuth = ({ hideImage }) => {
  useEffect(() => {
    const clipboard = new ClipboardJS('#copy', {
      text: () => window.location.href
    });
    clipboard.on('success', function() {
      message.info('复制成功!');
      
    })
    clipboard.on('error', function() {
      message.error('复制失败!');
    })
    return function cleanup() {
      clipboard.destroy();
    }
  }, [])
  return(
    <div className={styles.box} style={{minHeight: hideImage ? 'auto' : '100vh'}}>
      { hideImage ?
        null :
        <img className={styles.icon} src={IconImg} alt=""/>
      }
      <p>目前暂不支持移动端授权，您可以复制以下链接到web端打开</p>
      <p
        id="link"
        className={styles.link}>
        {window.location.href}
      </p>
      <Button
        type="primary"
        className={styles.btn}
        id="copy">
        复制链接
      </Button>
    </div>
  )
}
export default MobileAuth