import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import decryptedParams from '@/utils/decryptedParams';
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as _ from "lodash";
import { reload } from ".";

const errMsgList = [
  '登录失败，请刷新页面并重新登陆',
  '系统错误，请刷新后重试。',
  '登录超时，请重新登录',
  '流程超时,请重新登陆！',
  '获取token异常，请刷新重试',
  '当前会话已失效，请重新获取办税员列表。',
  '当前是未登录状态',
  '登录会话异常,请重新刷新页面！',
  '身份认证已失效，请重新登录！',
]

let showMsg = false;

export const taxAxios = axios.create({});

taxAxios.interceptors.response.use((response) => {
  
  const msg = _.get(response, 'data.message')
  // 匹配到列表错误信息时，提示立即刷新
  if (errMsgList.includes(msg) && !showMsg) {
    showMsg = true
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '身份认证已失效，请刷新页面重新登录！',
      okText: '立即刷新',
      cancelText: '取消',
      onOk: () => reload()
    });
  }
  return response
})

export const setSetTaxAxiosHeader = (key, value) => {
  taxAxios.interceptors.request.use((axiosConfig) => {
    const { params } = axiosConfig;
    if (key && value) {
      axiosConfig.headers[key] = value;
    }
    axiosConfig.params = { ...params, sp: uuidv4() };
    return axiosConfig;
  });
}


/** 初始化税局授权taxAxios */
export const initTaxAsios = () => {
  const params = decryptedParams();
  if (params.swiftCode) {
    setSetTaxAxiosHeader('swift-code', params.swiftCode)
  }
  const taxKey = params.taxKey;
  if (taxKey) {
    document.cookie = `taxKey=${taxKey}`;
    setSetTaxAxiosHeader('tax-key', taxKey)
    return false;
  }
  
  const savedtaxKey = decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent('taxKey').replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  if (savedtaxKey) {
    setSetTaxAxiosHeader('tax-key', savedtaxKey)
    return false;
  }
  setSetTaxAxiosHeader();
}
