import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input, Button, Modal, message } from 'antd';
import { withRouter } from 'react-router';
import HubeiCaAuthHoc from '../HubeiCaAuthHoc';
import SpTypeFormItem from '@/pages/electronicTaxBureau/components/FormComp/FormItemComp/SpTypeFormItem';

import TaxAuthProtocol from '@/components/common/TaxAuthProtocol';
import caIcon from '@/assets/images/hubei/ca.png';
import styles from './index.less';
const inputSize = 'large';

class HubeiCaAuth extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      agreeAuthProtocol: false
    };
    this.infoFormRef = React.createRef();
  }

  componentDidMount() {

    this.handleCheckSPDrive()
      .catch(() => {});
    
    const { qymc } = this.props.hubeiSPLoginStore.queryParams;
    this.infoFormRef.current.setFieldsValue({'companyName': qymc});
    
  }

  login = () => {
    this.infoFormRef.current.validateFields()
      .then(data => {
        this.props.taxHelperStore.setAuthFormData(data);
        if (!this.state.agreeAuthProtocol) {
          message.error('请先阅读并同意相关协议');
          throw new Error('请先阅读并同意相关协议');
        }
        const { isDriveError, isDeviceError, setValue } = this.props.hubeiSPLoginStore;
        setValue('formValue', data);
        if (isDriveError) {
          this.setState({ isLoading: true });
          return this.handleCheckSPDrive()
            .then(() => this.auth())
            .then(() => {
              this.setState({ isLoading: false });
            })
            .catch(error => {
              this.setState({ isLoading: false });
              console.log('error: ', error);
            })
        }
        if (isDeviceError) {
          this.setState({ isLoading: true });
          return this.handleCheckSPDrive()
            .then(() => this.auth())
            .then(() => {
              this.setState({ isLoading: false });
            })
            .catch(error => {
              this.setState({ isLoading: false });
              console.log('error: ', error);
            })
        }

        this.setState({ isLoading: true });
        this.auth()
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }
 
  /** 检查驱动 */
  handleCheckSPDrive = () => {
    const { strProvider, strContainer, dwProvType, crosFlag } = this.props.hubeiSPLoginStore;
    const params = {
      strProvider,
      strContainer,
      dwProvType,
      crosFlag
    };
    return this.props.hubeiSPLoginStore.checkSPDrive(params)
    .catch((error) => {

      if (error.readyState === 0) {
        this.handleDriveError();
      } else if(error.code === 167) {
        this.handleDeviceError();
      }
      return Promise.reject(error);
    });
  }

  auth = () => {
    const { strProvider, strContainer, dwProvType, crosFlag, authType } = this.props.hubeiSPLoginStore;
    const params = {
      strProvider,
      strContainer,
      dwProvType,
      crosFlag,
      authType
    };
    return this.props.hubeiSPLoginStore.gethello(params)
      .catch((errorData) => {
        if (errorData.code === 167) {
          this.handleDeviceError();
        } else {
          message.error(errorData.msg);
        }
        return Promise.reject(new Error('未获取到设备'));
      })
      .then((clienthello) => this.props.hubeiSPLoginStore.ajaxaction(clienthello))
      .then(({ serverHello, serverHello1 }) => this.props.hubeiSPLoginStore.getserverhello(serverHello, serverHello1))
      .then(({ clientAuth, serverHello, serverHello1 }) => this.props.hubeiSPLoginStore.getnsrsbh(clientAuth, serverHello, serverHello1))
      .then(({ clientAuth, serverHello, serverHello1, nsrsbhh }) => this.props.hubeiSPLoginStore.ajaxaction2(clientAuth, serverHello, serverHello1, nsrsbhh))
      .then((data) => {
        // const { cburl, serialNo } = this.props.hubeiSPLoginStore.queryParams;
        // this.props.history.replace(cburl ? `/authSuccess?serialNo=${serialNo}&cburl=${cburl}&watch=true` : `/authSuccess?serialNo=${serialNo}&&watch=true`);
        this.props.onFinishAuth();
      })
      .catch((error) => {
        console.log('error: ', error);
        return Promise.reject(error);
      });
  }

  handleDriveError = () => {
    Modal.warning({
      title: '未检测到驱动',
      content: <div>未检测到驱动，请先<a target="_blank" rel="noreferrer" href="https://etax.hubei.chinatax.gov.cn/portal/qdxz.c">下载对应驱动</a></div>
    });
  }

  handleDeviceError = () => {
    message.error('请插入CA证书');
  }

  hanldeAuthProtocol = () => {
    this.setState({
      agreeAuthProtocol: !this.state.agreeAuthProtocol
    })
  }

  btnText = () => {
    const taxType = this.props.taxHelperStore.getTaxType();
    if (['DZ_ANO', 'DZ_WFQ'].includes(taxType)) {
      return '下一步';
    }
    return '确认';
  }

  render() {
    const { nsrsbh } = this.props.hubeiSPLoginStore.queryParams;
    const taxType = this.props.taxHelperStore.getTaxType();
    return (
      <React.Fragment>
        <div className={styles.tip}>
          <img src={caIcon} alt="" />
          请插入CA证书，完成授权
        </div>
        <Form
          layout="vertical"
          ref={this.infoFormRef}
        >
          <Form.Item
            key="companyName"
            colon={false}
            label="企业名称："
            name="companyName"
            rules={[
              { required: true, message: '请输入企业名称'}
            ]}
            extra={`统一社会信用代码：${nsrsbh}`}
          >
            <Input disabled size={inputSize} placeholder="输入企业名称" />
          </Form.Item>
          <Form.Item
            key="ca_password"
            colon={false}
            label="证书密码："
            name="ca_password"
            rules={[
              { required: true, message: '请输入证书密码'}
            ]}
          >
            <Input.Password size={inputSize} placeholder="输入证书密码" />
          </Form.Item>
          {
            ['PRO', 'DZ_PRO', 'PLUS', 'DZ_PLUS'].includes(taxType) ? <SpTypeFormItem inputSize={inputSize} /> : null
          }
          <TaxAuthProtocol
            isAgree={this.state.agreeAuthProtocol}
            onChange={this.hanldeAuthProtocol}
            isOnlyAuthProtocol={['DZ_ANO'].includes(taxType)}
          >
            <Button
              block
              type="primary"
              size={inputSize}
              onClick={this.login}
              style={{marginTop: '25px'}}
              loading={this.state.isLoading}>{ this.btnText() }</Button>
          </TaxAuthProtocol>
        </Form>
      </React.Fragment>
    );
  }
}

export default inject(
  'hubeiSPLoginStore',
  'taxHelperStore'
)(withRouter(observer(HubeiCaAuthHoc(HubeiCaAuth))));
