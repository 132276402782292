import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

/** 河南 */
class Henan {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = (data) => {
    const params = {
      ...data,
      authentication: 'tpass.henan.chinatax.gov.cn:8443'
    }
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then((resp) => {
        const { mobile, uuid } = get(resp, 'data.data.data') || {};
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{
          label: mobile,
          value: mobile
        }];
        return this.bsrsList;
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = (props) => {
    const { formData, stepOneFormData } = props;
    const { bsr } = formData;
    const item = this.bsrs.find(item => item.mobile === bsr);
    const{ taxAccount, taxPassword } = stepOneFormData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params =  {
      userName: taxAccount,
      userPass: taxPassword,
      phoneNumber: bsr,
      uuid: item.uuid,
      serialNo,
      province,
      creditCode: nsrsbh,
      authentication: "tpass.henan.chinatax.gov.cn:8443",
      // uuid: bsr
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const smscodeId = get(resp, 'data.data.smscode_id') || '';
        this.smscodeId = smscodeId;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { captcha, bsr } = formData;
    const brsInfo = this.bsrs[0] || {};
    const params = {
      'uuid': brsInfo.uuid,
      'smsCodeId': this.smscodeId,
      'captcha': captcha,
      'serialNo': serialNo,
      'creditCode': nsrsbh,
      'province': province,
      'yddh': brsInfo.mobile,
      'authentication':'tpass.henan.chinatax.gov.cn:8443'
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  };

}

export default new Henan();