import styled from "styled-components";

export const FloatCard = styled.div`
  display: ${props => props.visible ? 'block':'none'};
  position: absolute;
  background: white;
  border-radius: 8px;
  top: ${props => (props.top + 'px')};
  left: ${props => (props.left + 'px')};
  z-index: 1000;
  transform: translate(-50%, -50%);
  padding: 8px 0;
`;

function Popup({visible, top, left, children}) {
  return (
    <FloatCard visible={visible} top={top} left={left}>
      {children}
    </FloatCard>
  )
}

export default Popup;