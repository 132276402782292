import React, { useState, useEffect, useMemo } from "react";
import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import { isProvinceOf } from './helper/func';
import { FORM_STEP_MAP } from './helper/data';
import { get } from 'lodash';
import TaxForm from './TaxForm';
import TaxFormButton from "./TaxFormButton";
import TaxFormAuthProtocol from './TaxFormAuthProtocol';
import TaxFormVerification from './TaxFormVerification';
import ExtraComponent from './ExtraComponent';
import useProvinceStore from "@/hooks/useProvinceStore";
import { AUTH_METHOD_TYPE_MAP } from '@/utils/taxUtil/dict';

import {getFakeAccountData} from "@/pages/guide/fake";

import styles from './index.less';

const TaxBureauStep = ({
  taxHelperStore,
  /** 是否新版授权 */
  NEW_AUTH,
  onFinishAuth }) => {

  /** 是否同意授权协议 */
  const [ agreeAuthProtocol, setAgreeAuthProtocol ] = useState(false)
  /** 验证器引用 */
  const [ verificationRef ] = useState(React.createRef());
  /** 表单区域引用 */
  const [ taxFormRef ] = useState(React.createRef());
  /** 表单步骤 */
  const [ formStep, setFormStep ] = useState(FORM_STEP_MAP.get('first'));
  /** 表单loading */
  const [ formLoading, setFormLoading ] = useState(false);

  /** 获取办税人列表loading */
  const [ bsrLoading, setBsrLoading ] = useState(false);

  /** 第一步表单数据 */
  const [ stepOneFormData, setStepOneFormData ] = useState(null);

  /** 江苏授权第一步的图形验证码结果，存储发送手机验证时会使用 */
  const [ jiangshuImageCaptcha, setJiangshuImageCaptcha ] = useState('');

  /** 贵州授权的第一次获取手机验证码的图片验证码结果，存储第二次发送手机验证码时会使用 */
  const [ guizhouImageCaptcha, setGuizhouImageCaptcha ] = useState('');

  const provinceKey = taxHelperStore.getProvincePingYing();
  const provinceStore = useProvinceStore("guide", NEW_AUTH);


  /**
   * 授权方式，（暂时只有山东身份使用）
   * SMS 短信验证码授权, PWD 账密授权, QR 扫二维码授权, WeChat 微信授权
   */
  const [ authMethodType, setAuthMethodType] = useState(AUTH_METHOD_TYPE_MAP.get('PWD'));

  /** 办税员列表 */
  const roleList = useMemo(() => provinceStore.bsrsList, [ provinceStore.bsrsList ]);


  /** 表单实例 */
  const formInstances = () => {
    return taxFormRef.current.formRef.current;
  };

  /** 修改表单显示步骤 */
  const changeFormStep = (step) => {
    // const { province } = taxHelperStore.authQueryParams;

    // formInstances().resetFields();

    setFormStep(step);
    // formInstances().setFieldsValue({
    //   captcha: '123456',
    // })
    // setTimeout(() => {
    //   console.log("set value")
    //   formInstances().setFieldsValue({
    //     captcha: '123456'
    //   })
    // });
  }

  /**
   * 自动填入授权省份，企业名称
   */
  useEffect(() => {
    const { qymc, province } = taxHelperStore.authQueryParams;
    const fakeData = getFakeAccountData(province, NEW_AUTH);
    formInstances().setFieldsValue({
      companyName: qymc,
      taxDeclareProvince: province,
      ...fakeData,
    });
  }, [ formStep, taxHelperStore.authQueryParams ]);

  useEffect(() => {
    // 立即获取办税员列表
    if (isProvince(['辽宁']) && !NEW_AUTH) {
      const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
      const params = {
        creditCode: nsrsbh,
        userName: nsrsbh,
        province,
        serialNo
      };
      getRoleList(params, { serialNo });
    }
  }, []);


  /** 检查是否同意协议 */
  const isAgreeP =async (params) => {
    if (!agreeAuthProtocol) {
      message.error('请先阅读并同意相关协议');
      throw new Error('请先阅读并同意相关协议');
    }
    return params;
  }

  /** 判断是否是当前省份 */
  const isProvince = (provinceName) => {
    const provinceKey = taxHelperStore.getProvincePingYing();

    return isProvinceOf(provinceKey)(provinceName);
  }

  /** 获取办税员列表 */
  const getRoleList = (params, options) => {
    setBsrLoading(true)
    return provinceStore.getRoleList(taxHelperStore.getQueryParams('province'), options, NEW_AUTH).finally(() => setBsrLoading(false))
  };

  /** 获取表单数据 */
  const formData = () => {
    return Promise.resolve(formInstances().getFieldsValue(true))
      .then(formData => {
        // 将表单值保存到store
        taxHelperStore.setAuthFormData(formData);
        return formData;
      });
  }

  /** 验证码数据 */
  const verificationData = () => {
    // console.log("yan zhen")
    return Promise.resolve().then(() => {
      let veriData;
      if (isProvince('贵州') && !NEW_AUTH) {
        veriData = '123456';
        setGuizhouImageCaptcha(veriData);
      }
      return veriData;
    })
  }

  const hanldeAuthProtocol = (val) => {
    setAgreeAuthProtocol(val);
  };

  // Mark: 新加 公共授权
  /** 公共授权 */
  const handleSubmitCommon = () => {
    return formData()
      .then(formData => isAgreeP(formData))
      // .then(formData => isGetCaptchaP(formData))
      .then(formData => {
        setFormLoading(true);
        return formData;
      })
      .then(formData => {
        let options = {};
        if (isProvince('内蒙古')) {
          options = { roleList}
        }

        return provinceStore.taxAuth({ formData, stepOneFormData, options }, NEW_AUTH);
      })
      .then(() => {
        // debugger
        onFinishAuth();
      })
      .catch(error => {
        /** 登录超时返回第一步重新登录 */
        if (isProvince('上海')) {
          const errorCode = get(error.response, 'data.errorCode');
          if (errorCode === 401206) {
            changeFormStep(FORM_STEP_MAP.get('first'));
          }
        }
      })
      .finally(() => {
        setFormLoading(false);
      });
  }

  /** 公共发送验证码 */
  const handleSendCaptcha = (validateFields) => {
    if (isProvince('贵州') && !NEW_AUTH) {
      if (formStep === FORM_STEP_MAP.get('first')) {
        return formInstances().validateFields(validateFields)
          .then(formData => verificationData().then(veriData => ({ ...formData, veriData })))
          .then(formDataExt => provinceStore.sendCaptcha({ formData: formDataExt, stepOneFormData }));
      }
      if (formStep === FORM_STEP_MAP.get('second')) {
        const options = { guizhouImageCaptcha };
        return formInstances().validateFields(validateFields)
          .then(formData => provinceStore.sendCaptcha({ formData, stepOneFormData, options }))
      }
    }
    return formInstances().validateFields(validateFields)
      .then(formData => {
        let options = {};
        if (isProvince('内蒙古')) {
          options = { roleList }
        }
        return provinceStore.sendCaptcha({ formData, stepOneFormData, options })
          .then(resp => {
          });
      });
  }

  // Mark: getBsrList 获取办税员列表

  /** 获取办税员列表 */
  const getBsrList = async () => {
    Promise.resolve(formInstances().getFieldsValue())
      .then((formData) => {
        if (isProvince('吉林') && !NEW_AUTH) {
          return Promise.resolve(formData).then(
           formData => {
              return verificationData().then(() => (formData));
            }
          );
        }
        if (isProvince('云南')) {
          return Promise.resolve(formData);
        }
        if (isProvince('贵州')  && !NEW_AUTH) {
          return Promise.resolve(formData)
            // .then(formData => isGetCaptchaP(formData))
            // .then((formData) => isAgreeP(formData));
        }
        /** 江西税局 取消点击下一步时的验证码 */
        // if (isProvince('江西')) {
        //   return Promise.resolve(formData)
        //     .then(formData => {
        //       return verificationData().then(veridata => {
        //         return { ...formData, captchaVerification: veridata };
        //       });
        //     });
        // }
        return formData;
      })
      .then(formData => {
        setStepOneFormData(formData);
        setFormLoading(true);
        const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
        const { taxAccount, taxPassword, captchaVerification, bsrType } = formData;
        let postData = {
          creditCode: nsrsbh,
          userName: taxAccount,
          userPass: taxPassword,
          /** 接口必传字段，安徽授权传验证器字段 */
          captchaVerification: captchaVerification ? captchaVerification : '',
          province,
          serialNo,
          sflx: bsrType
        };
        return postData;
      })
      .then(params => {
        const { serialNo } = taxHelperStore.authQueryParams;
        return getRoleList(params, { serialNo }, NEW_AUTH)
          .catch( error => {
            return Promise.reject(error);
          })
      })
      .then((data) => {
        setFormLoading(false);
        /** 贵州获取办税员列表，如果手机号为办税员手机号则会直接授权成功 */
        if (isProvince('贵州') && data?.loginSuccess && !NEW_AUTH) {
          onFinishAuth();
          return false;
        }
        changeFormStep(FORM_STEP_MAP.get('second'));
      })
      .catch(error => {
        console.log('error: ', error);
        setFormLoading(false);
      });
  }

  // Mark: 获取短信验证码

  /** 获取短信验证码 */
  const getCaptcha = () => {
    return handleSendCaptcha(['bsr']);
  }

  // Mark: handleSubmit 提交按钮方法

  const handleSubmit = () => {
    let provinceList = ['广东', '浙江'];
    // 旧版
    if (!NEW_AUTH) {
      provinceList = provinceList.concat([ '山东', '重庆', '湖南', '辽宁' ])
    }
    if (isProvince(provinceList)) {
      return handleSubmitCommon;
    }
    if (formStep === FORM_STEP_MAP.get('first')) {
      return getBsrList;
    }
    if (formStep === FORM_STEP_MAP.get('second')) {
      return handleSubmitCommon;
    }
  }


  return (
    <div className={styles.content}>
      <div className={styles.formWrap}>
        {/* 授权表单 */}
        {
          <TaxForm
            provinceStore={provinceStore}
            isProvince={isProvince}
            ref={taxFormRef}
            bsrLoading={bsrLoading}
            getCaptcha={getCaptcha}
            formStep={formStep}
            formInstances={formInstances}
            NEW_AUTH={NEW_AUTH}
          />
        }
        {/* 验证码器组件 */}
        <TaxFormVerification
          isProvince={isProvince}
          NEW_AUTH={NEW_AUTH}
          formStep={formStep}
          onFinishAuth={onFinishAuth}
          ref={verificationRef}
        />
        {/* 协议组件 */}
        <TaxFormAuthProtocol
          isProvince={isProvince}
          NEW_AUTH={NEW_AUTH}
          formStep={formStep}
          isAgree={agreeAuthProtocol}
          onChange={hanldeAuthProtocol}
        />
        {/* 按钮 */}
        <TaxFormButton
          formStep={formStep}
          isProvince={isProvince}
          authMethodType={authMethodType}
          NEW_AUTH={NEW_AUTH}
          btnOption={{
            block: true,
            size: 'large',
            type: 'primary',
            loading: formLoading,
            onClick: handleSubmit()
          }}
        />
      </div>
    </div>
  );

}

export default inject(
  'taxHelperStore',
)(observer(TaxBureauStep));