import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input, Button, Modal, message, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { looprquest, reload } from '@/utils';
import { get } from 'lodash';
import TaxAuthProtocol from '@/components/common/TaxAuthProtocol';
import SpTypeFormItem from '@/pages/electronicTaxBureau/components/FormComp/FormItemComp/SpTypeFormItem';
import AnhuiQrCodeAuthHoc from '../AnhuiQrCodeAuthHoc';

import styles from './index.less';
import InvalidCodeImg from '@/assets/images/anhui/invalid_code.png';
import { toJS } from 'mobx';
import SwitchVersion from '@/components/SwitchVersion';

const inputSize = 'large';

class QrCodeAuth extends Component {

  constructor(props) {
    super(props);
    this.NEW_AUTH = props.NEW_AUTH;
    this.state = {
      isLoading: false,
      isShowQrCodeCard: false,
      isQrCodeError: false,
      isCollectData: false,
      qrCodeErrorMessage: '',
    }
    this.infoFormRef = React.createRef();
  }

  componentDidMount() {
    const { qymc } = this.props.anhuiQrAuthStore.queryParams;
    this.infoFormRef.current.setFieldsValue({'companyName': qymc});
    if (!this.isPROPlug()) {
      this.setState({ isShowQrCodeCard: true });
      this.getQrCode();
      return false;
    }
  }

  handleGetQrCode = () => {
    this.getQrCode();
  }

  isPROPlug = () => {
    const taxType = this.props.taxHelperStore.getTaxType();
    return ['PRO', 'DZ_PRO', 'PLUS', 'DZ_PLUS'].includes(taxType);
  }

  getQrCode = () => {
    this.setState({ isLoading: true, isQrCodeError: false, isCollectData: false, qrCodeErrorMessage: '' });
    this.props.anhuiQrAuthStore.getQrCodeImage()
      .then(({ data }) => {
        const code = get(data, 'data.code');
        if (code === '10028') {
          throw new Error({ isCollectData: true });
        }
        this.setState({ isLoading: false, isQrCodeError: false, isCollectData: false, qrCodeErrorMessage: '' });
        this.handleCheckQrCodeResult();
        return true;
      })
      .catch(errorData => {
        const { errorMessage, isCollectData } = errorData;
        if (isCollectData) {
          this.setState({ isLoading: false, isQrCodeError: true, isCollectData: true, qrCodeErrorMessage: errorMessage });
          return false;
        }
        this.setState({ isLoading: false, isQrCodeError: true, isCollectData: false, qrCodeErrorMessage: errorMessage });
      })
  }

  handleCheckQrCodeResult = () => {
    const { checkQrCodeResult } = this.props.anhuiQrAuthStore;
    const INVALID_TIME = 15 * 60 * 1000;
    looprquest(
      checkQrCodeResult,
      (data) => {
        const code = get(data, 'code', '');
        const reStart = get(data, 'reStart', '');
        if (code === '10000') {
          this.props.onFinishAuth();
          return false;
        }
        if (reStart) {
          this.handleGetQrCode();
        }
        console.log('success callback: ', data);
      },
      (error) => {
        console.log('error callback: ', error);
      },
      {
        eachSecond: 3000,
        maxTime: 300,
        maxSecond: INVALID_TIME,
      }
    );
  }

  renderQrCode = () => {
    const { qrCodeImgurl, isCodeInvalidCode } = this.props.anhuiQrAuthStore;
    const codeImageUrl = isCodeInvalidCode ? InvalidCodeImg : qrCodeImgurl;
    const { isLoading, isCollectData, isQrCodeError, qrCodeErrorMessage } = this.state;
    if (isQrCodeError) {
      if (isCollectData) {
        return <div>系统繁忙，请稍后 <a onClick={this.handleGetQrCode}>刷新</a> 重试</div>
      }
      return ( <div>{qrCodeErrorMessage}</div> );
    }
    if (isLoading) {
      return (
        <div className={styles.loadingWrap}>
          <LoadingOutlined />
        </div>
      );
    }
    return ( <div className={styles.qrcodeImg} style={{ backgroundImage: `url(${codeImageUrl})` }}></div> );
  }

  onSpTypeChange = () => {
    this.infoFormRef.current.validateFields()
      .then(formData => {
        this.props.taxHelperStore.setAuthFormData(formData);
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }

  getQrCodeCard = () => {
    this.infoFormRef.current.validateFields()
      .then(formData => {
        this.props.taxHelperStore.setAuthFormData(formData);
        if (this.state.isShowQrCodeCard === false ) {
          this.setState({ isShowQrCodeCard: true });
          this.getQrCode();
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      })
  }

  /** 所有身份列表 */
  provinceList = () => {
    return this.props.taxAuthStore.taxDeclareProvince
      .map(item => {
        const { area, disabled } = item;
        return { label: area, value: area, disabled };
      });
  }
  handleProvinceChange = (selectProvince) => {
    const { setQueryParams, authQueryParams, } = this.props.taxHelperStore;
    const { serialNo } = authQueryParams;
    setQueryParams({ ...authQueryParams, province: selectProvince });
    /** 更新订单税局省份 */
    this.props.taxAuthStore.updateTaxOrderBureau({ serialNo, province: selectProvince });
  }
  render() {
    const { nsrsbh } = this.props.anhuiQrAuthStore.queryParams;
    const { isCodeInvalidCode } = this.props.anhuiQrAuthStore;
    const taxType = this.props.taxHelperStore.getTaxType();
    const { province } = this.props.taxHelperStore.authQueryParams
    return (
      <div className={styles.content}>
        <Form
          layout="vertical"
          ref={this.infoFormRef}
        >
          <Form.Item
            key="taxDeclareProvince"
            label="报税税局:"
            rules={[
              { required: true, message: '请选择报税税局' },
            ]}
          >
            <Select
              value={province}
              size="large"
              disabled={!this.NEW_AUTH}
              placeholder="请选择报税税局"
              options={this.provinceList()}
              onChange={this.handleProvinceChange}
            />
          </Form.Item>
          <Form.Item
            key="companyName"
            colon={false}
            label="企业名称："
            name="companyName"
            rules={[
              { required: true, message: '请输入企业名称'}
            ]}
            extra={`统一社会信用代码：${nsrsbh}`}
          >
            <Input disabled size={inputSize} placeholder="输入企业名称" />
          </Form.Item>
          {
            this.isPROPlug() ? <SpTypeFormItem onChange={this.onSpTypeChange} /> : null
          }
        </Form>
        {
          this.isPROPlug() && !this.state.isShowQrCodeCard ?
          <Button
            block
            type="primary"
            size={inputSize}
            onClick={this.getQrCodeCard}
            style={{marginTop: '25px'}}
            loading={this.state.isLoading}>获取二维码</Button> : null
        }
        {
          this.state.isShowQrCodeCard ?
          <React.Fragment>
            <div className={styles.qrcodeWrap}>
              <div className={styles.tip}>
                {
                  isCodeInvalidCode ?
                  <div>二维码已过期，请点击<a onClick={this.handleGetQrCode}>刷新</a></div> :
                  <div>请使用<span style={{color: '#f00'}}>支付宝</span>、<span style={{color: '#f00'}}>微信小程序</span>或皖税通APP扫码</div>
                }
              </div>
              <div className={styles.codeWrap}>
                { this.renderQrCode() }
              </div>
            </div>
            <TaxAuthProtocol
              hasCheckbox={false}
              isOnlyAuthProtocol={['DZ_ANO'].includes(taxType)}
            ></TaxAuthProtocol>
            <SwitchVersion />
          </React.Fragment> : null
        }
      </div>
    )
  }
}

export default AnhuiQrCodeAuthHoc(inject('anhuiQrAuthStore', 'taxHelperStore', 'taxAuthStore')(observer(QrCodeAuth)));
