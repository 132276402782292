import React from 'react';
import DefaultContainer from './components/DefaultContainer';
import RizhaoContainer from './components/RizhaoContainer';

const WhiteBox = (props) => {
  const source = props.source || '';
  switch (source) {
    case 'RZ':
      return <RizhaoContainer {...props} />;
    default:
      break;
  }
  return <DefaultContainer {...props} />;
};

export default WhiteBox;
