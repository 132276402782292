import { get, set } from 'lodash';
import { action, toJS } from 'mobx';
import { hxTaxApi } from '@/api';
import { encryptedParams } from '@/utils';
import AxinoErrorMap from '@/components/HxTaxBureau/helper/errorMap';

class HxTaxBuresu {

  getParams = () => {
    return {
      ...this.hxTaxFormData,
      ...this.formatUrlParams,
      ...this.localPrepareOrderInfo,
      ...this.orderInfo,
      serialNo: this.queryParams.serialNo || '',
      taxKey: this.queryParams.taxKey || '',
    };
  }

  /** 表单数据 */
  hxTaxFormData = {
    corpName: '',   // 企业名称
    creditCode: '',     // 统一社会信用代码
    deviceType: '',                    // 开票软件设备类型
    certPW: '',                   // 税控设备证书口令
    devicePW: '',                 // 税控设备口令
  };

  /** 链接传递过来的参数 */
  formatUrlParams = {
    userName: 'SYKG0001',
    taxpayerId: '913700000969569700',
    authorizationCode: 'LKLD735USA',
    cRegisterCode: '72425465',
    terminalId: '',
    itemCode: "20211543",
    platformCode: "SYKG0001",
  };

  /** url解析的请求参数 */
  queryParams = {};

  /** 本地软件返回数据 */
  localPrepareOrderInfo = {
    // areaCode 分机号
    // machineNo 地区编号
    // taxBureauCode 税务机关代号
  };

  /** 下订单返回订单信息 */
  orderInfo = {
    // taxpayerId: "91370103MA3MHR2B13",
    // itemCode: "20211543",
    // authCode: "4NQ8FFMYKL",
    // registerCodeCode: null,
    // endTime: "2022-08-04 15:17:20"
  }

  accessToken = '';

  setValue = (key, value) => {
    set(this, key, value);
  }

  /** 更新请求参数信息 */
  updateQueryParams(params) {
    if (!params) {
      console.error('没有获取到有效的请求参数，无法继续后续操作');
      return false;
    }
    const scStaticKeys = [
      'userName', 'taxpayerId', 'authorizationCode',
      'cRegisterCode', 'terminalId', 'itemCode', 'platformCode'
    ];

    scStaticKeys.forEach((key) => {
      if (params[key]) {
        this.formatUrlParams[key] = params[key] || '';
      }
    });

    this.hxTaxFormData.corpName = params.qymc || undefined;
    this.hxTaxFormData.creditCode = params.nsrsbh || undefined;

    this.queryParams = {...params};
  }

  /** 加密 */
  authEncrypt = (params) => {
    return hxTaxApi.authEncrypt(params)
      .then(action((resp) => {
        return get(resp, 'data.data.result');
      }))
      .catch(action((err) => {
        console.log('err: ', err);
        return Promise.reject(err);
      }))
  }

  /** 加密 */
  encrypt = (params) => {
    return hxTaxApi.encrypt({content: JSON.stringify(params)})
      .then(action((resp) => {
        return get(resp, 'data.data.result');
      }))
      .catch(action((err) => {
        console.log('err: ', err);
        return Promise.reject(err);
      }))
  }

  /** 解密 */
  decrypt = (data) => {
    return hxTaxApi.decrypt({content: data})
      .then(action((resp) => {
        const data = get(resp, 'data.data.result');
        return JSON.parse(data);
      }))
      .catch(action((err) => {
        console.log('err: ', err);
        return Promise.reject(err);
      }))
  }

  /**
   * 获取平台商身份令牌
   * @param {*} formData 
   */
  getServerPlatformAccessToken = () => {
    const { userName, taxpayerId, authorizationCode, cRegisterCode, terminalId } = this.formatUrlParams;
    const params = { userName, taxpayerId, authorizationCode, cRegisterCode, terminalId };
    return hxTaxApi.getServerPlatformAccessToken(params)
      .then(action((resp) => {
        const result = get(resp, 'data.data.result');
        this.accessToken = result;
        if (result) {
          this.accessToken = result;
          return result;
        }
        return Promise.reject(new Error('获取access_token失败'));
        
      }))
      .catch(action((error) => {
        const message = get(error, 'response.data.message') || '';
        return Promise.reject({ code: AxinoErrorMap.get(1010).code, error, message });
      }));
  }

  /**
   * 下单前信息查询（本地）
   */
   getLocalPrepareOrderInfo = () => {
    const { certPW, devicePW, creditCode } = toJS(this.hxTaxFormData);
    const encryptParams = { certPW, devicePW, taxpayerId: creditCode };
    return this.authEncrypt(encryptedParams(encryptParams, ['certPW', 'devicePW', 'taxpayerId']))
      .then((datagram) => ({ zipCode: '0', encryptCode: '2', access_token: this.accessToken, datagram }))
      .then((params) => hxTaxApi.getLocalPrepareOrderInfo(params))
      .then((resp) => {
        const datagram = get(resp, 'data.datagram');
        const code = get(resp, 'data.code') || '';
        const checkInfo = this.handleLocalErrorCode(code, resp);
        if (datagram && datagram !== '[]' && checkInfo.success) {
          return datagram;
        }
        console.log('resp------->', resp, checkInfo);
        if (!checkInfo.success) {
          return Promise.reject(checkInfo.error);
        }
        return Promise.reject(get(resp, 'data'));
      })
      .then((datagram) => this.decrypt(datagram))
      .then((localPrepareOrderInfo) => {
        this.localPrepareOrderInfo = localPrepareOrderInfo;
      })
      .catch(action((error) => {
        /** 判断错误是否是未插入税盘 */
        if (error.code === '200003') {
          const message = get(error, 'msg');
          return Promise.reject({ code: AxinoErrorMap.get(1020).code, message, error })
        }
        return Promise.reject({ code: AxinoErrorMap.get(1020).code, error });
      }));
  }

  /** 处理本地的错误码 */
  handleLocalErrorCode(code, errorResponse) {
    let isSuccess = true;
    let error = null;
    const packError = (error, code) => ({error, code})
    switch (code) {
      case '101012':
        isSuccess = false;
        error = packError(errorResponse, 1021);
        break;
      default:
        break;
    }
    return { success: isSuccess, error };
  }

  /**
   * 线上下单
   */
   requestServerSyncAddOrder = () => {
    const { cRegisterCode, itemCode, platformCode } = this.formatUrlParams;
    const { corpName, creditCode } = this.hxTaxFormData;
    const { areaCode, machineNo, taxBureauCode } = this.localPrepareOrderInfo;
    const params = {
      areaCode,
      corpName,
      itemCode,
      platformCode,
      "registerCode": cRegisterCode,
      taxpayerId: creditCode,
      "count": "",
    };
    return hxTaxApi.requestServerSyncAddOrder(params, this.accessToken)
      .then(action((resp) => {
        const data = get(resp, 'data.data');
        if (!data) {
          console.log('resp------->', resp);
          const message = get(resp, 'data.message');
          return Promise.reject(new Error(message));
        }
        this.orderInfo = data;
      }))
      .catch(action((error) => {
        const message = error.message;
        return Promise.reject({ code: AxinoErrorMap.get(1030).code, message, error });
      }));
  }

  /**
   * 5. 获取身份令牌
   * @param {*} formData 
   */
  getServerAccessToken = () => {
    const params = {};
    hxTaxApi.getServerPlatformAccessToken(params)
      .then(action((resp) => {
        console.log('getServerPlatformAccessToken----->', resp);
      }))
      .catch(action((error) => {
        console.log('error------>', error);
      }));
  }

  /**
   * 6 获取发票数据查询结果（本地）
   */
  getLocalInvoiceData = () => {
    hxTaxApi.getLocalInvoiceData({})
      .then(action((resp) => {
        console.log('resp====>: ', resp);
        console.log('getPrepareOrderInfo----->', resp);
      }))
      .catch(action((error) => {
        console.log('error------>', error);
      }));
  }

  resetStore = () => {}
}

export default new HxTaxBuresu();
