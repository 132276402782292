import qs from 'qs';

export function isSDKLocaltion() {

  const search = window.location.search;
  if (!search) {
    return false;
  }
  const query = qs.parse(search, { ignoreQueryPrefix: true });
  const isSDK = query.isSDK === 'true';
  return isSDK;
}
