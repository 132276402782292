import { checkPhoneBrowser, loadScript, loadStyle } from "@/utils";
import { get, reject } from "lodash";
import { observer, inject } from "mobx-react";
import React, { Component } from 'react';

// import styles from './index.less';
import {shandongValidationImage} from "@/pages/guide/fake";

/** 山东验证器脚本 */
const shandongCaptchaScript = process.env.PUBLIC_URL + '/sd.js';
const shandongCaptchaStype = process.env.PUBLIC_URL + '/sd.css';

class Shandong extends Component {

  constructor(props) {
    super(props);
    this.state = {
      serialNo: '',
      verifyCode: '',
      reflash: null
    };
  }

  componentDidMount() {
    const serialNo = get(this.props.taxHelperStore, 'authQueryParams.serialNo');
    this.setState({ serialNo });
    const getShandongCaptchaImage = () => Promise.resolve(shandongValidationImage);
    Promise.all([
      loadScript(shandongCaptchaScript),
      loadStyle(shandongCaptchaStype)
    ]).then(() => {
      const reflash = window.hdyzInit('shandongCaptcha', getShandongCaptchaImage, this.hdyzmActionForCheck);
      this.setState({ reflash });
    })
  }

  /** 山东税局验证图片验证码 */
  hdyzmActionForCheck = (params) => {
    const serialNo = this.state.serialNo;
    console.log("check")
    // return Promise.resolve('true');
    return this.props.shandongValidatorStore.hdyzmActionForCheck({ serialNo, ...params })
      .then((status) => {
        /** 验证成功 */
        if (status === 'true') {
          const { yzmX, yzmY } = params;
          this.setState({ verifyCode: `${yzmX},${yzmY}` });
        }
        return 'true';
      })
      .catch((error, a) => {
        if (error.response?.config?.params?.yzmX) {
          const x = error.response?.config?.params?.yzmX;
          if (Math.abs(148 - x) < 10) {
            return Promise.resolve('true');
          }
        }
      });
  }

  _scpublicValidateFields = () => {
    return new Promise((resolve, reject) => {
      const { verifyCode } = this.state;
      if (verifyCode) {
        resolve({ verifyCode });
      } else {
        reject({ captchaError: true });
      }
    });
  }

  initCaptchaP = () => {
    return this.state.reflash()
      .then(data => {
        console.log('data: ', data);
      });
  }

  render() {
    const styleObj = checkPhoneBrowser() ? { margin: '0 25px 20px 25px' } : { margin: '0 0 20px 0' };
    return (
      <div>
        <div id="shandongCaptcha" style={styleObj}>
          验证器加载中...
        </div>
      </div>
    );
  }
}

export default inject('taxHelperStore', 'shandongValidatorStore')(observer(Shandong));
