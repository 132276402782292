import qs from 'qs';
import { decrypted, encrypted } from './aesCrypted';

const decryptedParams = () => {
  const search = window.location.search;
  if (!search) {
    return {};
  }
  try {
    const { q, u } = qs.parse(search, { ignoreQueryPrefix: true, decoder: (c) => c });
    if (!q || !u) {
      return {};
    }
    const jsonStr = decrypted(q, u);
    const obj = {
      "qymc": "a",
      "nsrsbh": "a",
      "provice": "重庆",
      "cburl": "a",
      "serialNo": "a",
      "taxKey": "a",
      "taxType": "CS",
      "eleTaxAvailable": true
    }
    const str = encrypted(JSON.stringify(obj), u);
    return JSON.parse(jsonStr);
  } catch (error) {
    return {};
  }
}

export default decryptedParams;
