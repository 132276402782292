import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';

class Hebei {

  roleList = [];

  getRoleList = (params) => {
    return provinceValidatorApi.getHebeiRoleList(params)
      .then(resp => {
        const list = get(resp, 'data.data.data.content');
        this.roleList = list.map(item => ({ label: item.message, value: item.message}));
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        throw error;
      })
  }

}

export default new Hebei();
