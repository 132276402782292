/**
 * 安徽扫码授权相关接口
 */

 import { taxAxios } from '@/utils/taxAxios';

 /** 获取二维码 */
 export const getQrCodeImage = (params) => taxAxios.get(`/api/dbc/taxation/getTaxAuthQrCodeByOrderId`, { params });

/** 查询二维码扫码结果 */
export const checkQrCodeResult = (params) => taxAxios.get('/api/dbc/taxation/taxAuthStatus', { params });
