import { get, set } from 'lodash';
import { encodeBase64 } from '@/utils/taxUtil/encode';
import {
  AUTH_TYPE_DICT,
  TAX_ERROR_DICT,
  TAX_TYPE_DICT,
  DOWNLOAD_PLUGIN_TYPE,
  NEED_QUICK_AUTHORIZE_TYPE,
  PROVINCE_MAP as CHINA_PROVINCE_MAP
} from '@/utils/taxUtil';

class TaxHelperStore {

  /** 弹窗嵌入授权页面时，父窗口传入的MessageChannelPort */
  messageChannelPort = null;

  // 税局授权基本配置信息
  authQueryParams = {
    // /** 电子税局授权所在区域是否有效 */
    // eleTaxAvailable: false,
    // /** 纳税人识别号（也是社会信用代码） */
    // nsrsbh: '91340100MA2MYAHF40',
    // /** 软件通道是否开启（暂时只有电子税局+软件税局需要此字段判断） */
    // pluginStatus: false,
    // /** 税局区域 */
    // province: '安徽',
    // /** 企业名称 */
    // qymc: '安徽弘发钢结构有限公司',
    // /** 流水号 */
    // serialNo: 'SCT_20210727112635297748',
    // /** 税局Key */
    // taxKey: 'h09n87oqu6woywepr8jx',
    // /** 电子税局授权通道（暂时只有电子税局+软件税局需要此字段判断） */
    // taxStatus: false,
    // /** 税局类型，可以是单独的电子税局或者软件采票，也可以是组合 */
    // taxType: '',
    // /** 微风企回调地址 */
    // wfqUrl: null,
    // /** h5回调地址 */
    // cburl: null,
  };

  /** 授权表单信息 */
  authFormData = {};

  /** 是否是电子税局授权 */
  get isElectronTax() {
    const taxTypeInfo = this.getTaxTypeInfo();
    return taxTypeInfo.code >= 100 && taxTypeInfo.code < 200;
  }

  /** 是否是软件授权 */
  get isPluginTax() {
    const taxTypeInfo = this.getTaxTypeInfo();
    return taxTypeInfo.code >= 200 && taxTypeInfo.code < 300;
  }

  /** 是否是组合税局授权 */
  get isCombimeTax() {
    const taxTypeInfo = this.getTaxTypeInfo();
    return taxTypeInfo.code >= 300 && taxTypeInfo.code < 400;
  }

  /**
   * 电子税局授权所在区域是否有效
   * 对于单一税局类型，不做判断，返回true
   * 只对组合税局类型进行判断
   */
  get isAvailableProvinceTax() {
    if (this.isPluginTax) {
      return true;
    }
    return this.authQueryParams.eleTaxAvailable;
  }

  /**
   * 电子税局通道是否有效
   * 对于单一电子税局类型，不做判断，返回true
   * 只对组合税局类型进行判断
   */
  get isAvailableElectronStatus() {
    if (this.isElectronTax) {
      return true;
    }
    return this.authQueryParams.taxStatus;
  }

  /**
   * 软件通道所在区域是否有效
   * 对于单一税局类型，不做判断，返回true
   * 只对组合税局类型进行判断
   */
  get isAvailablePluginStatus() {
    if (this.isPluginTax) {
      return true;
    }
    return this.authQueryParams.pluginStatus;
  }

  setValue = (key, value) => {
    set(this, key, value);
  }

  setQueryParams = (params) => {
    this.authQueryParams = { ...params };
    return Promise.resolve(this.authQueryParams);
  }

  setAuthFormData = (formValue) => {
    this.resetFormStore();
    this.authFormData = { ...formValue };
  }

  getQueryParams = (key) => {
    return this.authQueryParams[key] || undefined;
  }

  /** 判断是否应该先下载软件 */
  shouldDownloadPluginAsBegin() {
    const taxType = this.getTaxType();
    const workType = this.getTaxWorkType();
    if (workType === this.NONE || workType === this.ELECTRON) {
      return false;
    }

    return DOWNLOAD_PLUGIN_TYPE.includes(taxType);
  }

  /** 判断是否需要提示用户快速授权，否则超出服务端获取数据的等待时间，取数失败 */
  shouldNeedQuickAuthorize = () => {
    const taxType = this.getTaxType();
    return NEED_QUICK_AUTHORIZE_TYPE.includes(taxType);
  }

  /**
   * 是否属于需要执行下一步的表单
   * 判断规则：
   * 1. 单子税局类无下一步，确认授权后，进入完成页
   * 2. 采集软件类无下一步，确认授权后，开启软件下载弹窗
   * 3. 单子税局 + 采集软件类分情况判断，（此函数已经做好判断）
   *    3-1 电子税局通道可用，采集软件通道可用，存在下一步
   *    3-2 电子税局通道可用，采集软件通道不可用，无下一步，确认授权后，进入完成页
   *    3-3 电子税局通道不可用，采集软件通道可用，无下一步，确认授权后，开启软件下载弹窗
   *    3-4 电子税局通道不可用，采集软件通道不可用，直接进入授权失败页
   * 特别说明：此函数只判断是否存在下一步，不做任何其他状态的反馈和业务的逻辑处理
  */
  shouldNextStep = () => {
    const taxType = this.getTaxType();
    const souldNextTax = ['WFQ', 'DZ_WFQ', 'DZ_ANO'];
    if (this.isElectronTax) {
      return false;
    }
    if (this.isPluginTax) {
      return souldNextTax.includes(taxType);
    }
    if (this.isCombimeTax) {
      const workType = this.getTaxWorkType();

      if (workType === AUTH_TYPE_DICT.ELECTRON || workType === AUTH_TYPE_DICT.NONE) {
        return false;
      }
      return souldNextTax.includes(taxType);
    }

    return false;
  }

  getTaxType = () => {
    return get(this.authQueryParams, 'taxType') || null;
  }

  getTaxKey = () => {
    return get(this.authQueryParams, 'taxKey') || null;
  }

  getCompanyName = () => {
    return get(this.authQueryParams, 'qymc') || null;
  }
  
  getTaxCburl = () => {
    const workType = this.getTaxWorkType();
    if (workType === AUTH_TYPE_DICT.ELECTRON) {
      return get(this.authQueryParams, 'cburl') || '';
    }
    return '';
  }

  getOutLink = () => {
    return get(this.authQueryParams, 'wfqUrl') || '';
  }

  /** 获取社会信用代码 */
  getCreditCode = () => {
    if (this.isPluginTax) {
      return '';
    }
    if (this.isCombimeTax) {
      const workType = this.getTaxWorkType();

      if (workType === AUTH_TYPE_DICT.PLUGIN) {
        return '';
      }
    }
    return this.authQueryParams.nsrsbh || '';
  }

  getTaxTypeInfo = () => {
    if (!this.authQueryParams.taxType) {
      return { code: 0 };
    }
    const taxTypeInfo = TAX_TYPE_DICT.get(this.authQueryParams.taxType) || { code: 0 };
    return taxTypeInfo;
  }

  getTaxWorkType = () => {
    const taxWorkStatus = this.getTaxWorkStatus();
    return taxWorkStatus.status;
  }

  getTaxWorkTypeAsync = () => {
    const taxWorkStatus = this.getTaxWorkStatus();
    return new Promise((resolve, reject) => {
      if (taxWorkStatus.status === AUTH_TYPE_DICT.NONE) {
        return reject(taxWorkStatus.errorInfo);
      }
      return resolve(taxWorkStatus.status);
    });
  }

  getProvincePingYing = () => {
    const province = this.getQueryParams('province');
    if (CHINA_PROVINCE_MAP.get(province)) {
      const provinceInfo = CHINA_PROVINCE_MAP.get(province);
      return provinceInfo.py;
    }
    return null;
  }

  /**
   * 获取税局开始工作的状态信息
   * 单通道类型：
   * A. 电子税局
   *  A-1 要判断所在区域是否有效，失败进入税局失效页，成功返回: ELECTRON
   *  A-2 不判断软件通道是否有效
   *  A-3 不判断电子通道是否有效
   * B. 采集软件，直接返回: PLUGIN
   *  B-1 不判断所在区域是否有效
   *  B-2 不判断软件通道是否有效
   *  B-3 不判断电子通道是否有效
   * 双通道类型：
   * C. 电子税局 + 采集软件
   *  C-1 区域有效，电子通道开启，软件通道开启，成功，返回: ELECTRON_PLUGIN
   *  C-2 区域有效，电子通道开启，软件通道关闭，成功，返回: ELECTRON
   *  C-3 区域有效，电子通道关闭，软件通道开启，成功，返回: PLUGIN
   *  C-4 区域有效，电子通道关闭，软件通道关闭，失败，返回: NONE
   *  C-5 区域无效，电子通道开启，软件通道开启，成功，返回: PLUGIN
   *  C-6 区域无效，电子通道开启，软件通道关闭，失败，返回: NONE
   *  C-7 区域无效，电子通道关闭，软件通道开启，成功，返回: PLUGIN
   *  C-8 区域无效，电子通道关闭，软件通道关闭，成功，返回: NONE
   * 特别说明：
   *  ELECTRON_PLUGIN 、ELECTRON 表示会进入电子授权表单页
   *  PLUGIN 表示会进入软件授权表单页
   *  NONE 表示会进入不支持授权页
   */
  getTaxWorkStatus = () => {
    const isPluginTax = this.isPluginTax;
    const isElectronTax = this.isElectronTax;
    const isCombimeTax = this.isCombimeTax;
    const isAvailableProvinceTax = this.isAvailableProvinceTax;

    function setResult(status, errorInfo) {
      return { status, errorInfo };
    }

    if (isPluginTax) {
      return setResult(AUTH_TYPE_DICT.PLUGIN, null);
    }

    if (isElectronTax) {
      return setResult(
        isAvailableProvinceTax ? AUTH_TYPE_DICT.ELECTRON : AUTH_TYPE_DICT.NONE,
        isAvailableProvinceTax ? null : TAX_ERROR_DICT.TAX_PROVINCE_UNAVAILABLE,
      );
    }

    if (isCombimeTax) {
      // 电子税局通道是否可用
      const isAvailableElectronStatus = this.isAvailableElectronStatus;
      // 采集软件通道是否可用
      const isAvailablePluginStatus = this.isAvailablePluginStatus;

      /** 电子通道开启，软件通道开启 **/
      const ElectronOpenPluginOpen = isAvailableElectronStatus && isAvailablePluginStatus;
      /** 电子通道开启，软件通道关闭 **/
      const ElectronOpenPluginClose = isAvailableElectronStatus && !isAvailablePluginStatus;
      /** 电子通道关闭，软件通道开启 **/
      const ElectronClosePluginOpen = !isAvailableElectronStatus && isAvailablePluginStatus;
      /** 电子通道关闭，软件通道关闭 **/
      const ElectronClosePluginClose = !isAvailableElectronStatus && !isAvailablePluginStatus;

      if (ElectronOpenPluginOpen) {
        /** 区域有效: ELECTRON_PLUGIN, 区域无效: PLUGIN */
        return setResult(isAvailableProvinceTax ? AUTH_TYPE_DICT.ELECTRON_PLUGIN : AUTH_TYPE_DICT.PLUGIN, null);
      }
      if (ElectronOpenPluginClose) {
        /** 区域有效: ELECTRON, 区域无效: NONE */
        return setResult(
          isAvailableProvinceTax ? AUTH_TYPE_DICT.ELECTRON : AUTH_TYPE_DICT.NONE,
          isAvailableProvinceTax ? null : TAX_ERROR_DICT.PROVINCE_AND_PLUGIN_STATUS_UNAVAILABLE,
        );
      }
      if (ElectronClosePluginOpen) {
        /** 区域有效: PLUGIN, 区域无效: PLUGIN */
        return setResult(AUTH_TYPE_DICT.PLUGIN, null);
      }
      if (ElectronClosePluginClose) {
        /** 区域有效: ELECTRON_PLUGIN, 区域无效: PLUGIN */
        return setResult(
          AUTH_TYPE_DICT.NONE,
          isAvailableProvinceTax ? TAX_ERROR_DICT.TAX_AND_PLUGIN_STATUS_UNAVAILABLE : TAX_ERROR_DICT.PROVINCE_AND_TAX_AND_PLUGIN_STATUS_UNAVAILABLE,
        );
      }
    }
    return setResult(AUTH_TYPE_DICT.NONE, TAX_ERROR_DICT.DEFAULT);
  }

  /** 长沙信息加密 */
  getCSEncode() {
    const StartupTag = this.authFormData.spsb;
    const baseInfo = {
      "Name": get(this.authQueryParams, 'qymc') || '',
      "TaxNumber": get(this.authQueryParams, 'nsrsbh') || '',
      "SerialNumber": get(this.authQueryParams, 'serialNo') || '',
      // "AuthTime": '2021-08-10',
      // 1: Ukey, 2: 百旺， 3：航天
      "StartupTag": StartupTag,
    };
    return encodeBase64(baseInfo);
  }

  /** 长沙信息加密 */
  getProEncode() {
    const StartupTag = this.authFormData.spsb;
    const baseInfo = {
      "Name": get(this.authQueryParams, 'qymc') || '',
      "TaxNumber": get(this.authQueryParams, 'nsrsbh') || '',
      "SerialNumber": get(this.authQueryParams, 'serialNo') || '',
      // "AuthTime": '2021-08-10',
      // 1: Ukey, 2: 百旺， 3：航天
      "StartupTag": StartupTag,
    };
    return encodeBase64(baseInfo);
  }

  resetFormStore = () => {
    this.authFormData = {};
  }

  resetStore = () => {
    this.resetFormStore();
  }
}

export default new TaxHelperStore();
