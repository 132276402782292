import React from 'react';
import { observer } from 'mobx-react';
import WhiteBox from '@/components/layout/whiteBox';
import HxTaxBureau from '@/components/HxTaxBureau';

import styles from './index.less';

const HxTaxPage = (props) => {
  return (
    <WhiteBox>
      <div className={styles.formWrapper}>
        <HxTaxBureau {...props} />
      </div>
    </WhiteBox>
  );
}

export default observer(HxTaxPage);
