import React from 'react';
import { inject, observer } from 'mobx-react';

/**
 * React 的错误边界处理组件
 * 对于组件内部的错误，可以在此处进行统一拦截和处理，但请注意以下几点无法进行捕获：
 * 1. 事件处理（了解更多: https://react.docschina.org/docs/error-boundaries.html#how-about-event-handlers）
 * 2. 异步代码（例如 setTimeout 或 requestAnimationFrame 回调函数）
 * 3. 服务端渲染
 * 4. ErrorBoundary 自身抛出来的错误（并非它的子组件）
 */
class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 将错误日志上报给服务器
    this.props.commonStore.sendErrorReport(new Error(error.message + '\n' + errorInfo.componentStack + '\n'));
  }

  render() {

    /** 页面存在错误后，可以按需进行优雅的内容显示，如果注释，则不会走 componentDidCatch */
    if (this.state.hasError) {
      return <div>错误啦</div>;
    }

    return this.props.children; 
  }
}
export default inject('commonStore')(observer(ErrorBoundary));
