import { Form, Input } from 'antd';

const PhoneIDCardCodeFormItem = ({ inputSize, formInstances }) => {
  return (
    <Form.Item
      key="username"
      colon={false}
      label="手机号/身份证/操作员代码："
      name="username"
      rules={[{ required: true, message: '请输入手机号/身份证/操作员代码' }]}
    >
      <Input
        size={inputSize}
        placeholder="请输入手机号/身份证/操作员代码"
        onBlur={(evt) => {
          const value = evt.target.value;
          if (value !== value.trim()) {
            const form = formInstances();
            form.setFieldsValue({ username: value.trim() });
          };
        }}
      />
    </Form.Item>
  );
}

export default PhoneIDCardCodeFormItem;
