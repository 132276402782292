import { Button } from 'antd';
import classnames from 'classnames';

import styels from './index.less';

const AuthButton = (props) => {
  const source = props.source || '';
  const btnClassName = classnames(
    styels.authDefaultBtn,
    {
      [styels.rzBtn]: source === 'RZ',
    },
    props.className);
  return (<Button {...props} className={btnClassName} />)
}

export default AuthButton;
