import React, { useEffect } from 'react';
import qs from 'qs';
import WhiteBox from '@/components/layout/whiteBox';
// import TicketTitle from '@/components/ticketTitle';
import { getTaxErrorInfo } from '@/utils/taxUtil';

import errorIcon from '@/assets/images/common/error.png';

import styles from './index.less';

const Invalidation = ({ location }) => {
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    const { code, message } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if ( message ) {
      setMessage(message);
      return false;
    }
    const errorInfo = getTaxErrorInfo(Number(code)) || {};
    setMessage(errorInfo.desc);
  }, [location.search]);

  return (
    <WhiteBox>
      {/* <TicketTitle /> */}
      <div className={styles.wrap}>
        <img src={errorIcon} alt=""/>
        <div>授权页加载失败</div>
        <div className={styles.tip}>{message}</div>
      </div>
    </WhiteBox>
  );
};

export default Invalidation;
