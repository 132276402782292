import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import FormWrapper from '../FormWrapper';
import './index.less';
import SelectPick from '@/components/SelectAndPick';

const SelectInput = (props) => {
  const { item } = props;
  const { children } = item;
  return (
    <div className={item.wrapperClassName}>
      <FormWrapper {...props}>
        <SelectPick options={item.options} {...item.inputProps}>
          {/* {createOptions(item.options || [])} */}
        </SelectPick>
      </FormWrapper>
      { children }
    </div>
  );
}

// item 下更多其他配置参考 FormWrapper.propTypes
SelectInput.propTypes = {
  item: PropTypes.object,
};

export default SelectInput;
