import { forwardRef, useState } from 'react';
import { Input, Upload, message } from 'antd';
import { inject, observer } from 'mobx-react';
import UPLOAD_IMG from '@/assets/images/selfFilling/upload.png';
import styles from './index.module.less';

const FileInput = forwardRef((props, _ref) => {

  const { placeholder, selfFillingStore, type, form } = props;

  const [fileName, setFileName] = useState(null);
  const [fileList, setFileList] = useState([]);

  const beforeUpload = (file) => {
    const isExcel = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      message.error('请上传xls、xlsx格式的文件!');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error('文件不能大于1M!');
    }
    return isExcel && isLt1M;
  }

  const onChange = (info) => {
    console.log(info, '----');

    if (info.file.status === 'done') {
      message.success('上传成功!');
      form.setFieldsValue({ [type]: info.file.response.data });
      setFileName(info.file.name);
    } else if (info.file.status === 'error') {
      message.error(info.file.response.message);
    } else if (info.file.status === 'removed') {
      message.success('移除成功!');
      form.setFieldsValue({ [type]: null });
      setFileName(null);
    }
    const isExcel = info.file.type === 'application/vnd.ms-excel' || info.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const isLt1M = info.file.size / 1024 / 1024 < 1;
    if (isExcel && isLt1M) {
      setFileList([...info.fileList]);
    } else {
      form.setFieldsValue({ [type]: null });
      setFileName(null);
      setFileList([]);
    }
  }

  const accept = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  return (
    <div className={styles.fileInput}>
      <Upload
        name='file'
        fileList={fileList}
        accept={accept}
        action='/api/dbc/taxation/upload'
        headers={{ 'tax-key': selfFillingStore.taxKey }}
        data={{ fileType: 'EXCEL' }}
        maxCount={1}
        beforeUpload={beforeUpload}
        onChange={onChange}
      >
        <Input value={fileName} disabled placeholder={placeholder} addonAfter={<img alt='' src={UPLOAD_IMG} />} />
      </Upload>
    </div >
  )
})

export default inject('selfFillingStore')(observer(FileInput));