import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

/** 湖南 */
class Hunan {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';
  mobile = '';

  /** 获取办税员列表 */
  getRoleList = (data) => {
    const params = {
      "authentication": "tpass.hunan.chinatax.gov.cn:8443",
      ...data
    }
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then((resp) => {
        const { mobile, uuid } = get(resp, 'data.data.data') || {};
        this.bsrs = [{ mobile, uuid }];
        this.mobile = mobile;
        this.bsrsList = [{
          label: mobile,
          value: uuid
        }];
        return this.bsrsList;
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { taxAccount, taxPassword } = stepOneFormData
    const params = {
      "serialNo": serialNo,
      "userName": taxAccount,
      "userPass": taxPassword,
      "province": province,
      "creditCode": nsrsbh,
      "uuid": bsr,
      "phoneNumber": this.mobile,
      "authentication": "tpass.hunan.chinatax.gov.cn:8443" //固定值
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const smscodeId = get(resp, 'data.data.smscode_id') || '';
        this.smscodeId = smscodeId;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { captcha, bsr } = formData;
    const params = {
      "orderNo": serialNo,
      "province": province,
      "creditCode": nsrsbh,
      "uuid": bsr,
      "smsCodeId": this.smscodeId,
      "smscodeId": this.smscodeId,
      "yddh": this.mobile,
      "captcha": captcha,
      "authentication": "tpass.hunan.chinatax.gov.cn:8443" //固定值
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  };

}

export default new Hunan();