import { observer } from 'mobx-react';
import React from 'react';
import Anhui from '@/components/common/province-validator/anhui';
import TestComponent from '@/components/testComponent';
import { Button } from 'antd';
// import Zhejiang from '@/components/common/province-validator/zhejiang';

class Test extends React.Component {

  constructor(props) {
    super(props);
    this.anhuiFormRef = React.createRef();
    this.zhejiangFormRef = React.createRef();
  }

  onTest = () => {
    const anhuiForm = this.anhuiFormRef.current;
    anhuiForm._scpublicValidateFields()
      .then((data) => {
        console.log('data ddd: ', data);
      }).catch(error => {
        console.log('error: ', error);
      });
    // const zhejiangForm = this.zhejiangFormRef.current;
    // zhejiangForm._scpublicAuthorize();
  }

  zhejiangSuccess = () => {
    console.log('zhejiangSuccess');
  }


  render() {
    return (
      <div style={{width: '400px'}}>
        <Anhui ref={this.anhuiFormRef} />
        {/* <Zhejiang ref={this.zhejiangFormRef} success={this.zhejiangSuccess} /> */}
        <Button onClick={this.onTest}>Test</Button>
        <TestComponent />
      </div>
    );
  }
}

export default observer(Test);
