import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import { looprquest, stopLooprquest } from '@/utils';

import WhiteBox from '@/components/layout/whiteBox';
import TicketTitle from '@/components/ticketTitle';

import successIcon from '@/assets/images/common/success.png';

import styles from './index.less';
import { inject, observer } from 'mobx-react';

const INVALID_TIME = 15 * 60 * 1000;

const Invalidation = ({ taxAuthStore, smengStore, taxHelperStore }) => {

  const timeRef = useRef(null)
  const [ queryParams, setQueryParams ] = useState({});

  const checkoutTaxAuthOrderCollectionStatus = (serialNo) => {
    return taxAuthStore.checkoutTaxAuthOrderCollectionStatus(serialNo)
      .then((resp) => {
        // PROCESSED:已完成,FAILED:失败，COLLECTING:采集中
        const status = resp.data;
        if (status === 'PROCESSED') {
          return status;
        } else {
          throw(status);
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  const checkStatus = (searchObj) => {
    const { serialNo, type } = searchObj;
    if (type === 'smeng') {
      return smengStore.checkSmengStatus({ orderNo: serialNo, serialNo })
        .then(status => {
          if (status === 'PROCESSED') {
            return status;
          } else {
            throw(status);
          }
        });
    }
    return checkoutTaxAuthOrderCollectionStatus(serialNo);
  };

  const checkStatue = (searchObj) => {
    const { serialNo, cburl } = searchObj;

    /** 轮询采数结果 */
    timeRef.current = looprquest(
      checkStatus.bind(null, searchObj),
      (data) => {
        setDescText('采数成功');
        /**
         * 如果是嵌入frame的方式巧用存电子税局授权页面,
         * 采数成功后发送数据给调用页面
         */
        const messageChannelPort = taxHelperStore.messageChannelPort;
        if (messageChannelPort && messageChannelPort.ports.length > 0) {
          messageChannelPort.ports[0].postMessage({ serialNo });
          return false;
        }
        if (cburl) {
          setTimeout(() => {
            window.location.href=cburl;
          }, 500);
        }
      },
      (error) => {
        console.log('error callback: ', error);
      },
      {
        eachSecond: 3000,
        maxTime: 100,
        maxSecond: INVALID_TIME,
      }
    );
  }

  const [ title, setTitle ] = useState('授权认证');
  const [ descText, setDescText ] = useState('授权成功，数据采集中...');

  useEffect(() => {
    const search = window.location.search;
    const searchObj = qs.parse(search, { ignoreQueryPrefix: true });
    setQueryParams(searchObj);
    if (searchObj.watch) {
      checkStatue(searchObj);
    }
    if (searchObj && searchObj.code === 'SUCCESS_CAIPAI') {
      setTitle('采数结果');
      setDescText('采数成功');
    }
    return () => stopLooprquest(timeRef)
  }, [])

  return (
    <WhiteBox>
      <TicketTitle title={title} />
      <div className={styles.wrap}>
        <img src={successIcon} alt=""/>
        <div>{descText}</div>
      </div>
    </WhiteBox>
  );
};

export default inject('taxAuthStore', 'smengStore', 'taxHelperStore')(observer(Invalidation));
