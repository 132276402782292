import { Form, Select } from 'antd';

const HubeiIdentityFormItem = ({ inputSize }) => {
  return (
    <Form.Item
      key="qydlry"
      colon={false}
      label="身份："
      name="qydlry"
      rules={[{ required: true, message: '请选择登录身份' }]}
    >
      <Select size={inputSize} placeholder="请选择登录身份">
        <Select.Option value="10">法定代表人</Select.Option>
        <Select.Option value="20">财务负责人</Select.Option>
        <Select.Option value="30">办税人</Select.Option>
      </Select>
    </Form.Item>
  );
}

export default HubeiIdentityFormItem;
