import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TextInput, DateInput, SelectInput, RadioInput, PasswordInput, NumberInput, CheckboxInput, GetCaptcha } from './FormItem';
import FormWrapper from './FormWrapper';
import { Col } from 'antd';

class AntdForm extends React.Component {
  render() {
    const { template, layout } = this.props;

    const handleInputItem = (item, idx) => {
      const inputItemProps = { item };
      const { type, disabled } = item;
      switch (type) {
        case 'text':
        case 'textArea':
          return !disabled ? <TextInput key={idx} {...inputItemProps} /> : null;
        case 'date':
          return !disabled ? <DateInput key={idx} {...inputItemProps} /> : null;
        case 'number':
          return !disabled ? <NumberInput key={idx} {...inputItemProps} /> : null;
        case 'password':
          return !disabled ? <PasswordInput key={idx} {...inputItemProps} /> : null;
        case 'select':
          return !disabled ? <SelectInput key={idx} {...inputItemProps} /> : null;
        case 'radio':
          return !disabled ? <RadioInput key={idx} {...inputItemProps} /> : null;
        case 'checkbox':
          return !disabled ? <CheckboxInput key={idx} {...inputItemProps} /> : null;
        case 'getCaptcha':
          return !disabled ? <GetCaptcha key={idx} {...inputItemProps} /> : null;
        default:
          break;
      }
    };
    const createFormItem = () => {
      if (!template || template.length === 0) {
        return null;
      }
      const output = [];
      template.forEach((item, idx) => {
        let formNode = null;
        if (item.hoc) {
          formNode = item.hoc(item, handleInputItem(item, idx), idx);
        } else {
          formNode = handleInputItem(item, idx);
        }
        if (layout) {
          output.push(<Col key={`col-${idx}`} {...layout}>{formNode}</Col>);
        } else {
          output.push(formNode);
        }
      });
      return output;
    };
    return (
      <React.Fragment>
        {createFormItem()}
      </React.Fragment>
    );
  }
}
AntdForm.propTypes = {
  template: PropTypes.array,
  layout: PropTypes.any,
};
AntdForm.defaultProps = {
  template: [],
};

function getFormValue(object, key, defaultValue) {
  const nil = typeof defaultValue !== 'undefined' ? defaultValue : undefined;
  if (!object || typeof object !== 'object') {
    return nil;
  }
  if (object.hasOwnProperty(key)) {
    return object[key];
  }
  return nil;
}

export {
  FormWrapper, getFormValue,
  TextInput, DateInput, SelectInput, RadioInput, PasswordInput, NumberInput,
};

export default observer(AntdForm);
