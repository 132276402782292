import { checkPhoneBrowser } from '@/utils';
import decryptedParams from '@/utils/decryptedParams';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import MobileAuth from '@/pages/home/MobileAuth';
import { CAN_ONT_ON_MOBILE_TYPE } from '@/utils/taxUtil/dict';

export default function ShowOnMobile(Component) {
  const WrappedComponent = (props) => {
    const params = decryptedParams();
    if (!isEmpty(params)) {
      if (CAN_ONT_ON_MOBILE_TYPE.includes(params.taxType) && checkPhoneBrowser()) {
        return ( <MobileAuth /> );
      }
    }
    return (<Component {...props} />);
  };
  return inject('taxHelperStore')(observer(WrappedComponent));
}
