import Worker from './hxtax.worker.js';

const validFileds = [
  {key: 'taxKey', require: true, desc: 'tax-key不存在'},
  // 获取平台商授权令牌的参数
  {key: 'userName', require: true, desc: '用户名'},
  {key: 'corpName', require: true, desc: '企业名称'},
  {key: 'creditCode', require: true, desc: '纳税人识别号'},
  {key: 'authCode', require: true, desc: '企业授权码'},
  {key: 'cRegisterCode', require: true, desc: '注册码'},
  {key: 'terminalId', require: false, desc: '终端号'},
  {key: 'platformCode', require: false, desc: '平台编码'},
  {key: 'serialNo', require: false, desc: '流水号'},
  // 请求key
  // {key: 'dumUserKey', require: true, desc: 'dmp-user-key'},
  // 上传必要参数
  // {key: 'creditCode', require: true, desc: '社会信用代码'},
  {key: 'machineNo', require: true, desc: '分机号'},
  {key: 'certPW', require: true, desc: '税控设备证书口令'},
];

/** 对传入参数进行必要性检查 */
function checkParamsValid(data) {
  let result = [];
  if (!data) {
    console.error('[参数校验异常] 传入参数不存在');
    return false;
  }

  validFileds.forEach((item) => {
    const key = item.key;
    if (item.require && !data[key]) {
      result.push(`${item.desc}(${item.key})`);
    }

    if (!item.require && !data[key]) {
      data[key] = null;
    }
  });

  if (result.length > 0) {
    console.error(`[参数校验异常] ${result.join('、')} 等字段不存在，请检查字段`);
  }

  return result.length === 0;
}

export function taxWork(params, callback) {
  const isValidParams = checkParamsValid(params);
  if (!isValidParams) {
    return Promise.resolve('[参数校验异常] 传入参数不存在或有遗漏');
  }

  return new Promise((resolve, reject) => {
    try {

      const worker = new Worker();
    
      worker.postMessage(params);
      // 通过postMessage向worker发送消息, 在worker.js中监听消息，执行任务
      worker.onmessage = function(event) {
        const datas = event.data || [];
        const data = datas[0] || {};
        console.log('onmessage--------->', datas);
        // worker.js中发送的消息会在此处接收
        if (data.pending) {
          callback(data);
        } else {
          if (data.success) {
            console.log('数据上传成功');
            callback(data);
            resolve(data);
          } else {
            console.error('[worker执行异常] 数据上传失败');
            reject(data);
          }
        }
      }
    } catch (error) {
      reject(error);
    }
  });
}