import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

const FormWrapper = ({ item, children }) => {

  const FormItem = Form.Item;

  return (
    <FormItem
      label={item.label}
      {...item.formItemProps}
      name={item.key}
      className={item.className || ''}
    >
      {children}
    </FormItem>
  );
}

FormWrapper.propTypes = {
  formProps: PropTypes.object,
  key: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
  value: PropTypes.any,
};

export default FormWrapper;
