import { provinceValidatorApi, taxAuthApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import { showErrorMessage } from '@/utils/taxUtil';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';

/** 贵州旧版 */
class GuizhouOld {

  bsrs = [];
  bsrsList = [];

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then( resp => {
        const status = get(resp, 'data.data.data.callback') || '';
        const bsrs = get(resp, 'data.data.data.flowData');

        if (status === 'loginSuccess') {
          return { loginSuccess: true };
        }
        /** 办税员列表不为空，进入下一步 */
        this.bsrs = bsrs;
        this.bsrsList = bsrs.map((item, idx) => {
          return {
            value: idx,
            label: `${item.sfmc}/${item.xm}/${item.yddh}/${item.zjhm}`
          }
        })
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '获取登录角色失败';
        message.error(errorMessage);
        return Promise.reject(error);
      });
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData, options }) => {
    /** 有 bsr 字段时为第二步发送验证码 */
    if (formData.bsr) {
      const { bsr } = formData;
      const { guizhouImageCaptcha } = options;
      const { captchaVerification } = guizhouImageCaptcha;
      const { taxAccount, taxPassword } = stepOneFormData;
      const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
      const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
      const params = {
        serialNo,
        username: taxAccount,
        password: taxPassword,
        creditCode: nsrsbh,
        province,
        uuid: selectedBsr.uuid,
        sjhm: selectedBsr.yddh,
        captchaVerification,
      };
      return provinceValidatorApi.taxAuthSendCaptcha(encryptedParams(params, [ 'username', 'password' ]))
        .catch(error => {
          showErrorMessage(error, '发送验证码失败');
          return Promise.reject(error);
        })
    }
    const { veriData, taxAccount, taxPassword, phone } = formData;
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const { captchaVerification } = veriData;
    const params = {
      serialNo,
      province,
      creditCode: nsrsbh,
      userName: taxAccount,
      userPass: taxPassword,
      phoneNumber: phone,
      captchaVerification
    };
    return provinceValidatorApi.guizhouSendCaptcha(encryptedParams(params, ['userName', 'userPass']))
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { captcha } = formData;
    const { serialNo, nsrsbh } = taxHelperStore.authQueryParams;
    const params = { captcha, creditCode: nsrsbh };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  };

}

export default new GuizhouOld();
