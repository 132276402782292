import {Button, Modal} from "antd";

function Notify() {
  return (
    <>
      <p>若您点击【确认】后，无法登录，可通过如下方式解决：</p>

      <p>1. 请依据页面的错误提示进行相应操作；</p>
      <p>2. 请在企业所属电子税务局官网登录入口选择【企业业务】，确认可否正常登录；</p>
      <p>3. 若始终无法登录，请与我方联系。</p>
      <p style={{color: '#faad14'}}>注意：登录成功后，为避免当前页面登录失效和数据采集中断，请您在15分钟内不要登录电子税局或其他设备！</p>
    </>
  )
}

export default Notify;