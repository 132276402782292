import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import AntdForm from '@/components/common/AntdForm';

import styles from './index.less';

const TaxForm = (props) => {
  
  const { inputSize, formData } = props;
  const [form] = Form.useForm();
  const [formTemplate, setTemplate] = React.useState([]);
  const [deviceType, setDeviceType] = React.useState(formData.deviceType || undefined);
  const isJSP = ['jsp', 'skp'].includes(deviceType);

  const getFormTemplates = () => {
    return [
      {
        key: 'corpName',
        label: '企业名称：',
        type: 'text',
        formItemProps: {
          rules: [
            { required: true, message: '请输入税控设备证书口令' },
          ]
        },
        inputProps: {
          placeholder: '请输入税控设备证书口令',
          size: inputSize,
          disabled: true,
        }
      },
      {
        key: 'deviceType',
        label: '开票软件设备类型：',
        type: 'select',
        options: [
          { label: '金税盘', value: 'jsp' },
          { label: '税务 Ukey', value: 'ukey' },
          { label: '税控盘', value: 'skp' },
        ],
        formItemProps: {
          rules: [
            { required: true, message: '请选择开票软件设备类型' },
          ]
        },
        inputProps: {
          placeholder: '请选择开票软件设备类型',
          size: inputSize,
          onChange: (value) => {
            setDeviceType(value);
          }
        },
      },
      {
        key: 'certPW',
        label: '税控设备证书口令：(请慎填证书口令，以防税盘锁死)',
        type: 'password',
        formItemProps: {
          rules: [
            { required: true, message: '请输入税控设备证书口令' },
          ]
        },
        inputProps: {
          placeholder: '请输入税控设备证书口令',
          size: inputSize,
        }
      },
      {
        key: 'devicePW',
        label: '税控设备口令：(请慎填证书口令，以防税盘锁死)',
        type: 'password',
        formItemProps: {
          rules: isJSP ? [] : [
            { required: true, message: '请输入税控设备口令' },
          ]
        },
        inputProps: {
          placeholder: '请输入税控设备口令',
          size: inputSize,
        }
      }
    ]
  }

  React.useEffect(() => {
    const template = getFormTemplates();
    setTemplate(template);
    return () => {
      setTemplate([]);
      form.resetFields();
    };
  }, [deviceType]);

  const antdFormProps = {
    template: formTemplate,
  };

  const formProps = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    initialValues: {...formData, deviceType},
  };

  return (
    <div className={styles.wrapper}>
      <Form form={form} {...formProps} layout="vertical">
        <AntdForm {...antdFormProps} />
        {props.renderFooter && props.renderFooter({form, ...formProps})}
      </Form>
    </div>
  );
}

TaxForm.propTypes = {
  renderFooter: PropTypes.func,
  formData: PropTypes.object,
};

export default TaxForm;
