import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

/** 贵州 */
class Guizhou {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = (data) => {
    const params = {
      authentication: 'tpass.guizhou.chinatax.gov.cn',
      ...data
    }
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then((resp) => {
        const { mobile, uuid } = get(resp, 'data.data.data') || {};
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{
          label: mobile,
          value: uuid
        }];
        return this.bsrsList;
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData }) => {
    const { bsr } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params =  {
      serialNo,
      province,
      creditCode: nsrsbh,
      authentication: 'tpass.guizhou.chinatax.gov.cn',
      uuid: bsr
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(params)
      .then(resp => {
        const smscodeId = get(resp, 'data.data.smscode_id') || '';
        this.smscodeId = smscodeId;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { captcha, bsr } = formData;
    const params = {
      'uuid': bsr,
      'smscodeId': this.smscodeId,
      'mobileCode': captcha,
      'serialNo': serialNo,
      'creditCode': nsrsbh,
      'province': province,
      'authentication':'tpass.guizhou.chinatax.gov.cn'
    };
    return provinceValidatorApi.taxAuthCaptchaCommit2(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  };

}

export default new Guizhou();