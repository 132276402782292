import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
// import { message } from "antd";
import { get } from "lodash";
import taxHelperStore from '../taxHelper';

const BSR_TYPE_DICT = {
  '1': '办税员',
  '2': '法定代表人',
  '3': '财务负责人',
  '4': '代理人',
  '5': '购票员',
  '6': '委托人',
};

/** 辽宁旧版 */
class LiaoNingOld {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = () => {
    const list = [
      { label: '法人代表', value: '01' },
      { label: '财务负责人', value: '02' },
      { label: '办税人', value: '03' },
    ]
    this.bsrsList = list;
    return Promise.resolve(list)
  }


  /** 发送验证码 */
  sendCaptcha = ({ formData }) => {
    const { bsr, phone, taxPassword } = formData;
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const params = {
      "serialNo": serialNo,
      "creditCode": nsrsbh,
      "province": province,
      "userName": nsrsbh,
      "userPass": taxPassword,
      "rylx": bsr,
      "phoneNumber": phone,
      "authorizationType": "SMS"
    }
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }


  taxAuth = ({ formData }) => {
    const { taxPassword, bsr, captcha, phone } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params = {
      "serialNo": serialNo,
      "province": province,
      "creditCode": nsrsbh,
      "userName": nsrsbh,
      "userPass": taxPassword,
      "bsrType": bsr,
      "yddh": phone,
      "captcha": captcha,
      "authorizationType": "SMS"
    }
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, encryptedParams(params, ['userName', 'userPass', 'zjhm']))
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

};

export default new LiaoNingOld();
