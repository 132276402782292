import React from 'react';
import { searchCompanyList, getCorpInfo, getRizhaoTaxAuthUrl } from '@/api/business';
import { getAccountInfoBySource, getToken } from '@/api/common';
import { setBusinessAxios } from '@/utils/businessAxios';
import { get, set } from 'lodash';

class RizhaoBusinessStore {

  source = 'RZ';

  notifyUrl = '';
  // 如果没有传递 notifyUrl, 则无法进行后续流程
  hasError = false;

  setValue = (path, value) => {
    set(this, path, value);
  }

  searchCompanyList = (params) => {
    return searchCompanyList(params)
      .then(resp => {
        const content = get(resp, 'data.data') || [];
        const options = content.map((text) => ({
          label: <span dangerouslySetInnerHTML={{__html: text}}></span>,
          value: text.replace(/<\/?em>/g, ''),
        }));
        return options;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      });
  }

  getCorpInfo = (params) => {
    return getCorpInfo(params)
      .then(resp => {
        const data = get(resp, 'data.data');
        return data;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      });
  }

  getAuthorizeUrl = (params) => {
    return getRizhaoTaxAuthUrl(params)
      .then(resp => {
        const url = get(resp, 'data.data.url');
        return url;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      });
  }

  getToken = () => {
    return this.getSourceInfo()
      .then((params) => getToken(params))
      .then(resp => {
        const token = get(resp, 'data.data.sc-api-token');
        setBusinessAxios(token);
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      });
  }

  getSourceInfo() {
    return getAccountInfoBySource(this.source)
      .then((resp) => {
        const respData = get(resp, 'data.data');
        // 2022年6月10日起，此地址将由 /rzBusiness?notifyUrl=http://xx.com 的方式传递 notifyUrl
        // this.notifyUrl = get(respData, 'notifyUrl');
        return {
          apiKey: get(respData, 'key'),
          sharedSecret: get(respData, 'sharedSecret'),
        };
      });
  }

  resetStore = () => {
    this.notifyUrl = '';
    // 如果没有传递 notifyUrl, 则无法进行后续流程
    this.hasError = false;
  }
  
}

export default new RizhaoBusinessStore();
