import { mobxFactory, mobxPolyfill } from 'src/utils/mobxFactory';
import timerStore from './timer';
import hxTaxBuresuStore from './hxTaxBuresu';
import zhejiangValidatorStore from './provinceValidator/zhejiang';
import hebeiValidatorStore from './provinceValidator/hebei';
import shandongValidatorStore from './provinceValidator/shandong';
import anhuiValidatorStore from './provinceValidator/anhui';
import sichuanStore from './province/sichuan';
import tianjinStore from './province/tianjin';
import zhengJiangStore from './province/zhejiang'
import jiangsuStore from './province/jiangsu';
import neimengguStore from './province/neimenggu'
import heilongjiangStore from './province/heilongjiang'
import yunnanStore from './province/yunnan';
import hainanStore from './province/hainan';
import anhuiStore from './province/anhui';
import jilinStore from './province/jilin_old';
import shanxiStore from './province/shanxi';
import shanxiTyStore from './province/shanxi_ty';
import ningbiStore from './province/ningbo'
import guizhouStore from './province/guizhou';
import gansuStore from './province/gansu';
import hebeiStore from './province/hebei';
import qingdaoStore from './province/qingdao';
import guangxiStore from './province/guangxi';
import jiangxiStore from './province/jiangxi';
import shangahiStroe from './province/shangahi';
import xinjiangStore from './province/xinjiang';
import dalianStore from './province/dalian';
import liaoningStore from './province/liaoning';
import guideStore from './province/guide';
import beijingStore from './province/beijing';
import shandongStore from './province/shandong';
import ningxiaStore from './province/ningxia';
import qinghaiStore from './province/qinghai';

import taxAuthStore from './taxAuth';
import commonStore from './common';
import hubeiSPLoginStore from './hubeiSPLogin';
import taxHelperStore from './taxHelper';
import anhuiQrAuthStore from './anhuiQrAuth';
import accessStore from './access';
import tradeStore from './trade';
import smengStore from './smeng';

import collectionModeStore from './collectionMode';
import selfFillingStore from './selfFilling';
import rizhaoBusinessStore from './business/rizhaoBusiness';

mobxPolyfill();

const stores = {
  qinghaiStore,
  guideStore,
  liaoningStore,
  beijingStore,
  shandongStore,
  ningxiaStore,
  xinjiangStore,
  timerStore,
  hxTaxBuresuStore,
  zhejiangValidatorStore,
  hebeiValidatorStore,
  shandongValidatorStore,
  anhuiValidatorStore,
  sichuanStore,
  tianjinStore,
  zhengJiangStore,
  jiangsuStore,
  neimengguStore,
  heilongjiangStore,
  yunnanStore,
  hainanStore,
  anhuiStore,
  taxAuthStore,
  commonStore,
  hubeiSPLoginStore,
  taxHelperStore,
  anhuiQrAuthStore,
  jilinStore,
  shanxiStore,
  shanxiTyStore,
  ningbiStore,
  guizhouStore,
  gansuStore,
  hebeiStore,
  qingdaoStore,
  guangxiStore,
  jiangxiStore,
  shangahiStroe,
  accessStore,
  tradeStore,
  smengStore,
  collectionModeStore,
  selfFillingStore,
  dalianStore,
  rizhaoBusinessStore,
};

export default mobxFactory(stores);
