import { Form, Input } from 'antd';

const TaxPasswordFormItem = ({ inputSize, disabled }) => {
  return (
    <Form.Item
      key="password"
      colon={false}
      label="电子税务局查询密码："
      name="password"
      rules={[{ required: true, message: '请输入电子税务局查询密码' }]}
    >
      <Input.Password size={inputSize} disabled={disabled} placeholder="请输入电子税务局查询密码" autoComplete="new-password" />
    </Form.Item>
  );
}

export default TaxPasswordFormItem;
