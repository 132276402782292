import React from 'react';
import {inject, observer} from 'mobx-react';
import {loadScript} from "@/utils";
// import {Form, Input, Select, Button, Row, Col, message} from 'antd';
// import {idValid} from '@/utils/formValidator';
// import {formLayout, formSize} from '../formConponentConfig';
// import { provinceValidatorApi } from '@/api';
// import {encryptedParams} from "@/utils";

const JQUERY_URL = process.env.PUBLIC_URL + '/jquery.min.js';
const CAPTCHA_SCRIPT_URL = process.env.PUBLIC_URL + '/statics/js/jilin_captcha.js';

class Jilin extends React.Component {

  options = {
    showStyle: "2",
    id: "dragCheckCode",
    submitId: "slow_login_btn",
    order_id: null,
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      identityOptions: [],
      loading: false,
    }
    const {authQueryParams} = this.props.taxHelperStore;
    this.options.order_id = authQueryParams.serialNo;
    this.options.onSuccess = this.onSuccess;
  }

  onSuccess = () => {
    const {jilinStore} = this.props;
    jilinStore.setVerifyStatus(true);
  }

  _scpublicValidateFields = () => {
    const {jilinStore} = this.props;
    if (jilinStore.verifySuccess) {
      return Promise.resolve(true);
    }
    return Promise.reject({ captchaError: true });
  }

  setLoading = (status) => {
    this.setState({
      loading: status,
    })
  }

  componentDidMount() {
    Promise.all([
      loadScript(JQUERY_URL),
      loadScript(CAPTCHA_SCRIPT_URL)
    ])
      .then(() => this.initCaptchaP())
      .catch();
  }

  initCaptchaP = () => {
    // const {jilinStore} = this.props;
    // return Promise.resolve(true)
    //   .then((data) => {
    //     this.dragCkCode = new window.DragCkCode(this.options);
    //     jilinStore.setCheckCodeInstance(this.dragCkCode);
    //   })
    //   .catch(() => {
    //
    //   });
  }

  // 获取身份信息
  // handleInfoButtonClick = () => {
  //   const { baseFormValidate } = this.props;
  //   baseFormValidate().then((values) => {
  //     const {authQueryParams: {nsrsbh, serialNo,}} = this.props.taxHelperStore;
  //     const params = {
  //       nsrsbh,
  //       order_id: serialNo,
  //       user_id: values.username,
  //       user_pass: values.password,
  //       area: '吉林',
  //     }
  //     // let params = {
  //     //   "creditCode": nsrsbh,
  //     //   "province": "吉林",
  //     //   "scuuid": "",
  //     //   "serialNo": serialNo,
  //     //   "userName": values.username,
  //     //   "userPass": values.password
  //     // }
  //     // params = encryptedParams(params, ['userName', 'userPass']);
  //     this.setLoading(true);
  //     provinceValidatorApi.getJilinTaxUserList(params).then(res => {
  //       if (res?.data?.data?.value) {
  //         const options = res.data.data.value.map(i => ({
  //           value: i.yhid,
  //           label: `${i.yhxm}/${i.lxdh}/${INFO_POSITION_DICT[i.rylx]}`
  //         }));
  //         this.setState({
  //           identityOptions: options,
  //         })
  //       } else {
  //         message.error(res?.data?.message || '获取信息失败');
  //       }
  //       this.setLoading(false);
  //     }).catch(err => {
  //       this.setLoading(false);
  //     })
  //   })
  // }

  render() {
    // const {identityOptions, loading} = this.state;
    return (
      <React.Fragment>
        {/*<div id='dragCheckCode' style={{marginBottom: '20px'}}>人机验证加载中...</div>*/}
        {/* <Form ref={this.formRef} layout={formLayout}>
          <Form.Item label="身份" required>
            <Row gutter={8}>
              <Col span={16}>
                <Form.Item
                  noStyle
                  name="identity"
                  rules={[
                    {required: true, message: "请选择身份"}
                  ]}
                >
                  <Select options={identityOptions} placeholder="请选择身份"/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button onClick={this.handleInfoButtonClick} loading={loading}>获取身份信息</Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label="办税员身份证"
            name="sfzjhm"
            rules={[
              {required: true, message: '请输入办税员身份证'},
              {validator: (_, value) => idValid(value, '请输入正确的身份证号码')}
            ]}
          >
            <Input placeholder="请输入办税员身份证" size={formSize}/>
          </Form.Item>
          <Form.Item
            label="办税员密码"
            name="bsymm"
            rules={[
              {required: true, message: '请输入办税员密码'}
            ]}
          >
            <Input.Password placeholder="请输入办税员密码" size={formSize}/>
          </Form.Item>
        </Form> */}
      </React.Fragment>
    )
  }
}

export default inject('jilinStore', 'taxHelperStore')(observer(Jilin));
