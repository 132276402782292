import React, {Component} from 'react';
import {Form} from 'antd';
import {inject, observer} from 'mobx-react';
import AntdForm from '@/components/common/AntdForm';
import {FORM_STEP_MAP} from '../helper/data';
import { getProvinces} from "@/pages/guide/fake";

import {
  PROVINCE_FORM_CONFIG_MAP,
  taxDeclareProvince,
  companyName,
  taxAccount,
  taxPassword,
  spsb,
  bsr,
  bsrType,
  bsrPassword,
  captcha,
  phone,
  OLD_PROVINCE_FORM_CONFIG_MAP,
} from '@/components/TaxBureauStep/TaxForm/formItemConfig';

class TaxForm extends Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.NEW_AUTH = props.NEW_AUTH
    this.state = {
      isBsrPasswordDisabled: false
    }
  }

  getCaptcha = () => {
    return this.props.getCaptcha()
      .then(() => {
        this.setState({isBsrPasswordDisabled: true});
      })
      .catch(error => {
        console.log('error: ', error);
        return Promise.reject(error);
      });
  }

  /** 办税员列表 */
  roleList = () => {
    const {provinceStore} = this.props;
    return provinceStore.bsrsList;
  }

  /** 所有身份列表 */
  provinceList = () => {
    // return getProvinces()
    //   .map(item => {
    //     const {area} = item;
    //     return {label: area, value: area};
    //   });
    return this.props.taxAuthStore.taxDeclareProvince
      .map(item => {
        const { area, disabled } = item;
        return { label: area, value: area, disabled };
      });
  }

  handleProvinceChange = (selectProvince) => {
    const {setQueryParams, authQueryParams,} = this.props.taxHelperStore;
    const {serialNo} = authQueryParams;
    const { taxDeclareProvince, taxAreaStatusModal } = this.props.taxAuthStore
    setQueryParams({...authQueryParams, province: selectProvince});
    const {qymc, province} = this.props.taxHelperStore.authQueryParams;
    
    const option = taxDeclareProvince.find((item) => item.area === selectProvince)
    // 维护中，弹窗提示
    if (option.status === 'MAINTENANCE') {
      taxAreaStatusModal(option)
    }

    this.props.provinceStore?.getRoleList(province);
    // const fakeData = getFakeAccountData(province);

    // setTimeout(() => {
    //   console.log('------------------------------------------------------------------')
    //   console.log(this.formRef.current)
    //   this.formRef.current.setFieldsValue({
    //     ...fakeData,
    //   });
    // }, 100);
    /** 更新订单税局省份 */
    // this.props.taxAuthStore.updateTaxOrderBureau({serialNo, province: selectProvince});
  }

  getSetpFormConfig = () => {
    const province = this.props.taxHelperStore.getProvincePingYing();
    const {formStep} = this.props;
    let formConfig = PROVINCE_FORM_CONFIG_MAP.get(province);
    if (!this.NEW_AUTH) {
      // 优先采用旧版，如不存在则使用新版
      formConfig = OLD_PROVINCE_FORM_CONFIG_MAP.get(province) || PROVINCE_FORM_CONFIG_MAP.get(province);
    }
    let setpFormConfig = [];
    if (formStep === FORM_STEP_MAP.get('first')) {
      setpFormConfig = formConfig.first;
    }
    if (formStep === FORM_STEP_MAP.get('second')) {
      setpFormConfig = formConfig.second;
    }
    return setpFormConfig;
  }

  getFormTemplates = (formInstances) => {
    const output = [];
    const {formStep} = this.props;
    const setpFormConfig = this.getSetpFormConfig();
    setpFormConfig.map(formItemConfig => {
      let formItemName = '';
      let options = {};
      if (typeof formItemConfig === 'string') {
        formItemName = formItemConfig;
      }
      if (typeof formItemConfig === 'object') {
        formItemName = formItemConfig.name;
        options = formItemConfig.options;
      }
      switch (formItemName) {
        case 'taxDeclareProvince':
          output.push(taxDeclareProvince(formInstances, {...options, selectList: this.provinceList()}, {
            onChange: this.handleProvinceChange,
            disabled: formStep === FORM_STEP_MAP.get('second') || !this.NEW_AUTH
          }));
          break;
        case 'companyName':
          const creditCode = this.props.taxHelperStore.getCreditCode();
          output.push(companyName(formInstances, {...options, creditCode}, {
            disabled: true
          }));
          break;
        case 'taxAccount':
          output.push(taxAccount(formInstances, {...options}));
          break;
        case 'taxPassword':
          output.push(taxPassword(formInstances, {...options}));
          break;
        case 'phone':
          output.push(phone(formInstances, {...options}));
          break;
        case 'spsb':
          const taxType = this.props.taxHelperStore.getTaxType();
          output.push(spsb(formInstances, {...options, taxType}));
          break;
        case 'bsr':
          output.push(bsr(formInstances, {...options, selectList: this.roleList()}, {
            onChange: this.props.bsrOnChange,
            loading: this.props.bsrLoading
          }));
          break;
        case 'bsrType':
          output.push(bsrType(formInstances, {...options}));
          break;
        case 'bsrPassword':
          const {isBsrPasswordDisabled} = this.state;
          output.push(bsrPassword(formInstances, {...options}, {disabled: isBsrPasswordDisabled}));
          break;
        case 'captcha':
          output.push(captcha(formInstances, {...options, getCaptcha: this.getCaptcha}));
          break;
        default:
          break;
      };
      return null;
    })
    return output;
  }

  render() {
    const {formData, formInstances} = this.props;
    const formProps = {
      labelCol: {span: 24},
      wrapperCol: {span: 24},
      initialValues: {
        ...formData,
        deviceType: ''
      },
    };
    return (
      <>
        <Form ref={this.formRef} {...formProps} layout="vertical">
          <AntdForm template={this.getFormTemplates(formInstances)}/>
        </Form>
      </>
    );
  }
}

export default inject(
  'taxHelperStore',
  'taxAuthStore',
  // 'provinceStore'
)(observer(TaxForm));
