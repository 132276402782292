import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

export const businessAxios = axios.create({});

export const setBusinessAxios = (token) => {
  businessAxios.interceptors.request.use((axiosConfig) => {
    const { params } = axiosConfig;
      axiosConfig.headers['sc-api-token'] = token;
      axiosConfig.params = { ...params, sp: uuidv4() };
      return axiosConfig;
    });
}
