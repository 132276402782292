import React, { useState } from 'react';
import imgHlTip from '@/assets/images/header/hl_tip.jpeg';
import config from '@/config';
import { inject, observer } from 'mobx-react';

import styles from './index.less';
import { isEmpty } from 'lodash';

const Header = ({ tradeStore, commonStore }) => {
  const [ modalVisiable, setModalVisiable ] = useState(false);
  const { logoURL, certificationUrl } = commonStore;
  const onMouseEnter = () => {
    if ( logoURL && certificationUrl ) {
      setModalVisiable(true);
    }
  };
  const onMouseLeave = () => {
    setModalVisiable(false);
  }
  if (!logoURL) {
    return null
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.box}>
        <div
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={styles.logo}
          style={logoURL ? {backgroundImage: `url("${encodeURI(logoURL)}")`} : {}}
        ></div>
        
        {
          modalVisiable ?
          <div className={styles.imgTip}>
            <img src={certificationUrl} alt="tip" />
          </div> : null
        }
      </div>
    </div>
  );
};

export default inject('tradeStore', 'commonStore')(observer(Header));
