import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Alert, Button, message } from 'antd';
import { isEmpty } from 'lodash';
import AntdForm from '@/components/common/AntdForm';
import WhiteBox from '@/components/layout/whiteBox';
import TicketTitle from '@/components/ticketTitle';
import { accountConfig, passwordConfig } from './FormItemConfig';
import decryptedParams from '@/utils/decryptedParams';
import { encryptedParams, gotoCburl } from '@/utils';
// import AuthProtocol from './AuthProtocol';
// import TaxFormAuthProtocol from './TaxFormAuthProtocol';
import TaxFormAuthProtocol from '@/components/common/TaxAuthProtocol'
import { initTaxAsios } from '@/utils/taxAxios';

import styles from './index.module.less';
import VerifyModal from './VerifyModal';

const Smeng = ({ smengStore, history, commonStore }) => {

  const [ queryParams, setQueryParams] = useState({});
  const [ isLoading, setIsloading ] = useState(false);
  const [ btnDisbaled, setBtnDisbaled ] = useState(false);
  const [verifyData, setVerifyData] = useState(undefined)
  const [ isAgree, setIsAgree ] = useState(false);

  const [ form ] = Form.useForm();

  const getFormTemplates = () => {
    return [
      accountConfig(),
      passwordConfig()
    ]
  };

  const formProps = {
    layout: 'vertical',
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };

  useEffect(() => {
    initTaxAsios();
  }, [])

  useEffect(() => {
    const params = decryptedParams();
    if (isEmpty(params)) {
      history.replace(`/invalidation?code=1099`);
    }
    setQueryParams(params);
    /** 协议获取-单个 */
    commonStore.getProtocolSingleV2(params)
    /** logo获取 */
    commonStore.getLogoInfo(params)
    const { account } = params;
    form.setFieldsValue({ account });
  }, [])

  const isAgreeP = () => {
    if (!isAgree) {
      message.error('请先阅读并同意相关协议');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  const onAuthSuccess = () => {
    const { serialNo, cburl } = queryParams;
    if (cburl) {
      gotoCburl(cburl);
      return false;
    }
    history.replace(`/authSuccess?serialNo=${serialNo}&type=smeng&watch=true`);
  }

  const handleSubmit = () => {
    return Promise.resolve()
      .then(() => isAgreeP())
      .then(() => form.validateFields())
      .then(data => {
        const { password } = data;
        const { serialNo } = queryParams;
        return {
          pwd: password,
          orderNo: serialNo,
        };
      })
      .then(params => {
        setIsloading(true);
        return smengStore.auth(encryptedParams(params, ['pwd']));
      })
      .then((res) => {
        if (res) {
          const values = form.getFieldsValue()
          setVerifyData({...res, values })
          return;
        } 
        setIsloading(false);
        setBtnDisbaled(true);
        onAuthSuccess()
      })
      .catch(error => {
        console.error(` error:`, error);
        setIsloading(false);
      })
  }

  const handleChangeAgree = () => {
    setIsAgree(!isAgree);
  }

  return (
    <WhiteBox>
      <TicketTitle />
      <div className={styles.alert}>
        <Alert
          showIcon
          message="请在15分钟内完成操作，以获取更多优质服务"
          type="warning"
        />
      </div>
      <div className={styles.formWrap}>
        <Form form={form} {...formProps} layout="vertical">
          <AntdForm template={getFormTemplates()} />
        </Form>
        {/* <AuthProtocol isAgree={isAgree} onChange={handleChangeAgree} /> */}
        {/* 协议组件 */}
        <TaxFormAuthProtocol
          // isProvince={isProvince}
          // formStep={formStep}
          isAgree={isAgree}
          onChange={handleChangeAgree}
        />
        {/* VerifyModal */}
        <VerifyModal
          queryParams={queryParams}
          data={verifyData}
          onAuthSuccess={onAuthSuccess}
        />
        <Button
          block
          size="large"
          type="primary"
          style={{ marginTop: '34px' }}
          loading={isLoading}
          disabled={btnDisbaled}
          onClick={handleSubmit}
        >登录</Button>
      </div>
    </WhiteBox>
  );
};

export default inject('smengStore', 'commonStore')(observer(Smeng));
