import { collectionModeApi } from '@/api';
import { get } from 'lodash';

class CollectionModeStore {

  buriedPoint = (params) => {
    return collectionModeApi.buriedPoint(params)
      .then(resp => {
        // const url = get(resp, 'data.data.url');
        // return url;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      });
  }

}

export default new CollectionModeStore();
