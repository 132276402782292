import { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'antd';
import WhiteBox from '@/components/layout/whiteBox';
import TicketTitle from '@/components/ticketTitle';
import SELF_IMG from '@/assets/images/collectionMode/self.png';
import SELF_SELECTED_IMG from '@/assets/images/collectionMode/selfSelected.png';
import INTELL_IMG from '@/assets/images/collectionMode/intelligence.png';
import INTELL_SELECTED_IMG from '@/assets/images/collectionMode/intelligenceSelected.png';
import styles from './index.module.less';

const CollectionMode = (props) => {

  const [isSelected, setIsSelected] = useState(true);

  const changeImg = (flag) => {
    setIsSelected(flag);
  }

  const nectStep = () => {
    const { buriedPoint } = props.collectionModeStore;

    if (!isSelected) {
      buriedPoint({ buriedPointType: 'TAX_MANUALLY' });
      props.history.push(`/selfFilling${window.location.search}`);
    } else {
      buriedPoint({ buriedPointType: 'TAX_AUTO' });
      props.history.push(`/authorize${window.location.search}`);
    }
  }

  return (
    <div className={styles.wrap}>
      <TicketTitle title="根据页面提示，完成对应操作" />
      <div className={styles.container}>
        <div className={styles.img}>
          <img alt='' className={isSelected ? styles.selectedLeft : styles.unselected} src={isSelected ? INTELL_SELECTED_IMG : INTELL_IMG} onClick={() => changeImg(true)} />
          <img alt='' className={!isSelected ? styles.selectedRight : styles.unselected} src={isSelected ? SELF_IMG : SELF_SELECTED_IMG} onClick={() => changeImg(false)} />
        </div>
        <div className={styles.btnBox}>
          <Button onClick={nectStep} className={styles.btn} type='primary' shape="round" size='large'>下一步</Button>
        </div>
      </div>
    </div>
  )
}

export default inject('collectionModeStore')(observer(CollectionMode));