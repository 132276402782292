import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

function sdk() {
  return Component => {
    function WrappedComponent(props) {
      const isSDK = props.commonStore.isSDK();
      return (<Component {...props} isSDK={isSDK} />);
    }
    return inject('commonStore')(observer(withRouter(WrappedComponent)));
  };
}

export default sdk;
