import { inject, observer } from 'mobx-react';
import TicketTitle from '@/components/ticketTitle';
import { withRouter } from 'react-router';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { get, isEmpty } from 'lodash';
import { parse } from 'query-string';
import { encodeBase64 } from '@/utils/taxUtil/encode';
import { downloadFile, linkTo } from '@/utils';

import wh from '@/assets/images/common/wh.png';

import styles from './index.less';

const QszcResult = (props) => {

  const getQueryParams = () => {
    const search = get(props, 'location.search');
    const queryParams = parse(search);
    return queryParams;
  }

  const [ queryParams ] = useState(getQueryParams());

  const lunchAppP = () => {
    const { nsrsbh, qrmc, serialNo, spsb, pluginType } = queryParams;
    if (nsrsbh && qrmc && serialNo && spsb) {
      const baseInfo = {
        "Name": qrmc,
        "TaxNumber": nsrsbh,
        "SerialNumber": serialNo,
        "StartupTag": spsb
      };
      const header = ['DZ_PLUS', 'PLUS'].includes(pluginType) ? 'TaxRobotPlus' : 'TaxRobotSilence';
      encodeBase64(baseInfo)
        .then((text) => {
          const url = `${header}:${text}`;
          linkTo(url);
        })
        .catch(() => {});
    }
  }

  useEffect(() => {
    lunchAppP();
    const { pluginType } = queryParams;
    const { pluginConfig, getPluginConfig } = props.taxAuthStore;
    if (isEmpty(pluginConfig)) {
      getPluginConfig(pluginType);
    }
  },[]);

  const downloadPlugins = () => {
    const { pluginType } = queryParams;
    const url = props.taxAuthStore.pluginConfig.exeUrl;
    if (!url) {
      console.error(`没有 ${pluginType} 对应的资源文件`);
      return false;
    }
    downloadFile(url);
  }

  const downloadhelp = () => {
    const { pluginType } = queryParams;
    const url = props.taxAuthStore.pluginConfig.helperUrl;
    if (!url) {
      console.error(`没有 ${pluginType} 对应的操作指引文件`);
      return false;
    }
    downloadFile(url);
  }

  const renderPROResult = () => {
  return (
      <div className={styles.main}>
        <img className={styles.img} src={wh} alt="" />
        <div className={styles.subTitle}>请按照下列提示，完成操作</div>
        <div className={styles.step}>
          <ol>
            <li>操作前，请先关闭360等杀毒软件；</li>
            <li>若无法正常跳转，请点击【立即下载】，下载发票助手软件；</li>
            <li>已安装软件，请点击【立即启动】予以启动；</li>
            <li>如对操作有疑问，请点击【操作手册】了解如何操作；</li>
          </ol>
        </div>
        <div className={styles.btnWrap}>
          <Button type="primary" size="large" onClick={downloadPlugins}>立即下载</Button>
          <Button style={{color: '#4AACFF'}} size="large" onClick={lunchAppP}>已安装，立即启动</Button>
          <a onClick={downloadhelp}>操作手册</a>
        </div>
      </div>
    );
  }

  const renderPROPLUSResult = () => {
    return (
      <div className={styles.main}>
        <img className={styles.img} src={wh} alt="" />
        <div className={styles.subTitle}>请按照下列提示，完成操作</div>
        <div className={styles.step}>
          <ol>
            <li>操作前请确认电脑已经连接税盘；</li>
            <li>操作前，请先关闭360等杀毒软件；</li>
            <li>请在开票电脑下载发票助手（本软件）；</li>
            <li>若无法正常跳转，请先点击【立即下载】按钮；</li>
            <li>已安装发票助手，请点击【立即启动】予以启动；</li>
            <li>如对操作有疑问，请点击【操作手册】了解如何操作；</li>
          </ol>
        </div>
        <div className={styles.btnWrap}>
          <Button type="primary" size="large" onClick={downloadPlugins}>立即下载</Button>
          <Button style={{color: '#4AACFF'}} size="large" onClick={lunchAppP}>已安装，立即启动</Button>
          <a onClick={downloadhelp}>操作手册</a>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.content}>
      {/* <TicketTitle title="采票服务授权" /> */}
      { ['DZ_PRO', 'PRO'].includes(queryParams.pluginType) ? renderPROResult() : null }
      { ['DZ_PLUS', 'PLUS'].includes(queryParams.pluginType) ? renderPROPLUSResult() : null }
    </div>
  );
}

export default inject('taxHelperStore', 'taxAuthStore')(withRouter(observer(QszcResult)));
