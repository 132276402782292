import { Form, Checkbox, Modal } from 'antd';
import { observer, inject } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import * as DOMpurify from 'dompurify';

import styles from './index.module.less';

const Protocol = ({ isAgreen, onAgreenChange, commonStore }) => {

  const { protocolList, protocolInfo } = commonStore;

  const [ isShowAgreen, setIsShowAgreen ] = useState(false);


  const handleAgreen = (evt ) => {
    evt.preventDefault();
    setIsShowAgreen(true);
  }

  const activeProtocolContent = DOMpurify.sanitize(protocolInfo.content);

  return (
    <>
      <Form.Item>
        <Checkbox checked={isAgreen} onChange={onAgreenChange}>
        <span className={styles.checkText}>
          勾选并同意
          <a onClick={(evt) => handleAgreen(evt)}>《{protocolInfo.name}》</a>
        </span>
        </Checkbox>
      </Form.Item>
      <Modal
        width={700}
        title={protocolInfo.name}
        visible={isShowAgreen}
        footer={null}
        onCancel={() => { setIsShowAgreen(false); }}
      >
        <div className={styles.modalContent} dangerouslySetInnerHTML={{ __html: activeProtocolContent }}></div>
      </Modal>
    </>
  );
};

export default inject('commonStore')(observer(Protocol));
