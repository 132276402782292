import {inject, observer} from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Input, Select} from 'antd';
import {toJS} from 'mobx';
import {encryptedParams} from '@/utils';

import {formLayout, formSize} from '../formConponentConfig';

/**
 * 解析浙江登录角色
 */
const getTypeRole = (item) => {
  // if (item.type === 'cwfzr') {
  //   return '财务负责人';
  // }
  // if (item.type === 'fddbr') {
  //   return '法定代表人';
  // }
  // if (item.type === 'dzswj' && !!item.sflx && item.sflx.indexOf('9') > -1) {
  //   return '社会共治';
  // }
  // return '办税员';
  return (["财务负责人", "法定代表人", "社会共治", "办税员"])[Math.floor(Math.random() * 3)];
};

class Zhejiang extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isLoading: false,
      roleList: []
    };
    this.extraParams = {};
    this.formRef = React.createRef()
  }

  _scPublicgetZhejiangBsyList = (params) => {
    this.extraParams = params;
    const {username, password, creditCode} = params;
    const postData = {creditCode, userName: username, userPass: password};
    return this.props.guideStore.getRoleList("浙江")
      .then(data => {
        this.setState({isModalVisible: true});
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }

  componentDidUpdate() {
    if (this.formRef.current) {
      const {bsrsList: roleList} = this.props.guideStore;
      this.formRef.current.setFieldsValue({
        password: '123456',
        username: toJS(roleList)[0].value
      });
    }
  }

  handleOk = () => {
    this.setState({isLoading: true});
    this.formRef.current.validateFields()
      .then(data => {
        const param = {...this.extraParams, ...data};
        return this.props.taxAuth(encryptedParams(param, ['username', 'password']));
      })
      // .then(() => {
      //   this.setState({ isModalVisible: false });
      //   this.setState({ isLoading: false });
      // })
      .catch(error => {
        this.setState({isLoading: false});
        console.log('error-----: ', error);
      })
  }

  handleCancel = () => {
    this.setState({isModalVisible: false});
  }

  render() {
    const {isModalVisible, isLoading} = this.state;
    const {bsrsList: roleList} = this.props.guideStore;
    console.log(toJS(roleList), 'zejiang')
    return (
      <Modal title="选择角色登录"
             cancelText="取消"
             okText="确认"
             visible={isModalVisible}
             confirmLoading={isLoading}
             onOk={this.handleOk}
             onCancel={this.handleCancel}
      >
        <Form ref={this.formRef} layout={formLayout}>
          <Form.Item
            label="登录角色"
            name="username"
            rules={[
              {required: true, message: '请选择登录角色'}
            ]}
          >
            <Select placeholder="请选择登录角色" size={formSize}>
              {
                toJS(roleList).map(item => <Select.Option key={item.value}
                                                          value={item.value}>{item.label}/{getTypeRole(item)}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[
              {required: true, message: '请输入登录密码'}
            ]}
          >
            <Input.Password placeholder="请输入登录密码" size={formSize}/>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

Zhejiang.propTypes = {
  /**
   * taxAuth 授权方法
   */
  taxAuth: PropTypes.func.isRequired
}

export default inject('guideStore')(observer(Zhejiang));
