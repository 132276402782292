import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

class ZheJiang {

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { taxAccount, taxPassword } = formData;
    const params = encryptedParams({
      "serialNo": serialNo,
      "username": taxAccount,
      "password": taxPassword,
      "creditCode": nsrsbh,
      "province": province,
      "authentication": "tpass.zhejiang.chinatax.gov.cn"
    }, ['username', 'password'])
    return provinceValidatorApi.taxAuthCaptchaCommit2(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  };

}

export default new ZheJiang();