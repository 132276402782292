import { provinceValidatorApi } from '@/api';
import { message } from 'antd';
import { get } from 'lodash';
import { encryptedParams } from '@/utils';
import taxHelperStore from '../taxHelper';

/** 湖南旧版 */
class HunanOld {

  /** 授权 */
  taxAuth({ formData }) {
    const {companyName, taxAccount, taxPassword } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params = {
      companyName,
      username: taxAccount,
      password: taxPassword,
      creditCode: nsrsbh,
      serialNo: serialNo,
      province,
    };
    return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password']))
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      });
  }

}

export default new HunanOld();