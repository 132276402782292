import { taxAxios } from '@/utils/taxAxios';

/** 授权 */
export const auth = (params) => {
  return taxAxios.post('/api/dbc/taxation/tobacco/auth', params);
}

/** 检查采数状态 */
export const checkStatus = (params) => {
  return taxAxios.get('/api/dbc/taxation/tobacco/status', { params });
}

/** 烟草发送认证验证码(tobaccoAuthUsingPOST) */
export const postSendSms = (params) => {
  return taxAxios.post('/api/dbc/taxation/tobacco/sendSms', params);
}

/** 验证码认证授权(tobaccoAuthUsingPOST) */
export const postVerify = (params) => {
  return taxAxios.post('/api/dbc/taxation/tobacco/verify', params);
}
