/**
 * 这个是用来加密、解密二类卡密码的，其实只要加密就行了，解密是后端来做
 * 我们这边用uuid这个包的v4来生成uuid，然后用split('-')函数把uuid分割成数组
 * 生成好的uuid大致为：'9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
 * 分割之后为：["9b1deb4d", "3b7d", "4bad", "9bdd", "2b0d7b3dcb6d"]
 * 分割好之后，取下标为2,3的两个，后按照3232这样的顺序拼成新的字符串
 * 新拼成的字符串就是加密、解密用的key
 * 传给后端的时候，就传uuid、加密后的字符串就行了
 */

import * as CryptoJS from 'crypto-js';

const createKey = (uuid) => {
  const uuidArr = uuid.split('-');
  const key = `${uuidArr[3]}${uuidArr[2]}${uuidArr[3]}${uuidArr[2]}`;
  return CryptoJS.enc.Utf8.parse(key);
}

/**
 * 加密操作
 * @param value 需要加密的值
 * @param uuid 自动生成的uuid
 */
const encrypted = (value, uuid) => {
  const key = createKey(uuid);
  const currentValue = CryptoJS.enc.Utf8.parse(value);
  const encrypt = CryptoJS.AES.encrypt(
    currentValue,
    key,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
  return encrypt.toString();
};

/**
 * 解密操作
 * @param value 需要解密的值
 * @param uuid 自动生成的uuid
 */
const decrypted = (value, uuid) => {
  const key = createKey(uuid);
  const decrypt = CryptoJS.AES.decrypt(
    value,
    key,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export {
  encrypted,
  decrypted
};
