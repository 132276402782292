import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import taxHelperStore from '../taxHelper';
import { get } from "lodash";
import { message } from "antd";

class Shanghai {
  bsrs = [];
  bsrsList = [];

  /** 获取验证成功返回记录 */
  smscode_id = '';

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const { mobile, uuid } = get(resp, 'data.data.data');
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{
          label: mobile,
          value: uuid
        }];
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      });
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr } = formData;
    const { taxAccount, taxPassword } = stepOneFormData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { mobile } = this.bsrs.find(item => item.uuid === bsr);
    const params = {
      serialNo,
      province,
      creditCode: nsrsbh,
      phoneNumber: mobile,
      uuid: bsr,
      userName: taxAccount,
      userPass: taxPassword,
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, [ 'userName', 'userPass' ]))
      .then(resp => {
        const { smscode_id } = get(resp, 'data.data');
        this.smscode_id = smscode_id;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { bsr, captcha } = formData;
    const { serialNo, nsrsbh } = taxHelperStore.authQueryParams;
    const { mobile, uuid } = this.bsrs.find(item => item.uuid === bsr);
    const params = {
      creditCode: nsrsbh, 
      captcha: captcha,
      yddh: mobile,
      smsCodeId: this.smscode_id,
      uuid
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        let errorMessage = get(error, 'response.data.message') || '授权失败';
        const errorCode = get(error, 'response.data.errorCode');
        message.error(errorMessage);
        return Promise.reject(error);
      });
  };

};

export default new Shanghai();
