import { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Alert, Form, Spin } from 'antd';
import { isEmpty } from 'lodash';
import WhiteBox from '@/components/layout/whiteBox';
import TicketTitle from '@/components/ticketTitle';
import FormBox from './component/Form';
import SUSPENSION_IMG from '@/assets/images/selfFilling/suspension.png';
import decryptedParams from '@/utils/decryptedParams';

import styles from './index.module.less';

const SelfFilling = (props) => {

  const [form] = Form.useForm();
  const { loading, setValue } = props.selfFillingStore;
  const params = decryptedParams();
  const onFinishAuth = () => {
    const { taxHelperStore } = props;
    const cburl = taxHelperStore.getTaxCburl();
    if (cburl) {
      window.location.href = cburl;
    } else {
      props.history.replace(`/authSuccess?serialNo=${params.serialNo}&&watch=true`);
    }
  }

  const checkAuthStatus = (serialNo) => {
    props.taxAuthStore.getAuthStatus(serialNo)
      .then(isTaxAuth => {
        if (isTaxAuth) {
          onFinishAuth();
        }
      })
      .catch(errorData => {
        const { errorCode } = errorData;
        if (errorCode === 403220) {
          props.history.replace(`/invalidation?code=1098`);;
        }
      });
  }

  useEffect(() => {
    checkAuthStatus(params.serialNo);
  }, []);

  if (isEmpty(params)) {
    props.history.replace(`/invalidation?code=1099`);
    return;
  }
  setValue('taxKey', params.taxKey);
  setValue('serialNo', params.serialNo);

  const goIntelligent = () => {
    props.history.push(`/authorize${window.location.search}`);
  }

  const renderSuspension = () => (
    <div className={styles.suspension}>
      <img alt='' src={SUSPENSION_IMG} onClick={goIntelligent} />
    </div>
  )

  const qyParams = {
    qymc: params.qymc,
    nsrsbh: params.nsrsbh,
  };

  return (
    <WhiteBox>
      <Spin size="large" spinning={loading}>
        <div className={styles.container}>
          <TicketTitle title="根据页面提示，完成对应操作" />
          <Alert
            className={styles.alert}
            message="建议使用智能填报服务，提升您的上传效率的同时，能确保填报的数据更加准确"
            type="warning"
            showIcon
          />
          <div className={styles.content}>
            <FormBox form={form} qyParams={qyParams} />
            {renderSuspension()}
          </div>
        </div>
      </Spin>
    </WhiteBox>
  )
}

export default inject('selfFillingStore', 'taxHelperStore', 'taxAuthStore')(observer(SelfFilling));