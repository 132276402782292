import React from 'react';
import { observer } from 'mobx-react';
import DefaultTitle from './components/DefaultTitle';
import RizhaoTitle from './components/RizhaoTitle';

const TicketTitle = ({ title, source }) => {
  switch (source) {
    case 'RZ':
      return <RizhaoTitle title={title} />;
    default:
      break;
  }
  return <DefaultTitle title={title} />;
};

export default observer(TicketTitle);
