import React from 'react';
import { Route } from 'react-router-dom';
import AutoLayout from '@/components/layout/AutoLayout';

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={props => (
        <AutoLayout {...props} {...route}>
          <route.component {...props} routes={route.routes} />
        </AutoLayout>
      )}
    />
  );
};

export default RouteWithSubRoutes;
