import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import { get } from "lodash";
import taxHelperStore from '../taxHelper';


const MAP_BSRTYPE = {
  1: '办税员',
  2: '法定代表人',
  3: '财务负责人',
  6: '购票员'
};

/** 深圳旧版 */
class ShenzhenOld {

  // bsrsRespData = {};
  bsrs = [];
  bsrsList = [];

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const bsrs = get(resp, 'data.data.data');
        this.bsrs = bsrs;
        this.bsrsList = bsrs.map((item, idx) => {
          return {
            value: idx,
            label: `${MAP_BSRTYPE[item.bsrType]}/${item.xm}/${item.mobile}`
          };
        });
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      });
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr } = formData;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const { taxAccount, taxPassword } = stepOneFormData;
    const { bdgxId, bsrType, mobile, } = selectedBsr;
    const params = {
      serialNo,
      userName: taxAccount,
      userPass: taxPassword,
      creditCode: nsrsbh,
      province,
      authorizationType: 'SMS',
      phoneNumber: mobile,
      bsrType,
      bdgxId,
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName' , 'userPass']))
      .catch(error =>  {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  taxAuth = ({ formData, stepOneFormData }) => {
    const { bsr, captcha } = formData;
    const { taxAccount, taxPassword } = stepOneFormData;
    const { serialNo, nsrsbh } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { bdgxId, bsrType, mobile, } = selectedBsr;
    const params = {
      creditCode: nsrsbh,
      captcha,
      authorizationType: "SMS", 
      userName: taxAccount,
      userPass: taxPassword,
      yddh: mobile,
      bsrType,
      bdgxId,
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, encryptedParams(params, ['userName', 'userPass']))
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

}

export default new ShenzhenOld();
