import styled from 'styled-components';
import { Checkbox } from 'antd';

export const SelectBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SelectItem = styled.span`
  padding: 5px 16px;
  margin-right: 4px;
  color: ${(props) => (props.active ? '#0076F6' : '')};
  cursor: pointer;
`;

export const CustomCheckBoxGroup = styled(Checkbox.Group)`
  line-height: 32px;
`;
