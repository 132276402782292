import { useMemo } from "react";
import { PROVINCE_MAP } from '@/utils/taxUtil';

import sichuanStore from '@/stores/province/sichuan';
import sichuanOldStore from '@/stores/province/sichuan_old';
import tianjinStore from '@/stores/province/tianjin';
import zhengJiangStore from '@/stores/province/zhejiang';
import neimengguStore from '@/stores/province/neimenggu';
import heilongjiangStore from '@/stores/province/heilongjiang'
import yunnanStore from '@/stores/province/yunnan';
import yunnanOldStore from '@/stores/province/yunnan_old';
import hainanStore from '@/stores/province/hainan';
import anhuiStore from '@/stores/province/anhui';
import jilinStore from '@/stores/province/jilin';
import jilinOldStore from '@/stores/province/jilin_old';
import shanxiStore from '@/stores/province/shanxi';
import jiangsuStore from '@/stores/province/jiangsu';
import jiangsuOldStore from '@/stores/province/jiangsu_old';
import guizhouStore from '@/stores/province/guizhou';
import guizhouOldStore from '@/stores/province/guizhou_old';
import beijingStore from '@/stores/province/beijing';
import beijingOldStore from '@/stores/province/beijing_old';
import henanStore from '@/stores/province/henan';
import gansuStore from '@/stores/province/gansu';
import qingdaoStore from '@/stores/province/qingdao';
import xiamenStore from '@/stores/province/xiamen';
import hebeiStore from '@/stores/province/hebei';
import hebeiOldStore from '@/stores/province/hebei_old';
import shenzhenStore from '@/stores/province/shenzhen';
import shenzhenOldStore from '@/stores/province/shenzhen_old';
import ningboStore from '@/stores/province/ningbo';
import xinjiangStore from "@/stores/province/xinjiang";
import chongqingStore from '@/stores/province/chongqing';
import chongqingOldStore from '@/stores/province/chongqing_old';
import xizangStore from '@/stores/province/xizang';
import guangxiStore from '@/stores/province/guangxi';
import guangxiOldStore from '@/stores/province/guangxi_old';
import hunanStore from '@/stores/province/hunan';
import hunanOldStore from '@/stores/province/hunan_old';
import ShandongStore from '@/stores/province/shandong';
import ShandongOldStore from '@/stores/province/shandong_old';
import ShangahiStore from '@/stores/province/shangahi';
import ShanxiTyStore from '@/stores/province/shanxi_ty';
import GuangdongStore from '@/stores/province/guangdong';
import JiangxiStore from '@/stores/province/jiangxi';
import fujianStore from '@/stores/province/fujian';
import dalianStore from "@/stores/province/dalian";
import liaoningStore from "@/stores/province/liaoning";
import liaoningOldStore from "@/stores/province/liaoning_old";
import guideStore from "@/stores/province/guide";
import ningxiaStore from "@/stores/province/ningxia";
import qinghaiStore from '@/stores/province/qinghai';

const useProvinceStore = (province, NEW_AUTH) => {
  const desp = [];
  desp.push(province);
  const store = useMemo(() => {

    switch (province) {
      case PROVINCE_MAP.get('青海').py:
        return qinghaiStore;
      case PROVINCE_MAP.get('四川').py:
        return NEW_AUTH ? sichuanStore : sichuanOldStore;
      case PROVINCE_MAP.get('天津').py:
        return tianjinStore;
      case PROVINCE_MAP.get('宁夏').py:
        return ningxiaStore;
      case PROVINCE_MAP.get('浙江').py:
        return zhengJiangStore;
      case PROVINCE_MAP.get('内蒙古').py:
        return neimengguStore;
      case PROVINCE_MAP.get('黑龙江').py:
        return heilongjiangStore;
      case PROVINCE_MAP.get('云南').py:
        return NEW_AUTH ? yunnanStore : yunnanOldStore;
      case PROVINCE_MAP.get('海南').py:
        return hainanStore;
      case PROVINCE_MAP.get('安徽').py:
        return anhuiStore;
      case PROVINCE_MAP.get('吉林').py:
        return NEW_AUTH ? jilinStore : jilinOldStore;
      case PROVINCE_MAP.get('陕西').py:
        return shanxiStore;
      case PROVINCE_MAP.get('江苏').py:
        return NEW_AUTH ? jiangsuStore : jiangsuOldStore;
      case PROVINCE_MAP.get('贵州').py:
        return NEW_AUTH ? guizhouStore : guizhouOldStore;
      case PROVINCE_MAP.get('北京').py:
        return NEW_AUTH ? beijingStore : beijingOldStore;
      case PROVINCE_MAP.get('河南').py:
        return henanStore;
      case PROVINCE_MAP.get('甘肃').py:
        return gansuStore;
      case PROVINCE_MAP.get('青岛').py:
        return qingdaoStore;
      case PROVINCE_MAP.get('厦门').py:
        return xiamenStore;
      case PROVINCE_MAP.get('河北').py:
        return NEW_AUTH ? hebeiStore : hebeiOldStore;
      case PROVINCE_MAP.get('深圳').py:
        return NEW_AUTH ? shenzhenStore : shenzhenOldStore;
      case PROVINCE_MAP.get('西藏').py:
        return xizangStore;
      case PROVINCE_MAP.get('广西').py:
        return NEW_AUTH ? guangxiStore : guangxiOldStore;
      case PROVINCE_MAP.get('宁波').py:
        return ningboStore;
      case PROVINCE_MAP.get('新疆').py:
        return xinjiangStore
      case PROVINCE_MAP.get('重庆').py:
        return NEW_AUTH ? chongqingStore : chongqingOldStore;
      case PROVINCE_MAP.get('湖南').py:
        return NEW_AUTH ? hunanStore : hunanOldStore;
      case PROVINCE_MAP.get('山东').py:
        return NEW_AUTH ? ShandongStore : ShandongOldStore;
      case PROVINCE_MAP.get('上海').py:
        return ShangahiStore;
      case PROVINCE_MAP.get('山西').py:
        return ShanxiTyStore;
      case PROVINCE_MAP.get('广东').py:
        return GuangdongStore;
      case PROVINCE_MAP.get('江西').py:
        return JiangxiStore;
      case PROVINCE_MAP.get('福建').py:
        return fujianStore;
      case PROVINCE_MAP.get('大连').py:
        return dalianStore;
      case PROVINCE_MAP.get('辽宁').py:
        return NEW_AUTH ? liaoningStore : liaoningOldStore;
      default:
        return guideStore; // 操作引导
    }
  }, desp);
  return store;
}

export default useProvinceStore;
