import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { loadScript, loadStyle } from "@/utils";

import styles from './index.less';

class Captcha extends React.Component {

  componentDidMount() {
    this.addScripts()
      .then(() => {
        setTimeout(() => {
          this.initRenderCaptcha();
        }, 500);
      });
  }

  initRenderCaptcha = () => {
    const { cb } = this.props;
    window.initNECaptcha({
      element: '#captcha',
      captchaId: '1a623022803d4cbc86fa157ec267bb36',
      width: '100%',
      mode: 'float',
      onVerify: function (err, data) {
        if(!err){
            console.log('data: ', data);
            cb(data['validate']);
          }
      }
    });
  }

  addScripts = () => {
    const jqueryScriptUrl = process.env.PUBLIC_URL + '/jquery.min.js';
    const mloadingScriptUrl = process.env.PUBLIC_URL + '/statics/jiangshu/mloading.js';
    const captchadunUrl = 'https://cstaticdun.126.net/load.min.js?t=201903281201';

    const mloadingStyle = process.env.PUBLIC_URL + '/statics/jiangshu/mloading.css';

    return loadScript(jqueryScriptUrl)
      .then(() => {
        loadScript(mloadingScriptUrl);
      })
      .then(() => {
        Promise.all([
          loadScript(captchadunUrl),
          loadStyle(mloadingStyle)
        ])
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }
  
  /** 刷新验证码 */
  reFlash = () => {
    this.initRenderCaptcha();
  }

  render() {
    return (
      <div className={styles.captach} id="captcha">加载中...</div>
    );
  }
}

Captcha.propTypes = {
  cb: PropTypes.func.isRequired
}

export default observer(Captcha);
