import TaxAuthProtocol from '@/components/common/TaxAuthProtocol';
import React from 'react';
import { FORM_STEP_MAP } from '../helper/data';

const AuthProtocol = ({ formStep, isAgree, onChange, isProvince, NEW_AUTH }) => {
  let provinceList = [ '广东', '浙江'];
  // 旧版
  if (!NEW_AUTH) {
    provinceList = provinceList.concat(['山东', '重庆', '湖南', '辽宁'])
  }
  if (isProvince(provinceList)) {
    return <TaxAuthProtocol isAgree={isAgree} onChange={onChange} />;
  }
  if (formStep === FORM_STEP_MAP.get('second')) {
    return <TaxAuthProtocol isAgree={isAgree} onChange={onChange} />;
  }
  return null;
};

export default AuthProtocol;
