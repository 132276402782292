import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';

class Shandong {

  /** 山东税局获取验证码图片 */
  getShandongCaptchaImage = (serialNo) => {
    return provinceValidatorApi.getShandongCaptchaImage(serialNo)
      .then(({ data }) => get(data, 'data.data'))
      .catch(error => {
        // const errorMessage = get(error, 'response.data.message') || '授权失败';
        // message.error(errorMessage);
        throw error;
      });
  }

  /** 山东税局验证图片验证码 */
  hdyzmActionForCheck = (params) => {
    return provinceValidatorApi.hdyzmActionForCheck(params)
      .then(({ data }) => get(data, 'data.data.data'))
      .catch(error => {
        console.log('error: ', error);
        throw error;
      })
  }

}

export default new Shandong();