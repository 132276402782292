import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import qs from 'qs';

import styles from './index.less';


const Access = ({ accessStore, history }) => {

  const gotoInvalidation = ({ message, code } = {}) => {
    if ( code ) {
      history.replace(`/invalidation?code=${ code }`);
      return false;
    }
    history.replace(`/invalidation?message=${ message }`);
  }

  useEffect(() => {
    const search = history.location.search;
    const { channelCode, param } = qs.parse(search, { ignoreQueryPrefix: true });
    if (channelCode && param) {
      accessStore.getAuthorizeUrl({ channelCode, param })
        .then( url => {
          console.log('url: ', url);
          // history.replace(url);
          window.location = url;
        })
        .catch(errorData => {
          const { message } = errorData;
          gotoInvalidation( { message });
        });
    } else {
      gotoInvalidation({ code: 1097 });
    }
  }, []);

  return (
    <div className={styles.loadWrap}>
      <Spin size="large" />
    </div>
  );
}

Access.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default inject('accessStore')(observer(Access));
