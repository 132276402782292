import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

/** 四川旧版 */
class SichuanOld {

  bsrs = [];
  bsrsList = [];

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then((resp) => {
        
        const data = get(resp, 'data.data.data.bsrs') || [];
        this.bsrs = data;
        this.bsrsList = data.map(item => {
          return {
            value: item.bdgxId,
            label: `${item.roleName}/${item.xm}/${item.mobile}/${item.sfzjhm}`
          };
        })
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '获取登录角色失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr, bsrPassword } = formData;
    const bsrItem = this.bsrs.find(item => item.bdgxId === bsr);
    const { bsrType, mobile, sfzjhm, roleName } = bsrItem;
    const { taxAccount: username } = stepOneFormData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params =  {
      serialNo,
      username,
      password: bsrPassword,
      creditCode: nsrsbh,
      province,
      sfzjhm,
      bsrType,
      mobile,
      roleName
    };
    return provinceValidatorApi.taxAuthSendCaptcha(encryptedParams(params, ['username', 'password']))
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  // /** 获取验证码 */
  // getCaptcha = (formData, stepOneFormData) => {
  //   return Promise.resolve(formData)
  //     .then(formData => {
  //       const { bsr, bsrPassword } = formData;
  //       const bsrItem = this.bsrs.find(item => item.bdgxId === bsr);
  //       const { bsrType, mobile, sfzjhm, roleName } = bsrItem;
  //       return { bsrType, mobile, sfzjhm, roleName, password: bsrPassword };
  //     })
  //     .then(({ bsrType, mobile, sfzjhm, roleName, password }) => {
  //       const { taxAccount: username } = stepOneFormData;
  //       const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
  //       return {
  //         serialNo,
  //         username,
  //         password,
  //         creditCode: nsrsbh,
  //         province,
  //         sfzjhm,
  //         bsrType,
  //         mobile,
  //         roleName
  //       };
  //     })
  //     .then( params => this.taxAuthSendCaptcha(encryptedParams(params, ['username', 'password'])))
  // }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { nsrsbh } = taxHelperStore.authQueryParams;
    const { captcha } = formData;
    const { serialNo } = taxHelperStore.authQueryParams;
    const params = {
      creditCode: nsrsbh,
      captcha
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  };

}

export default new SichuanOld();