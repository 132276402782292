import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import taxHelperStore from '../taxHelper';

class Guangdong {
  /** 授权 */
  taxAuth = ({ formData }) => {
    const { taxAccount, taxPassword } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const params = {
      companyName: taxAccount,
      username: taxAccount,
      password: taxPassword,
      creditCode: nsrsbh,
      serialNo,
      province,
      authentication: 'tpass.guangdong.chinatax.gov.cn'
    };
    return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password']))
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  };
};

export default new Guangdong();
