import styled from "styled-components";

export const Box = styled.div`
  box-shadow: 0 0 26px 0 #E5E5E5;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const Title = styled.div`
  background-image: url("/statics/guide/2.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: auto 40px;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  z-index: 1000;
`;

export const MenuItem = styled.div`
  padding: 10px 30px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    background: #E0F1FF;
  }
`
