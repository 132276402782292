import { observer } from 'mobx-react';
import React from 'react';
import Captcha from './captcha';

class Anhui extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.captchaRef = React.createRef();
    this.state = {
      captchaValue: null
    }
  }

  _scpublicValidateFields = () => {
    const captchaValue = this.state.captchaValue;
    if (captchaValue) {
      return Promise.resolve(captchaValue);
    }
    return Promise.reject({ captchaError: true });
  }

  captchaCb = (data) => {
    this.setState({ captchaValue: data })
  }

  initCaptchaP = () => {
    this.captchaRef.current.reFlash();
    this.setState({ captchaValue: null })
  }

  render() {
    return (
      <React.Fragment>
        <div style={{marginBottom: '20px'}}>
          <Captcha cb={this.captchaCb.bind(this)} ref={this.captchaRef} />
        </div>
      </React.Fragment>
    );
  }
}

export default observer(Anhui);
