import { Button, Modal } from 'antd';
import React from 'react';
import styles from './index.module.less'
import { CheckCircleOutlined } from '@ant-design/icons';

const ModalContent = ({ content , btn, secondsToGo, onClick, text }) => (
  <div>
    <div>{content}</div>
    {
      btn ? (
        <div className={styles.center}>
          <Button type="primary" onClick={onClick}>知道了{`（${secondsToGo}s${text}）`}</Button>
        </div>
      ) : (
        <p className={styles['count-down']}>{`（${secondsToGo}s${text}）`}</p>
      )
    }
  </div>
)


/** 倒计时弹窗 */
export const countDownModal = ({
  title,
  content,
  delay = 5,
  btn = false,
  text = '',
  className = '',
  icon = <CheckCircleOutlined style={{color: '#52c41a'}} />
}) => {
  return new Promise((resolve) => {

    let secondsToGo = delay;

    const destroy = (force = false) => {
      if (force === true) {
        modal.destroy();
        return;
      }
      if (secondsToGo <= 0) {
        modal.destroy();
      }
      setTimeout(() => {
        // 判断如果节点不存在了，则说明已经移除了
        if (!document.querySelector(`.${styles['count-down-modal']}`) && secondsToGo <= 0) {
          window.removeEventListener('focus', destroy)
        }
      }, 1000);
    }

    const modal = Modal.success({
      centered: true,
      icon: null,
      title: <div className={styles.title}> { icon }{title}</div>,
      closable: false,
      okCancel: false,
      width: '385px',
      className: [styles['count-down-modal'], className].join(' '),
      content: <ModalContent content={content} btn={btn} secondsToGo={secondsToGo} onClick={() => destroy(true)} text={text}/>,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: <ModalContent content={content} btn={btn} secondsToGo={secondsToGo} onClick={() => destroy(true)} text={text}/>,
      });
    }, 1000);

    // 监听窗口获得焦点事件
    window.addEventListener('focus', destroy)

    setTimeout(() => {
      clearInterval(timer);
      destroy('定时器')
      window.modal = modal;
      resolve()
    }, secondsToGo * 1000 + 20);
  })
};