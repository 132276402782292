import React from 'react';
import DefaultContent from './components/DefaultContent';
import RizhaoContent from './components/RizhaoContent';

const FormContent = (props) => {
  const source = props.source || '';
  switch (source) {
    case 'RZ':
      return <RizhaoContent {...props} />;
    default:
      break;
  }
  return <DefaultContent {...props} />;
};

export default FormContent;
