import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import { message } from "antd";
import { get } from "lodash";
import taxHelperStore from '../taxHelper';

class Xiamen {
  /** 办税员列表 */
  bsrs = [];
  bsrsList = [];

  /** 验证码 返回 smscode_id */
  smsCodeId = '';

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const bsr = get(resp, 'data.data.data');
        this.bsrs = [ bsr ];
        this.bsrsList = [ bsr ].map((item, idx) => {
          const { mobile } = item;
          return {
            value: idx,
            label: mobile
          };
        });
      })
      .catch(error => {
        showErrorMessage(error, '获取办税员失败');
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr } = formData;
    const { taxAccount, taxPassword } = stepOneFormData;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { mobile } = selectedBsr;
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const params = {
      serialNo,
      userName: taxAccount,
      userPass: taxPassword,
      creditCode: nsrsbh,
      province,
      phoneNumber: mobile,
      uuid: selectedBsr.uuid,
      authentication: 'tpass.xiamen.chinatax.gov.cn'
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params,  [ 'userName', 'userPass' ]))
      .then(resp => {
        const smsCodeId = get(resp, 'data.data.smscode_id', '');
        this.smsCodeId = smsCodeId;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { bsr, captcha } = formData;
    const { serialNo, nsrsbh } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { uuid, mobile } = selectedBsr;
    const postData = {
      creditCode: nsrsbh,
      captcha,
      uuid,
      yddh: mobile,
      smsCodeId: this.smsCodeId,
      authentication: 'tpass.xiamen.chinatax.gov.cn',
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, postData)
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      });
  }

};

export default new Xiamen();