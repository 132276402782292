import { Form } from 'antd';
import CompanyInput from '../../../CompanyInput';

const CompanyNameFormItem = ({ creditCode, defaultValue, inputSize }) => {
  return (
    <Form.Item
      key="companyName"
      colon={false}
      label="企业名称："
      name="companyName"
      rules={[
        { required: true, message: '请输入企业名称' },
      ]}
      initialValue={defaultValue}
    >
      <CompanyInput size={inputSize} disabled placeholder="请输入企业名称" creditCode={creditCode} />
    </Form.Item>
  );
}

export default CompanyNameFormItem;
