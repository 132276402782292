import React, { useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, } from 'antd';
import AuthProtocol from  './protocol/AuthProtocol';
import InstallProtocol from  './protocol/InstallProtocol';
import { AUTH_TYPE_DICT } from '@/utils/taxUtil';
import config from '@/config';

import styles from './index.less';
import SCProtocolContent from './protocol/AuthProtocol/SCProtocolContent';
import HLProtocolContent from './protocol/AuthProtocol/HLProtocolContent';
import GZProtocolContent from './protocol/AuthProtocol/GZProtocolContent';
import XZProtocolContent from './protocol/AuthProtocol/XZProtocolContent';
import * as DOMPurify from 'dompurify';

import {getProtocolList} from "@/pages/guide/fake";

const TaxAuthProtocol = (props) => {
  const {
    isAgree,
    hasCheckbox = true,
    isOnlyAuthProtocol,
    commonStore
  } = props;
  const [ authProVisible, setAuthProVisible ] = useState(false);
  const [ installProVisible, setInstallProVisible ] = useState(false);
  const [ currentProtocolIndex, setCurrentProtocolIndex  ] = useState(0);

  const protocolList = useMemo(() => {
    const { name, content } = commonStore.protocolInfo || {}
    return getProtocolList();
  }, [ commonStore.protocolInfo ]);

  const getProtocolContent = (content) => {
    const activeProtocolContent = DOMPurify.sanitize(content);
    return <div className={styles.modalContent} dangerouslySetInnerHTML={{ __html: activeProtocolContent }}></div>;
  }

  const currentProtocol = useMemo(() => {
    const content = protocolList[currentProtocolIndex].content;
    // const content = getProtocol();
    if (typeof content === 'string') {
      return getProtocolContent(content);
    }
    return content;
  }, [ currentProtocolIndex, protocolList ]);

  const currentProtocolTitle = useMemo(() => protocolList[currentProtocolIndex].name, [ currentProtocolIndex, protocolList ])

  const hanldeAuthProtocol = (event) => {
    const { checked } = event.target;
    if (checked) {
      setAuthProVisible(true)
    } else {
      props.onChange(false);
    }
    // if (props.onChange) {
    //   props.onChange(event.target.checked);
    // }
  }

  const workType = props.taxHelperStore.getTaxWorkType();

  const isShowInstallProtol = () => {
    if (isOnlyAuthProtocol) {
      return false;
    }
    if ([AUTH_TYPE_DICT.ELECTRON_PLUGIN, AUTH_TYPE_DICT.PLUGIN].includes(workType)) {
      return true;
    }
    return false;
  }

  const showProtocolcontent = (index) => {
    setCurrentProtocolIndex(index);
    setAuthProVisible(true);
  }

  const protocolLinks = () => {
    return protocolList.map((protocolItem, idx) => (
      <a className={styles.protocolBtn} type="link" key={idx} onClick={() => showProtocolcontent(idx)}>《{protocolItem.name}》</a>
    ));
  };

  return (
    <div>
      {
        hasCheckbox ?
        <div>
          <Checkbox checked={isAgree} onChange={hanldeAuthProtocol}>
            <span className={styles.unSelected}>我已阅读并同意</span>
          </Checkbox>
          {/* <a className={styles.protocolBtn} type="link" onClick={() => setAuthProVisible(true)}>《{protocolTitle()}》</a> */}
          { protocolLinks() }
          {
            isShowInstallProtol() ?
            <React.Fragment>
              和 <a className={styles.protocolBtn} type="link" onClick={() => setInstallProVisible(true)}>《软件安装许可协议》</a>
            </React.Fragment> : null
          }
        </div> :
        <div style={{fontSize: '13px'}}>
          <span className={styles.unSelected}>扫码登录则表示阅读并同意</span>
          {/* <a className={styles.protocolBtn} type="link" onClick={() => setAuthProVisible(true)}>《{protocolTitle()}》</a> */}
          { protocolLinks() }
          {
            isShowInstallProtol() ?
            <React.Fragment>
              和 <a className={styles.protocolBtn} type="link" onClick={() => setInstallProVisible(true)}>《软件安装许可协议》</a>
            </React.Fragment> : null
          }
        </div>
      }
      {
        props.children || null
      }
      <AuthProtocol
        title={currentProtocolTitle}
        visible={authProVisible}
        content={currentProtocol}
        closeModal={() => setAuthProVisible(false)}
        onOk={() => {
          setAuthProVisible(false)
          props.onChange?.(true)
        }}
      />
      <InstallProtocol
        visible={installProVisible}
        closeModal={() => setInstallProVisible(false)}
      />
    </div>
  );
}

export default inject('taxHelperStore', 'commonStore')(observer(TaxAuthProtocol));
