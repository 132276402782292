import { useState } from 'react';

import { Container, ButtonGroup, UpDownArrow } from './Collapsable.styled';
import { Button } from './styled';
import Select from './Select';

const Collapsable = ({ collapsable, ...props }) => {
  // const [collapsed, { toggle: toggleCollapsed }] = useToggle(collapsable);
  const [collapsed, setCollapsed] = useState(collapsable);
  const toggleCollapsed = () => setCollapsed(!collapsed)
  return (
    <Container collapsed={collapsed}>
      <Select {...props} />
      {collapsable && (
        <ButtonGroup>
          <Button onClick={toggleCollapsed}>
            更多
            <UpDownArrow active={collapsed} />
          </Button>
        </ButtonGroup>
      )}
    </Container>
  );
};

Collapsable.defaultProps = {
  collapsable: true,
};

export default Collapsable;
