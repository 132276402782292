import React, { useState, useEffect, Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Form, Input, Select, Button, Alert, Modal, Space, message } from 'antd';
import WhiteBox from '@/components/layout/whiteBox';
import TicketTitle from '@/components/ticketTitle';

import styles from './index.less';

const inputSize = 'large';

class HubeiTest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
    this.infoFormRef = React.createRef();
  }

  componentDidMount() {
    const { strProvider, strContainer, dwProvType, crosFlag } = this.props.hubeiSPLoginStore;
    const params = {
      strProvider,
      strContainer,
      dwProvType,
      crosFlag
    };
    this.props.hubeiSPLoginStore.checkSPDrive(params);
  }

  login = () => {
    this.infoFormRef.current.validateFields()
      .then(data => {
        console.log('data: ', data);
        const { loginPath, setValue } = this.props.hubeiSPLoginStore;
        setValue('formValue', data);
        if (loginPath === 'gethello') {
          this.gethello();
        } else {
          Modal.info({
            title: '未检查到税盘驱动设备',
            content: '尊敬的纳税人：您的CA设备驱动可升级，升级后可以支持各浏览器（如谷歌浏览器、360安全浏览器（“需切换到极速模式”）、360极速浏览器、QQ浏览器、搜狗浏览器、猎豹浏览器、遨游浏览器等现代浏览器），建议您点击【驱动下载】重新下载并安装驱动，然后使用各浏览器的“极速模式”登录，这将提升您使用电子税务局的速度和体验。',
            onOk(){}
          });
        }
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }

  gethello = () => {
    const { strProvider, strContainer, dwProvType, crosFlag, authType } = this.props.hubeiSPLoginStore;
    const params = {
      strProvider,
      strContainer,
      dwProvType,
      crosFlag,
      authType
    };
    this.props.hubeiSPLoginStore.gethello(params);
  }

  render() {
    return (
      <WhiteBox>
        <TicketTitle />
        <div className={styles.form}>
          <Form
            layout="vertical"
            ref={this.infoFormRef}
          >
            {/* <Form.Item
              key="orderId"
              colon={false}
              label="订单号："
              name="orderId"
              initialValue="SCT_20210925181331097960"
              rules={[
                { required: true, message: '请输入订单号'}
              ]}
            >
              <Input size={inputSize} placeholder="请输入订单号" />
            </Form.Item> */}
            {/* <Form.Item
              key="nsrsbh"
              colon={false}
              label="纳税人识别号："
              name="nsrsbh"
              initialValue="92420106MA4JDJH61A"
              rules={[
                { required: true, message: '请输入纳税人识别号'}
              ]}
            >
              <Input size={inputSize} placeholder="请输入纳税人识别号" />
            </Form.Item> */}
            <Form.Item
              key="ca_password"
              colon={false}
              label="证书密码："
              name="ca_password"
              rules={[
                { required: true, message: '请输入证书密码'}
              ]}
            >
              <Input size={inputSize} placeholder="输入证书密码" />
            </Form.Item>
          </Form>
          <Button
            block
            type="primary"
            size={inputSize}
            onClick={this.login}
            loading={this.state.isLoading}>登录</Button>
        </div>
      </WhiteBox>
    );
  }

}

export default inject('hubeiSPLoginStore')(observer(HubeiTest));
