export const AREA_MAP = {
  "河北省": "河北",
  "浙江省": "浙江",
  "安徽省": "安徽",
  "山东省": "山东",
  "湖南省": "湖南",
  "广东省": "广东",
  "重庆市": "重庆",
  "湖北省": "湖北",
  "吉林省": "吉林",
  "四川省": "四川",
  "云南省": "云南",
  "海南省": "海南",
  "贵州省": "贵州",
  "天津市": "天津",
  "江苏省": "江苏",
  "陕西省": "陕西",
  "北京市": "北京",
  "河南省": "河南",
  "甘肃省": "甘肃",
  "西藏自治区": "西藏",
  "广西壮族自治区": "广西",
  "上海市": "上海",
  "山西省": "山西",
  "江西省": "江西",
  "福建省": "福建",
  "内蒙古自治区": "内蒙古",
  "黑龙江省": "黑龙江",
  "辽宁省": "辽宁",
  "新疆维吾尔自治区": "新疆",
  "青海省": "青海",
  "宁夏回族自治区": "宁夏",
  "香港特别行政区": "香港",
  "澳门特别行政区": "澳门",
}

export const SPECIAL_AREA = new Map([
  ["山东", ["山东", "青岛"]],
  ["辽宁", ["辽宁", "大连"]],
  ["广东", ["广东", "深圳"]],
  ["福建", ["福建", "厦门"]],
  ["浙江", ["浙江", "宁波"]],
])