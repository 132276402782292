import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { useInterval } from "ahooks";
import decryptedParams from "@/utils/decryptedParams";

const AuthProtocol = ({
  title,
  content,
  visible,
  closeModal,
  onOk
}) => {
  // 一个倒计时，用useInterval , 必须阅读5s，才可以关闭
  const [second, setSecond] = useState(5);
  const [interval, setInterval] = useState(1000);
  const { source } = decryptedParams();

  useEffect(() => {
    if (visible && source === 'SC_YS') {
      setSecond(5);
      setInterval(1000);
    } else {
      setSecond(0);
      setInterval(undefined);
    }
  }, [visible])
  
  

  const clear = useInterval(() => {
    if (second > 0) {
      setSecond(second - 1)
    }
    if (second === 0) {
      setInterval(undefined)
    }
  }, interval);


  return (
    <Modal
      visible={visible}
      title={title}
      style={{ top: 30 }}
      width="1000px"
      onCancel={closeModal}
      closable={source !== 'SC_YS'}
      keyboard={source !== 'SC_YS'}
      maskClosable={source !== 'SC_YS'}
      footer={[
        <Button
          key="authprotocol1"
          type="primary"
          disabled={second > 0}
          onClick={onOk}>
            确认并关闭
          {second > 0 ? `(${second}秒)` : ''}
        </Button>
      ]}
    >
      {/* { renderProtocolContent() } */}
      { content }
    </Modal>
  );
};

export default AuthProtocol;
