import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { loadScript, looprquest } from "@/utils";
import { Form, Button, Input, Modal, message } from 'antd';
import Protocol from './Protocol';
import ShowOnMobile from './HOC/ShowOnModile';
import { Spin } from 'antd';
import Header from 'src/components/layout/header';
import qs from 'qs';

import styles from './index.less';
import { setSetTaxAxiosHeader } from '@/utils/taxAxios';

const Trade = ({ tradeStore, commonStore, history }) => {

  const [ isLoading, setLoading ] = useState(false);
  const [ isAgreen, setIsAgreen ] = useState(false);
  const [ isBtnDisable,  setIsBtnDisable ] = useState(false);
  const [ loadParams, setLoadParams ] = useState(true);
  const [ clientUrl, setClientUrl ] = useState('');
  const [ form ] = Form.useForm();

  const addScript = () => {
    const client = process.env.PUBLIC_URL + '/statics/trade/client.js';
    const websocket = process.env.PUBLIC_URL + '/statics/trade/websocket.js';
    return Promise.all([ loadScript(client), loadScript(websocket) ]);
  }

  /** 3s后返回业务系统 */
  const urlBack = () => {
    const { cburl } = tradeStore.hgUrlParams;
    if (cburl) {
      setTimeout(() => {
        window.location.href = cburl;
      }, 3000);
      return false;
    }
  }

  /**
   * 采数完成后操作
   */
  const collectDataSuccess = () => {
    message.destroy();
    message.success('数据授权已完成, 即将返回业务系统');
    /** 取消登录按钮已按状态 */
    window.isLoginButtonPressed = false;
    urlBack();
  };

  const handleCheckeCusQueryStatus = (errorData, resolve) => {
    const { errorCode } = errorData;
    if (errorCode === 409208) {
      collectDataSuccess();
      resolve();
      /** 跳出循环 */
      return Promise.reject({ outLopp: true });
    }
    return Promise.reject(errorData);
  };

  /** 处理checkeQueryStatus处理 */
  const handleCheckeTaxQueryStatus = (errorData, resolve) => {
    const { errorCode, message: errMsg } = errorData;
    if (errorCode === 403232) {
      const errorMessage = errMsg;
      message.destroy();
      message.success(errorMessage);
      /** 取消登录按钮已按状态 */
      window.isLoginButtonPressed = false;
      urlBack();
      resolve();
      /** 跳出循环 */
      return Promise.reject({ outLopp: true });
    }
    return Promise.reject(errorData);
  }

  /** 处理checkeCusQueryStatus loop 的错误回调方法 */
  const checkeCusQueryStatusLoppErrorCb  = (error, reject) => {
    message.destroy();
    Modal.error({
      'title': '获取数据失败',
      content: '获取数据失败，请刷新重试',
      cancelButtonProps: null
    });
    reject(new Error('获取数据失败'));
  }

  // /** 处理checkeTaxQueryStatus loop 的错误回调方法 */
  // const checkeTaxQueryStatusLoppErrorCb  = (error, resolve, reject) => {
  //   message.destroy();
  //   // reject(new Error('获取数据失败'));
  //   message.success('数据量较大，会持续采集，即将返回业务系统');
  //   /** 取消登录按钮已按状态 */
  //   window.isLoginButtonPressed = false;
  //   urlBack();
  // }

  /** 获取单一窗口报关单单号 */
  const checkSwSignNos = (serialNo) => {
    message.destroy();
    message.warning('获取数据中，请勿关闭页面', 0);
    // return new Promise((reslove, reject) => {
    //   looprquest(
    //     () => {
    //       return tradeStore.checkeQueryStatus({ serialNo, order_id: serialNo })
    //       .then((res) => {
    //         console.log(`😋🙃 ~ .then ~ res:`, res);
    //         const { data, code, message: msg } = res;
    //         if (code === '10000') {
    //           message.error(msg)
    //           return data;
    //         }
    //         // 10021：内部错误）则终止轮询
    //         if (code === '10021') {
    //           message.error(msg)
    //           return Promise.reject({ outLopp: true });
    //         }
    //         if (code === '10022') {
    //           return 
    //         }
    //         throw new Error('');
    //       })
    //       .catch((errorData) => {
    //         console.error(`😋🙃 ~ returnnewPromise ~ errorData:`, errorData);
    //         return Promise.reject({ outLopp: true });
    //       })
    //     },
    //     (data) => {
    //       message.destroy()
    //       reslove(data)
    //     },
    //      (err) => {
    //       console.error(err);
    //       reject(err)
    //     }, 
    //     { eachSecond: 3000, maxTime: 6000, maxSecond: 1000 * 60 * 300 }
    //   )
    // })
    /** 获取报关单号列表，并上传加密信息 */
    const getSignNosAndCrawlRest = (resolve, reject) => {
      const times = 3000;
      tradeStore.checkeQueryStatus({ serialNo, order_id: serialNo })
      .then((res) => {
        const { data, code, message: msg } = res;
        // 若返回 （10000：采集完成）则终止轮询
        if (code === '10000')  return resolve(data);

        Modal.error({ title: '采集失败', content: msg })
        reject(data);
      })
      .catch((err) => {
        console.error(err);
        const { data, code, message: msg } = err?.data || {};
        // 10022状态，提取data中的报关单号前端加密，加密后调用 /sw/crawl_rest 接口
        if (code === "10022") {
          // 存在则上报
          if (data?.length > 0) {
            postSwCrawlRest(data)
            .then(() => {
              setTimeout(() => {
                getSignNosAndCrawlRest(resolve, reject)
              }, times);
            })
            .catch((err) => {
              console.error(err);
              reject(err)
            })
            return
          }
          // 下一次轮询
          setTimeout(() => {
            getSignNosAndCrawlRest(resolve, reject)
          }, times);
          return
        }
        // 若返回 （10021：内部错误）则终止轮询，错误提示用户
        if (code === '10021') {
          Modal.error({ title: '采集失败', content: msg })
          reject(data);
          return;
        }
        reject(err)
      })
    }

    return new Promise((resolve, reject) => {
      getSignNosAndCrawlRest(resolve, reject)
    })
  }

  /** 上传报关单号加密数据 */
  const postSwCrawlRest = async (data) => {
    console.log(`获取到的data`, data);
    const { serialNo } = tradeStore.hgUrlParams;
    const password = window.$V('password');
    const icCard = window.$V('icCard');
    /** 签名 */
    const signP = (pwd, icC, item) => {
      return new Promise((resolve, reject) => {
        window.EportClient.signData(
          pwd,
          `${icC}||${item}`,
          (msg) => {
            console.log(`😋🙃 ~ returnnewPromise ~ msg:`, msg);
            resolve(msg);
            // if (msg.Result) {
            // } else {
            //   reject(msg);
            // }
          }
        )
      })
    };

    if (Array.isArray(data)) {
      
      const sign_list = [];
      let signResError = '';
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        // sign_list[item] = ;
        const signRes =  await signP(password, icCard, item);
        console.log(`😋🙃 ~ postSwCrawlRest ~ signRes:`, signRes);
        // sign_list.push({ [item]: signRes.Result[0]})
        if (signRes.Result) {
          sign_list.push({
            cus_ciq_no: item,
            sign: signRes.Data[0]
          })
        } else {
          signResError = signRes?.Error[0]
        }
      }
      // 存在加密错误信息
      if (signResError) {
        Modal.error({title: '错误提示', content: signResError})
      }
      const res = await tradeStore.postSwCrawlRest({
        ic_card: icCard,
        order_id: serialNo,
        sign_list,
        error: signResError
      })
      if (signResError) {
        return Promise.reject(signResError)
      }
      const resData = res?.data?.data
      if (resData.code !== '10000') {
        message.error(resData.message)
        return Promise.reject()
      }
    }

    if (typeof data === 'object') {
      // collectDataSuccess()
      return
    }
  }
  

  /** 报关单采集状态查询 */
  const checkCusQueryStatus = (serialNo) => {
    message.destroy();
    message.warning('获取数据中，请勿关闭页面', 0);
    return new Promise((resolve, reject) => {
      looprquest(
        () => {
          return tradeStore.checkeQueryStatus({ serialNo, order_id: serialNo })
            .then(data => {
              const { cusQueryStatus } = data;
              if (cusQueryStatus === 'PROCESSED') {
                return data;
              }
              throw new Error('');
            })
            .catch(errorData => {
              return handleCheckeCusQueryStatus(errorData, resolve);
            });
        },
        () => {
          tradeStore.getCusQueryList({ serialNo })
            .then(async data => {
              const password = window.$V('password');
              const icCard = window.$V('icCard');
              const dataList = data.slice();
              let isSendSignError = false;
              
              const sendSignFail = () => {
                message.destroy();
                Modal.error({
                  'title': '获取签名失败',
                  content: '获取签名失败，请插入IC卡刷新重试',
                  cancelButtonProps: null
                });
              }
              
              /** 签名 */
              const signP = (pwd, icC, item) => {
                return new Promise((resolve, reject) => {
                  window.EportClient.signData(
                    pwd,
                    `${icC}||${item.cusCiqNo}`,
                    (msg) => {
                      if (msg.Result) {
                        resolve(msg.Data[0]);
                      } else {
                        reject(msg);
                      }
                    }
                  )
                })
              };

              /** 获取单条报关单详情失败，重新登录
               * 失败后重试，最多尝试3次
               */
              const hgLoginP = () => {
                const { serialNo } = tradeStore.hgUrlParams;
                const certNo = window.$V('certNo');
                const icCard = window.$V('icCard');
                const signData = window.$V('signData');
                const userPin = window.$V('userPin');
                const params = { serialNo, certNo, icCard, signData, userPin };
                return new Promise((resolve, reject) => {
                  looprquest(
                    () => tradeStore.hgLogin(params),
                    () => resolve(),
                    () => reject(),
                    { eachSecond: 1, maxTime:3, maxSecond: 1000 * 10 }
                  );
                })
              }

              /** 获取单条报关单详情 */
              const getDecDetailSingleP = (password, icCard, element) => {
                return signP(password, icCard, element)
                  .then(signData => {
                    return tradeStore.getDecDetailSingle({ ...element, sign: signData }, serialNo);
                  })
                  .catch(error => {
                    // sendSignFail();
                    return Promise.reject(error);
                  });
              };

              /** 获取单条报关单详情，
               * 获取失败后尝试重新获取cookice（失败后尝试3次），获取成功后继续获取报关单
               */
              const handleGetDecDetailSingleP = (password, icCard, element) => {
                return getDecDetailSingleP(password, icCard, element)
                  .catch(() => {
                    return hgLoginP()
                      .then(() => getDecDetailSingleP(password, icCard, element));
                  })
                  .catch(() => {
                    sendSignFail();
                  });
              };
              
              for (let index = 0; index < dataList.length; index++) {
                const element = dataList[index];
                try {
                  await handleGetDecDetailSingleP(password, icCard, element);
                } catch (error) {
                  /** 获取单条报关单详情 */
                  isSendSignError = true;
                  break;
                }
              }

              if (isSendSignError) {
                message.destroy();
                reject();
              } else {
                resolve();
              }

            })
            .catch(error => {
              /** 取消登录按钮已按状态 */
              window.isLoginButtonPressed = false;
              reject(new Error('获取数据失败'));
            })
        },
        (error) => {
          checkeCusQueryStatusLoppErrorCb(error, reject);
        },
        { eachSecond: 3000, maxTime: 6000, maxSecond: 1000 * 60 * 300 }
      );
    });
  }

  /** 税单采集状态查询 */
  const checkTaxQueryStatus = (serialNo) => {
    message.destroy();
    message.warning('获取数据中，请勿关闭页面', 0);
    return new Promise((resolve, reject) => {
      looprquest(
        () => {
          return tradeStore.checkeQueryStatus({ serialNo })
            .then(data => {
              const { taxStatus } = data;
              if (taxStatus === 'PROCESSED') {
                return data;
              }
              throw new Error('');
            })
            .catch(errorData => {
              return handleCheckeTaxQueryStatus(errorData, resolve);
            });
        },
        () => {
          collectDataSuccess();
          resolve();
        },
        (error) => {
          // checkeTaxQueryStatusLoppErrorCb(error, resolve, reject);
          message.destroy();
          message.success('数据量较大，会持续采集，即将返回业务系统');
          /** 取消登录按钮已按状态 */
          window.isLoginButtonPressed = false;
          urlBack();
          resolve();
        },
        { eachSecond: 3000, maxTime: 600, maxSecond: 1000 * 60 * 30 }
      );
    });
  }

  /** 解析参数 */
  const getQueryParams = () => {
    const search = window.location.search;
    const { channelCode, param } = qs.parse(search, { ignoreQueryPrefix: true });
    if (channelCode && param) {
      return tradeStore.getHgUrlParam({ channelCode, param })
        .then((urlParam) => {
          if (urlParam.swiftCode) {
            setSetTaxAxiosHeader('swift-code', urlParam.swiftCode)
          }
          return commonStore.getProtocolSingleV2(urlParam);
        })
        .then(() => {
          setLoadParams(false);
        })
        .catch((error) => {
          const { errorCode, message: errorMsg } = error;
          setIsBtnDisable(true);
          setLoading(false);
          form.resetFields();
          if (errorCode === 403232 ) {
            /** 有同名公司正在采集数据 */
            history.replace(`/invalidation?message=${errorMsg}`);
          } else {
            history.replace(`/invalidation?code=1097`);
          }
          return Promise.reject(error);
        });
    }
    history.replace(`/invalidation?code=1097`);
    return Promise.reject('query 参数解析错误');
  }

   /**
   * 判断授权企业是否和读卡器中的企业相同
   */
  const checkoutCompanyNname = ({ isTest } = { isTest: false }) => {
    if (isTest) {
      return Promise.resolve();
    }
    const { qymc } = tradeStore.hgUrlParams;
    const scCompanyNameStr = window.scCompanyNameStr || '';
    if (scCompanyNameStr.indexOf(qymc) > -1) {
      return Promise.resolve();
    }
    message.error(`请插入 ${qymc} 的IC卡`);
    return Promise.reject();
  }

  /** 提交表单 */
  const formSubmit = () => {
    setLoading(true);
    /** $V是添加的脚本注入到window中的方法，参数为input元素id，获取对应input的value */
    const { serialNo } = tradeStore.hgUrlParams;
    const certNo = window.$V('certNo');
    const icCard = window.$V('icCard');
    const signData = window.$V('signData');
    const userPin = window.$V('userPin');
    const params = { serialNo, certNo, icCard, signData, userPin };
    console.log(`😋🙃 ~ formSubmit ~ params:`, params);
    checkoutCompanyNname({ isTest: true })
      .then(() => tradeStore.auth(params))
      .then(() => checkSwSignNos(serialNo))
      // .then(postSwCrawlRest)
      // .then(() => checkTaxQueryStatus(serialNo))
      .then((data) => {
        collectDataSuccess()
        setLoading(false);
      })
      .catch((error) => {
        message.destroy();
        console.error('error: ', error);
        setLoading(false);
        /** 取消登录按钮已按状态 */
        window.isLoginButtonPressed = false;
      })
  }

  /** 获取客户端控件安装包下载地址 */
  const getClientApp = () => {
    var swVersionScript = "https://app.singlewindow.cn/sat/swVersion.js";
    loadScript(swVersionScript)
      .then(() => {
        const clientUrl = window.SwVersion.getIkeyDownloadUrl();
        setClientUrl(clientUrl);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }

  useEffect(() => {

    getQueryParams()
      .then(() => {
        return addScript();
      })
      .then(() => {
        /** 获取客户端控件安装包下载地址 */
        getClientApp();
        /** 检查是否安装客户端控件 */
        window.init();
        /** 注入检测到IC卡/Key客户端控件未安装时的回调函数 */
        window.delayAlertSetupControl= () => {
          setIsBtnDisable(true);
          setLoading(false);
          form.resetFields();
          Modal.error({
            'title': '未安装IC卡/Key客户端',
            content: '请下载安装IC卡/Key客户端控件，否则，将无法使用IC卡/Key',
            cancelButtonProps: null
          });
        }
        /** 注入form submit 回调函数 */
        window.formSubmit = formSubmit;        
        /** 注入获取签名错误的错误提示方法 */
        window.handleSignErrorAlert = (errMsg) => {
          Modal.error({
            'title': '授权失败',
            content: errMsg,
            cancelButtonProps: null
          });
          setLoading(false);
        }
        /**
         * 注入获取读卡器包含企业名称字符串
         * 获取到企业名称后保存到window.scCompanyNameStr
        */
        window.getCompanyName = (result) => {
          console.log('result: ', result);
          window.scCompanyNameStr = result.Data[1];
        };
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }, []);

  const isAgreenP = () => new Promise((resolve, reject) => {
    if (isAgreen) {
      resolve();
      return false;
    }
    message.error('请先勾选并同意《报关数据授权协议》');
    reject();
  })

  const authSubmit = () => {
    form.validateFields()
      .then(() => isAgreenP())
      .then(() => {
        window.doCAIdentify();
        setLoading(true);
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }

  const onAgreenChange = (evt) => {
    const isChecked = evt.target.checked;
    setIsAgreen(isChecked);
  }

  const imgBg = process.env.PUBLIC_URL + '/statics/trade/bg.png';
  const downloadIcon = process.env.PUBLIC_URL + '/statics/trade/download_icon.png';

  const { random } = tradeStore.hgUrlParams;

  if (loadParams) {
    return (
      <div className={styles.loadWrap}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.bg} style={{ backgroundImage: `url(${imgBg})`}}>
      <div className={styles.headerWrap}>
        <Header />
      </div>
      <div className={styles.formWrap}>
        <div className={styles.title}>进出口数据登录认证</div>
        <Form form={form} id="fm1" layout="vertical">
          <Form.Item
            name='password'
            label="IC卡密码"
            rules={[{ required: true, message: '请输入IC卡密码' }]}
          >
            <Input
              type="password"
              size="large"
              id="password"
              name="password"
              style={{ borderRadius: '4px' }}
              placeholder="请输入IC卡密码"
            />
          </Form.Item>
          <Protocol isAgreen={isAgreen} onAgreenChange={onAgreenChange} />
          <Button
            block
            id="login"
            size='large'
            type='primary'
            onClick={authSubmit}
            loading={isLoading}
            disabled={isBtnDisable}
            style={{ borderRadius: '4px', marginTop: '20px' }}>登录认证</Button>
        </Form>
        <div className={styles.des}>
          <div className={styles.dest}>请您按照以下步骤进行：</div>
          <div className={styles.step}><span>1、请先插入IC卡。</span><span>2、在输入框中输入IC卡密码。</span></div>
          <div className={styles.downBtnWrap}>
            <a id="downloadLink" href={clientUrl} download><img alt="download" src={downloadIcon} />客户端控件下载</a>
          </div>
        </div>
        <input name="random" id="random" hidden defaultValue={random} />
        <input type="hidden" id="signData" name="signData" path="signData" />
        <input type="hidden" id="icCard" name="icCard" path="icCard" />
        <input type="hidden" id="certNo" name="certNo" path="certNo" />
        <input type="hidden" id="userPin" name="userPin" value="userPin" />
      </div>
    </div>
  );
}

export default inject('tradeStore', 'commonStore')(observer(ShowOnMobile(Trade)));
