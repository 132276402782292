import { Form, Select } from 'antd';
import { isFunction } from 'lodash';

const SpTypeFormItem = ({ inputSize, onChange }) => {

  const thisOnChange = (value, option) => {
    if (isFunction(onChange)) {
      onChange(value, option);
    }
  }

  return (
    <Form.Item
      key="spsb"
      colon={false}
      label="开票软件设备类型："
      name="spsb"
      rules={[{ required: true, message: '请选择开票软件设备类型' }]}
    >
      <Select onChange={thisOnChange} size={inputSize ? inputSize : 'large'} placeholder="请选择开票软件设备类型">
        <Select.Option value="1">Ukey</Select.Option>
        <Select.Option value="2">税控盘</Select.Option>
        <Select.Option value="3">金税盘</Select.Option>
      </Select>
    </Form.Item>
  );
}

export default SpTypeFormItem;
