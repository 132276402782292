import {provinceValidatorApi} from "@/api";
import {encryptedParams} from "@/utils";
import {showErrorMessage} from "@/utils/taxUtil";
import {get} from "lodash";
import taxHelperStore from '../taxHelper';

import {getRoleListApi, fakeQrCode} from "@/pages/guide/fake";

/** 引导页store */
class GuideStore {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = (params, options, NEW_AUTH) => {
    return getRoleListApi(params, NEW_AUTH)
      .then(data => {
        console.log(data, params, 'aaa');
        this.bsrsList = data;
      })
      .catch(error => {
        showErrorMessage(error, '获取办税员失败');
        return Promise.reject(error);
      });
  }

  /** 发送验证码 */
  sendCaptcha = ({formData}) => {
    const {nsrsbh, province, serialNo} = taxHelperStore.authQueryParams;
    const {bsr} = formData;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const {uuid} = selectedBsr;
    const params = {
      serialNo,
      province,
      uuid,
      creditCode: nsrsbh,
      authentication: 'tpass.chongqing.chinatax.gov.cn'
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(params)
      .then(resp => {
        const data = get(resp, 'data.data') || {};
        this.smscodeId = data['smscode_id'];
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  taxAuth = ({formData}) => {
    // const {captcha, bsr} = formData;
    // const {nsrsbh, serialNo, province} = taxHelperStore.authQueryParams;
    // const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    // const {uuid} = selectedBsr;
    // const params = {
    //   province,
    //   smscodeId: this.smscodeId,
    //   mobileCode: captcha,
    //   creditCode: nsrsbh,
    //   uuid,
    //   authentication: 'tpass.chongqing.chinatax.gov.cn'
    // };
    return Promise.resolve();
    // return provinceValidatorApi.passwordAuth(serialNo, params)
    //   .catch(error => {
    //     showErrorMessage(error, '授权失败');
    //     return Promise.reject(error);
    //   });
  }

  /** 获取税局授权方式 */
  getAuthMethodType(province) {
    const area2authType = {
    }
    return Promise.resolve(area2authType[province]);
    // return provinceValidatorApi.getAuthMethodType(province)
    //   .then(resp => get(resp, 'data.data.data'));
  }

  /** 授权获取二维码 */
  getQRCode({formData, options}) {
    const {companyName, taxAccount, taxDeclareProvince, taxPassword} = formData;
    const {nsrsbh, serialNo, province} = taxHelperStore.authQueryParams;
    const {verifyCode} = options;
    const params = {
      companyName,
      province,
      serialNo,
      username: taxAccount.toLocaleUpperCase(),
      taxDeclareProvince,
      creditCode: nsrsbh,
      password: taxPassword,
      verifyCode,
      authType: 'WeChat'
    };
    this.handleTaxAuthResp();
    return Promise.resolve();
    // return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password']))
    //   .then(resp => {
    //     this.handleTaxAuthResp(resp);
    //   })
    //   .catch(error => {
    //     showErrorMessage(error, '获取授权二维码失败');
    //     return Promise.reject(error);
    //   })
  };

  handleTaxAuthResp = (resp) => {
    const data = get(resp, 'data.data.data');
    this.qrCode = fakeQrCode();
    // this.bsrs = get(data, 'infoData.czryObj') || [];
    // this.bsrsList = this.bsrs.map(item => {
    //   const {czryxm} = item;
    //   return {label: czryxm, value: czryxm};
    // });
  };
};

export default new GuideStore();
