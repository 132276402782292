import { Checkbox, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { SelectBox, SelectItem, CustomCheckBoxGroup } from './Select.styled';
import DynamicCheckbox from './DynamicCheckbox';
import NumberRange from './NumberRange';
const { RangePicker } = DatePicker;

const Select = ({ options, value, onChange, multiple, transform, showCustom, itemStyle }) => {
  const handleClick = (value) => () => {
    if (transform) {
      onChange(transform(value));
    } else onChange?.(value);
  };

  const selected = (val) => {
    if (value instanceof Array) {
      return value.includes(val);
    }
    return value === val;
  };

  if (multiple) {
    return (
      <CustomCheckBoxGroup value={value} onChange={onChange}>
        {options.map((option) => {
          return (
            <Checkbox key={JSON.stringify(option.value)} value={option.value}>
              {option.label}
            </Checkbox>
          );
        })}
        {showCustom && (
          <DynamicCheckbox
          // transform={(value) => {
          //   return { min: value[0]?.format('YYYY'), max: value[1]?.format('YYYY') };
          // }}
          // onChange={(val) => onChange([...(value || []), val])}
          >
            {/*<RangePicker bordered={false} format="YYYY" picker="year" />*/}
            <NumberRange />
          </DynamicCheckbox>
        )}
      </CustomCheckBoxGroup>
    );
  }

  return (
    <SelectBox>
      {options.map((item) => {
        return (
          <SelectItem key={JSON.stringify(item.value)} onClick={handleClick(item.value)} active={selected(item.value)} style={itemStyle}>
            {item.label}
          </SelectItem>
        );
      })}
    </SelectBox>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    }),
  ),
  mode: PropTypes.oneOf(['multiple', 'single']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  onChange: PropTypes.func,
};

Select.defaultProps = {
  mode: 'single',
};

export default Select;
