import React from 'react';
import styels from './index.less';

const RizhaoContainer = ({ children }) => {
  return (
    <div className={styels.wrap}>
      <div className={styels.container}>
        {children}
      </div>
    </div>
  );
};

export default RizhaoContainer;
