import { getAuthorizeUrl as getAuthorizeUrlApi } from '@/api/access';
import { get } from 'lodash';

class AccessStore {

  getAuthorizeUrl = (params) => {
    return getAuthorizeUrlApi(params)
      .then(resp => {
        const url = get(resp, 'data.data.url');
        return url;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      });
  }
}

export default new AccessStore();
