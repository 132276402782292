import React from 'react'
import Captcha from '.';
import { Input } from 'antd';
import styles from "./index.module.less";

/**
 * 验证码输入框
 * @param {{
 *  captchProps,
 * }} props 
 * @returns 
 */
const CaptchaInput = (props) => {
  const { captchProps, className = '', ...other } = props;
  return (
    <Input
      {...other}
      className={`${styles.captchInput} ${className}`}
      addonAfter={<Captcha {...captchProps} />}
    />
  )
}

export default CaptchaInput