import React from 'react';

import styels from './index.less';

const DefaultContent = ({ children }) => {
  return (
    <div className={styels.wrap}>
      {children}
    </div>
  );
};

export default DefaultContent;
