import React from 'react';
import { hxTaxApi } from '@/api';
import { Button } from 'antd';

const TestComponent = () => {

  const [result, setResult] = React.useState('');

  const handleLocalRequest = () => {
    setResult('请求发起中');
    hxTaxApi.getLocalTest()
      .then(() => {
        setResult('访问成功对了✔');
      })
      .catch(() => {
        setResult('访问失败❌');
      });
  }

  const handleLocalRequest2 = () => {
    setResult('请求发起中');
    hxTaxApi.getLocalTest2()
      .then(() => {
        setResult('访问成功对了✔');
      })
      .catch(() => {
        setResult('访问失败❌');
      });
  }

  const handleLocalRequest3 = () => {
    setResult('请求发起中');
    hxTaxApi.getLocalTest3()
      .then(() => {
        setResult('访问成功对了✔');
      })
      .catch(() => {
        setResult('访问失败❌');
      });
  }
  return (
    <div>
      <div>请求状态：{result}</div>
      <div>
        <Button type="primary" onClick={handleLocalRequest}>测试本地（localhost）API</Button>
        <Button type="primary" onClick={handleLocalRequest2}>测试本地（127.0.0.1）API</Button>
        <Button type="primary" onClick={handleLocalRequest3}>测试本地（sc-test）API</Button>
      </div>
    </div>
  );
}

export default TestComponent;
