import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { showErrorMessage } from '@/utils/taxUtil';
import { encryptedParams } from '@/utils';

const authentication = 'tpass.shaanxi.chinatax.gov.cn';

/** 陕西 */
class Shanxi {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then( resp => {
        const { mobile, uuid } = get(resp, 'data.data.data');
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{ value: 0, label: mobile }];
      })
      .catch( error => {
        const errorMessage = get(error, 'response.data.message') || '获取办税员失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr } = formData;
    const { taxAccount, taxPassword } = stepOneFormData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { mobile } = selectedBsr;
    const params = {
      serialNo,
      userName: taxAccount,
      userPass: taxPassword,
      creditCode: nsrsbh,
      province,
      authentication: 'tpass.shaanxi.chinatax.gov.cn',
      phoneNumber: mobile,
      uuid: selectedBsr.uuid
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const data = get(resp, 'data.data') || {};
        this.smscodeId = data['smscode_id'];
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { captcha, bsr } = formData;
    const { serialNo, nsrsbh } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { uuid, mobile } = selectedBsr;
    const params = {
      creditCode: nsrsbh,
      captcha,
      uuid,
      smsCodeId: this.smscodeId,
      yddh: mobile,
      authentication: 'tpass.shaanxi.chinatax.gov.cn'
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  };

}

export default new Shanxi();
