import dayjs from 'dayjs';
import { observer, inject } from 'mobx-react';
import styles from './index.less';

const HLProtocolContent = ({ taxHelperStore, companyName }) => {

  const thisCompanyName = companyName || taxHelperStore.getCompanyName();
  
  return (
    <div className={styles.content}>
      <p>尊敬的客户</p>
      <p><strong>【重要提示】</strong></p>
      <p><strong>贵公司作为本次授权的授权方（以下或简称：您）须确认在签署本授权书时，当前操作人是中国籍年满18周岁且具有完全民事行为能力的自然人。此外，还需确保操作人是已经在市场监督管理局登记注册并有效存续的企业法人的法定代表人或股东。请仔细阅读本协议全部内容，特别是黑色加粗字体条款部分，以便授权方充分知悉并理解己方权利与义务。如授权方不同意本授权书所有条款，请不要进行下一步操作，否则即视为授权方已阅读并同意受本授权书条款的约束。</strong></p>
      <p><strong>授权书签订及履行地：重庆市渝北区</strong></p>
      <p><strong>授权日期：{dayjs().format('YYYY年MM月DD日')}</strong></p>
      <h2>第一条 华龙信用收集信息授权</h2>
      <div className={styles.paragraph}>
        <p><strong>1.1 授权方理解并同意，通过包括但不限于在华龙信用服务平台数据收集服务的授权页面主动输入被收集软件系统或网站账户和密码的方式，授权华龙信用在不违反现行法律法规许可范围的前提下，代授权方查询并收集授权方自身硬件或软件系统中存储的或网站页面展示的与授权方有关的信息（此类信息包括但不限于工商信息、司法信息、税务信息、发票信息、海关信息、订烟信息及ERP系统中的信息等），并予以存储、加工、推送给被授权的金融机构 。</strong></p>
      </div>
      <div className={styles.paragraph}>
        <p>1.2 上述信息从内容上包括：</p>
        <p><strong>1.2.1 工商信息，包括但不限于工商登记注册、变更等信息等；</strong></p>
        <p><strong>1.2.2 税务信息，包括但不限于过去36个月内纳税信息、增值税发票信息等，例如纳税主体信息、纳税人信息等级、申报明细、纳税人状态、税额、税率、税收违法案件信息等；</strong></p>
        <p><strong>1.2.3 海关信息，包括但不限于报关数据、税单数据、海关基本信息等；</strong></p>
        <p>1.2.4 订烟信息，包括但不限于用户信息、历史订单信息、订单详情信息等；</p>
        <p>1.2.5 法律诉讼信息，包括但不限于企业被执行人信息、企业失信被执行人信息、法院判决等；</p>
        <p>1.2.6 行政处罚信息，包括但不限于行政机关或法律、法规授权的组织公示与授权方相关的处罚信息等；</p>
        <p>1.2.7 经营信息，包括但不限于ERP系统中的采购、销售等信息，例如金额、数量、产品名称等；</p>
        <p>1.2.8 财务信息，包括但不限于财务报表、对外投资、银行账户信息等；</p>
        <p>1.2.9 资产信息，包括有形资产和无形资产，例如存款、动产、不动产、知识产权、债权等。</p>
        <p><strong>华龙信用不会收集个人的宗教信仰、基因、指纹、血型、疾病和病史信息以及适用的法律法规禁止我们收集的任何个人信息，同时华龙信用也不会收集个人的聊天、短信、通话内容等个人信息，也不会收集、追踪个人在社交媒体上的言论信息。</strong></p>
      </div>
      <div className={styles.paragraph}>
        <p>1.3 上述信息从形式上包括但不限于：</p>
        <p>1.3.1 授权方因办理各类业务而留存在华龙信用及合作机构的信息；</p>
        <p>1.3.2 授权方向华龙信用及合作机构主动提供的信息；</p>
        <p>1.3.3 储存在授权方自身硬件或软件系统需华龙信用根据授权主动收集的信息；</p>
        <p>1.3.4 授权方已经向社会公开的或第三方合法拥有的授权方信息；</p>
        <p>1.3.5 被授权方基于法律法规规定或信息主体授权所获得的其他信息；</p>
        <p>1.3.6 国家法律、行政法规、部门规章、地方性法规未禁止采集的与授权企业有关的其他信息。</p>
      </div>
      <div className={styles.paragraph}>
        <p>1.4 授权方知晓并同意：信息收集过程中，华龙信用可聘请第三方（包括自然人、法人或法律规定的其他组织）为其提供技术服务，以便获得授权方授权收集的信息。但此情形下，华龙信用并未将本授权书中收集信息的授权转委托给该方，华龙信用将明确要求该第三方未经授权方同意不得存储、使用授权方的信息。如该第三方侵犯授权方关于信息保护的合法权益的，华龙信用将解除与该第三方的聘用协议，并积极配合授权方向第三方主张权利。</p>
      </div>
      <div className={styles.paragraph}>
        <p>1.5 授权方确认充分知悉不良信息（如有）可能影响对您的信用评价。您确认并同意信息提供者向我们提供您的不良信息（如有）。我们将依法对您的不良信息进行保存，保存期限为自不良行为或事件终止之日起为5年；超过5年的，我们将依法纳入历史数据，无必须不予公示。在不良信息保存期限内，如您需要对不良信息作出说明，请您及时联系我们，我们将予以记载。</p>
      </div>
      <div className={styles.paragraph}>
        <p><strong>授权方充分理解并知晓上述信息对于授权方的重要性，以及对外提供或授权收集、存储、使用上述信息可能产生的风险（这些风险包括但不限于信息泄漏、被授权主体依法依约提供给第三方后被他人不当利用等）。</strong></p>
      </div>
      <h2>第二条 华龙信用使用信息授权</h2>
      <div className={styles.paragraph}>
        <p>2.1 授权方同意并授权华龙信用在保证授权方信息安全的前提下，依照自行设立的模型、模式、格式、规则、流程等对前述通过合法途径收集的授权方信息进行保存、整理、分析、加工、匿名化处理等操作，以包括但不限于接口、网页或纸质信用报告等形式提供给上述合作机构，用于评价授权方信用情况或核实授权方信息的真实性，但是法律规定可以不经同意的除外。</p>
      </div>
      <div className={styles.paragraph}>
        <p>2.2 授权方同意并授权华龙信用将因上述收集授权所得到的信息，用于与华龙信用签订合作协议金融机构对授权方的信用评估，具体包括但不限于以下使用目的：</p>
      </div>
      <div className={styles.paragraph}>
        <p>2.2.1 审核授权方的业务申请；</p>
        <p>2.2.2 合理评估授权方的经营状况、防控风险；</p>
        <p>2.2.3 对授权方或相关借款人进行贷中监控；</p>
        <p>2.2.4 处理贷后管理事务（包括催收）；</p>
        <p>2.2.5 处理授权方征信异议；</p>
        <p>2.2.6 依法或经有权部门要求；</p>
        <p>2.2.7 审核授权方作为提出贷款申请组织或机构的董监高、法定代表人、股东、担保人、企业经营者、实际控制人、授权经办人等的信息资料的真实性、合法性、完整性、准确性和有效性；</p>
        <p>2.2.8 华龙信用及下属平台向授权方提供的合法经营范围内的其他业务。</p>
      </div>
      <h2>第三条 华龙信用共享信息授权</h2>
      <div className={styles.paragraph}>
        <p>3.1 授权方同意并授权华龙信用基于为授权方提供更优质服务和产品的目的，在遵守国家法律法规以及行业标准的基础上向华龙信用合作机构（包括但不限于征信机构、银行及金融机构、电子证据存证服务机构等，本授权书提及的“伙伴”均具有前述含义）提供其基于授权方的授权而查询、收集、保存所得到的授权方的相关信息。</p>
      </div>
      <div className={styles.paragraph}>
        <p>3.2 授权方同意并授权华龙信用将授权方提供给华龙信用及合作机构的信息、享受华龙信用及合作机构服务产生的信息（包括本协议签署之前提供和产生的信息）以及华龙信用根据本授权书约定查询、收集、保存的信息，用于华龙信用及其合作机构为授权方提供服务、开展市场调查与信息数据与分析服务。</p>
      </div>
      <div className={styles.paragraph}>
        <p>3.3 授权方授权华龙信用基于为使华龙信用及合作机构可以更全面、准确地记录、评估授权方或相关人员情况，从华龙信用合作机构处获取、查询、收集授权方的信息并予以保存。</p>
      </div>
      <h2>第四条 合作机构使用信息授权</h2>
      <div className={styles.paragraph}>
        <p>4.1 授权方同意授权华龙信用的合作机构<strong>查询、使用、打印、保存</strong>华龙信用及下属平台推送给该等机构已经授权方单一授权的信息。</p>
        <p><strong>4.2 授权方在使用华龙信用合作机构的任何产品和服务时，应当充分了解并接受该等合作机构的合同条款等规则。华龙信用及下属平台对此不作任何保证，亦不代表华龙信用及下属平台对该等合作机构、其产品及服务的任何推荐、支持。鉴于互联网传播的特性，华龙信用及其下属平台通过互联网方式推送给该等合作机构的授权方信息有被劫持或被该等合作机构不当利用或泄露给非授权对象的风险。授权方应自行甄别并在合法的情况下使用华龙信用及下属平台服务。因该等合作机构任何原因造成授权方信息泄露及其他损失的情形，华龙信用不承担任何责任。</strong></p>
      </div>
      <h2>第五条</h2>
      <div className={styles.paragraph}>
        <p><strong>本授权书自授权方点击“我已阅读并同意《“重庆华龙强渝信用管理有限公司”企业信息收集及使用授权书》”之日起生效，至授权方或相关申请人与本授权书前述合作机构的单一授权订单相关业务终结之日止。本授权书效力终止后，我们将在合理期限内通过技术手段对授权方主体身份信息及关联个人信息数据进行去标识化（匿名化）处理，去标识化处理的信息将无法识别主体。授权方充分理解并同意，在此情况下华龙信用及下属平台有权使用已经去标识化的信息；并在不透露授权方主体身份信息及其关联个人身份信息的前提下，华龙信用及下属平台有权对相关数据库进行分析并予以商业化的利用。本授权书效力终止后，授权方主动向华龙信用及下属平台书面申请时，华龙信用将根据适用的合理期限停止使用并删除经华龙信用整理、分析、加工、匿名化处理等操作的授权方信息。本授权系授权方对华龙信用作出的单方承诺，效力具有独立性，不因其他合同的任何条款无效而失效。</strong></p>
      </div>
      <h2>第六条</h2>
      <div className={styles.paragraph}>
        <p>授权方同意华龙信用及下属平台提供服务过程中涉及的所有经华龙信用创作、编辑或加工内容（包括但不限于经华龙信用编辑或加工的网页、文字、图片、音频、视频、图表等）的知识产权归华龙信用及下属平台所有。除另有特别声明外，华龙信用及平台提供相关服务时所依托软件的著作权、专利权及其他知识产权均归华龙信用及平台所有。</p>
      </div>
      <h2>第七条</h2>
      <div className={styles.paragraph}>
        <p>授权方自愿接收政府机构、金融机构、华龙信用及下属平台联合向授权方提供的持续短信通知服务（通知的信息包括但不限于华龙信用及下属平台平台服务信息、产品信息等）。如对授权方的日常经营造成影响的，授权方可自行通过短信方式取消订阅。<strong>授权方承诺不会因此向国家税务局、银行、华龙信用及下属平台投诉或主张任何补偿。</strong></p>
      </div>
      <h2>第八条</h2>
      <div className={styles.paragraph}>
        <p><strong>授权方承诺，当授权方的行为涉嫌违反法律法规或违反本协议和/或规则的，华龙信用及下属平台有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息，或直接停止提供服务。如授权方的行为使华龙信用及下属平台遭受任何损失的（包括但不限于受到第三方的索赔、受到行政管理部门的处罚等），授权方还应当赔偿或补偿华龙信用及下属平台遭受的全部损失及（或）发生的全部费用，包括但不限于诉讼费、保全费、律师费等维权费用以及因第三方权利主张而支出的费用等。</strong></p>
      </div>
      <h2>第九条</h2>
      <div className={styles.paragraph}>
        <p>授权方有权对我们的服务提出异议或进行投诉，我们的客户服务中心（联系电话023-63205701）将尽合理努力向您提供必要协助，我们根据适用的法定期限进行核查、处理及答复。</p>
      </div>
      <h2>第十条</h2>
      <div className={styles.paragraph}>
        <p>授权方同意华龙信用及下属平台有权根据需要适时的修改本协议及/或各类规则，并在平台上公示。<strong>授权方如继续使用平台服务，即表示授权方理解并接受新修订的协议和规则。</strong></p>
      </div>
      <h2>第十一条</h2>
      <div className={styles.paragraph}>
        <p>若授权方与被授权主体发生任何纠纷或争议，首先应友好协商解决；协商不成的，授权方同意将纠纷或争议向华龙信用注册地有管辖权的人民法院提起诉讼解决，并适用中华人民共和国大陆地区法律（不包括冲突法），且诉讼费、保全费、律师费、中介费等维权费用以及因第三方权利主张产生的费用等均由违约方承担。</p>
      </div>
      <h2>第十二条</h2>
      <div className={styles.paragraph}>
        <p>授权方声明：授权方已仔细阅读上述所有条款，并已特别注意字体加粗的内容。平台已应授权方要求对相关条款予以明确说明。授权方对所有条款的含义及相应的法律后果已全部通晓并充分理解，授权方自愿作出上述授权、承诺和声明。</p>
      </div>
    </div>
  );
};

export default inject('taxHelperStore')(observer(HLProtocolContent));
