import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import { taxAxios } from '@/utils/taxAxios';
import { message, Modal } from 'antd';
import { get } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './index.module.less';

/** 获取验证码图片 */
const doChinesVerifyCodeUrl = '/api/dbc/taxation/doChinesVerifyCode';
const getDoChinesVerifyCodeImg = (params) => {
  return taxAxios.get(doChinesVerifyCodeUrl, { params })
    .then(res => `data:image/*;base64,${res.data}`);
};

/** 验证验证码 */
const doVerifyUrl = '/api/dbc/taxation/jsonController2';
const doVerify = (params) => {
  return taxAxios.post(doVerifyUrl, params)
    .then(resp => {
      const reCode = get(resp, 'data.reCode');
      if (reCode === '0') {
        const error = get(resp, 'data');
        return Promise.reject(error);
      }
      return resp;
    });
};

const getNodeCoords = (_node) => {
  let top = _node.offsetTop
  let left = _node.offsetLeft
  let val = _node.offsetParent
  while (val != null) {
    top += val.offsetTop
    left += val.offsetLeft
    val = val.offsetParent
  }
  return { x: left, y: top };
}

/** 验证器图片大小 */
const VERIFY_IMAGE_WIDTH = {
  width: 302,
  height: 220
};

let intervalId = '';

class Jiangxi extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isModalvisible: false,
      verifyImage: '',
      pointList: [],
      verifyResponData: '',
      /** 是否Loading验证码图片 */
      isLoadingImg: false,
      /** 是否正在验证 */
      isVerifing: false,
    };
  }

  /** 加载验证码图片 */
  loadVerifyCodeImg = () => {
    const serialNo = get(this.props.taxHelperStore, 'authQueryParams.serialNo');
      const params = {
        d: '',
        count: '5',
        width: VERIFY_IMAGE_WIDTH.width,
        height: VERIFY_IMAGE_WIDTH.height,
        charDeviation: '35',
        rdn: new Date().getTime(),
        serialNo,
      };
      this.setState({ isLoadingImg: true, pointList: [] });
      getDoChinesVerifyCodeImg(params)
        .then(verifyImage => {
          this.setState({ verifyImage, isLoadingImg: false });
        });
  };

  /** 验证验证码 */
  verifyCode = (pointList) => {
    const { authQueryParams: { serialNo } } = this.props.taxHelperStore;
    const params = {
      orderId: serialNo,
      sid: '17000001',
      coordsList: pointList
    };
    this.setState({ isVerifing: true });
    doVerify(params)
      .then(resp => {
        const reData = get(resp, 'data.reData');
        this.setState({
          verifyResponData: reData,
          isModalvisible: false,
          isVerifing: false,
        });
      })
      .catch(error => {
        this.setState({
          isVerifing: false,
        });
        this.loadVerifyCodeImg();
        const { reMsg } = error;
        message.error(reMsg);
      });
  };

  resetState() {
    this.setState({
      isModalvisible: false,
      verifyImage: '',
      pointList: [],
      verifyResponData: '',
      isLoadingImg: false,
      isVerifing: false,
    });
  };

  componentWillUnmount() {
    if (intervalId) {
      clearTimeout(intervalId);
    }
    this.resetState();
  }

  /** 监听验证码是否验证成功 */
  getVerifyResponDataP = () => {
    return new Promise((resolve) => {
      intervalId = setInterval(() => {
        const { verifyResponData, pointList } = this.state;
        if (verifyResponData && pointList.length === 2) {
          clearTimeout(intervalId);
          resolve({ verifyResponData, pointList });
          this.resetState();
        }
      }, 1000);
    }); 
  }


  /** 外部调用，获取验证码 */
  _scpublicValidateFields = () => {
    this.loadVerifyCodeImg();
    this.setState({ isModalvisible: true });
    return this.getVerifyResponDataP();
  };

  verifyImgRef = createRef();

  getClickPointOnVerifyImg(evt) {
    const verifyImgEle = this.verifyImgRef.current;
    const ndoeCoords = getNodeCoords(verifyImgEle);
    const { pageX, pageY } = evt;
    return { x: pageX - ndoeCoords.x - 15, y: pageY - ndoeCoords.y - 15 };
  };

  handleImageClick(evt) {
    const point = this.getClickPointOnVerifyImg(evt);
    const oldPointList = [ ...this.state.pointList];
    let newPointList = oldPointList;
    if (oldPointList.length < 2) {
      newPointList = [ ...newPointList, point ];
      this.setState({ pointList: newPointList });
    }
    if (newPointList.length === 2) {
      this.verifyCode(newPointList);
    }
  };

  /** 重新加载验证码图片 */
  handleRefresh() {
    this.loadVerifyCodeImg();
  }

  renderReFreshBtn = () => {
    const { isLoadingImg, isVerifing } = this.state;
    return (
      <div className={styles.btnWrap}>
        {
          !isLoadingImg && !isVerifing
          ? <a onClick={this.handleRefresh.bind(this)}>刷新</a>
          : <span className={styles.refreshDisabled}>刷新</span>
        }
      </div>
    );
  };

  renderLoadingMask = () => {
    const { isVerifing } = this.state;
    if (isVerifing) {
      return <div className={styles.loadingMask}>验证中...</div>;
    }
    return null;
  };

  render() {
    const { isModalvisible, verifyImage, isLoadingImg } = this.state;
    const { pointList } = this.state;
    return (
      <Modal
        width={350}
        title="请完成验证"
        closeIcon={this.renderReFreshBtn()}
        maskClosable={false}
        visible={isModalvisible}
        footer={null}
      >
        <div className={styles['verifyPanel']}>
          {
            isLoadingImg ?
            <div className={styles.verifyImageLoading}>
              <LoadingOutlined style={{ fontSize: '30px'}} spin />
            </div> :
            <div className={styles.verifyImage}>
              <img
                alt=''
                src={verifyImage}
                ref={this.verifyImgRef}
                onClick={this.handleImageClick.bind(this)}
              />
            </div>
          }
          {
            pointList.map((item, index) => {
              const { x, y } = item;
              const top = y;
              const left = x;
              return (
                <div key={index} style={{ top: `${top}px`, left: `${left}px`}} className={styles.pointTag}>{index + 1}</div>
              );
            })
          }
          {
            this.renderLoadingMask()
          }
        </div>
      </Modal>
    );
  }
};

export default inject('taxHelperStore')(observer(Jiangxi));
