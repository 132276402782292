import React, { useEffect, useState } from 'react'
import styles from './index.module.less';
import { Button, Tooltip } from 'antd';
import { reload } from '@/utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { parse, stringify, stringifyUrl } from 'query-string';
import { getOldTaxBureau } from '@/api/common';
import { get } from 'lodash';

const router = {
  '/authorize': {
    switchURL: '/auth',
    btnText: '切换至旧版登录',
  },
  '/auth': {
    switchURL: '/authorize',
    btnText: '切换至新版登录',
  },
  '/guide/auth': {
    switchURL: '/guide/authOld',
    btnText: '切换至旧版登录',
  },
  '/guide/authOld': {
    switchURL: '/guide/auth',
    btnText: '切换至新版登录',
  },
}
// getOldTaxBureau
const SwitchVersion = (props) => {
  /** 存在旧版本的地区 */
  const [oldList, setOldList] = useState([])
  const {  taxHelperStore } = props;
  const { province } = taxHelperStore.authQueryParams;
  const { pathname, search } = window.location;
  const { btnText, switchURL } = router[pathname] || {};

  useEffect(() => {
    getOldTaxBureau()
      .then((res) => {
        const list = get(res, 'data.data') || [];
        setOldList(list)
      })
  }, [])
  
  
  const onClick = () => {
    const newSearch = {
      ...parse(search, { decode: false }),
      area: province,
    }
    const searchString = stringify(newSearch, { encode: false });
    window.location.replace(`${switchURL}?${searchString}`)
  }

  const include = oldList.includes(province)
  return (
    <div className={styles.index} data-include={include}>
      {
        include && (
          <div className={styles.row}>
            <Button
              type="link"
              className={styles.btn}
              onClick={onClick}
            >
              { btnText }
            </Button>
            <Tooltip title="请优先使用新版登录，若新版登录维护或无法正常登录时，可使用旧版登录" trigger="click">
              <QuestionCircleOutlined className={styles.icon} />
            </Tooltip>
          </div>
        )
      }
      <Button className={styles.reload} type="link" onClick={reload}>点击刷新</Button>
    </div>
  )
}

export default inject(
  'taxHelperStore',
)(observer(SwitchVersion))
