import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import { get } from "lodash";
import taxHelperStore from '../taxHelper';

/** 黑龙江 */
class Heilongjiang {
  // bsrsRespData = {};
  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = (data) => {
    const params = {
      "authentication": "tpass.heilongjiang.chinatax.gov.cn",
      ...data
    }
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const { mobile, uuid } = get(resp, 'data.data.data');
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{ value: 0, label: mobile }];
      })
      .catch(error => {
        showErrorMessage(error, '获取登录角色失败');
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData }) => {
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const { bsr } = formData;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { uuid, mobile } = selectedBsr;
    const params = {
      serialNo,
      creditCode: nsrsbh,
      province,
      uuid,
      authentication: 'tpass.heilongjiang.chinatax.gov.cn',
      phoneNumber: mobile,
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(params)
      .then(resp => {
        const data = get(resp, 'data.data') || {};
        this.smscodeId = data['smscode_id'];
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { captcha, bsr } = formData;
    const { nsrsbh, serialNo } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { uuid, mobile } = selectedBsr;
    const params = {
      creditCode: nsrsbh,
      captcha: captcha,
      uuid,
      smsCodeId: this.smscodeId,
      yddh: mobile,
      authentication: 'tpass.heilongjiang.chinatax.gov.cn'
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }
  
}

export default new Heilongjiang();
