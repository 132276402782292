import React from 'react';

import styles from './index.less';

const Content = ({ children }) => {
  return (
    <div className={styles.wrap}>
      {children}
    </div>
  );
};

export default Content;
