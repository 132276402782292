import {useMemo, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {message} from "antd";
import {Helmet} from "react-helmet";

import {Form} from 'antd';
import styled from "styled-components";
import {getAreas} from "@/api/guide";
import {useCache} from "ease-hooks";

import Search from "./components/Search";
import {Select} from "@/components/TagSelect";
import Map from './components/map';

const itemStyle = {
  width: '55px',
  padding: "0 0 0 0",
  height: '32px',
  lineHeight: '32px',
}

const PageContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

const Container = styled.div`
  height: 100vh;
  background: white;
  overflow-y: scroll;
`;

const InnerContainer = styled.div`
  padding: 15px;
`;

const FormItemStyled = styled(Form.Item)`
  & .ant-form-item-control-input {
    align-items: normal;
  }
`;

const FormItemLabel = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: #666666;
`;

function AreaSelect() {
  // data: {cityArea, mapAreas, provinceAreas}

  const history = useHistory();

  const [data] = useCache("GUIDE_AREA_DATA", () => {
    return getAreas().then(res => {
      return res.data || {};
    })
  }, 1, 1);

  const provinceOptions = useMemo(() => {
    let result = [];
    if (data?.provinceAreas && data?.cityAreas) {
      for (let item of data?.provinceAreas) {
        result.push({value: item.area, label: item.area})
      }
    }
    return result;
  }, [data]);

  const searchOptions = useMemo(() => {
    let result = [];
    if (data?.provinceAreas && data?.cityAreas) {
      for (let item of data?.provinceAreas) {
        result.push({value: item.area, label: item.area})
      }
      for (let item of data?.cityAreas) {
        result.push({value: item.area, label: item.area})
      }
    }
    return result;
  }, [data]);

  const cityOptions = useMemo(() => {
    if (data?.cityAreas) {
      return data.cityAreas.map(i => ({value: i.area, label: i.area}));
    }
    return [];
  }, [data]);

  const nestedData = useMemo(() => {
    let result = {};
    if (data?.provinceAreas && data?.cityAreas) {
      for (let item of data?.provinceAreas) {
        result[item.area] = item.status;
      }
      for (let item of data?.cityAreas) {
        result[item.area] = item.status;
      }
    }
    return result;
  }, [data])

  const go = useCallback((area) => {
    if (["台湾省", "香港", "澳门"].includes(area)) {
      return;
    }
    if (nestedData[area] === "PAUSED") {
      message.destroy();
      message.warn("暂未开通，敬请期待");
      return;
    }
    console.log("jumping to auth")
    history.push(`/guide/auth?area=${area}`);
  }, [ nestedData ]);


  return (
    <PageContainer>
      <Helmet>
        <title>税票授权指引</title>
      </Helmet>
      <Container>
        <Search options={searchOptions} onConfirm={go}/>
        <InnerContainer>
          <FormItemStyled label={<FormItemLabel>省市</FormItemLabel>} labelCol={{span: 3}} wrapperCol={{span: 21}}
                          colon={true}>
            <Select options={provinceOptions} itemStyle={itemStyle} onChange={go}/>
          </FormItemStyled>
          <FormItemStyled label={<FormItemLabel>单列</FormItemLabel>} labelCol={{span: 3}} wrapperCol={{span: 21}}
                          colon={true}>
            <Select options={cityOptions} itemStyle={itemStyle} onChange={go}/>
          </FormItemStyled>
          <Map data={data?.mapAreas} onClick={go}/>
        </InnerContainer>
      </Container>
    </PageContainer>

  )
}

export default AreaSelect;