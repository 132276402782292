import { provinceValidatorApi } from '@/api';
import { encryptedParams } from '@/utils';
import { message } from 'antd';
import { get } from 'lodash';
import taxHelperStore from '../taxHelper';

class ShandongOld {

  taxAuth({ formData, options }) {
    console.log('formData: ', formData);
    const { companyName, taxAccount, taxDeclareProvince, taxPassword } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const { verifyCode } = options;
    const params = {
      companyName,
      province,
      serialNo,
      username: taxAccount,
      taxDeclareProvince,
      creditCode: nsrsbh,
      password: taxPassword,
      verifyCode,
    };
    return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password']))
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  };
}

export default new ShandongOld();
