import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import { message } from "antd";
import { get } from "lodash";
import taxHelperStore from '../taxHelper';

const BSR_TYPE_DICT = {
  BSRYDDH: '办税人',
  BSRFLXDH: '办税人',
  BYHLXDH: '办税人',
  GPRLXDH: '发票领用人',
  CWFZRYDDH: '财务负责人',
  FDDBRYDDH: '法定代表人'
};

const getBsrSourceList = (bsrKeyList, bsrs) => {
  const output = [];
  bsrKeyList
    .map(key => ({ type: key, source: bsrs[key]}))
    .filter(item => item.source)
    .forEach(item => {
      const { source, type } = item;
      if (type === 'BSRFLXDH') {
        if (source && source.length > 0) {
          source.forEach(sitem => {
            const phone = sitem.BSRLXDH;
            const label = BSR_TYPE_DICT[type];
            output.push({
              source: sitem,
              type,
              label,
              phone,
              value: sitem.BSRLXDH_XH
            });
          });
        }
        return false;
      }
      if (type === 'BYHLXDH') {
        if (source && source.length > 0) {
          source.forEach((sitem) => {
            const phone = sitem.LXDH;
            const label = BSR_TYPE_DICT[type];
            output.push({
              source: sitem,
              type,
              label,
              phone
            });
          });
        }
        return false;
      }
      if (type === 'GPRLXDH') {
        if (source && source.length > 0) {
          source.forEach((sitem) => {
            const phone = sitem.LXDH;
            const label = BSR_TYPE_DICT[type];
            output.push({
              source: sitem,
              type,
              label,
              phone,
              value: sitem.LXDH_XH
            });
          })
        }
        return false;
      }
      if (type === 'STATUS') {
        return false;
      }
      const phone = source;
      const label = BSR_TYPE_DICT[type];
      output.push({ source, type, label, phone, value: '' });
    });
  return output;
};

/** 处理返回的办税员列表数据 */
const formatBsrsList = (bsrs) => {
  const bsrKeyList = Object.keys(bsrs);
  const bsrSourceList = getBsrSourceList(bsrKeyList, bsrs);
  return bsrSourceList;
};

/** 广西旧版 */
class GuangxiOld {

  bsrsResp = {};
  bsrs = [];
  bsrsList = [];

  captchaResp = {};

   /** 获取办税员列表 */
  getRoleList = (params) =>  {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const bsrsResp = get(resp, 'data.data.data') || {};
        this.bsrsResp = bsrsResp;
        const bsrs = formatBsrsList(bsrsResp);
        this.bsrs = bsrs;
        const bsrsList = bsrs.map((item, idx) => {
          const { label, phone } = item;
          return {
            value: idx,
            label: `${label}（${phone}）`,
          }
        });
        this.bsrsList = bsrsList;
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '获取登录角色失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  };

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData }) => {
    const { bsr } = formData;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const { taxAccount, taxPassword } = stepOneFormData;
    const { type, phone, value } = selectedBsr;
    const params = {
      serialNo,
      province,
      creditCode: nsrsbh,
      userName: taxAccount,
      userPass: taxPassword,
      phoneSeq: value,
      sfmc: type
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName' , 'userPass']))
      .then((res) => {
        this.captchaResp = get(res, "data.data")
        return res;
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { captcha } = formData;
    const { nsrsbh, serialNo } = taxHelperStore.authQueryParams;
    const { sflx, xh, dx_xh, sessionid } = this.captchaResp;
    const params = {
      xh,
      sflx,
      captcha,
      dxXh: dx_xh,
      sessionId: sessionid,
      creditCode: nsrsbh, 
    };
    return provinceValidatorApi.taxAuthCaptchaCommit(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

};

export default new GuangxiOld();
