import React from 'react';
import { Input, } from 'antd';

import styles from './index.less';

const CompanyInput = ({size, placeholder, defaultValue, disabled, value, onChange, creditCode}) => {
  return (
    <div>
      <Input
        size={size}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      {creditCode && <div className={styles.taxNotice}>统一社会信用代码：{creditCode}</div>}
    </div>
  );
}

export default CompanyInput;
