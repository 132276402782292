// 电子税局类型的税局表单的配置
export const electronTaxComponentConfigMap = new Map([
  ['zhe_jiang', ['taxDeclareProvince', 'qymc', 'taxAccount', 'taxPassword',]],
  ['guang_dong', ['taxDeclareProvince', 'qymc', { name: 'taxAccount', options: { placeholder: '请输入用户名/实名手机号码' } }, 'taxPassword',]],
  ['an_hui', ['taxDeclareProvince', 'qymc', 'taxAccount', 'taxPassword',]],
  ['shan_dong', ['taxDeclareProvince', 'qymc', 'phoneIDCardCode', 'taxPassword',]],
  ['hu_bei', ['taxDeclareProvince', 'qymc', {
    name: 'taxAccount',
    options: {
      label: '电子税务局查询账号（个人账号非企业账号）',
      placeholder: '请输入身份证号/手机号/用户名'
    }
  },
    {
      name: 'taxPassword',
      options: {
        placeholder: '请输入个人用户密码'
      }
    }
  ]],
]);


// [旧版]电子税局类型的税局表单的配置
export const old_electronTaxComponentConfigMap = new Map([
  ['guang_dong', ['taxDeclareProvince', 'qymc', { name: 'taxAccount', options: { placeholder: '请输入用户名/实名手机号码' } }, 'taxPassword',]],
  ['hu_bei', ['taxDeclareProvince', 'qymc', {name: 'taxAccount', options: { placeholder: '请输入社会信用代码/用户名' }}, 'taxPassword', 'hubeiIdentity']],
  ['zhe_jiang', ['taxDeclareProvince', 'qymc', 'taxAccount', 'taxPassword',]],
  ['tian_jin', ['taxDeclareProvince', 'qymc', 'taxAccount', 'taxPassword']],
]);

// 插件类型的税局表单的配置
export const pluginTaxComponentConfigMap = new Map([
  ['DX', ['qymc', 'nsrsbh',]],
  // ['CS', ['qymc', 'nsrsbh', 'spsb']], /** spsb  税盘类型 */
  ['CS', ['qymc', 'nsrsbh', 'spsb']], /** spsb  税盘类型 */
  ['WFQ', ['qymc', 'nsrsbh',]],
  ['PRO', ['qymc', 'nsrsbh', 'spsb']],
  ['PLUS', ['qymc', 'nsrsbh', 'spsb']],
  // 电子税局 + 采集插件类型时， 当电子税局通道关闭，按以下规则进行
  ['DZ_DX', ['qymc', 'nsrsbh',]],
  ['DZ_CS', ['qymc', 'nsrsbh', 'spsb']],
  ['DZ_WFQ', ['qymc', 'nsrsbh',]],
  ['DZ_PRO', ['qymc', 'nsrsbh', 'spsb']],
  ['DZ_PLUS', ['qymc', 'nsrsbh', 'spsb']]
]);
