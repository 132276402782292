import { v4 as uuidv4 } from 'uuid';
import { encrypted } from './aesCrypted';
import { checkIeBrowser } from './mobxFactory';
import { message } from 'antd';

/**
 * 动态加载脚本
 * @param scripturl 脚本地址 
 * @returns Promise
 */
export function loadScript(scripturl) {
  return new Promise((resolve, reject) => {
    try {
      const scriptEle = document.createElement('script');
      scriptEle.setAttribute('src', scripturl);
      scriptEle.setAttribute('type', 'text/javascript');
      scriptEle.addEventListener('load', () => {
        resolve(scriptEle);
      });
      document.body.appendChild(scriptEle);
    } catch (error) {
      reject(error);
    }
  });
}

export function loadStyle(cssurl) {
  return new Promise((resolve, reject) => {
    try {
      const linkEle = document.createElement('link');
      linkEle.setAttribute('href', cssurl);
      linkEle.setAttribute('rel', 'stylesheet');
      linkEle.addEventListener('load', () => {
        resolve(linkEle);
      });
      document.body.appendChild(linkEle);
    } catch (error) {
      reject(error);
    }
  });
}

export const checkPhoneBrowser = () => {
  const ua = navigator.userAgent;
  const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'iPad'];
  for (let i = 0, len = agents.length; i < len; i++) {
    if (ua.indexOf(agents[i]) > 0) {
      // 如果找到一个手机标识, 就设置为false并跳出循环
      return true;
    }
  }
  return false;
}

// 判断是否是ie浏览器
export const isIE = () => {
  // const userAgent = navigator.userAgent;
  // const isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
  // const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  return checkIeBrowser(11);
}

export const encryptedParams = (params, keys) => {
  const uuid = uuidv4();
  const enParams = {};
  keys.forEach((key) => {
    if (params.hasOwnProperty(key)) {
      enParams[key] = encrypted(params[key], uuid);
    }
  });
  return { ...params, ...enParams, scuuid: uuid };
};

export const linkTo = (url) => {
  const exeTag = 'sc-exe-tmp-cls';
  const element = document.querySelector(exeTag) || document.createElement('a');
  element.className = exeTag;
  element.href = url;
  document.body.append(element);
  // 兼容火狐浏览器
  if (element.click) {
    element.click();
  } else if (element.fireEvent) {
    element.fireEvent('onclick')
  } else if (document.createEvent) {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, true);
    element.dispatchEvent(evt);
  };
}


export const downloadFile = (url, target = '_self') => {
  linkTo(url);

}

let timeout = null;

/**
 * 
 * @param {*} reqP: Promise 轮询请求
 * @param {*} success: function 轮询成功后执行回调
 * @param {*} fail: function 轮询结束后失败的回调
 * @param {*} option: {} option.eachSecond 每次间隔时间，
 * option.maxTime 轮询持续次数
 * option.maxSecond。
 * option.maxTime 和 option.maxSecond 任意一个达到条件轮询停止
 */
export const looprquest = (reqP, success, fail, option) => {
  const { eachSecond, maxTime, maxSecond } = option;
  const startTime = new Date().getTime();
  timeout = setTimeout(() => {
    reqP()
      .then(data => {
        success(data);
      })
      .catch((error) => {
        const { outLopp } = error;
        if (outLopp) {
          // fail(error);
          return false;
        }
        if (maxTime > 1) {
          const endTime = new Date().getTime();
          if ((startTime + maxSecond) > endTime) {
            const _maxTime = maxTime - 1;
            const _maxSecond = maxSecond - (endTime - startTime)
            looprquest(reqP, success, fail, { ...option, maxTime: _maxTime, maxSecond: _maxSecond });
          } else {
            fail(error);
          }
        } else {
          fail(error);
        }
      });
  }, eachSecond);
  return timeout;
}

export const stopLooprquest = () => {
  if (timeout) {
    clearTimeout(timeout);
  }
}

/** 数组去重 */
export const unique = (arr, compare) => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  const newArray = [];
  for (let i = 0; i < arr.length; i++) {
    const comItem = arr[i];
    const comArr = arr.slice(i + 1);
    const hasItem = comArr.some(item => compare(item, comItem));
    if (!hasItem) {
      newArray.push(comItem);
    }
  }
  return newArray;
}

export const gotoCburl = (cburl, msg = '授权成功，即将跳转...') => {
  message.info(msg);
  setTimeout(() => {
    window.location.href = cburl;
  }, 3000);
}

/**
 * 刷新页面
 */
export const reload = () => {
  window.location.reload()
}

/**
 * 将对象的键名由下划线改为小驼峰命名
 * @param {Object} obj 
 * @returns 
 */
export function convertToCamelCase(obj) {
  let newObj = {};
  for (let key in obj) {
    let newKey = key.replace(/_([a-z])/g, function (match, p1) {
      return p1.toUpperCase();
    });
    newObj[newKey] = obj[key];
  }
  return newObj;
}

/**
 * 判断是否为移动端设备，是则返回true
 */
export function isMobileDevice() {
  // 这里简单地检查User-Agent中是否包含常见的移动设备关键词
  const mobileKeywords = ['Android', 'iPhone', 'iPad', 'iPod', 'Windows Phone', 'MQQBrowser'];
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  for (let keyword of mobileKeywords) {
    if (userAgent.indexOf(keyword) !== -1) {
      return true; // 如果User-Agent中包含任意一个关键词，则认为是移动设备
    }
  }
  return false; // 否则认为是PC端
}

/**
 * 用屏幕宽度推断是否为“移动端”
 */
export function isMobileScreenWidth() {
  // 获取当前窗口的宽度
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  // 如果屏幕宽度小于等于768像素，则认为是“移动端”
  // 注意：这个阈值可以根据你的具体需求进行调整
  const mobileScreenWidth = 768;
  return screenWidth <= mobileScreenWidth;
}