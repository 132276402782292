import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FORM_STEP_MAP } from '../helper/data';
import { looprquest, stopLooprquest } from '@/utils';
import useProvinceStore from "@/hooks/useProvinceStore";

import styles from './index.module.less';
import { message } from 'antd';

const INVALID_TIME = 10 * 60 * 1000;

const ExtraComponent = (props) => {
  const { isProvince, formStep, taxHelperStore, onFinishAuth, changeFormStep } = props;

  const provinceKey = taxHelperStore.getProvincePingYing();
  const provinceStore = useProvinceStore(provinceKey);
  const { qrCode } = provinceStore;

  /** 轮询检查二维码授权结果 */
  const checkQrCodeAuthStatus = () => {
    const { serialNo } = taxHelperStore.authQueryParams;
    const loopHandle = looprquest(
      provinceStore.checkShangdongTaxAuthStatus.bind(null, serialNo),
      data => {
        onFinishAuth();
      },
      error => {
        message.info('二维码即将失效，请重新授权获取二维码', 5);
        changeFormStep(FORM_STEP_MAP.get('first'));
      },
      {
        eachSecond: 3000,
        maxTime: 100,
        maxSecond: INVALID_TIME,
      }
    );
    return loopHandle;
  };

  useEffect(() => {
    if (isProvince('山东')) {
      const loopHandle = checkQrCodeAuthStatus();
      return () => {
        stopLooprquest(loopHandle);
      };
    }
  }, []);

  if (isProvince('山东') && formStep === FORM_STEP_MAP.get('second')) {
    return (
      <div className={styles.content}>
        <div className={styles.imgWrap}>
          <img src={qrCode} alt="qr" />
        </div>
        <div className={styles.desc}>
          <div>1. 长按识别或截图转发，由法人使用微信扫一扫授权</div>
          <div>2. 授权后，请保持或回退到当前页面，等待结果通知</div>
        </div>
      </div>
    );
  }
  return null;
};

export default inject(
  'taxHelperStore'
)(observer(ExtraComponent));
