import React from 'react';
import { loadScript, loadStyle } from "@/utils";
import { delay } from 'lodash';
import { Modal } from 'antd';
import loadingImg from '@/assets/images/anhui/loading.png';

class Guizhou extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
      modalWidth: 500,
    };
  }

  captchaRef = React.createRef();

  componentDidMount() {
    this.addScripts()
    .then(() => {
      const width = window.$('body').width();
      if (width < this.state.modalWidth) {
        this.setState({ modalWidth: width - 40 })
      };
    })
    .catch();
  }

  addScripts = () => {
    const jqueryScriptUrl = process.env.PUBLIC_URL + '/jquery.min.js';
    const mloadingScriptUrl = process.env.PUBLIC_URL + '/statics/guizhou/mloading.js';
    
    const mloadingStyle = process.env.PUBLIC_URL + '/statics/guizhou/mloading.css';

    return loadScript(jqueryScriptUrl)
      .then(() => {
        window.getCaptchaUrl = "/api/dbc/taxation/gzCaptchaGet";
        window.checkCaptchaUrl = "/api/dbc/taxation/gzCaptchaCheck";
        window.openVoice = 'Y';
        Promise.all([
          loadScript(mloadingScriptUrl),
          loadScript('https://etax.guizhou.chinatax.gov.cn/sso/js/plugs/getOsBrower.js'),
          loadScript('https://etax.guizhou.chinatax.gov.cn/sso/js/js/sm4.js'),
          loadScript('https://etax.guizhou.chinatax.gov.cn/sso/resources4/tax-module/captcha/js/verify.js'),
          loadStyle(mloadingStyle)
        ])
      });
  }

  initRenderCaptcha = () => {
    const { modalWidth } = this.state;
    return new Promise((resolve, reject) => {
      const captchaEle = this.captchaRef.current;
      window.$(captchaEle).pointsVerify({
        mode:'pop',     
        containerId: "zhmmCode",
        imgSize : {       
          width: `${modalWidth - 80}px`,
          height: `${(modalWidth - 80) / 2}px`,
        },
        beforeCheck: () => { 
          var flag = true;
          return flag
        },
        success: (params) => {
          resolve(params);
          delay(() => {
            this.handleCancel()
          }, 300);
        },
        error: (error) => {
          // reject(error);
        }
      });
    })
  }

  handleCancel = () => {
    this.setState({ isShowModal: false });
  }

  _scpublicValidateFields = () => {
    /** 打开图片验证弹窗 */
    this.setState({ isShowModal: true });
    /** 设置loading */
    delay(() => {
      const backImg = window.$('.verify-img-panel .back-img');
      if (backImg.length > 0) {
        backImg.attr({src: loadingImg});
      }
    }, 100)

    return this.initRenderCaptcha();
  }
  
  render() {
    const { modalWidth, isShowModal } = this.state;
    return (
      <>
        <Modal
          visible={isShowModal}
          onCancel={this.handleCancel}
          width={modalWidth}
          maskClosable={false}
          closable={false}
          footer={null}
        >
          <div id="zhmmCode" ref={this.captchaRef}></div>
        </Modal>
      </>
    );
  }
}

export default Guizhou;
