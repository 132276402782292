// import { makeAutoObservable } from 'mobx';
import * as tradeApi from '@/api/trade';
import { get } from 'lodash';
import { message } from 'antd';
import { setSetTaxAxiosHeader } from '@/utils/taxAxios';
import { convertToCamelCase } from '@/utils';

class TradeStore {
  // constructor() {
  //   makeAutoObservable(this);
  // }

  hgUrlParams = {};

  /** 获取授权参数 */
  getHgUrlParam = (params) => {
    return tradeApi.getHgUrlParam(params)
      .then(resp => {
        /** 从response headers 中获取tax-key，配置到axios request headers 中 */
        const taxKey = get(resp, 'headers.tax-key') || '';
        if (taxKey) {
          setSetTaxAxiosHeader('tax-key', taxKey)
        }
        const data = get(resp, 'data.data') || {};
        const random = data?.data?.random;
        // 用里面的替换外面的
        if (random) {
          data.random = random;
        }
        this.hgUrlParams = data;
        return data;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        // const errorMessage = get(errorData, 'message') || '获取订单数据失败';
        // message.error(errorMessage);
        return Promise.reject(errorData);
      });
  }

  /** 登录授权 */
  auth = (params) => {
    const request = this.hgUrlParams.hgCollectType === 'SC' ? tradeApi.collectSwCrawl : tradeApi.auth
    return request({
      ...convertToCamelCase(this.hgUrlParams.data),
      ...params,
    })
      .catch(error => {
        const errorData = get(error, 'response.data');
        const errorMessage = get(errorData, 'message') || '授权失败';
        const errorCode = get(errorData, 'errorCode');
        if (errorCode === 409202) {
          return Promise.resolve();
        }
        message.error(errorMessage);
        return Promise.reject(errorData);
      });
  }

  /** 报关单状态查询 */
  checkeQueryStatus = (params) => {
    return tradeApi.getSwSignNos(params)
      .then(({ data }) => {
        return data.data
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        // const errorMessage = get(errorData, 'message') || '授权失败';
        // message.error(errorMessage);
        return Promise.reject(errorData);
      })
  }

  /** 上传报关单号加密数据 */
  postSwCrawlRest = (data) => {
    return tradeApi.postSwCrawlRest(data)
  }

  /** 报关单查询列表 */
  getCusQueryList = (params) => {
    return tradeApi.getCusQueryList(params)
      .then(resp => {
        const data = get(resp, 'data.data') || [];
        return data;
      })
      .catch(error => {
        const errorData = get(error, 'response.data');
        // const errorMessage = get(errorData, 'message') || '授权失败';
        // message.error(errorMessage);
        return Promise.reject(errorData);
      })
  }

  /** 报关单详情单条 */
  getDecDetailSingle = (params, serialNo) => {
    return tradeApi.getDecDetailSingle(params, serialNo)
      .catch (error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      })
  }

  /** 登录，cookice失效后登录 */
  hgLogin = (params) => {
    return tradeApi.hgLogin(params)
      .catch(error => {
        const errorData = get(error, 'response.data');
        return Promise.reject(errorData);
      })
  }
}

export default new TradeStore();
