import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, } from 'antd';
import AuthProtocol from  './AuthProtocol';

import styles from './index.less';

const CreateAuthProtocol = (props) => {
  const { isAgree, source, companyName } = props;
  const [authProVisible, setAuthProVisible] = React.useState(false);

  const [read, setRead] = React.useState(false);

  const handleAuthProtocol = (event) => {
    if (props.enableWatchScroll && !read) {
      props.onChange(false);
      setAuthProVisible(true);
      return null;
    }
    const { checked } = event.target;
    if (checked) {
      setAuthProVisible(true)
    } else {
      props.onChange(false);
    }
    // if (props.onChange) {
    //   props.onChange(event.target.checked);
    // }
    // if (props.onChange) {
    //   props.onChange(event.target.checked);
    // }
  }

  const handleCloseModal = (unreadProtocol) => {
    setRead(!unreadProtocol);
    setAuthProVisible(false);
    props.onChange(true);
  }

  const protocolTitle = () => {
    switch (source) {
      case 'RZ':
        return '《日照银行小微企业税务授权书》';
      default:
        break;
    }
  }

  return (
    <div>
      <div>
        <Checkbox checked={isAgree} onChange={handleAuthProtocol}>
          <span className={styles.unSelected}>我已阅读并同意</span>
        </Checkbox>
        <a className={styles.protocolBtn} type="link" onClick={() => setAuthProVisible(true)}>{protocolTitle()}《企业信息收集及使用授权书》</a>
      </div>
      {props.children || null}
      <AuthProtocol
        companyName={companyName}
        enableWatchScroll={props.enableWatchScroll}
        visible={authProVisible}
        source={source}
        closeModal={handleCloseModal}
        onOk={() => {
          setAuthProVisible(false)
          props.onChange?.(true)
        }}
      />
    </div>
  );
}

export default observer(CreateAuthProtocol);
