import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'antd';
import { checkPhoneBrowser } from '@/utils';
import HLProtocolContent from './HLProtocolContent';
import RZProtocolContent from './RZProtocolContent';
import styles from './index.less';

const isMobile = checkPhoneBrowser();

const AuthProtocol = ({ visible, closeModal, source, enableWatchScroll, companyName }) => {

  const [unread, setUnread] = useState(enableWatchScroll || false);
  const [time, setReadTime] = useState(5);
  const timerIntervalRef = useRef(null);
  const modalContentRef = useRef(null);

  const loopTimer = (currentTime) => {
    clearInterval(timerIntervalRef.current);
    timerIntervalRef.current = setTimeout(() => {
      if (currentTime > 0) {
        setReadTime(currentTime - 1);
        loopTimer(currentTime - 1);
      } else {
        clearInterval(timerIntervalRef.current);
      }
    }, 1000)
  }

  useEffect(() => {

    return () => {
      clearInterval(timerIntervalRef.current);
    }
  }, [visible]);

  const handleOnScroll = (event) => {
    if (!enableWatchScroll) {
      return null;
    }
    checkBrowseToBottom(event.target, time);
  }


  const checkBrowseToBottom = (element, currentTime) => {
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const offsetHeight = element.offsetHeight;
    const bottomDistance = scrollHeight - offsetHeight - scrollTop;
    // 距离底部允许有一定的误差
    if (bottomDistance < 10) {
      setUnread(false);
      loopTimer(currentTime);
    }
  }

  const handleCloseModal = () => {
    // 如果启用了需要观看到底部，才能够关闭弹窗，则不予关闭
    if (enableWatchScroll && unread) {
      return null;
    }
    closeModal(unread);
  }

  const renderProtocolContent = () => {
    switch (source) {
      case 'RZ':
        return (
          <div>
            <h2 className={styles.title}>《日照银行小微企业税务授权书》</h2>
            <RZProtocolContent />
            <h2 className={styles.title}>《企业信息收集及使用授权书》</h2>
            <HLProtocolContent companyName={companyName} />
          </div>
        );  
      default:
        break;
    }
  }

  const protocolTitle = () => {
    switch (source) {
      case 'RZ':
        return '日照银行小微企业税务授权书';
      default:
        return '授权协议';
    }
  }

  const shouldDisabled = unread || time > 0;
  const modalStyle = isMobile ? { top: 0, margin: 0, maxWidth: '100%' } : { top: 30 };

  return (
    <Modal
      visible={visible}
      title={protocolTitle()}
      style={modalStyle}
      width="1000px"
      maskClosable={false}
      closable={false}
      wrapClassName={styles.modalWrapper}
      onCancel={() => closeModal(shouldDisabled)}
      footer={[
        <Button
          key="authprotocol1"
          type="primary"
          disabled={shouldDisabled}
          onClick={handleCloseModal}>
          {!unread && time > 0 ? `${time}s ` : ''}确认并关闭
        </Button>
      ]}
    >
      <div ref={modalContentRef} className={styles.content} onScroll={handleOnScroll}>
        { renderProtocolContent() }
      </div>
    </Modal>
  );
};

export default AuthProtocol;
