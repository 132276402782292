import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import FormWrapper from '../FormWrapper';

const TextInput = (props) => {
  const { item } = props;
  const { TextArea } = Input;
  const handleInputType = () => {
    if (['textArea'].includes(item.type)) {
      return <TextArea {...item.inputProps} />;
    }
    return <Input {...item.inputProps} />;
  };
  return (
    <FormWrapper {...props}>
      {handleInputType()}
    </FormWrapper>
  );
}

// item 下更多其他配置参考 FormWrapper.propTypes
TextInput.propTypes = {
  item: PropTypes.object,
};

export default TextInput;
