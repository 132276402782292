import { Form, Input } from 'antd';

const CreditsCodeFormItem = ({ inputSize }) => {
  return (
    <Form.Item
      key="nsrsbh"
      colon={false}
      label="纳税人识别编号："
      name="nsrsbh"
      rules={[{ required: true, message: '请输入纳税人识别编号' }]}
    >
      <Input size={inputSize} disabled placeholder="请输入纳税人识别编号" />
    </Form.Item>
  );
}

export default CreditsCodeFormItem;
