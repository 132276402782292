import React from 'react';
import { Button } from 'antd';
import { observer, inject } from 'mobx-react'
import { FORM_STEP_MAP } from '../helper/data';
import { AUTH_METHOD_TYPE_MAP } from '@/utils/taxUtil/dict';
import styles from './index.less'
import { reload } from '@/utils/index';
import {BigButton, Icon} from "@/pages/guide/component/Button.styled";
import SwitchVersion from '@/components/SwitchVersion';

const TaxFormButton = ({ formStep, btnOption, isProvince, taxHelperStore, authMethodType, NEW_AUTH }) => {

  const btnText = () => {
    const taxType = taxHelperStore.getTaxType();
    let provinceList = [ '广东', '浙江'];
    // 旧版
    if (!NEW_AUTH) {
      provinceList = provinceList.concat(['山东', '重庆', '湖南', '辽宁'])
    }

    if (isProvince(provinceList)) {
      return '确认';
    }
    if (formStep === FORM_STEP_MAP.get('first')) {
      return '下一步';
    }
    if (['DZ_ANO', 'DZ_WFQ'].includes(taxType)) {
      return '下一步';
    }
    return '确认';
  };
  return (
    <div style={{marginTop: '34px'}}>
      <BigButton icon={<Icon src="/statics/guide/3.png" />} {...btnOption}>{btnText()}</BigButton>
      <SwitchVersion />
    </div>
  );
}

export default inject(
  'taxHelperStore'
)(observer(TaxFormButton));