/** 授权方式字典 */
export const AUTH_TYPE_DICT = {
  /** 电子税局 + 软件 */
  ELECTRON_PLUGIN: 'ELECTRON_PLUGIN',
  /** 电子税局 */
  ELECTRON: 'ELECTRON',
  /** 软件 */
  PLUGIN: 'PLUGIN',
  /** 都没有 */
  NONE: 'NONE',
}

/** 税局异常码 */
export const TAX_ERROR_DICT = {
  DEFAULT: {code: 1099, desc: '请联系管理员或返回重试'},
  ORDER_EXPIRE: {code: 1098, desc: '链接已失效，请重新获取'},
  QUERY_EXPIRE: {code: 1097, desc: '链接参数解析错误，请重新获取'},
  TAX_PROVINCE_UNAVAILABLE: {code: 1000, desc: '税局区域不可用'},
  TAX_STATUS_UNAVAILABLE: {code: 1001, desc: '税局通道已关闭'},
  PLUGIN_STATUS_UNAVAILABLE: {code: 1002, desc: '软件通道已关闭'},
  PROVINCE_AND_PLUGIN_STATUS_UNAVAILABLE: {code: 1003, desc: '税局区域不可用，软件通道已关闭'},
  TAX_AND_PLUGIN_STATUS_UNAVAILABLE: {code: 1004, desc: '税局通道、软件通道已关闭'},
  PROVINCE_AND_TAX_AND_PLUGIN_STATUS_UNAVAILABLE: {code: 1005, desc: '税局区域不可用，税局通道、软件通道已关闭'},
};

/** 省会地区映射 */
export const PROVINCE_MAP = new Map([
  ['黑龙江', { name: '黑龙江省', shortName: '哈尔滨', py: 'hei_long_jiang', code: '230000'}],
  ['河北', { name: '河北省', shortName: '石家庄', py: 'he_bei', code: '130000'}],
  ['甘肃', { name: '甘肃省', shortName: '兰州', py: 'gan_su', code: '620000'}],
  ['云南', { name: '云南省', shortName: '昆明', py: 'yun_nan', code: '530000'}],
  ['四川', { name: '四川省', shortName: '成都', py: 'si_chuan', code: '510000'}],
  ['吉林', { name: '吉林省', shortName: '长春', py: 'ji_lin', code: '220000'}],
  ['辽宁', { name: '辽宁省', shortName: '沈阳', py: 'liao_ning', code: '210000'}],
  ['青海', { name: '青海省', shortName: '西宁', py: 'qing_hai', code: '630000'}],
  ['陕西', { name: '陕西省', shortName: '西安', py: 'shan_xi', code: '610000'}],
  ['河南', { name: '河南省', shortName: '郑州', py: 'he_nan', code: '410000'}],
  ['山东', { name: '山东省', shortName: '济南', py: 'shan_dong', code: '370000'}],
  ['山西', { name: '山西省', shortName: '太原', py: 'shan_xi_taiyuan', code: '140000'}],
  ['安徽', { name: '安徽省', shortName: '合肥', py: 'an_hui', code: '340000'}],
  ['湖北', { name: '湖北省', shortName: '武汉', py: 'hu_bei', code: '420000'}],
  ['湖南', { name: '湖南省', shortName: '长沙', py: 'hu_nan', code: '430000'}],
  ['江苏', { name: '江苏省', shortName: '南京', py: 'jiang_su', code: '320000'}],
  ['贵州', { name: '贵州省', shortName: '贵阳', py: 'gui_zhou', code: '520000'}],
  ['浙江', { name: '浙江省', shortName: '杭州', py: 'zhe_jiang', code: '330000'}],
  ['江西', { name: '江西省', shortName: '南昌', py: 'jiang_xi', code: '360000'}],
  ['广东', { name: '广东省', shortName: '广州', py: 'guang_dong', code: '440000'}],
  ['福建', { name: '福建省', shortName: '福州', py: 'fu_jian', code: '350000'}],
  ['台湾', { name: '台湾省', shortName: '台北', py: 'tai_wan', code: ''}],
  ['海南', { name: '海南省', shortName: '海口', py: 'hai_nan', code: '460000'}],
  ['广西', { name: '广西', shortName: '南宁', py: 'guang_xi', code: '450000'}],
  ['内蒙古', { name: '内蒙古', shortName: '呼和浩特', py: 'nei_meng_gu', code: '150000'}],
  ['宁夏', { name: '宁夏回族自治区', shortName: '银川', py: 'ning_xia', code: '640000'}],
  ['新疆', { name: '新疆', shortName: '乌鲁木齐', py: 'xin_jiang', code: '650000'}],
  ['西藏', { name: '西藏', shortName: '拉萨', py: 'xi_zang', code: '540000'}],
  ['澳门特别行政区', { name: '澳门特别行政区', shortName: '澳门', py: 'ao_men', code: ''}],
  ['北京', { name: '北京市', shortName: '北京', py: 'bei_jing', code: '110000'}],
  ['上海', { name: '上海市', shortName: '上海', py: 'shang_hai', code: '310000'}],
  ['香港特别行政区', { name: '香港特别行政区', shortName: '香港', py: 'xiang_gang', code: '810000'}],
  ['天津', { name: '天津市', shortName: '天津', py: 'tian_jin', code: '120000'}],
  ['重庆', { name: '重庆市', shortName: '重庆', py: 'chong_qing', code: '500000'}],
  ['青岛', { name: '青岛市', shortName: '青岛', py: 'qing_dao', code: '3702'}],
  ['厦门', { name: '厦门市', shortName: '厦门', py: 'xia_men', code: ''}],
  ['深圳', { name: '深圳市', shortName: '深圳', py: 'shen_zhen', code: ''}],
  ['宁波', { name: '宁波市', shortName: '宁波', py: 'ning_bo', code: ''}],
  ['大连', { name: '大连市', shortName: '大连', py: 'da_lian', code: ''}],
]);

/** 税局类型标识 */
export const TAX_TYPE_DICT = new Map([
  /** code: 标识码，desc：描述 */
  ['DZ_SJ', {code: 101, desc: '电子税务局'}],
  ['DX', {code: 201, desc: '大象'}],
  ['WFQ', {code: 202, desc: '微风企'}],
  ['CS', {code: 203, desc: '长沙'}],
  ['AISINO', {code: 204, desc: '爱信诺'}],
  ['PRO', {code: 205, desc: 'PRO'}],
  ['PLUS', {code: 206, desc: 'PRO_PLUS'}],
  ['DZ_DX', {code: 301, desc: '电子税务局,大象'}],
  ['DZ_WFQ', {code: 302, desc: '电子税务局,微风企'}],
  ['DZ_CS', {code: 303, desc: '电子税务局,长沙'}],
  ['DZ_ANO', {code: 304, desc: '电子税务局,爱信诺'}],
  ['DZ_PRO', {code: 305, desc: '电子税务局,Pro'}],
  ['DZ_PLUS', {code: 306, desc: '电子税务局，PRO_PLUS'}]
]);

/** 需要优先下载软件的类型 */
export const DOWNLOAD_PLUGIN_TYPE = ['CS', 'DZ_CS', 'AISINO', 'DZ_ANO'];

/** 需要提示用户快速授权的软件 */
export const NEED_QUICK_AUTHORIZE_TYPE = ['DZ_SJ', 'DX', 'WFQ', 'CS', 'PRO', 'PLUS', 'DZ_DX', 'DZ_WFQ', 'DZ_CS', 'DZ_PRO', 'DZ_PLUS'];

/** 授权表单需要 开票软件设备的类型 */
export const NEED_SPSB_TYPE = ['PRO', 'DZ_PRO', 'PLUS', 'DZ_PLUS', 'DZ_CS', 'CS'];

/** 如果是插件授权或是电子税局加插件授权则不能在手机上操作，提示到电脑端操作的类型 */
export const CAN_ONT_ON_MOBILE_TYPE = ['DZ_DX', 'DZ_WFQ', 'DZ_CS', 'DZ_PRO', 'DZ_PLUS'];

export const AUTH_METHOD_TYPE_MAP = new Map([
  [ 'SMS', 'SMS' ], // 账密授权
  [ 'PWD', 'PWD' ], // 密码授权
  [ 'QR', 'QR' ],   // 二维码授权
  [ 'WeChat', 'WeChat' ],  // 微信授权
]);
