import { provinceValidatorApi } from "@/api";
import { encryptedParams } from "@/utils";
import { showErrorMessage } from "@/utils/taxUtil";
import { get } from "lodash";
import taxHelperStore from '../taxHelper';


class Chongqing {

  bsrs = [];
  bsrsList = [];

  smscodeId = '';

  /** 获取办税员列表 */
  getRoleList = (data) => {
    const params = {
      ...data,
      authentication: 'tpass.chongqing.chinatax.gov.cn'
    }
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const { mobile, uuid } = get(resp, 'data.data.data');
        this.bsrs = [{ mobile, uuid }];
        this.bsrsList = [{ value: 0, label: mobile }];
      })
      .catch(error => {
        showErrorMessage(error, '获取办税员失败');
        return Promise.reject(error);
      });
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData }) => {
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const { bsr } = formData;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { uuid } = selectedBsr;
    const params = {
      serialNo,
      province,
      uuid,
      creditCode: nsrsbh,
      authentication: 'tpass.chongqing.chinatax.gov.cn'
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(params)
      .then(resp => {
        const data = get(resp, 'data.data') || {};
        this.smscodeId = data['smscode_id'];
      })
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      })
  }

  taxAuth = ({ formData }) => {
    const { captcha, bsr } = formData;
    const { nsrsbh, serialNo, province } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const { uuid } = selectedBsr;
    const params = {
      province,
      smscodeId: this.smscodeId,
      mobileCode: captcha,
      creditCode: nsrsbh,
      uuid,
      authentication: 'tpass.chongqing.chinatax.gov.cn'
    };
    return provinceValidatorApi.passwordAuth(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

};

export default new Chongqing();
