import { Component } from 'react';
// import { Tabs } from 'antd';
import QrCodeAuth from './QrCodeAuth';

import styles from './index.less';

// const { TabPane } = Tabs;

class AnhuiEleAuthWrap extends Component {
  render() {
    const { NEW_AUTH, onFinishAuth } = this.props
    return (
      <div className={styles.form}>
        <QrCodeAuth onFinishAuth={onFinishAuth} NEW_AUTH={NEW_AUTH}/>
        {/* <Tabs defaultActiveKey="1">
          <TabPane tab="账密认证" key="1">
            {this.props.children}
          </TabPane>
          <TabPane tab="扫码认证" key="2">
            <QrCodeAuth onFinishAuth={onFinishAuth} />
          </TabPane>
        </Tabs> */}
      </div>
    );
  }
}

export default AnhuiEleAuthWrap;
