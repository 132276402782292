import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import { unique, encryptedParams } from '@/utils';
import { YUNNAN_BSR_TYPE_MAP } from './dict';
import taxHelperStore from '../taxHelper';

/** 云南旧版 */
class YunnanOld {

  /** 办税员列表 */
  bsrs = [];

  bsrsList = []

  /** 获取办税员列表  */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const data = get(resp, 'data.data.data', []) || [];
        const bsrs = Object.keys(data).map(key => {
          return data[key];
        });
        const bsrsFilter = unique(bsrs, (item1, item2) => {
          return item1.xm === item2.xm && item1.sjhm === item2.sjhm && item1.sfzjhm === item2.sfzjhm && item1.type !== item2.type;
        })
        this.bsrs = bsrsFilter.map(item => {
          return {
            value: item.uuid,
            label: `${YUNNAN_BSR_TYPE_MAP.get(item.type)}/${item.xm || ' - '}/${item.sjhm || ' - '}/${item.sfzjhm || ' - '}`
          };
        });

        this.bsrsList = bsrsFilter.map(item => {
          return {
            value: item.uuid,
            label: `${YUNNAN_BSR_TYPE_MAP.get(item.type)}/${item.xm || ' - '}/${item.sjhm || ' - '}/${item.sfzjhm || ' - '}`
          };
        });
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '获取办税员失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { bsrPassword, bsr } = formData;
    const { nsrsbh, qymc, serialNo, province } = taxHelperStore.authQueryParams;
    const params = {
      companyName: qymc,
      username: bsr,
      creditCode: nsrsbh,
      password: bsrPassword,
      serialNo,
      province
    };

    return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password']))
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      });
  }

}

export default new YunnanOld();
