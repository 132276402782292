import React from 'react';
import { observer } from 'mobx-react';

import Header from '../header';
// import Footer from '../footer';
import Content from '../content';
import sdk from '@/components/hoc/sdk';

const AutoLayout = (props) => {
  if (props.isSDK || props.isFullPage) {
    return <>{props.children}</>
  }
  return (
    <>
      <Header />
      <Content>{props.children}</Content>
      {/* <Footer /> */}
    </>
  );
}

export default sdk()(observer(AutoLayout));
