import { provinceValidatorApi } from '@/api';
import taxHelperStore from '../taxHelper';
import { get } from 'lodash';
import { message } from 'antd';
import { showErrorMessage } from '@/utils/taxUtil';
import { encryptedParams } from '@/utils';

/** 北京旧版 */
class BeijingOld {

  /** 办税员列表 */
  bsrs = [];
  bsrsList = [];

  selectedBsr = {};

  /** 获取办税员列表 */
  getRoleList = (params) => {
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const bsrs = get(resp, 'data.data.data.data', []) || [];
        this.bsrs = bsrs;
        this.bsrsList = bsrs.map((item, idx) => {
          return {
            value: item.key,
            label: `${item.sfmc}/${item.xm}/${item.zjhm}`
          };
        })
      })
      .catch( error => {
        const errorMessage = get(error, 'response.data.message') || '获取办税员失败';
        message.error(errorMessage);
        return Promise.reject(error);
      });
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData }) => {
    const { bsr } = formData;
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find(item => item.key === bsr);
    this.selectedBsr = selectedBsr;
    const { key } = selectedBsr;
    const params = {
      serialNo,
      creditCode: nsrsbh,
      province,
      uuid: key
    };
    return provinceValidatorApi.taxAuthSendCaptcha2(params)
      .catch(error => {
        showErrorMessage(error, '发送授权验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData }) => {
    const { serialNo } = taxHelperStore.authQueryParams;
    const { captcha, bsr } = formData;
    const { nsrsbh } = taxHelperStore.authQueryParams;
    const { key } = this.selectedBsr;
    const params = { captcha, creditCode: nsrsbh, uuid: key };
    return provinceValidatorApi.beijinCaptchaAuth(serialNo, params)
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

};

export default new BeijingOld();
