import { forwardRef, Children, cloneElement, useState } from 'react';
import { Checkbox } from 'antd';

const DynamicCheckbox = forwardRef((props, ref) => {
  const { children, transform, onChange } = props;
  const [value, setValue] = useState('');
  const handleChange = (value) => {
    let newValue = value;
    if (transform) {
      newValue = transform(value);
    }
    onChange?.(newValue);
    setValue(newValue);
  };
  return (
    <Checkbox ref={ref} value={value}>
      {Children.map(children, (child) => {
        return cloneElement(child, { onChange: handleChange, value: value });
      })}
    </Checkbox>
  );
});

export default DynamicCheckbox;
