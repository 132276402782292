import axios from 'axios';
import config from '@/config';
import { taxAxios } from '@/utils/taxAxios';

const localUrl = config.dev ? 'http://localhost:8100/' : 'http://localhost:8100/';

/** 加密 */
export const encrypt = (params) => {
  return taxAxios.post('/api/dbc/taxation/aisino/encrypt', params);
}

/** 授权加密 */
export const authEncrypt = (params) => {
  return taxAxios.post('/api/dbc/taxation/aisino/auth/encrypt', params);
}

/** 解密 */
export const decrypt = (params) => {
  return taxAxios.post('/api/dbc/taxation/aisino/decrypt', params);
}

/** 获取平台商身份令牌（誉存->密钥->航信接口） **/
export const getServerPlatformAccessToken = (params) => {
  return taxAxios.get('/api/dbc/taxation/aisino/getYsztToken', { params });
}

/** 下单前信息查询（本地） **/
export const getLocalPrepareOrderInfo = (params) => {
  return axios.create({}).post(`${localUrl}51ys/base/prepareorder/info/query`, params)
}

/** 线上下单（誉存->密钥->航信接口） **/
export const requestServerSyncAddOrder = (params, accessToken) => {
  return taxAxios.post(`/api/dbc/taxation/aisino/orderBooking?accessToken=${accessToken}`, params);
}

/** 5. 获取客户身份令牌（誉存->密钥->航信接口） **/
/** 6. 获取发票数据查询结果（本地） **/
export const getLocalInvoiceData = (params) =>  axios.create({}).post(`${localUrl}51ys/kp/invoice/data/query`, params);

/** 7. 上传发票数据查询结果（誉存接口） **/
export const sendServerInvoiceData = (params) => taxAxios.post('/api/dbc/taxation/aisino/saveInvoiceData', params);

const thisAxios = () => {
  return axios.create({})
}

export const getLocalTest = () => thisAxios().post(`${localUrl}testData`);
export const getLocalTest2 = () => axios.post(`http://127.0.0.1:8100/testData`);
export const getLocalTest3 = () => axios.post(`http://sc-test:8100/testData`);
