import { useEffect, useState } from "react";
import styles from "./index.module.less";
import { Button, Modal } from "antd";

/** 手机号验证 */
export const phoneReg = /^1[3-9]\d{9}$/;

const Captcha = ({
  className = "",
  phone,
  needPhone = true,
  onSendCaptch,
  ...rest
}) => {
  const [count, setCount] = useState(0);
  const [loading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (count > 0 || loading) return;
    if (needPhone && !phoneReg.test(phone)) {
      Modal.error({title: "请输入正确的手机号"});
      return;
    }
    const params = { phone };
    setIsLoading(true);
    onSendCaptch?.(params)
      .then(() => {
        setCount(60);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const id = setInterval(() => {
      if (count <= 0) return;
      setCount(count - 1);
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [count]);


  if (count > 0) {
    return (
      <Button type="primary" className={styles.btn} {...rest}>
        {count}s后重新发送
      </Button>
    );
  }
  return (
    <Button loading={loading} type="primary" onClick={handleClick} className={styles.btn} {...rest}>
      获取验证码
    </Button>
  );
};

export default Captcha;
