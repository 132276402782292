import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';
import { showErrorMessage } from '@/utils/taxUtil';

/** 江苏（旧版） */
class JiangshuOld {

  bsrs = [];
  bsrsList = [];

  getRoleList = (data) => {
    const params = {
      ...data,
      nevalidate: data.captchaVerification || ''
    }
    return provinceValidatorApi.getBsyList(encryptedParams(params, ['userName', 'userPass']))
      .then(resp => {
        const bsrs = get(resp, 'data.data.data.info');
        this.bsrs = bsrs;
        this.bsrsList = bsrs.map((item, idx) => {
          return {
            ...item,
            value: idx,
            label: `${item.xm}/${item.tel}`
          }
        })
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '获取登录角色失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  /** 发送验证码 */
  sendCaptcha = ({ formData, stepOneFormData, options }) => {
    const { jiangshuImageCaptcha } = options;
    const { bsr } = formData;
    const { taxAccount, taxPassword, bsrType } = stepOneFormData;
    const { nsrsbh, province, serialNo } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const params = {
      serialNo: serialNo,
      userName: taxAccount,
      userPass: taxPassword,
      creditCode: nsrsbh,
      province,
      phoneNumber: selectedBsr.sjhm,
      rybz: selectedBsr.rybz,
      rylx: bsrType,
      xm: selectedBsr.xm,
      nevalidate: jiangshuImageCaptcha,
      authorizationType: 'SMS'
    };
    return  provinceValidatorApi.taxAuthSendCaptcha2(encryptedParams(params, ['userName', 'userPass']))
      .catch(error => {
        showErrorMessage(error, '发送验证码失败');
        return Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData, stepOneFormData, options }) => {
    const { bsr, captcha } = formData;
    const { jiangshuImageCaptcha } = options;
    const { taxAccount, taxPassword, bsrType } = stepOneFormData;
    const { serialNo, nsrsbh, province } = taxHelperStore.authQueryParams;
    const selectedBsr = this.bsrs.find((_, idx) => idx === bsr);
    const params = {
      serialNo,
      username: taxAccount,
      password: taxPassword,
      creditCode: nsrsbh,
      province,
      rybz: selectedBsr.rybz,
      xm: selectedBsr.xm,
      rylx: bsrType,
      sjhm: selectedBsr.sjhm,
      nevalidate: jiangshuImageCaptcha,
      verifyCode: captcha,
    };
    return provinceValidatorApi.taxAuthCaptchaCommit2(serialNo, encryptedParams(params, [ 'username', 'password' ]))
      .catch(error => {
        showErrorMessage(error, '授权失败');
        return Promise.reject(error);
      });
  }

}

export default new JiangshuOld();
