import React from 'react';
import { observer } from 'mobx-react';

import styles from './index.less';

const RizhaoTitle = ({ title }) => {
  return (
    <div className={styles.wrap}>
      <div><span className={styles.logo}></span></div>
      <div className={styles.border}></div>
      <div className={styles.titleBox}>
        <span className={styles.leftMark}></span>
        <span>{title || '填写被邀请企业的信息'}</span>
        <span className={styles.rightMark}></span>
      </div>
    </div>
  );
};

export default observer(RizhaoTitle);
