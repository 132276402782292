import { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Select, Input, Button, Cascader, message } from 'antd';
import Item from './Item';
import {
  taxpayerType,
  criterionType,
  getFiveYaer,
  financeTaxTotal,
  financeTaxSingle,
  vatTaxTotal,
  vatTaxSingle,
  incomeTaxTotal,
  incomeTaxSingle,
} from '../../dictionary';

import styles from './index.module.less';

const dateOptions = getFiveYaer();

const FormBox = (props) => {
  const { form, qyParams, selfFillingStore } = props;
  const { Option } = Select;

  const [isFSingle, setIsFSingle] = useState(false);
  const [isVSingle, setIsVSingle] = useState(false);
  const [isISingle, setIsISingle] = useState(false);

  const onFinish = (value) => {
    selfFillingStore.manualSubmit(value)
      .then(res => {
        form.resetFields();
      });
  }

  const handleDown = () => {
    const { exeStandardType, taxpayerType } = form.getFieldsValue();
    if (!exeStandardType || !taxpayerType) {
      message.warning('请先选择纳税人类型和执行会计准则！');
    } else {
      selfFillingStore.downloadTaxTmp({
        standardType: exeStandardType,
        taxpayerType
      }).then(url => {
        window.location.href = url;
      });
    }
  }

  const renderTop = () => {
    return (
      <div>
        <Form.Item label="企业名称" name="qymc" required>
          <Input disabled />
        </Form.Item>
        <p className={styles.code}>统一社会信用代码：{qyParams.nsrsbh}</p>
        <Form.Item label="纳税人类型" name="taxpayerType" rules={[{ required: true, message: '请选择纳税人类型' }]}>
          <Select placeholder="请选择纳税人类型">
            {
              Object.getOwnPropertyNames(taxpayerType).map((item, index) => (
                <Option key={index} value={item}>{taxpayerType[item]}</Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item label="执行会计准则" name="exeStandardType" rules={[{ required: true, message: '请选择会计准则' }]}>
          <Select placeholder="请选择会计准则">
            {
              Object.getOwnPropertyNames(criterionType).map((item, index) => (
                <Option key={index} value={item}>{criterionType[item]}</Option>
              ))
            }
          </Select>
        </Form.Item>
        <Button type="primary" size='large' onClick={handleDown} className={styles.downloadBtn}>下载模板</Button>
      </div>
    )
  }

  const handleFUpload = () => {
    setIsFSingle(!isFSingle);
  }
  const handleVUpload = () => {
    setIsVSingle(!isVSingle);
  }
  const handleIUpload = () => {
    setIsISingle(!isISingle);
  }

  const renderFinance = () => {
    let placeholder = isFSingle ? financeTaxTotal : financeTaxSingle;
    return (
      <Item
        label='财务报表'
        required={true}
        isSingle={isFSingle}
        handleUpload={handleFUpload}
        placeholder={placeholder}
        type='finace'
        form={form}
      />
    )
  }

  const renderVat = () => {
    let placeholder = isVSingle ? vatTaxTotal : vatTaxSingle;
    return (
      <Item
        label='增值税报表'
        required={true}
        isSingle={isVSingle}
        handleUpload={handleVUpload}
        placeholder={placeholder}
        type='finace'
        form={form}
      />
    )
  }

  const renderIncome = () => {
    let placeholder = isISingle ? incomeTaxTotal : incomeTaxSingle;
    return (
      <Item
        label='所得税报表'
        required={true}
        isSingle={isISingle}
        handleUpload={handleIUpload}
        placeholder={placeholder}
        type='finace'
        form={form}
      />
    )
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    initialValues: {
      qymc: qyParams.qymc
    },
    onFinish: onFinish
  };

  return (
    <div className={styles.form}>
      <Form
        style={{ width: '100%' }}
        {...layout}
        form={form}
        layout="vertical"
      >
        {renderTop()}
        <Form.Item label="申报时间" name="date" rules={[{ required: true, message: '请选择申报时间' }]}>
          <Cascader options={dateOptions} placeholder="请选择申报时间" />
        </Form.Item>
        {renderFinance()}
        {renderVat()}
        {renderIncome()}
        <Form.Item>
          <Button type="primary" size='large' htmlType="submit" className={styles.submit}>确认授权</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default inject('selfFillingStore')(observer(FormBox));