import { observer } from "mobx-react";
import React, { Component } from "react";
import { loadScript } from "@/utils";

/** 广东验证器脚本地址 */
const SCRIPT_URL = '//g.alicdn.com/sd/ncpc/nc.js?t=2015052012';

class Guangdong extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validatorData: null
    }
  }
  componentDidMount() {
    loadScript(SCRIPT_URL).then(() => {
      this.initCaptcha();
    }).catch((error) => {
      console.log('error: ', error);
    });
  }

  initCaptcha = () => {
    const ncAppkey = 'FFFF0000000001771572';
    const ncToken = [ncAppkey, (new Date()).getTime(), Math.random()].join(':');
    const ncScene = 'login';
    const NCOpt =  {
      renderTo: '#captcha',
      appkey: ncAppkey,
      scene: ncScene,
      token: ncToken,
      customWidth: '100%',
      trans: {name1: 'code200'},
      callback: (data) => {
        this.setState({
          validatorData: {
            token: ncToken,
            csessionid: data.csessionid,
            sig: data.sig
          }
        });
      }
    };
    const nc = new window.noCaptcha(NCOpt);
    nc.upLang('cn', {
        _startTEXT: '请按住滑块，拖动到最右边',
        _yesTEXT: '验证通过',
        _error300: '哎呀，出错了，点击<a href="javascript:__nc.reset()">刷新</a>再来一次',
        _errorNetwork: '网络不给力，请<a href="javascript:__nc.reset()">点击刷新</a>',
    });
  }

  _scpublicInitCaptcha = () => {
    this.setState({ validatorData: null });
    return this.initCaptcha();
  };

  _scpublicValidateFields = () => {
    return new Promise((resolve, reject) => {
      const { validatorData } = this.state;
      if (validatorData) {
        resolve(validatorData);
      } else {
        reject({ captchaError: true });
      }
    });
  }

  render() {
    return (
      <div style={{marginBottom: '20px'}} id="captcha">loading...</div>
    );
  }
}

export default observer(Guangdong);
