import { provinceValidatorApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import taxHelperStore from '../taxHelper';
import { encryptedParams } from '@/utils';

const INFO_POSITION_DICT = {
  "01": '法定代表人',
  "02": '财务负责人',
  "03": '办税人',
  "04": '开票员',
  "07": '办税人',
}
let checkCodeInstance = null;

/** 吉林旧版 */
class JilinOld {

  /** 办税员列表 */
  bsrs = [];
  bsrsList = [];

  verifySuccess = true;

  getRoleList = (data, options) => {
    const postData = encryptedParams(data, ['userName', 'userPass']);;
    const params = {
      ...postData,
      serialNo: options.serialNo,
      province: '吉林',
    }

    return provinceValidatorApi.getBsyList(params)
      .then((response) => {
        const options = get(response, 'data.data.data.value') || [];
        if (response.status === 200) {
          this.bsrs = options;
          this.bsrsList = options.map((item) => ({
            ...item,
            value: item.yhid,
            label: `${item.yhxm}/${item.lxdh}/${INFO_POSITION_DICT[item.rylx]}`,
          }));
          return Promise.resolve(this.bsrs);
        }
        return Promise.reject({response});
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '身份列表获取失败';
        message.error(errorMessage);
        throw Promise.reject(error);
      });
  }

  /** 授权 */
  taxAuth = ({ formData, stepOneFormData }) => {
    const {bsr, bsrPassword} = formData;
    const {nsrsbh, qymc, serialNo, province} = taxHelperStore.authQueryParams;
    const { taxAccount, taxPassword } = stepOneFormData;
    const selectedBsr = this.bsrs.filter(i => i.value === bsr);
    const params = {
      companyName: qymc,
      creditCode: nsrsbh,
      sfzjhm: bsr,
      bsymm: bsrPassword,
      province,
      serialNo,
      uuid: bsr,
      xm: selectedBsr.yhxm,
      sjhm: selectedBsr.lxdh,
      username: taxAccount,
      password: taxPassword
    };
    return provinceValidatorApi.passwordAuth(serialNo, encryptedParams(params, ['username', 'password', 'sfzjhm', 'bsymm']))
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  setCheckCodeInstance(ckCode) {
    checkCodeInstance = ckCode;
  }

  setVerifyStatus(status) {
    this.verifySuccess = status;
  }
}

export default new JilinOld();
