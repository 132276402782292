import React from 'react';

import styles from './index.less';

const TicketTitle = ({ title }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{title || '请根据页面提示，完成操作'}</div>
      <div className={styles.border}></div>
    </div>
  );
};

export default TicketTitle;
