import React from 'react';
import Anhui from '@/components/common/province-validator/anhui';
import Hebei from '@/components/common/province-validator/hebei';
import Guangdong from '@/components/common/province-validator/guangdong';
import Zhejiang from '@/components/common/province-validator/zhejiang';
import Shandong from '@/components/common/province-validator/shandong';
import Jilin from '@/components/common/province-validator/jilin';
import Jiangshu from '@/components/common/province-validator/jiangshu';
import Guizhou from '@/components/common/province-validator/guizhou';
import Jiangxi from '@/components/common/province-validator/jiangxi';

import { PROVINCE_MAP } from '@/utils/taxUtil';

class Verification extends React.Component {
  constructor(props) {
    super(props);
    /** 区分新版本标识 */
    this.NEW_AUTH = props.NEW_AUTH
    this.anhuiRef = React.createRef();
    this.hebeiRef = React.createRef();
    this.zhejiangRef = React.createRef();
    this.guangdongRef = React.createRef();
    this.shandongRef = React.createRef();
    this.hunanRef = React.createRef();
    this.jilinRef = React.createRef();
    this.jiangshuRef = React.createRef();
    this.guizhouRef = React.createRef();
    this.jiangxiRef = React.createRef();
  }

  isProvince = (province) => this.props.provinceKey === PROVINCE_MAP.get(province).py;

  validateFields = () => {
    if (this.isProvince('安徽') && !this.NEW_AUTH) {
      const anhuiInstence = this.anhuiRef.current;
      return anhuiInstence._scpublicValidateFields();
    }
    if (this.isProvince('河北')) {
      return this.hebeiRef.current._scpublicValidateFields();
    }
    if (this.isProvince('广东')) {
      return this.guangdongRef.current._scpublicValidateFields();
    }
    if (this.isProvince('吉林') && !this.NEW_AUTH) {
      return this.jilinRef.current._scpublicValidateFields();
    }
    if (this.isProvince('江苏')) {
      return this.jiangshuRef.current._scpublicValidateFields();
    }
    if (this.isProvince('江西')) {
      return this.jiangxiRef.current._scpublicValidateFields();
    }
    if (this.isProvince('山东')) {
      return this.shandongRef.current._scpublicValidateFields();
    }
    if (this.isProvince('贵州')) {
      return this.guizhouRef.current._scpublicValidateFields();
    }
  }

  errorVerificationCb = () => {
    /** 使用Anhui2需要 */
    // if (this.isProvince('an_hui')) {
    //   this.anhuiRef.current.initCaptchaP();
    // }
    if (this.isProvince('广东')) {
      this.guangdongRef.current._scpublicInitCaptcha();
    }
    if (this.isProvince('江苏')) {
      this.jiangshuRef.current.initCaptchaP();
    }
    // if (this.isProvince('贵州'))
  }

  /** 浙江获取登录角色列表 */
  zhejiangGetBsyList = (params) => {
    return this.zhejiangRef.current._scPublicgetZhejiangBsyList(params);
  }

  /** 浙江授权方法 */
  zhejiangTaxAuth = (param) => {
    return this.props.taxAuth(param)
      .then(() => this.props.onFinishAuth())
      .catch(error => Promise.reject(error));
  }

  // /** 广东重置滑动验证器 */
  // guangdongInitCaptcha = () => this.guangdongRef.current._scpublicInitCaptcha();

  render() {
    const { nsrsbh } = this.props.params;
    return (
      <React.Fragment>
        {/* <a onClick={this.errorVerificationCb}>test</a> */}
        {/* { this.isProvince('安徽') && <Anhui ref={this.anhuiRef} />} */}
        { this.isProvince('河北') && nsrsbh && <Hebei ref={this.hebeiRef} creditCode={nsrsbh} />}
        { this.isProvince('浙江') && <Zhejiang ref={this.zhejiangRef} taxAuth={this.zhejiangTaxAuth} />}
        { this.isProvince('广东') && <Guangdong ref={this.guangdongRef} />}
        { this.isProvince('山东') && <Shandong ref={this.shandongRef} />}
        { (!this.NEW_AUTH && this.isProvince('吉林')) && <Jilin ref={this.jilinRef} baseFormValidate={this.props.baseFormValidate}/>}
        { (!this.NEW_AUTH && this.isProvince('江苏')) && <Jiangshu ref={this.jiangshuRef} />}
        { (!this.NEW_AUTH && this.isProvince('贵州')) && <Guizhou ref={this.guizhouRef} />}
        { this.isProvince('江西') && <Jiangxi ref={this.jiangxiRef} />}
      </React.Fragment>
    );
  }
}

export default Verification;
