import { Form, Input } from 'antd';

const TaxAccountFormItem = ({ inputSize, formInstances, options = {} }) => {
  const placeholder = options.placeholder ? options.placeholder : '请输入纳税人识别号/社会信用代码';
  const label = options?.label || "电子税务局查询账号"
  return (
    <Form.Item
      key="username"
      colon={false}
      label={`${label}：`}
      name="username"
      rules={[{ required: true, whitespace: true, message: placeholder }]}
    >
      <Input
        size={inputSize}
        placeholder={placeholder}
        onBlur={(evt) => {
          const value = evt.target.value;
          if (value !== value.trim()) {
            const form = formInstances();
            form.setFieldsValue({ taxAccount: value.trim() });
          };
        }}
      />
    </Form.Item>
  );
}

export default TaxAccountFormItem;
