import { checkPhoneBrowser } from '@/utils';
import { inject, observer } from 'mobx-react';
import MobileAuth from '@/pages/home/MobileAuth';

export default function ShowOnMobile(Component) {
  const WrappedComponent = (props) => {
    if (checkPhoneBrowser()) {
      return ( <MobileAuth /> );
    }
    return (<Component {...props} />);
  };
  return inject('taxHelperStore')(observer(WrappedComponent));
}
