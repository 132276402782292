import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import styles from "./index.less"

/** 省份税局状态维护列表 */
const ProvinceStatusList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <div>
      <Button
        onClick={toggleModalOpen}
        type="link"
        className={styles.btn}
      >税局维护状态</Button>
      <Modal
        visible={isModalOpen}
        onOk={toggleModalOpen}
        onCancel={toggleModalOpen}
        width="90%"
        centered
        footer={null}
        className={styles.modal}
      >
        <div className={styles.content}>
          <iframe
            title="税局状态维护列表"
            width="100%"
            height="100%"
            src="https://fx.yucunkeji.com/provinceStatus"
          >
          </iframe>
        </div>
      </Modal>
    </div>
  )
}

export default ProvinceStatusList