import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import styles from './index.less'
import { Spin, message } from 'antd';
import { getFMAuthorizeUrl } from '@/api/fm';

function parseQueryParams(location) {
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};

  for (let [key, value] of searchParams.entries()) {
    queryParams[key] = encodeURI(value);
  }

  return queryParams;
}


/** 富民银行授权 */
const FuMinAuth = () => {
  const location = useLocation();
  const queryParams = parseQueryParams(location);
  
  useEffect(() => {
    getFMAuthorizeUrl(queryParams)
      .then((res) => {
        const url = res?.data?.data?.url
        if (url) {
          window.location.replace(url)
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message || err.message || '网络异常，请稍后再试！')
      })
  }, [])
  

  return (
    <div className={styles.fumin}>
      <Spin tip='加载中...'></Spin>
    </div>
  )
}

export default FuMinAuth