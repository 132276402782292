import React from 'react';

import styels from './index.less';

const DefaultContainer = ({ children }) => {
  return (
    <div className={styels.wrap}>
      {children}
    </div>
  );
};

export default DefaultContainer;
