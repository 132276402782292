import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { loadScript, loadStyle, checkPhoneBrowser } from "@/utils";

import loadingImg from '@/assets/images/anhui/loading.png';

import styles from './index.less';

const validateTextMap = new Map([
  [0, '点击进行验证'],
  [1, '验证已通过']
])

class Anhui extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      validateState: 0, // 0为验证器初始状态， 1为验证器通过验证
    }
    this.isMobile = checkPhoneBrowser();
  }

  componentDidMount() {
    this.addAnhuiScripts();
  }

  closeCaptchaModal = () => {
    const closeButton = document.getElementsByClassName('layui-layer-close');
    closeButton.item(0).click();
  };

  showCaptchaModal = () => {

    const validateState =  this.state.validateState;
    if (validateState === 1) {
      return false;
    }
    
    const { cb } = this.props;
    const area = this.isMobile ? ['300px', '246px'] : ['390px', '285px'];
    const imgSize = this.isMobile ? { width: '268px', height: '164px'} : { width: '355px', height: '200px'};
    window.$.open({
      type: 1,
      title: false,
      area: area,
      content: '<div class="as-pd10" style="overflow:hidden;"><div id="captcha" class="sc-captcha-box"></div></div>',
      success: () => {
        window.$('#captcha').pointsVerify({
          baseUrl: '/api/dbc/taxation/ah/',
          imgSize: imgSize,
          ready: () => {
            const captchaImg = window.$('#captcha img');
            captchaImg.attr('src', loadingImg);
          },
          success: validate => {
            if (typeof cb === 'function') {
              cb(validate);
            }
            this.setState({ validateState: 1 });
            this.closeCaptchaModal();
          }
        });
      },
      error: error => {
        console.log('error');
      },
      cancel: cancel => {
        console.log('cancel');
      }
    });
  }

  addAnhuiScripts = () => {
    const jqueryScriptUrl = process.env.PUBLIC_URL + '/jquery.min.js';
    const layerURL = 'https://etax.anhui.chinatax.gov.cn//cas/webjars/layer/3.1.1/layer.js';
    const anhuiCaptchaScriptUrl = process.env.PUBLIC_URL + '/ah_captcha.js';
    const anhuiUtils = 'https://etax.anhui.chinatax.gov.cn/cas/webjars/aisino/0.0.1/aisino-utils.js?r=0.1';
    const cryptoURL = 'https://etax.anhui.chinatax.gov.cn/cas/static/verify/js/crypto.js?v=20210528211938';
    const json2URL = 'https://etax.anhui.chinatax.gov.cn/cas/static/verify/js/json2.js?v=20210528211938';
    const verifyURL = 'https://etax.anhui.chinatax.gov.cn/cas/static/verify/js/verify.js?v=20210528211938';
    const captchaLinkHref = 'https://etax.anhui.chinatax.gov.cn/cas/webjars/captcha/captcha.min.css?v=0.1';
    const verifyCss = 'https://etax.anhui.chinatax.gov.cn/cas/static/verify/css/verify.css';

    return loadScript(jqueryScriptUrl)
      .then(() => {
        loadScript(layerURL);
      })
      .then(() => {
        Promise.all([
          loadScript(anhuiCaptchaScriptUrl),
          loadScript(anhuiUtils),
          loadScript(cryptoURL),
          loadScript(json2URL),
          loadScript(verifyURL),
          loadStyle(captchaLinkHref),
          loadStyle(verifyCss),
        ])
      })
      .then(data => {
        console.log('data: ', data);
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }
  
  /** 刷新验证码 */
  reFlash = () => {
    this.setState({ validateState: 0 });
  }

  render() {
    const validateText = validateTextMap.get(this.state.validateState);
    return (
      <div className={styles['capycha-action']} onClick={this.showCaptchaModal}>{validateText}</div>
    );
  }
}

Anhui.propTypes = {
  cb: PropTypes.func.isRequired
}

export default observer(Anhui);
