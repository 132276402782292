import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';
import FormWrapper from '../FormWrapper';

const DateInput = (props) => {
  const { item } = props;
  const handleInputType = () => {
    const inputType = item.inputType;
    switch (inputType) {
      case 'range':
        return (<DatePicker.RangePicker getCalendarContainer={triggerNode => triggerNode.parentElement} {...item.inputProps} />);
      case 'month':
        return (<DatePicker.MonthPicker getCalendarContainer={triggerNode => triggerNode.parentElement} {...item.inputProps} />);
      default:
        return (<DatePicker getCalendarContainer={triggerNode => triggerNode.parentElement} {...item.inputProps} />);
    }
  };
  const handleDateValue = (value) => {
    const valType = typeof value;
    const format = item.format || 'YYYY-MM-DD HH:mm:ss';
    if (valType === 'string') {
      return moment(value, format);
    } else if (Array.isArray(value)) {
      return [moment(value[0], format), moment(value[1], format)];
    }
  }
  if (!item.handle) {
    item.handle = handleDateValue;
  }
  return (
    <FormWrapper {...props}>
      {handleInputType()}
    </FormWrapper>
  );
}

// item 下更多其他配置参考 FormWrapper.propTypes
DateInput.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
};

export default DateInput;
