/**
 * 爱信诺授权获取流程错误map
 */
export default new Map([
  [1010, {code: 1010, des: '第一次获取token', message: '授权失败'}],
  [1020, {code: 1020, des: '下单前查询', message: '授权失败'}],
  [1021, {code: 1021, des: '服务调用失败，税号与插入的税控设备不一致，请检查后重试', message: '税号与插入的税控设备不一致，请检查后重试'}],
  [1030, {code: 1030, des: '下单', message: '下单错误'}],
  [2010, {code: 2010, des: '企业身份令牌获取失败', message: '企业身份令牌已失效'}],
  [2011, {code: 2011, des: '没有获取到企业身份令牌', message: '企业身份令牌已失效'}],
  [2012, {code: 2012, des: '传入参数加密失败', message: '企业身份令牌获取失败'}],
  [2013, {code: 2013, des: '传入参数不是正确的对象，序列化失败', message: '企业身份令牌获取失败'}],
  [2020, {code: 2020, des: '本地查询获取数据', message: '开票数据获取失败'}],
  [2021, {code: 2021, des: '服务调用失败，此产品服务商未提供《用户授权协议》，请联系当地航天信息。', message: '开票数据获取失败，请联系当地航天信息'}],
  [2022, {code: 2022, des: '企业不同意用户授权协议', message: '请先阅读并同意《用户授权协议》'}],
  [2030, {code: 2030, des: '上传数据', message: '开票数据上传失败'}],
  [2031, {code: 2031, des: '开票数据上传前分片失败', message: '开票数据上传失败'}],
  [2032, {code: 2032, des: '开票数据分片上传失败', message: '开票数据上传失败'}],
  [2039, {code: 2039, des: '开票数据不存在', message: '开票数据上传失败'}],
]);
