import axios from 'axios';

const interceptors = () => {
  axios.interceptors.request.use((axiosConfig) => {
    axiosConfig.headers['platform-type'] = 'SC';
    return axiosConfig;
  });
}

export default interceptors;
