import styles from './index.less';

const RZProtocolContent = () => {
  return (
    <div>
        <p><strong>重要提示：</strong></p>
        <p className={styles.paragraph}>
          <strong>请在勾选/签署本授权书前，仔细阅读、充分理解本授权书各条款内容，特别是免除或减轻日照银行股份有限公司（亦称“贵行”）责任，或限制您权利的条款，其中免除或者减轻责任条款将以加黑加粗形式提示您。您的签署行为即视为您已阅读并同意本授权书条款的约束，本授权书即生效。</strong>
        </p>
        <p><strong>日照银行股份有限公司：</strong></p>
        <p className={styles.paragraph}>一、兹不可撤销地授权:</p>
        <p className={styles.paragraph}>
          ①贵行依照国家相关规定采集并向金融信用信息基础数据库及其他依法成立的征信机构报送本单位信用信息（包含本单位因未及时履行合同义务产生的不良信息等）；
        </p>
        <p className={styles.paragraph}>
          <strong>②贵行在办理涉及本单位业务时，基于业务需要向金融信用信息基础数据库等依法设立的征信机构查询、打印本单位的信用信息；</strong>
        </p>
        <p className={styles.paragraph}>
          <strong>③贵行可将上述信息用于本单位授信申请、担保资格审查、资质审查等业务环节。</strong>
        </p>
        <p className={styles.paragraph}>二、授权期限：本授权书有效期自同意本协议之日起生效，至本单位在贵行申请办理或担保的业务、或本单位的关联企业在贵行办理的业务（包括但不限于贷款、保理、贴现、承兑、保函等）结束之日止。</p>
        <p className={styles.paragraph}>三、<strong>本单位知晓，贵行有权在业务存续期内，基于贷后管理等业务管理需要查询本单位相关报告，而无需再次获得本单位授权。</strong>如果贵行超出本授权书范围进行数据报送和查询使用本单位信息，则贵行应承担与此有关的法律责任。</p>
        <p className={styles.paragraph}>
          <strong>
            四、若上述业务未获批准办理，本授权书、本单位证件复印件及本单位信用报告等资料无须退回。:纳入这些信息对您的贷款评分等结果可能产生的不利影响。
          </strong>
        </p>
        <p className={styles.paragraph}>
          <strong>声明：本单位已仔细阅读上述所有条款，并已特别注意字体加黑的内容。贵行已应本单位要求对相关条款予以明确说明。本单位对所有条款的含义及相应的法律后果已全部通晓并充分理解，本单位自愿作出上述授权、承诺和声明。</strong>
        </p>
      </div>
  );
};

export default RZProtocolContent;
