import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col, Button } from 'antd';
import { isFunction, get } from 'lodash';
import FormWrapper from '../FormWrapper';

const TIME_OUT = 120;

const GetCaptcha = (props) => {
  const { item } = props;
  const getCaptchaProps = get(item, 'captchaProps.getCaptcha');
  const timeOutSecd = get(item, 'captchaProps.timeOut');

  const [ btnText, setBtnText] = useState('获取验证码');
  const [ btnDisabled, setBtnDisabled ] = useState(false);

  const getCaptcha = () => {
    return getCaptchaProps();
  }

  const timeout = (lestSecond) => {
    if (lestSecond > 0) {
      setBtnDisabled(true);
      setTimeout(() => {
        setBtnText(`${lestSecond - 1} 秒后重试`);
        timeout(lestSecond - 1);
      }, 1000);
    } else {
      setBtnDisabled(false);
      setBtnText('获取验证码');
    }
  }

  const startTimeout = () => {
    timeout(timeOutSecd || TIME_OUT);
  }

  const btnClick = () => {
    if (isFunction(getCaptcha)) {
      setBtnDisabled(true);
      setBtnText('获取验证码...');
      getCaptcha()
        .then(() => {
          setBtnDisabled(false);
          startTimeout();
        })
        .catch(error => {
          setBtnDisabled(false);
          setBtnText('获取验证码');
        });
    }
  }

  return (
    <FormWrapper {...props}>
      <Captcha
        inputProps={{...item.inputProps}}
        buttonProps={{block: true, disabled: btnDisabled, size: item.inputProps.size, type: 'primary', onClick: btnClick}}
        btnText={btnText}
      />
    </FormWrapper>
  );
}

// For Solve issue Input element's value can't set with Form.setFieldsValue
function Captcha({onChange, value, inputProps, buttonProps, btnText}) {
  return (
    <Row gutter={8}>
      <Col span={14}>
        <Input {...inputProps} onChange={onChange} value={value} />
      </Col>
      <Col span={10}>
        <Button
          {...buttonProps}
        >{btnText}</Button>
      </Col>
    </Row>
  )
}

GetCaptcha.propTypes = {
  item: PropTypes.object,
};

export default GetCaptcha;
