import {useEffect, useRef, useState} from "react";
import {useCache} from "ease-hooks";
import * as echarts from "echarts";
import {Box,Title, MenuItem} from "./map.styled";

import Popup from "@/pages/guide/areaSelect/components/popup";

import {AREA_MAP, SPECIAL_AREA} from "@/pages/guide/areaSelect/components/utils";

export const colors = ['#5A80FE', '#6AE45A', '#FBC850'];

function draw(chart, data) {
  const options = {
    // tooltip: {
    //   trigger: 'item',
    // },
    visualMap: {
      min: 0,
      max: 1,
      realtime: false,
      inRange: {
        color: ['#DAEFFF', '#3B9CFF' ]
      },
      show: false,
    },
    series: [
      {
        type: "map",
        map: "CHINA",
        center: [103.823557,36.058039],
        zoom: 1.2,
        select: {
          disabled: true,
        },
        label: {
          show: true,
          color: '#808090',
          fontSize: 8,
          formatter: (a) => {
            if (SPECIAL_AREA.get(a.name)) {
              return `${a.name}(${SPECIAL_AREA.get(a.name)[1]})`
            }
            return a.name;
          }
        },
        roam: true,
        scaleLimit: {
          min: 1.2,
          max:15,
        },
        itemStyle: {
          borderColor: '#FFF',
        },
        data: data,
        nameMap: AREA_MAP,
      },
    ],
  };
  chart.setOption(options);
}

function Map({data, onClick}) {
  const container = useRef(null);
  const [geoData] = useCache("CHINA_GEO_DATA", () => import("@/assets/josn/map_data.json"), 3, 30);
  const [cardPos, setCardPos] = useState({top:0, left:0});
  const [chart, setChart] = useState(null);
  const [menu, setMenu] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (geoData && container.current && data) {
      // initialization
      const newData = data.map(i => ({name: i.area, value: i.status === 'NORMAL' ? 1 : 0}))
      echarts.registerMap("CHINA", geoData);
      const chart = echarts.init(container.current);

      chart.on('click',  (params) => {
        if (params?.name) {
          if (SPECIAL_AREA.get(params?.data?.name)) {
            setMenu(SPECIAL_AREA.get(params.data.name));
            setCardPos({left: params.event.offsetX, top: params.event.offsetY});
            setVisible(true);
            return;
          }
          onClick?.(params.name)
        }
        setVisible(false);
      });

      setChart(chart);
      draw(chart, newData);
      return () => {
        chart.dispose();
        chart.off('click')
      }
    }
  }, [geoData, container.current, data]);

  return (
    <Box>
      <Title>税票服务地图</Title>
      <Popup visible={visible} top={cardPos.top} left={cardPos.left}>
        {menu.map(item => {
          return <MenuItem key={item} onClick={() => onClick(item)}>{item}</MenuItem>
        })}
      </Popup>
      <div ref={container} id="container" style={{width: "100%", height: "400px"}}></div>
    </Box>
  );
}

export default Map;