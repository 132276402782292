import styled from "styled-components";
import {Button, Space} from "antd";

export const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

export const BigButton = styled(Button)`
  flex-shrink: 0;
  & ${Icon} {
    position: absolute;
    right: 10px;
    top: ${props => props.size === 'large' ? '10px':'6px'};
  }
`