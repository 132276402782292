import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import FormWrapper from '../FormWrapper';

const CheckboxInput = (props) => {
  const { item } = props;
  return (
    <FormWrapper {...props}>
      <Checkbox.Group {...item.inputProps} />
    </FormWrapper>
  );
}

// item 下更多其他配置参考 FormWrapper.propTypes
CheckboxInput.propTypes = {
  item: PropTypes.object,
};

export default CheckboxInput;
