import { taxAuthApi } from '@/api';
import { get } from 'lodash';
import { message } from 'antd';
import { countDownModal } from '@/utils/countDownModal';
import { InfoCircleOutlined } from '@ant-design/icons';

class taxAuth {

  /** 插件配置信息 */
  pluginConfig = {};

  /** 已开通授权的省份 */
  taxDeclareProvince = [];


  /** 提示税局维护状态 */
  taxAreaStatusModal = (info) => {
    console.log(info);
    const style = {marginBottom: 2, textAlign: 'center'}
    const { maintenanceTimeList } = info;
    const maintenanceTimeListText = maintenanceTimeList ? (
      <>
        <div style={{ marginTop: 20, textAlign: 'center' }}>维护时间：</div>
        {maintenanceTimeList.map((item) => (
          <div style={style}>{`${item.start.replace("-", "/")}-${item.end.replace("-", "/")}`}</div>
        ))}
      </>
    ) : '';
    countDownModal({
      title: '提示',
      btn: true,
      icon: <InfoCircleOutlined color="#faad14" style={{ color: '#faad14' }} />,
      content: (
        <>
          <p style={{textAlign: 'center'}}>
            {`${info.name}税局维护中，授权登录和数据采集可能异常，详见【税局维护状态】。\n`}
          </p>
          {maintenanceTimeListText}
        </>
      )
    })
  }

  /** 获取开通的身份列表 */
  getTaxDeclareProvince = () => {
    return taxAuthApi.getProvinceList()
      .then(({ data }) => {
        const list = get(data, 'data')?.map((item) => {
          return { ...item, area: item.name, disabled: item.status === 'NOT_OPENED' }
        })
        this.taxDeclareProvince = list;
        return list
      });
  };

  /** 更新订单税局省份 */
  updateTaxOrderBureau = (params) => {
    return taxAuthApi.updateTaxOrderBureau(params)
      .catch(error => {
        console.log('error: ', error);
      });
  };

  /**
   * 税局授权
   * @param {*} params 授权参数
   * @param {string} NEW_AUTH 授权版本，区分新旧版本
   */
  taxAuth = (params) => {
    const { serialNo, username, password, creditCode, province, ...other } = params;
    let _params = params
    if (province === '湖北') {
      _params = {
        "orderId": serialNo,
        "username": username,
        "password": password,
        "creditCode": creditCode,
        "province": province,
        "authentication": "tpass.hubei.chinatax.gov.cn", //固定值,
        ...other,
      }
    }
    return taxAuthApi.taxAuth(_params, params.serialNo)
      .then(({ data }) => {
        const code = get(data, 'data.code');
        if (code === '10028') {
          throw new Error({ isCollectData: true });
        }
      })
      .catch(error => {
        const errorCode = get(error, 'response.data.errorCode', '') || '';
        let errorMessage = get(error, 'response.data.message', '') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  /**
   * 软件授权
   * @param {*} params 授权参数
   */
  pluginAuth = (params) => {
    return taxAuthApi.commonTaxAuthorization(params)
      .then(response => {
        const data = get(response, 'data');
        return Promise.resolve(data);
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.message') || '授权失败';
        message.error(errorMessage);
        return Promise.reject(error);
      })
  }

  /**
   * 获取采集状态
   * @param {*} serialNo 订单号
   * @returns 
   */
  checkoutTaxAuthOrderCollectionStatus = (serialNo) => {
    return taxAuthApi.checkoutTaxAuthOrderCollectionStatus(serialNo)
      .then(response => {
        const data = get(response, 'data');
        return data;
      })
      .catch(error => {
        console.log('error: ', error);
        return Promise.reject(error);
      })
  }

  /**
   * 获取插件配置信息
   * @param {*} taxType 授权方式 可用值:DZ_SJ,DX,WFQ,CS,AISINO,DZ_DX,DZ_WFQ,DZ_CS,DZ_ANO,PRO,DZ_PRO
   * @returns 
   */
  getPluginConfig = (taxType) => {
    return taxAuthApi.getPluginConfig(taxType)
      .then(response => {
        const config = get(response, 'data.data');
        this.pluginConfig = config;
      })
      .catch(error => {
        console.log('error: ', error);
      })
  }

  /**
   * 后去授权状态
   * @param {*} serialNo 订单流水号
   * @returns 授权状态
   */
  getAuthStatus = (serialNo) => {
    return taxAuthApi.getAuthStatus(serialNo)
      .then(resp => {
        const isTaxAuth = get(resp, 'data.data', false);
        return isTaxAuth;
      })
      .catch(error => Promise.reject(get(error, 'response.data')));
  }

}

export default new taxAuth();
