import { TAX_ERROR_DICT } from '@/utils/taxUtil';
import { message } from 'antd';
import { get } from 'lodash';

/** 获取错误信息 */
export function getTaxErrorInfo(code) {
  const keys = Object.keys(TAX_ERROR_DICT);
  for (let idx = 0; idx < keys.length; idx++) {
    const item = TAX_ERROR_DICT[keys[idx]];
    if (item.code === code) {
      return item;
    }
  }
  return TAX_ERROR_DICT.DEFAULT;
}

/**
 * 处理显示请求错误信息
 * @param {*} error 请求返回错误
 * @param {*} defaultMessage 解析返回错误信息出错时显示的错误信息
 */
 export const showErrorMessage = (error, defaultMessage) => {
  const errorMsg = get(error, 'response.data.message') || '';
  message.error(errorMsg || defaultMessage);
};

/**
 * 处理数据部接口的中间件,不适用于java数据
 * @param {Promise} promise 
 */
export const requestMiddleware = async (promise) => {
  const res = await promise.catch((err) => {
    const data = err?.response?.data;
    message.error(data?.data?.message || data?.message || '网络异常，请稍后再试！')
    return Promise.reject(err)
  })
  const data = res.data.data || {}
  if (data.code !== '10000') {
    message.error(data.message)
    return Promise.reject(res)
  }
  return data.data
}