import { isArray } from "lodash";
import { PROVINCE_MAP } from '@/utils/taxUtil';

/** 判断是否是当前省份 */
export const isProvinceOf = (province) => {
  return (provinceName) => {
    if (isArray(provinceName)) {
      return provinceName.some(name => {
        return PROVINCE_MAP.get(name).py === province;
      });
    }
    if ( typeof provinceName === 'string') {
      return PROVINCE_MAP.get(provinceName).py === province;
    }
    return false;
  }
};
