import { businessAxios } from '@/utils/businessAxios';

/** 获取企业模糊搜索列表 */
export const searchCompanyList = (params) => {
  return businessAxios.get('/api/dbc/external/enterprise/name/match', { params });
}

/** 获取工商基本信息 */
export const getCorpInfo = (params) => {
  return businessAxios.get('/api/dbc/external/enterprise/corp/info/base', { params });
}

/** 税票授权url-日照 */
export const getRizhaoTaxAuthUrl = (params) => {
  return businessAxios.post('/api/dbc/external/taxation/taxAuthDZ', params);
}
