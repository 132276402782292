// import { checkPhoneBrowser } from '@/utils';
// import MobileAuth from '@/pages/home/MobileAuth';

export default function AnhuiQrCodeAuthHoc(Component) {
  const WrappedComponent = (props) => {
    /** 如果是手机上打开提示到电脑端操作 */
    // if (checkPhoneBrowser()) {
    //   return ( <MobileAuth hideImage={true} /> );
    // }
    return ( <Component {...props } />);
  };
  return WrappedComponent;
}
