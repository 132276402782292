import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isEmpty, get } from 'lodash';
import { Form, Button, Alert, Modal, Space, message } from 'antd';
import { parse } from 'query-string';
import decryptedParams from '@/utils/decryptedParams';
import { encryptedParams, downloadFile, linkTo } from '@/utils';
import {
  electronTaxComponentConfigMap,
  old_electronTaxComponentConfigMap,
  pluginTaxComponentConfigMap
} from './helper/data';

import TicketTitle from '@/components/ticketTitle';
import WhiteBox from '@/components/layout/whiteBox';
import HxTaxBureau from '@/components/HxTaxBureau';
import TaxBureauStep from '@/components/TaxBureauStep';
import TaxAuthProtocol from '@/components/common/TaxAuthProtocol';
import Verification from './components/Verification';
import PlugConfirmation from './components/PlugConfirmation';
import TaxDeclareProvince from './components/FormComp/FormItemComp/TaxDeclareProvince';
import CompanyNameFormItem from './components/FormComp/FormItemComp/CompanyNameFormItem';
import TaxAccountFormItem from './components/FormComp/FormItemComp/TaxAccountFormItem';
import TaxPasswordFormItem from './components/FormComp/FormItemComp/TaxPasswordFormItem';
import PhoneIDCardCodeFormItem from './components/FormComp/FormItemComp/PhoneIDCardCodeFormItem';
import CreditsCodeFormItem from './components/FormComp/FormItemComp/CreditsCodeFormItem';
import SpTypeFormItem from './components/FormComp/FormItemComp/SpTypeFormItem';
import HubeiIdentityFormItem from './components/FormComp/FormItemComp/HubeiIdentityFormItem';
import HubeiEleAuthWrap from './components/HubeiEleAuthWrap';
import AnhuiEleAuthWrap from './components/AnhuiEleAuthWrap';

import ShowOnMobile from '@/components/hoc/ShowOnMobile';
import { NEED_SPSB_TYPE } from '@/utils/taxUtil/dict';

import styles from './index.less';
// import TaxAuthHelper from './helper';
import { AUTH_TYPE_DICT, PROVINCE_MAP } from '@/utils/taxUtil';
import { toJS } from 'mobx';
import config from '@/config';
import SwitchVersion from '@/components/SwitchVersion';
import { countDownModal } from '@/utils/countDownModal';
import SelectPick from '@/components/SelectAndPick';

// header认证key: tax-key
// http://xx?q=base64({"qymc":"","nsrsbh":"","province":"","cburl":"","serialNo":"","taxKey":"","taxType":"","eleTaxAvailable":true})

class ElectronicTaxBureau extends Component {
  
  constructor(props) {
    super(props);
    /** 当前是否新版本 */
    this.NEW_AUTH = props.NEW_AUTH ?? true
    this.state = {
      value: undefined,
      params: {},
      // 判断是否是开通了的省市, true开通, false没有开通
      isProvice: true,
      // 是否需要优先进入软件下载页，长沙软件、爱信诺软件等
      isPluginDownload: false,
      downloadPlugVisible: false,
      // 下载软件是否可关闭,大象软件在授权成功后，不能关闭弹窗，否则无法再次打开
      downloadPlugClosable: true,
      plugConfirmationVisible: false,
      startPlugConfirmationVisible: false,
      formItemDisabled: false,
      inputSize: 'large',
      // 下一步的页面,如果下一步还有表单的类型
      nextStep: false,
      // 是否同意授权
      agreeAuthProtocol: false,
      // isLoading
      isLoading: false,
      // 表单信息
      formData: {},
      /** 为提高采票成功率的超时提示，爱信诺可能不需要该提示 */
      shouldNoticeQuickAuthorize: false,
      // 获取初始化页面数据
      isGetInitPage: true,
    }
    this.infoFormRef = React.createRef();
    this.verificationRef = React.createRef();
  }

  initMessageChannel = () => {
    window.addEventListener('message', data => {
      this.props.taxHelperStore.setValue('messageChannelPort', data);
    })
  }

  checkAuthStatus = (serialNo) => {
    this.props.taxAuthStore.getAuthStatus(serialNo)
      .then(isTaxAuth => {
        this.setState({ isGetInitPage: false });
        if (isTaxAuth) {
          return this.onFinishAuth(false);
        }
      })
      .catch(errorData => {
        const { errorCode } = errorData;
        if (errorCode === 403220) {
          this.props.history.replace(`/invalidation?code=1098`);;
        }
      });
  }

  componentDidMount() {
    
    const { taxHelperStore } = this.props;
    const { area } = parse(window.location.search);
    const params = decryptedParams();
    if (area) {
      params.province = area;
    }
    if (config.target === 'dev') {
      console.log(`仅开发模式打印`);
      console.log(params);
    }

    /** 初始化消息接收 */
    this.initMessageChannel();

    /** 检查订单授权状态 */
    this.checkAuthStatus(params.serialNo);

    /** 获取插件配置信息 */
    if (params.taxType) {
      const { taxType } = params;
      this.props.taxAuthStore.getPluginConfig(taxType);
    }

    /** 将解密后的参数存储到hubeiSPLoginStore中 */
    this.props.hubeiSPLoginStore.setValue('queryParams', params);
    /** 将解密后的参数存储到anhuiQrAuthStore中 */
    this.props.anhuiQrAuthStore.setValue('queryParams', params);

    /** 解密参数，获取税局授权基本配置信息 */
    // this.taxHelper.inject(params);
    taxHelperStore.setQueryParams(params);

    this.initAuthForm(params);
  }

  initAuthForm = (params) => {
    const { history, hxTaxBuresuStore } = this.props;
    this.shouldBegin(params)
    .then((data) => {

        /** 获取全部可授权省份列表 */
        this.props.taxAuthStore.getTaxDeclareProvince()
        .then((list) => {
          const { taxHelperStore } = this.props;
          const { province } = taxHelperStore.authQueryParams;
          const { taxAreaStatusModal } = this.props.taxAuthStore;
          const option = list?.find((item) => item.name === province)
          // 维护中，弹窗提示
          if (option.status === 'MAINTENANCE') {
            taxAreaStatusModal(option)
          }
        })

        /** 协议获取-单个 */
        this.props.commonStore.getProtocolSingleV2(params)

        /** logo获取 */
        this.props.commonStore.getLogoInfo(params)


        this.initTaxAuth(data);
        hxTaxBuresuStore.updateQueryParams(data);

        // 长沙软件在第二步才开始有，这里不存在
        if (!this.state.isPluginDownload) {
          this.updateFormValue(data);
        }
      })
      .catch((error) => {
        let errorCode = null;
        if (typeof error === 'object' && error.code) {
          errorCode = error.code;
        } else {
          errorCode = 1099;
        }
        history.replace(`/invalidation?code=${errorCode}`);
      });
  }

  /** 判断是否可以进入税局授权页 */
  shouldBegin(params) {
    const { taxHelperStore } = this.props;
    return new Promise((resolve, reject) => {
      if (isEmpty(params)) {
        return reject('请求参数不存在');
      }
      taxHelperStore.getTaxWorkTypeAsync()
        .then(() => {
          return resolve(params);
        })
        .catch((errorInfo) => {
          return reject(errorInfo);
        });
    });
  }

  /** 初始化工作 */
  initTaxAuth(params) {
    const { taxHelperStore } = this.props;
    const provinceKey = taxHelperStore.getProvincePingYing();
    // const taxKey = taxHelperStore.getTaxKey();
    const shouldPluginDownload = taxHelperStore.shouldDownloadPluginAsBegin();
    const shouldQuickAuthorize = taxHelperStore.shouldNeedQuickAuthorize();

    this.setState({
      params,
      isProvice: !!provinceKey,
      isPluginDownload: shouldPluginDownload,
      shouldNoticeQuickAuthorize: shouldQuickAuthorize,
    });
  }

  // 初始化自动回填的信息
  updateFormValue = (formValue) => {
    const { taxHelperStore } = this.props;
    const form = this.infoFormRef.current;
    const workType = taxHelperStore.getTaxWorkType();
    const value = {
      companyName: formValue.qymc || undefined,
      creditCode: formValue.nsrsbh || undefined,
      taxDeclareProvince: formValue.province || undefined,
    }
    if (workType === AUTH_TYPE_DICT.PLUGIN) {
      delete value.creditCode;
      value.nsrsbh = formValue.nsrsbh || undefined;
    }
    if (form) {
      form.setFieldsValue(value);
    }
  }

  getTaxAuthFormItem = (type, options) => {
    const { taxHelperStore } = this.props;
    const { inputSize } = this.state;
    const creditCode = taxHelperStore.getCreditCode();
    const { province, qymc } = taxHelperStore.authQueryParams;
    const formInstances = () => this.infoFormRef.current;
    switch (type) {
      case 'taxDeclareProvince':
        return <TaxDeclareProvince key={type} defaultValue={province} inputSize={inputSize} disabled={!this.NEW_AUTH} />;
      case 'qymc':
        return <CompanyNameFormItem key={type} defaultValue={qymc} creditCode={creditCode} inputSize={inputSize} />;
      case 'taxAccount':
        return <TaxAccountFormItem key={type} formInstances={formInstances} options={options} inputSize={inputSize} />;
      case 'taxPassword':
        return <TaxPasswordFormItem key={type} inputSize={inputSize} />;
      case 'phoneIDCardCode':
        return <PhoneIDCardCodeFormItem key={type} formInstances={formInstances} inputSize={inputSize} />;
      case 'nsrsbh':
        return <CreditsCodeFormItem key={type} inputSize={inputSize} />;
      case 'spsb':
        return <SpTypeFormItem key={type} inputSize={inputSize} />;
      case 'hubeiIdentity':
        return <HubeiIdentityFormItem key={type} inputSize={inputSize} />;
      default:
        break;
    }
  };

  getTaxAuthForm = (componentConfigList) => {
    return componentConfigList.map((componentConfig) => {
      let componentType = '';
      let options = {};
      if (typeof componentConfig === 'string') {
        componentType = componentConfig;
      }
      if (typeof componentConfig === 'object') {
        componentType = componentConfig.name;
        options = componentConfig.options;
      }
      return this.getTaxAuthFormItem(componentType, options);
    })
  };

  baseFormData = () => this.infoFormRef.current.validateFields();
  verificationData = () => this.verificationRef.current.validateFields();
  errorVerificationCb = () => this.verificationRef.current.errorVerificationCb();

  getBaseFormDataP = () => {
    return this.baseFormData()
      .then((formData) => {
        this.props.taxHelperStore.setAuthFormData(formData);
        return formData;
      })
  }

  extraData = () => {
    return new Promise((resolve, reject) => {
      const { nsrsbh, serialNo, province } = this.props.taxHelperStore.authQueryParams;
      if (nsrsbh && serialNo && province) {
        resolve({ creditCode: nsrsbh, serialNo, province });
      }
      else {
        reject(new Error('参数错误'));
      }
    })
  }

  checkAgreeProtocol = (data) => {
    return new Promise((resolve, reject) => {
      const { agreeAuthProtocol } = this.state;
      if (!agreeAuthProtocol) {
        message.error('请先阅读并同意相关协议');
        reject(new Error('请先阅读并同意相关协议'));
        return false;
      }
      resolve(data);
    })
  }

  zhejiangGetBsyList = () => {
    Promise.all([this.getBaseFormDataP(), this.extraData()])
      .then(([baseData, extData]) => ({ ...baseData, ...extData }))
      .then((params) => this.checkAgreeProtocol(params))
      .then((postData) => {
        this.setState({ isLoading: true });
        return this.verificationRef.current.zhejiangGetBsyList(postData)
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            return Promise.reject(error);
          });
      })
      .catch(error => {
        console.log('error: ', error);
      });
  }

  
  guangdongAuth = () => {
    const { taxAuth } = this.props.taxAuthStore;
    Promise.all([this.getBaseFormDataP(), this.extraData()])
      .then(([baseData, extData]) => ({ ...baseData, ...extData }))
      .then((data) => {
        return this.verificationData()
          .then((texData) => ({ ...data, ...texData }))
          .catch(error => {
            const captchaError = get(error, 'captchaError');
            if (captchaError) { message.error('请先完成验证'); }
            return Promise.reject(error);
          });
      })
      .then((params) => this.checkAgreeProtocol(params))
      .then((params) => {
        this.setState({ isLoading: true });
        const postData = encryptedParams(params, ['username', 'password']);
        return taxAuth(postData)
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            // this.verificationRef.current.guangdongInitCaptcha();
            this.errorVerificationCb();
            return Promise.reject(error);
          })
      })
      .then(() => this.onFinishAuth())
      .catch(error => {
        // this.errorVerificationCb();
        console.log('error: ', error);
      })
  }

  commonAuth = () => {
    const { taxAuth } = this.props.taxAuthStore;
    return Promise.all([this.getBaseFormDataP(), this.extraData()])
      .then(([baseData, extData]) => ({ ...baseData, ...extData }))
      .then((params) => this.checkAgreeProtocol(params))
      .then((params) => {
        this.setState({ isLoading: true });
        const postData = encryptedParams(params, ['username', 'password']);
        return taxAuth(postData, this.NEW_AUTH)
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            return Promise.reject(error);
          })
      })
      .then(() => this.onFinishAuth())
      .catch(error => {
        console.log('error: ', error);
      })
  }

  // Mark: 授权方法

  onStartAuth = () => {
    const { province } = this.props.taxHelperStore.authQueryParams;
    if (province === '浙江' && !this.NEW_AUTH) {
      return this.zhejiangGetBsyList();
    }
    if (province === '广东' && !this.NEW_AUTH) {
      return this.guangdongAuth();
    }
    return this.commonAuth();
  }

  /**
   * 软件确认授权页面
   */
  onPluginAuth = () => {
    const { taxHelperStore, taxAuthStore } = this.props;
    const { pluginAuth } = taxAuthStore;
    this.getBaseFormDataP()
      .then((params) => this.checkAgreeProtocol(params))
      .then(() => {
        const params = { serialNo: taxHelperStore.getQueryParams('serialNo') }
        return pluginAuth(params)
          .then(() => {
            this.setState({ isLoading: false });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            throw error;
          })
      })
      .then(() => {
        this.onFinishAuth();
      })
      .catch((error) => {
        console.error('error--软件表单--->', error);
      });
  }

  /**
   * 完成授权之后，进行后续操作（只对授权完成的进行判断，授权错误，停留在当前状态即可）
   * 主要对于不同方式的税局授权进行处理，处理逻辑如下：
   * A. 电子税局
   *    授权完成以后，跳转到授权完成页（未实现）
   * B. 采集软件
   *    B-1：长沙软件授权通过后，弹窗提示启动软件（安装是在授权开始就弹出的）
   *    B-2：大象软件授权通过后，弹窗提示下载软件或启动软件
   *    B-3：微风企软件授权通过后，进入微风企的授权页面，后端返回对应的url地址
  */
  onFinishAuth = async (isTip = true) => {
    const { taxHelperStore } = this.props;
    const taxType = taxHelperStore.getTaxType();
    const workType = taxHelperStore.getTaxWorkType();
    const cburl = taxHelperStore.getTaxCburl();
    const serialNo = taxHelperStore.getQueryParams('serialNo') || '';
    const nsrsbh = taxHelperStore.getQueryParams('nsrsbh') || '';
    const qrmc = taxHelperStore.getQueryParams('qymc') || '';
    
    if (isTip) {
      await countDownModal({
        title: '提示',
        text: ' 内自动跳转',
        content: '为避免当前页面登录失效和数据采集中断，请您在15分钟内不要登录电子税局或其他设备！'
      })
    }
   
    if (workType === AUTH_TYPE_DICT.ELECTRON) {
      if (cburl) {
        window.location.href = cburl;
      } else {
        this.props.history.replace(`/authSuccess?serialNo=${serialNo}&&watch=true`);
      }
      return Promise.resolve();
    }
    if ([AUTH_TYPE_DICT.PLUGIN, AUTH_TYPE_DICT.ELECTRON_PLUGIN].includes(workType)) {
      // 大象
      if (['DX', 'DZ_DX'].includes(taxType)) {
        this.setState({
          downloadPlugClosable: false,
          downloadPlugVisible: true
        });
      }
      // 长沙
      if (['CS', 'DZ_CS'].includes(taxType)) {
        this.setState({ startPlugConfirmationVisible: true });
      }
      // 微风企
      if (['WFQ', 'DZ_WFQ'].includes(taxType)) {
        const outlink = taxHelperStore.getOutLink() || '';
        if (outlink) {
          window.location.href = outlink;
        } else {
          return Promise.reject(new Error(`${taxType} 没有获取到微风企外部地址`));
        }
      }
      // 爱信诺
      if (['AISINO', 'DZ_ANO'].includes(taxType)) {
        this.setState({ nextStep: true });
      }
      // 企数智查PRO
      if (['PRO', 'DZ_PRO'].includes(taxType)) {
        const { spsb } = toJS(taxHelperStore.authFormData);
        this.props.history.replace(`qszcResult?serialNo=${serialNo}&nsrsbh=${nsrsbh}&qrmc=${qrmc}&pluginType=${taxType}&spsb=${spsb}&taxType=${workType}`);
      }
      // 企数智查PRO_PLUS
      if (['PLUS', 'DZ_PLUS'].includes(taxType)) {
        const { spsb } = toJS(taxHelperStore.authFormData);
        this.props.history.replace(`qszcResult?serialNo=${serialNo}&nsrsbh=${nsrsbh}&qrmc=${qrmc}&pluginType=${taxType}&spsb=${spsb}&taxType=${workType}`);
      }
      return Promise.resolve();
    }
    return Promise.reject(new Error(`${taxType} 异常`));
  }

  handlePlugOnOk = () => {
    const isPluginDownload = this.state.isPluginDownload;
    this.setState({ isPluginDownload: false }, () => {
      if (isPluginDownload) {
        this.updateFormValue(this.props.taxHelperStore.authQueryParams);
      }
    });
  }

  handlePlugOnCancel = () => {
    this.setState({ downloadPlugVisible: true });
  }

  openEXE = () => {
    const { taxHelperStore } = this.props;
    taxHelperStore.getCSEncode()
      .then((text) => {
        const url = `TaxRobot:${text}`;
        return linkTo(url);
      });
  }

  hanldeAuthProtocol = (isAgree) => {
    this.setState({ agreeAuthProtocol: isAgree });
  }

  /** 渲染电子税局授权表单 */
  renderElectronTaxFormItems = () => {
    const { taxHelperStore } = this.props;
    const workType = taxHelperStore.getTaxWorkType();
    const taxType = taxHelperStore.getTaxType();
    const province = taxHelperStore.getProvincePingYing();

    let electronTaxComponent = electronTaxComponentConfigMap.get(province);
    // 旧版则用旧的配置
    if (!this.NEW_AUTH) {
      electronTaxComponent = old_electronTaxComponentConfigMap.get(province);
    }

    /** 如果是电子税局加PRO插件表单添加开票软件设备类型选择 */
    if (workType === AUTH_TYPE_DICT.ELECTRON_PLUGIN && NEED_SPSB_TYPE.includes(taxType)) {
      electronTaxComponent = [...electronTaxComponent, 'spsb'];
    }

    if (!electronTaxComponent) {
      return null;
    }
    return this.getTaxAuthForm(electronTaxComponent);
  }

  /** 渲染软件授权表单 */
  renderPluginTaxFormItems = () => {
    const { taxHelperStore } = this.props;
    const taxType = taxHelperStore.getTaxType();
    const pluginTaxComponent = pluginTaxComponentConfigMap.get(taxType);
    if (!pluginTaxComponent) {
      return null;
    }
    return this.getTaxAuthForm(pluginTaxComponent);
  }

  renderTaxFormitems = () => {
    const { taxHelperStore } = this.props;
    const workType = taxHelperStore.getTaxWorkType();
    /** 电子税局加插件或纯电子税局情况 */
    if ([AUTH_TYPE_DICT.ELECTRON_PLUGIN, AUTH_TYPE_DICT.ELECTRON].includes(workType)) {
      return this.renderElectronTaxFormItems();
    }

    /** 纯插件情况 */
    if (workType === AUTH_TYPE_DICT.PLUGIN) {
      return this.renderPluginTaxFormItems();
    }

    return null;
  }

  renderVerification = () => {
    const { taxHelperStore } = this.props;
    const workType = taxHelperStore.getTaxWorkType();
    const provinceKey = taxHelperStore.getProvincePingYing();

    // 此处不应该会出现 workType = NONE 的情况，initTax 已经做好了拦截
    const isPlugin = AUTH_TYPE_DICT.PLUGIN === workType;
    // 进入下一级表单（表示电子税局授权已完成）将不再需要验证器
    const { nextStep } = this.state;

    if (isPlugin || nextStep) {
      return null;
    }
    const { taxAuth } = this.props.taxAuthStore;
    return <Verification
      ref={this.verificationRef}
      taxAuth={taxAuth}
      NEW_AUTH={this.NEW_AUTH}
      onFinishAuth={this.onFinishAuth}
      params={this.props.taxHelperStore.authQueryParams}
      provinceKey={provinceKey}
      baseFormValidate={this.baseFormData}
    />
  }

  renderActionButton = () => {
    const { taxHelperStore } = this.props;
    const workType = taxHelperStore.getTaxWorkType();
    const shouldNext = taxHelperStore.shouldNextStep();
    const taxType = taxHelperStore.getTaxType();
    const { nextStep } = this.state;

    // 此处不应该会出现 workType = NONE 的情况，initTax 已经做好了拦截
    const isPlugin = AUTH_TYPE_DICT.PLUGIN === workType;

    const submitAuthHandler = isPlugin ? this.onPluginAuth : this.onStartAuth;
    
    if (shouldNext && !nextStep) {
      return (
        <>
          <Button
            block
            size="large"
            type="primary"
            className={styles.btn}
            loading={this.state.isLoading}
            onClick={submitAuthHandler}
          >下一步</Button>
          <SwitchVersion />
        </>
      )
    }

    const unSubmitTaxType = ['DZ_ANO', 'AISINO'];
    if (unSubmitTaxType.includes(taxType) && shouldNext) {
      return null;
    }

    return (
      <>
        <Button
          className={styles.btn}
          type="primary"
          size="large"
          onClick={submitAuthHandler}
          block
          loading={this.state.isLoading}
        >确认</Button>
        <SwitchVersion />
      </>
    )
  }

  renderForm = () => {
    const { agreeAuthProtocol, nextStep, inputSize, params } = this.state;
    const { taxHelperStore } = this.props;
    const province = taxHelperStore.getProvincePingYing();
    const taxType = taxHelperStore.getTaxType();
    const workType = taxHelperStore.getTaxWorkType();

    if (['AISINO', 'DZ_ANO'].includes(taxType)) {
      const workType = taxHelperStore.getTaxWorkType();
      const isPlugin = AUTH_TYPE_DICT.PLUGIN === workType;
      if (isPlugin || nextStep) {
        return (
          <div className={styles.form}>
            <HxTaxBureau {...this.props} inputSize={inputSize} params={params} taxType={workType} />
          </div>
        )
      }
    }

    let provinceList = [
      PROVINCE_MAP.get('四川').py,
      PROVINCE_MAP.get('宁夏').py,
      PROVINCE_MAP.get('青海').py,
      PROVINCE_MAP.get('新疆').py,
      PROVINCE_MAP.get('山西').py,
      PROVINCE_MAP.get('大连').py,
      PROVINCE_MAP.get('内蒙古').py,
      PROVINCE_MAP.get('黑龙江').py,
      PROVINCE_MAP.get('云南').py,
      PROVINCE_MAP.get('海南').py,
      PROVINCE_MAP.get('安徽').py,
      PROVINCE_MAP.get('吉林').py,
      PROVINCE_MAP.get('陕西').py,
      PROVINCE_MAP.get('江苏').py,
      PROVINCE_MAP.get('贵州').py,
      PROVINCE_MAP.get('辽宁').py,
      PROVINCE_MAP.get('北京').py,
      PROVINCE_MAP.get('河南').py,
      PROVINCE_MAP.get('甘肃').py,
      PROVINCE_MAP.get('青岛').py,
      PROVINCE_MAP.get('厦门').py,
      PROVINCE_MAP.get('河北').py,
      PROVINCE_MAP.get('宁波').py,
      PROVINCE_MAP.get('重庆').py,
      PROVINCE_MAP.get('西藏').py,
      PROVINCE_MAP.get('广西').py,
      PROVINCE_MAP.get('江西').py,
      PROVINCE_MAP.get('深圳').py,
      PROVINCE_MAP.get('湖南').py,
      PROVINCE_MAP.get('山东').py,
      PROVINCE_MAP.get('天津').py,
      PROVINCE_MAP.get('上海').py,
      PROVINCE_MAP.get('福建').py
    ]

    // 新版本才使用配置表单
    if (this.NEW_AUTH) {
      provinceList = provinceList.concat([
        PROVINCE_MAP.get('广东').py,
        PROVINCE_MAP.get('浙江').py,
      ])
    }

    if (provinceList.includes(province) && workType !== AUTH_TYPE_DICT.PLUGIN) {
      return (
        <TaxBureauStep onFinishAuth={this.onFinishAuth} NEW_AUTH={this.NEW_AUTH} />
      );
    }

    return (
      <div className={styles.form}>
        <Form
          layout="vertical"
          ref={this.infoFormRef}
        > 
          {this.renderTaxFormitems()}
        </Form>
        {this.renderVerification()}
        <TaxAuthProtocol
          isAgree={agreeAuthProtocol}
          isOnlyAuthProtocol={['DZ_ANO'].includes(taxType) && !nextStep}
          onChange={this.hanldeAuthProtocol}
        >
          {this.renderActionButton()}
        </TaxAuthProtocol>
      </div>
    );
  }

  showRef = () => {
    const jiangxiInstens = this.JiangxiRef.current;
    jiangxiInstens.scPublicValidateFields().then((data) => {
      console.log('data: ', data);
    }).catch((error) => {
      console.log('error: ', error);
    });
  }

  downloadPlugOnCancel = () => {
    this.setState({ downloadPlugVisible: false });
  }

  plugConfirmationNotInstallClick = () => {
    this.setState({
      downloadPlugVisible: true,
      plugConfirmationVisible: false
    });
  }

  plugConfirmationFooter = () => {
    return (
      <div className={styles.plugConfirmationFooter}>
        <Space size="large">
          <Button onClick={this.plugConfirmationNotInstallClick}>未安装</Button>
          <Button type="primary">已安装，去授权</Button>
        </Space>
      </div>
    );
  }
  // 重定向到软件下载地址
  redirectDownload = () => {
    const { taxHelperStore, taxAuthStore } = this.props;
    const url = taxAuthStore.pluginConfig.exeUrl;
    if (!url) {
      console.error(`没有 ${taxHelperStore.getTaxType()} 对应的资源文件`);
      return false;
    }
    downloadFile(url);
  }

  // 重定向到Ukey下载地址
  redirectDownloadUkey = () => {
    const { taxHelperStore, taxAuthStore } = this.props;
    const url = taxAuthStore.pluginConfig.ukUrl;
    if (!url) {
      console.error(`没有 ${taxHelperStore.getTaxType()} 对应的资源文件`);
      return false;
    }
    downloadFile(url);
  }

  // 重定向到帮助的下载地址
  redirectHelperDownload = () => {
    const { taxHelperStore, taxAuthStore } = this.props;
    const url = taxAuthStore.pluginConfig.helperUrl;
    if (!url) {
      console.error(`没有 ${taxHelperStore.getTaxType()} 对应的资源文件`);
      return false;
    }
    downloadFile(url, '_blank');
  }

  renderAlert() {
    const { shouldNoticeQuickAuthorize } = this.state;
    if (!shouldNoticeQuickAuthorize) {
      return <div className={styles.alert}></div>;
    }
    return (
      <div className={styles.alert}>
        <Alert
          showIcon
          message="请在15分钟内完成操作，以获取更多优质服务"
          type="warning"
        />
      </div>
    )
  }

  renderAuthForm = () => {
    const { nextStep } = this.state;

    const { taxHelperStore } = this.props;
    const province = taxHelperStore.getProvincePingYing();
    const taxType = taxHelperStore.getTaxType();
    if (province === 'hu_bei' &&
      (['DZ_SJ', 'DZ_DX', 'DZ_CS', 'DZ_WFQ', 'DZ_PRO', 'DZ_PLUS'].includes(taxType) || (['DZ_ANO'].includes(taxType) && nextStep !== true))
    ) {
      return (
        <HubeiEleAuthWrap onFinishAuth={this.onFinishAuth} NEW_AUTH={this.NEW_AUTH}>
          {this.renderForm()}
        </HubeiEleAuthWrap>
      );
    }
    if (province === 'an_hui' && !this.NEW_AUTH  &&
      (['DZ_SJ', 'DZ_DX', 'DZ_CS', 'DZ_WFQ', 'DZ_PRO', 'DZ_PLUS'].includes(taxType) || (['DZ_ANO'].includes(taxType) && nextStep !== true))
    ) {
      return (
        <AnhuiEleAuthWrap onFinishAuth={this.onFinishAuth} NEW_AUTH={this.NEW_AUTH}>
          {this.renderForm()}
        </AnhuiEleAuthWrap>
      );
    }
    return this.renderForm();
  }
  render() {
    const { taxHelperStore } = this.props;
    const { isPluginDownload, downloadPlugVisible, plugConfirmationVisible, startPlugConfirmationVisible, downloadPlugClosable } = this.state;
    const taxType = taxHelperStore.getTaxType();
  
    if (this.state.isGetInitPage) {
      return null;
    }
    console.log(this.state.value);
    return (
      <>
        <WhiteBox>
          <TicketTitle {...this.props} />
          {this.renderAlert()}
          {
            isPluginDownload ?
              <PlugConfirmation onOk={this.handlePlugOnOk} onCancel={this.handlePlugOnCancel} /> :
              this.renderAuthForm()
          }
        </WhiteBox>
        <Modal
          title="下载软件"
          visible={downloadPlugVisible}
          footer={null}
          keyboard={false}
          closable={downloadPlugClosable}
          maskClosable={false}
          onCancel={this.downloadPlugOnCancel}
        >
          {
            'AISINO' === taxType &&
            <span className={styles.downloadPlugTextTip}>
              请注意：信誉宝软件必下，如开票软件为Ukey，还需下载Ukey软件
            </span>
          }
          <div className={styles.downloadPlugText}>
            <span>信誉宝软件</span>
            <div className={styles.downloadPlugTextIcon} onClick={this.redirectDownload}></div>
          </div>
          {
            'AISINO' === taxType &&
            <div className={styles.downloadPlugText}>
              <span>Ukey软件</span>
              <div className={styles.downloadPlugTextIcon} onClick={this.redirectDownloadUkey}></div>
            </div>
          }
          <div className={styles.downloadPlugText}>
            <span>采票软件安装及操作手册</span>
            <div className={styles.downloadPlugTextIcon} onClick={this.redirectHelperDownload}></div>
          </div>
        </Modal>
        <Modal
          title="授权认证"
          visible={plugConfirmationVisible}
          keyboard={false}
          maskClosable={false}
          closable={false}
          footer={this.plugConfirmationFooter()}
        >
          <div className={styles.plugConfirmation}>
            <div className={styles.plugConfirmationTitle}>软件状态确认</div>
            <div className={styles.plugConfirmationDescription}>请先确认采票软件状态，否则可能会导致采票失败</div>
          </div>
        </Modal>
        <Modal
          visible={startPlugConfirmationVisible}
          keyboard={false}
          maskClosable={false}
          closable={false}
          footer={null}
        >
          <div className={styles.startPlugConfirmation}>
            <div className={styles.title}>授权成功</div>
            <div className={styles.content}>
              <div className={styles.downloadPlugText}>
                <span>1、如果<span className={styles.highlight}> 已安装 </span>软件，直接点击“启动软件”</span>
              </div>
              <div className={styles.downloadPlugText}>
                <span>
                  <span>1、如果<span className={styles.highlight}> 未安装 </span>软件，请先 </span>
                  <span onClick={this.redirectDownload} className={styles.link}>下载</span>
                  <span> 并安装软件，再点击“启动软件”</span>
                </span>

              </div>
              <div className={styles.actionWrapper}>
                <Button type="primary" className={styles.actionButton} onClick={this.openEXE}>启动软件</Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

ElectronicTaxBureau.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

const ElectronicTaxBureauObserver = inject(
  'taxAuthStore',
  'anhuiQrAuthStore',
  'hubeiSPLoginStore',
  'hxTaxBuresuStore',
  'taxHelperStore',
  'commonStore'
)(observer(ElectronicTaxBureau));

export default ShowOnMobile(ElectronicTaxBureauObserver);
